import { Pipe, PipeTransform } from '@angular/core';
// import { formatNumber } from '@angular/common';

@Pipe({
  name: 'decimalWrapper'
})
export class DecimalWrapperPipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if (!value) {
      return '0';
    }

    const [ integer, decimal ] = value.toString().split('.');
    // const formattedInteger = `${formatNumber(+integer, 'fr-FR')}`;

    return (decimal) ? integer.concat(`,<small>${decimal}</small>`) : integer;
  }

}
