<section class="page">
  <div class="page__content">
    <div class="top">
      <div class="stepper">
        <img class="stepper__img" ngSrc="../../../../assets/custom-icons/topup-binance-account-step2.svg"
             alt="step2" height="24"
             width="113">

        <span class="stepper__description">{{ 'topup.binance.connect_details_stepText' | translate }}</span>
      </div>

      <div class="text">
        <h2 class="text__title">{{ 'topup.binance.connect_details_text_area_header' | translate }}</h2>

        <p class="text__description">{{ 'topup.binance.connect_details_text_area_paragraph' | translate }}</p>
      </div>

      <form [formGroup]="form" class="form">
        <ion-list>
          <ion-item class="form__item" lines="none">
            <ion-input formControlName="clientName"
                       [label]="'topup.binance.connect_firstStep_ClientName_label' | translate"
                       labelPlacement="stacked"></ion-input>
          </ion-item>

          <ion-item class="form__item" lines="none">
            <ion-select
                class="always-flip"
                interface="popover"
                labelPlacement="stacked"
                toggleIcon="chevron-down-sharp"
                formControlName="accountNumber"
                [label]="'topup.binance.connect_details_account_label' | translate"
                [placeholder]="'topup.binance.connect_details_account_placeholder' | translate">
              <ion-select-option *ngFor="let option of accountsList"
                                 [value]="option.id">{{ option.number }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item class="form__item" lines="none">
            <ion-select
                class="always-flip"
                formControlName="currency"
                interface="popover"
                labelPlacement="stacked"
                toggleIcon="chevron-down-sharp"
                [label]="'topup.binance.connect_details_currency_label' | translate"
                [placeholder]="'topup.binance.connect_details_currency_placeholder' | translate">
              <ion-select-option *ngFor="let option of options"
                                 [value]="option.value">{{ option.text }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
              [ngClass]="{'form__item_error': showError('amount')}"
              class="form__item" lines="none">
            <ion-input
                formControlName="amount"
                [label]="'topup.binance.connect_details_amount_label' | translate"
                [placeholder]="'topup.binance.connect_details_amount_placeholder'  | translate"
                inputmode="decimal"
                (ionInput)="removeSpacesCommasAndLetters()"
                labelPlacement="stacked"></ion-input>
          </ion-item>

          <span
              *ngIf="showError('amount')"
              class="form__err-message">{{ getErrorMessages | translate }}</span>
        </ion-list>
      </form>
    </div>

    <div class="bottom">
      <div class="buttons-container">
        <ion-button routerLink="/top-up-account" size="large" expand="full" shape="round" fill="outline" color="medium">
          {{ 'topup.binance.connect_cancelButton' | translate }}
        </ion-button>

        <ion-button (click)="onSubmit()" [disabled]="form.invalid" size="large" expand="full" shape="round"
                    fill="outline" color="success">
          <ion-icon slot="start" name="arrow-green"></ion-icon>
          {{ 'topup.binance.connect_nextButton' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</section>
