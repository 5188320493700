import { Component, Input, OnInit } from '@angular/core';

import { HistoryOperationType, MoneyOperation } from '../../../users/operations/operations.interface';
import { ROLES } from '../../../users/users.roles';

@Component({
  selector: 'app-transfer-recipe',
  templateUrl: './transfer-recipe.component.html',
  styleUrls: ['./transfer-recipe.component.scss']
})
export class TransferRecipeComponent implements OnInit {

  @Input() operation?: MoneyOperation;

  roles = ROLES;

  operationType: HistoryOperationType;

  constructor() { }

  ngOnInit() {
    if (this.operation) {
      this.operationType = (
        (this.operation.openapiData?.type === 'TO_CARD' || this.operation.openapiData?.type === 'INTERNAL') &&
        this.operation.openapiData.subType === 'TOPUP' && this.operation.amount > 0)
        ? 'TOPUP'
        : (this.operation.openapiData?.type === 'TO_CARD')
          ? 'TO_CARD'
          : (this.operation.paymentType === 'SWIFT')
            ? 'SWIFT'
            : (
                // this.operation.amount < 0 &&
                (this.operation.paymentType === 'TRANSFER' || this.operation.paymentType === 'SEPA' || this.operation.paymentType === 'UNKNOWN')
              )
              ? 'SEPA'
              : (this.operation.amount < 0 && this.operation.paymentType === 'CARD_OPERATION' &&
                (this.operation.operationType === null || (this.operation.operationType !== '6011' && this.operation.operationType !== '6010')))
                ? 'PURCHASE'
                : (this.operation.amount < 0 && this.operation.paymentType === 'CARD_OPERATION' &&
                  (this.operation.operationType === '6011' || this.operation.operationType === '6010'))
                  ? 'CASH_WITHDRAWAL'
                  : null;
    }
    console.log('operationType', this.operationType);
  }

}
