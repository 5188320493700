import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { map, finalize, mergeMap } from 'rxjs/operators';

import {
  ExchangeRates, CurExchangePayload, CurExchangePayloadConfirm, CurExchangeSuccessResp, CurExchangeConfirmCodeResp
} from './currency-exchange.interfaces';

export interface RatesResp {
  rates: ExchangeRates[];
}

@Injectable({
  providedIn: 'root'
})
export class CurrencyExchangeService {

  currencyConfirmData?: any;

  private isLoadingSource = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSource.asObservable();

  static getRate(rateList: ExchangeRates[], currencyFrom: string, currencyTo: string, exchangeAmount: number): number {
    const activeRate = rateList
      .filter(rate => rate.currencyFrom === currencyFrom)
      .find(rate => rate.currencyTo === currencyTo);
    if (!activeRate) { return 0; }
    return exchangeAmount
      ? activeRate.rate
      : ( (+exchangeAmount > activeRate.fromAmount4) && (activeRate.fromAmount4 !== 0) )
        ? activeRate.rate4
        : ( (+exchangeAmount > activeRate.fromAmount3) && (activeRate.fromAmount3 !== 0) )
          ? activeRate.rate3
          : ( (+exchangeAmount > activeRate.fromAmount2) && (activeRate.fromAmount2 !== 0) )
            ? activeRate.rate2
            : activeRate.rate;
  }

  constructor(
    private http: HttpClient) {
  }

  requestExchangeRates(accountId: string): Observable<ExchangeRates[]> {
    const url = environment.baseApi + `/v1/deals/conversion/rates/` + accountId;
    this.setLoadingStatus(true);
    return this.http.get<RatesResp>(url).pipe(
      map(response => response.rates),
      mergeMap(rates => (rates.length) ? of(rates) : throwError(() => new Error('No exchange rate assigned to account'))),
      finalize(() => this.setLoadingStatus(false)),
    );
  }

  setConfirmData(currencyConfirmData: CurExchangePayload) {
    this.currencyConfirmData = currencyConfirmData;
  }

  getCurrencyConfirmData(): CurExchangePayload {
    return this.currencyConfirmData;
  }

  configureConfirmObject(
    debitAccountNumber: string,
    creditAccountNumber: string,
    amountSell: number,
    currencyCodeSell: string,
    amountBuy: number,
    currencyCodeBuy: string,
    rate: number,
    accountId: string
  ): CurExchangePayloadConfirm {
    return {
      debitAccountNumber,
      creditAccountNumber,
      amountSell,
      currencyCodeSell,
      amountBuy,
      currencyCodeBuy,
      rate,
      accountId
    };
  }

  initCurrencyExchange(payload: CurExchangePayloadConfirm): Observable<CurExchangeConfirmCodeResp> {
    const url = environment.baseApi + `/v1/deals/conversion`;
    this.setLoadingStatus(true);
    return this.http.post<CurExchangeConfirmCodeResp>(url, payload)
      .pipe(
        finalize(() => this.setLoadingStatus(false))
      );
  }

  confirmCurrencyExchange(payload: CurExchangePayloadConfirm): Observable<CurExchangeSuccessResp> {
    console.log('payload', payload);
    const url = environment.baseApi + `/v1/deals/conversion`;
    const headers = new HttpHeaders({
      'X-Confirmation-Code': payload.confCode,
      'X-Confirmation-Token': payload.confToken
    });
    this.setLoadingStatus(true);
    return this.http.post<CurExchangeSuccessResp>(url, payload, {headers})
      .pipe(
        finalize(() => this.setLoadingStatus(false))
      );
  }

  private setLoadingStatus(value: boolean): void {
    const currentValue = this.isLoadingSource.getValue();
    if (value !== currentValue) {
      this.isLoadingSource.next(value);
    }
  }

}

