<div [ngClass]="customClass">
  <section class="select-custom" 
          [ngClass]="{
            isOpen: !!dropDawnIsOpen,
            isClose: !dropDawnIsOpen,
            isDisable: isDisable,
            hasErrors: hasErrors
          }">

      <div class="flex-row">
        <div class="label-wrap">
          <ng-content select="label"></ng-content>
        </div>
        <div class="placeholder-wrap">
          <ng-content select=".placeholder"></ng-content>
        </div>
      </div>

      <div *ngIf="dropDawnIsOpen" class="dropdown-wrapper">
        <div
          class="dropdown-list customScrollbar"
          [ngClass]="{ 'custom-height': customHeight }"
          [ngStyle]="{ 'height': customHeight }">
            <ng-content></ng-content>
        </div>
      </div>

  </section>
</div>
