<ion-app>
	<ion-header class="ion-no-border">
		<ion-toolbar>
			<ion-title>Edit</ion-title>
			<ion-icon slot="start" name="back"
        (click)="goBack()">
      </ion-icon>
		</ion-toolbar>
	</ion-header>
	<ion-content class="ion-padding">
		<div class="TemplatesEdit">
			<ion-list class="TemplatesEdit-list">
				<ion-item class="TemplatesEdit-item">
					<ion-label class="ion-text-wrap">
						<p>Template name</p>
						<span class="TemplatesEdit-item-value">Ann Swan</span>
					</ion-label>
				</ion-item>
				<ion-item class="TemplatesEdit-item">
					<ion-label class="ion-text-wrap">
						<p>From your card</p>
						<span class="TemplatesEdit-item-value">2775 76** **** 0229</span>
					</ion-label>
				</ion-item>
				<ion-item class="TemplatesEdit-item">
					<ion-label class="ion-text-wrap">
						<p>To card</p>
						<span class="TemplatesEdit-item-value">6578 98** **** 7007</span>
					</ion-label>
				</ion-item>
				<ion-item class="TemplatesEdit-item">
					<ion-label class="ion-text-wrap">
						<p>Card owner name</p>
						<span class="TemplatesEdit-item-value">Olishia Farmery</span>
					</ion-label>
				</ion-item>
				<ion-item class="TemplatesEdit-item">
					<ion-label class="ion-text-wrap">
						<p>Comment</p>
						<span class="TemplatesEdit-item-value">Thank u dude)</span>
					</ion-label>
				</ion-item>
			</ion-list>
			<div class="TemplatesEdit-bottom">
				<ion-item class="page-group transfer-amount">
					<ion-label slot="start">Amount</ion-label>
					<div slot="end" class="page-group__value">
					  <ion-input value="300" placeholder="0.00"></ion-input>
					  <span>EUR</span>
					  <!-- <ion-select [interfaceOptions]="optionsCurrency" interface="popover">
						<ion-select-option value="EUR" selected>EUR</ion-select-option>
					  </ion-select> -->
					</div>
				</ion-item>
				<div class="TemplatesEdit-buttons">
					<ion-button class="buttonDelete" expand="full" shape="round" fill="outline" color="danger" size="small">Delete Template</ion-button>
					<ion-button class="buttonSave" expand="full" shape="round" fill="outline" color="success" size="small">Save</ion-button>
				</div>
			</div>
		</div>
	</ion-content>
</ion-app>
