import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountrySelectComponent } from './country-select/country-select.component';
import { CountrySelectItemComponent } from './country-select-item/country-select-item.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';

@NgModule({
  declarations: [
    CountrySelectComponent,
    CountrySelectItemComponent,
  ],
  exports: [
    CountrySelectComponent
  ],
  imports: [
    CommonModule,
    InternationalizationModule,
  ]
})
export class CountrySelectModule { }
