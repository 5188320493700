<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-title *ngIf="transferType === 'webLinkInfo'; else phoneNumber">{{ 'currency-exchange.info_title' | translate }}</ion-title>
      <ng-template #phoneNumber>
        <ion-title>{{ 'howItWorks.title' | translate }}</ion-title>
      </ng-template>
    </ion-toolbar>
  </ion-header>

  <ion-content *ngIf="transferType === 'webLinkInfo'; else phoneNumberInfo" class="Info ion-padding">
<!--    <p>Members can transfer money from their <b>Black Cat Card</b> accounts directly into another member’s account,-->
<!--      and the money is available immediately. Nonmembers can transfer money from any financial institution, and-->
<!--      funds take about four business days to arrive. Need to pay a friend or relative and don’t have cash or a-->
<!--      check on you? Just use <b>Transfer by Web Link</b>.-->
<!--      It&apos;s free, easy, convenient and secure.</p>-->
    <p> {{ 'info_transfer_conf.first_text' | translate }} {{ 'info_transfer_conf.second_text' | translate }}</p>
  </ion-content>
  <ng-template #phoneNumberInfo>
    <ion-content class="Info ion-padding">
      <p>{{ 'info_transfer_conf.third_text' | translate }}.</p>
      <p *appPermission="[ roles.BCC_INDIVIDUAL_PERSON, roles.BCC_LEGAL_PERSON ]">{{ 'info_transfer_conf.fourth_text' | translate }}.</p>
    </ion-content>
  </ng-template>
</ion-app>
