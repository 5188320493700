import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, merge, Observable, Subscription } from 'rxjs';
import { CurrencyExchangeService } from '../../+currency-exchange/currency-exchange.service';
import {
  BankPaymentType,
  MoneyOperation,
  MoneyOperationRequest,
  PaymentOperationTemplate
} from '../../users/operations/operations.interface';
import { concatAll, filter, finalize, first, flatMap, map, pluck, switchMap, tap, toArray } from 'rxjs/operators';
import { OrganizationsService } from '../../users/organizations/organizations.service';
import { OperationRequestModel, OperationsService } from '../../users/operations/operations.service';
import { Account, TrustedClient } from '../../users/organizations.interface';
import { RightContainerService } from '../right-container.service';
import { clone } from 'lodash';
import { MovementMoneyHistoryComponent } from '../transfers-history/movement-money-history/movement-money-history.component';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CalendarSingleSelectComponent } from '../../ui-elements/calendars/calendar-single-select/calendar-single-select.component';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-exchange-money-history',
  templateUrl: './exchange-money-history.component.html',
  styleUrls: ['./exchange-money-history.component.scss']
})
export class ExchangeMoneyHistoryComponent implements OnInit, OnDestroy {

  dateFrom?: number;
  dateTo?: number;

  isLoading = true;

  paymentType: BankPaymentType = 'CONVERSION';

  filterIsOpen = false;

  trustedClients: Array<TrustedClient> = [];
  operationsArray?: Array<{ date: string; operations: Array<any> }>;

  private loadedPage = 0;
  private loadedPageMax = 0;

  private subscribe?: Subscription;

  readonly isLoading$: Observable<boolean> = this.organizations.isLoading$
    .pipe(
      switchMap(() => this.operations.isLoading$)
    );

  readonly operationList$ = this.operations.operations$
    .pipe(
      map(operations => this.operations.getModifiedOperations(operations)),
      // tap((res) => console.log(11111111, res))
    );

  private static getOperationRequestModel(
    accounts: Array<Account>,
    paymentType: BankPaymentType,
    dateFrom: number,
    dateTo: number,
    page = 0 ): OperationRequestModel {
    return {
      accountsIds: accounts.map(account => account.id),
      paymentType: paymentType,
      dateFrom: dateFrom,
      dateTo: dateTo,
      page: page
    };
  }

  constructor(
    private currency: CurrencyExchangeService,
    private organizations: OrganizationsService,
    private operations: OperationsService,
    private cdr: ChangeDetectorRef,
    private rightContainer: RightContainerService,
    private popoverController: PopoverController,
  ) { }

  ngOnInit() {
    this.subscribe = this.organizations.activeTrustedClients$
      .pipe(
        filter(trustedClients => !!trustedClients.length),
        tap( trustedClients => {
          this.trustedClients = clone(trustedClients);
          this.cdr.markForCheck();
        }),
        flatMap(() => this.organizations.isLoading$),
        filter(isLoading => !isLoading),
        first(),
        flatMap(() => this.organizations.getAccountListForClients(this.trustedClients)),
        map(accounts => ExchangeMoneyHistoryComponent.getOperationRequestModel(accounts, this.paymentType, this.dateFrom, this.dateTo)),
        flatMap(operationRequestModel => this.operations.requestOperations(operationRequestModel)),
        pluck('content'),
        concatAll(),
        filter(item => !!item.amount),
        toArray()
      )
      .subscribe((response: Array<MoneyOperation>) => {
        this.isLoading = false;
        this.operationsArray = this.operations.getModifiedOperations(response);
        // console.log('operationsArray', this.operationsArray);
        // this.loadedPageMax = response.maxPageNumber;
      });
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  switchHistoryFilter() {
    this.filterIsOpen = !this.filterIsOpen;
  }

  clearHistoryFilter() {
    this.dateFrom = null;
    this.dateTo = null;
    this.loadedPageMax = 1;
    this.getOperationList(null, null)
      .subscribe((response) => {
        // this.loadedPageMax = response.maxPageNumber;
        this.operationsArray = this.operations.getModifiedOperations(response);
      });
  }

  // setAccount(account: Account) {
  //   this.operations.requestOperations({ accountsIds: [ account.id ], dateFrom: this.dateFrom, dateTo: this.dateTo })
  //     .subscribe(operationsData => {
  //       this.loadedPageMax = operationsData.maxPageNumber;
  //       this.operations.setOperations(operationsData.content);
  //     });
  // }

  async onSetDataStart(ev: Event) {
    const popover = await this.popoverController.create({
      cssClass: 'popover-history-filter popover-start',
      component: CalendarSingleSelectComponent,
      backdropDismiss: true,
      event: ev,
    });
    popover.onDidDismiss()
      .then(result => {
        if (result.data) {
          this.dateFrom = result.data;
          this.getOperationList(this.dateFrom, this.dateTo)
            .subscribe((response) => {
              // this.loadedPageMax = response.maxPageNumber;
              this.operationsArray = this.operations.getModifiedOperations(response);
            });
        }
      });
    return await popover.present();
  }

  async onSetDataEnd(ev: Event) {
    const popover = await this.popoverController.create({
      cssClass: 'popover-history-filter popover-end',
      component: CalendarSingleSelectComponent,
      backdropDismiss: true,
      event: ev,
    });
    popover.onDidDismiss()
      .then(result => {
        if (result.data) {
          this.dateTo = result.data;
          this.getOperationList(this.dateFrom, this.dateTo)
            .subscribe((response) => {
              // console.log('response', response);
              // this.loadedPageMax = response.maxPageNumber;
              this.operationsArray = this.operations.getModifiedOperations(response);
            });
        }
      });
    return await popover.present();
  }

  repeatOperation(event) {
    console.log('repeat event', event);
  }

  // doInfinite(event) {
  //   console.log('Begin async operation'/*, this.loadedPageMax, this.loadedPage*/);
  //   // this.loadedPage = ++this.loadedPage;
  //   if (this.loadedPage <= this.loadedPageMax) {
  //     this.getOperationList(this.dateFrom, this.dateTo, this.loadedPage + 1)
  //       .subscribe(([ operationsData, currentOperationList ]) => {
  //         this.loadedPageMax = operationsData.maxPageNumber;
  //         this.loadedPage = operationsData.pageNumber;
  //         const operationList = currentOperationList.concat(operationsData.content);
  //         event.target.complete();
  //         this.operations.setOperations(operationList);
  //       });
  //   } else {
  //     event.target.complete();
  //   }
  // }

  private getOperationList(dateFrom: number, dateTo: number) {
    return this.organizations.getAccountListForClients(this.trustedClients)
      .pipe(
        map(accounts => ExchangeMoneyHistoryComponent.getOperationRequestModel(accounts, this.paymentType, dateFrom, dateTo)),
        flatMap( operationRequestModel => this.operations.requestOperations(operationRequestModel)),
        pluck('content'),
        concatAll(),
        filter(item => !!item.amount),
        toArray()
      );
  }

}
