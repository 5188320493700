import { Injectable } from '@angular/core';
import { ModalsService } from './modals.service';

import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';

@Injectable({
  providedIn: 'root'
})
export class ComingSoonModalsService {

  constructor(
    private modals: ModalsService) {
  }

  openModal() {
    return this.modals.openModal({
      component: ComingSoonComponent,
      cssClass: 'ComingSoon'
    });
  }

}
