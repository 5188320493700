import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringClipping'
})
export class StringClippingPipe implements PipeTransform {

  transform(value: string, args?: string /* format - XX:XX:XX */): string {
    // console.log(value, args);
    const [ startNumberCharacters, lastNumberCharacters, totalNumberCharacters ] = args ? args.split(':') : [ null, null, null ];

    const firstCharacters = value.substring(0, +startNumberCharacters);
    const lastCharacters = value.substring(value.length - Number(lastNumberCharacters), value.length);
    // console.log({ firstCharacters: firstCharacters, lastCharacters: lastCharacters });

    const balance = Number(totalNumberCharacters) - Number(startNumberCharacters) - Number(lastNumberCharacters);
    const missingCharacters = new Array(balance).join('*');

    return (args) ? firstCharacters + missingCharacters + lastCharacters : value;
  }

}
