import { Component, OnInit } from '@angular/core';

import {RightContainerService} from '../right-container.service';

@Component({
  selector: 'app-info-crypto-deposit',
  templateUrl: './info-crypto-deposit.component.html',
  styleUrls: ['./info-crypto-deposit.component.scss']
})
export class InfoCryptoDepositComponent implements OnInit {

  constructor(private rightContainer: RightContainerService) { }

  ngOnInit() {
  }

}
