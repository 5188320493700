import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges,
  ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { clone } from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import { map, switchMap, concatAll, filter, toArray } from 'rxjs/operators';

import { OrganizationsService } from 'app/users/organizations/organizations.service';
import { TrustedClient, Account, OperationType, AccountType } from 'app/users/organizations.interface';

interface TrustedClientExtended extends TrustedClient {
  accounts: Array<Account>;
}

const CLIPPING_ARGUMENT = '2:13:20';

@Component({
  selector: 'app-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSelectComponent implements OnInit, OnChanges, OnDestroy {

  @Input() operationType!: OperationType | OperationType[];
  @Input() accountType!: AccountType[];

  @Input() labelName = 'Account';
  @Input() customHeight!: string; // provide property for custom select component
  @Input() customClass!: string;

  @Input() shortLabel = false;
  @Input() shortNameInList = false;
  @Input() hasErrors = false;

  @Input() selectAccountById!: string;

  @Output() selectAccount = new EventEmitter<Account>();
  @Output() emptyAccounts = new EventEmitter();

  accountListIsEmpty = false;

  clippingArgumentForLabel!: string;
  clippingArgumentForList!: string;

  activeClientList!: Array<TrustedClientExtended>;
  selectedAccount!: Account;
  selectedAccountClient!: TrustedClient;

  disableClientSelector = false;

  subscribe!: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private organizations: OrganizationsService) {
  }

  ngOnInit() {
    this.clippingArgumentForLabel = (this.shortLabel) ? CLIPPING_ARGUMENT : null;
    this.clippingArgumentForList = (this.shortNameInList) ? CLIPPING_ARGUMENT : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.operationType?.currentValue || changes.accountType?.currentValue) {
      this.subscribe?.unsubscribe();
      this.subscribe = this.organizations.activeTrustedClients$
        .pipe(
          switchMap((clientList) => {
            return this.organizations.getAccountListByAvailableOperation(this.operationType, clientList)
              .pipe(
                concatAll(),
                filter(account => this.accountType ? this.accountType.some(type => type === account.accountType) : true),
                toArray(),
                map<Account[], [ TrustedClient[], Account[] ]>((accountList) => [ clientList, accountList ])
              );
          })
        )
        .subscribe(([ clientList, accountList ]) => {
          this.accountListIsEmpty = !accountList.length;
          this.emptyAccounts.emit(this.accountListIsEmpty);
          if (this.accountListIsEmpty) {
            this.resetSelectedAccount();
          }
          this.activeClientList = this.updateActiveClientList(clientList, accountList);
          if (!this.accountListIsEmpty && this.activeClientList.length === 1 && this.activeClientList[0].accounts.length === 1) {
            const firstAccount = this.activeClientList[0].accounts[0];
            this.disableClientSelector = true;
            this.onSetAccount(firstAccount);
          }
          this.cdRef.markForCheck();
        });
    }
    if (changes.selectAccountById?.currentValue) {
      const accountId = this.selectAccountById;
      forkJoin([
        this.organizations.getAccountListByAvailableOperation(this.operationType)
          .pipe(
            map(accountList => this.organizations.findAccountByAccountId(accountList, accountId))
          ),
        this.organizations.getTrustedClientByAccountId(accountId)
      ])
        .subscribe(([ account, trustedClient ]) => this.onSetAccount(account, trustedClient));
    }
  }

  ngOnDestroy() {
    this.subscribe?.unsubscribe();
  }

  onSetAccount(account: Account, client?: TrustedClient) {
    this.selectedAccount = account;
    this.selectedAccountClient = (client)
      ? client
      : this.activeClientList.find(a => a.clientId === account.clientId);
    this.selectAccount.emit(account);
  }

  resetSelectedAccount() {
    this.selectedAccount = null;
    this.selectedAccountClient = null;
  }

  private updateActiveClientList(clientList: Array<TrustedClient>, accountList: Array<Account>): Array<TrustedClientExtended> {
    return clientList.map((client: TrustedClientExtended) => {
      const clientClone = clone(client);
      clientClone.accounts = this.organizations.filterAccountListByClientId(accountList, client.clientId);
      // temp solution
      /*clientClone.accounts.forEach(account => {
        if (account.number === 'MT53PAPY36836000002656370000092') {
          account.status = 'BLOCKED';
        }
      });*/
      return clientClone;
    });
  }

  /*private getAccountList(operationType?: OperationType): Observable<Account[]> {
    return (operationType)
      ? this.organizations.getAccountListByAvailableOperation(operationType)
      : this.organizations.getAccountListForOrganizations();
  }*/

}
