<!--<app-is-loading-->
<!--    [isLoading]="(isLoading$ | async)"-->
<!--&gt;</app-is-loading>-->

<ion-app>

  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-icon *ngIf="!filterIsOpen" slot="primary" class="History-filter__icon" name="filter"
                (click)="switchHistoryFilter()">
      </ion-icon>
      <ion-icon *ngIf="filterIsOpen" (click)="switchHistoryFilter()" slot="primary" class="History-filter__icon" name="filter-active"></ion-icon>
      <ion-title>{{ 'currency_exchange.exchange_history' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>

    <div class="History">
      <div *ngIf="filterIsOpen" class="History-filter">

        <div class="History-filter__clear">
          <ion-button (click)="clearHistoryFilter()" expand="full" shape="round" fill="outline" color="dark" size="small">
            {{ 'home.clear_filter' | translate }}
          </ion-button>
        </div>

        <div class="History-filter__date-range">
          <div class="data-start">
            <small>{{ 'home.start_date' | translate }}:</small>
            <p>
              <span *ngIf="!dateFrom">{{ 'CryptoHistory.Filter.Period.Choose' | translate }}</span>
              <span *ngIf="dateFrom">{{ dateFrom | date:'dd.MM.yyyy' }}</span>
            </p>
            <ion-icon name="datetime" (click)="onSetDataStart($event)"></ion-icon>
          </div>
          <div class="data-end">
            <small>{{ 'home.end_date' | translate }}:</small>
            <p>
              <span *ngIf="!dateTo">{{ 'CryptoHistory.Filter.Period.Choose' | translate }}</span>
              <span *ngIf="dateTo">{{ dateTo | date:'dd.MM.yyyy' }}</span>
            </p>
            <ion-icon name="datetime" (click)="onSetDataEnd($event)"></ion-icon>
          </div>
        </div>

        <div class="History-filter__panel">
          <!--          <app-client-select-->
          <!--              customClass="client-select&#45;&#45;filter"-->
          <!--              customHeight="550px"-->
          <!--              labelName="Select account"-->
          <!--              [shortLabel]="true"-->
          <!--              [shortNameInList]="true"-->
          <!--              (selectAccount)="setAccount($event)">-->
          <!--          </app-client-select>-->
          <!--          <div class="History-filter__currency">-->
          <!--            <ion-select interface="popover" interfaceOptions="popover">-->
          <!--              <ion-select-option value="EUR" selected>EUR</ion-select-option>-->
          <!--              <ion-select-option value="CHF">CHF</ion-select-option>-->
          <!--            </ion-select>-->
          <!--          </div>-->
          <!--          <ion-list class="History-filter__list">-->
          <!--            <ion-item lines="none">-->
          <!--              <ion-label>Start Balance</ion-label>-->
          <!--              <ion-label class="History-filter__value dark">57 921<small>,00 EUR</small></ion-label>-->
          <!--            </ion-item>-->
          <!--            <ion-item lines="none">-->
          <!--              <ion-label>Expenses</ion-label>-->
          <!--              <ion-label [innerHTML]="expenses | hcurrency:'EUR'" class="History-filter__value"></ion-label>-->
          <!--            </ion-item>-->
          <!--            <ion-item lines="none">-->
          <!--              <ion-label>Incomes</ion-label>-->
          <!--              <ion-label [innerHTML]="incomes | hcurrency:'EUR'" class="History-filter__value green"></ion-label>-->
          <!--            </ion-item>-->
          <!--            <ion-item lines="none">-->
          <!--              <ion-label>End Balance</ion-label>-->
          <!--              <ion-label [innerHTML]="balance | hcurrency:'EUR'" class="History-filter__value dark"></ion-label>-->
          <!--            </ion-item>-->
          <!--          </ion-list>-->
        </div>

      </div>

      <div *ngIf="operationsArray?.length === 0" class="History-empty">
        <svg viewBox="0 0 120 120">
          <path fill="#77A45C" d="M55.47 49.393a2.137 2.137 0 0 1-2.136-2.122c0-1.165.962-2.121 2.136-2.121h39.714c1.174 0 2.136.956 2.136 2.121a2.137 2.137 0 0 1-2.136 2.122H55.47zm0 11.26a2.122 2.122 0 0 1-2.136-2.122c0-1.165.962-2.121 2.136-2.121h28.847c1.173 0 2.136.956 2.136 2.121a2.137 2.137 0 0 1-2.136 2.122H55.47zm0 10.327a2.122 2.122 0 0 1-2.136-2.121c0-1.166.962-2.122 2.136-2.122h39.714c1.174 0 2.136.956 2.136 2.122a2.137 2.137 0 0 1-2.136 2.121H55.47zm3.779 7.53c1.173 0 2.136.956 2.136 2.122a2.137 2.137 0 0 1-2.136 2.121h-3.78a2.137 2.137 0 0 1-2.135-2.121c0-1.166.962-2.122 2.136-2.122h3.779zm0 10.328c1.173 0 2.112.933 2.136 2.122a2.137 2.137 0 0 1-2.136 2.121h-3.78a2.137 2.137 0 0 1-2.135-2.121c0-1.166.962-2.122 2.136-2.122h3.779zM95.184 78.51c1.174 0 2.136.956 2.136 2.122a2.137 2.137 0 0 1-2.136 2.121H67.746a2.137 2.137 0 0 1-2.136-2.121c0-1.166.962-2.122 2.136-2.122h27.438zM86.43 90.936a2.137 2.137 0 0 1-2.136 2.122H67.746a2.137 2.137 0 0 1-2.136-2.122c0-1.165.962-2.121 2.136-2.121h16.547c1.174 0 2.136.956 2.136 2.121z"/>
          <path fill="#596778" d="M82.345 116.347a2.15 2.15 0 0 0 3.005 0l7.229-7.18 7.23 7.18c.422.42.962.63 1.502.63s1.08-.21 1.502-.63l4.365-4.336c.4-.396.634-.932.634-1.492V38.506c0-5.316-4.342-9.629-9.694-9.629H81.805V5.121A2.137 2.137 0 0 0 79.67 3H14.3a2.137 2.137 0 0 0-2.136 2.121v71.99c0 5.316 4.343 9.629 9.694 9.629h12.3a2.137 2.137 0 0 0 2.135-2.122 2.137 2.137 0 0 0-2.136-2.121h-4.271a9.542 9.542 0 0 0 1.69-5.409V38.506c0-2.985 2.44-5.41 5.445-5.41 3.004 0 5.446 2.425 5.446 5.41v71.99c0 .14.023.28.046.42.024.093.047.163.07.256.024.046.024.093.048.14.047.093.094.186.14.256.024.023.024.07.048.093.07.117.164.233.258.327l4.366 4.336a2.15 2.15 0 0 0 3.004 0l7.23-7.18 7.229 7.18a2.15 2.15 0 0 0 3.004 0l7.23-7.18 7.205 7.203zM27.327 38.506v38.606c0 2.984-2.44 5.408-5.445 5.408-3.005 0-5.446-2.424-5.446-5.408V7.22h61.121v21.657H37.021c-5.352 0-9.694 4.313-9.694 9.629zm39.08 73.342l-7.229-7.18a2.125 2.125 0 0 0-1.502-.63c-.54 0-1.08.21-1.502.63l-7.23 7.18-2.23-2.215V38.506c0-.304-.023-.607-.046-.886 0-.094-.024-.164-.024-.257a5.82 5.82 0 0 0-.093-.63c-.024-.093-.024-.162-.047-.256a4.953 4.953 0 0 0-.212-.792c0-.024 0-.024-.023-.047a7.946 7.946 0 0 0-.305-.793c-.024-.07-.07-.14-.094-.233-.094-.186-.164-.373-.282-.56-.047-.07-.07-.14-.117-.232a9.745 9.745 0 0 0-.4-.653c-.023-.023-.023-.047-.046-.07v-.023h53.023c3.004 0 5.445 2.424 5.445 5.408v71.151l-2.23 2.215-7.229-7.18a2.15 2.15 0 0 0-3.004 0l-7.23 7.18-7.158-7.157a2.15 2.15 0 0 0-3.005 0l-7.23 7.157z"/>
        </svg>
        <p>{{ 'history.empty_text' | translate }}</p>
      </div>

      <div *ngIf="isLoading" class="History-panel customScrollbar ion-padding">
        <ng-container>
          <ion-list class="History-list" lines="full">
            <div class="History-data">
              <span>
                <ion-skeleton-text [animated]="true" style="width: 50px"></ion-skeleton-text>
              </span>
            </div>
            <app-exchange-money-skeleton></app-exchange-money-skeleton>
            <app-exchange-money-skeleton></app-exchange-money-skeleton>
            <div class="History-data">
              <span>
                <ion-skeleton-text [animated]="true" style="width: 50px"></ion-skeleton-text>
              </span>
            </div>
            <app-exchange-money-skeleton></app-exchange-money-skeleton>
            <app-exchange-money-skeleton></app-exchange-money-skeleton>
            <app-exchange-money-skeleton></app-exchange-money-skeleton>
          </ion-list>
        </ng-container>
      </div>

      <div *ngIf="operationsArray?.length > 0" class="History-panel customScrollbar ion-padding">
        <app-is-updating
            [isLoading]="(isLoading$ | async) && operationsArray?.length > 0">
        </app-is-updating>

        <ng-container *ngFor="let groupOperations of operationsArray; first as isFirst">
          <div class="History-data">
          <span *ngIf="groupOperations.operations.length > 0 " [ngStyle]="{
              'opacity': (isFirst && (isLoading$ | async) && operationsArray?.length > 0) ? 0 : 1
            }">
              {{ groupOperations.date | date:'dd.MM.yyyy' }}
            </span>
          </div>
          <ion-list class="History-list" lines="full">
            <app-exchange-money-history-item
                *ngFor="let operation of groupOperations?.operations"
                [item]="operation"
                [trustedClients]="trustedClients"
                [isLoading]="isLoading$"
                (repeatOperation)="repeatOperation($event)"
                class="app-history-item">
            </app-exchange-money-history-item>
          </ion-list>
        </ng-container>

      </div>
    </div>

  </ion-content>
</ion-app>
