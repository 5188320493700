<div class="top-up">
  <main class="top-up__main">
    <div class="top-up__warning-banner">
      {{ warningBannerTranslateKey() | translate }}
      <ion-icon name="alert-circle-outline" color="danger"></ion-icon>
    </div>

    <ng-container *ngIf="availableState && intermediaryBankDetails()?.bankDetails as details">
      <div class="top-up-list">
        <div class="top-up-list__item" *ngFor="let detail of details">
          <label [for]="detail.name" class="top-up-list__label">
            {{ translationKeys[detail.name] | translate }}
          </label>
          <span [id]="detail.name" class="top-up-list__value">{{ detail.value }}</span>
        </div>
      </div>

      <div class="instruction-button-container">
        <button [routerLink]="['/', 'instruction']"
                class="top-up__instruction-button">{{ 'topup.incoming_SEPA.instruction_button' | translate }}
        </button>
      </div>
    </ng-container>
  </main>
</div>

<footer *ngIf="availableState" class="top-up__footer">
  <div *ngIf="itWasCopied()" class="top-up__footer-copy-message">{{ 'topup.info_copied' | translate }}</div>

  <button class="top-up__footer-button top-up__footer-button_copy"
          [disabled]="!intermediaryBankDetails()"
          (click)="copyToClipboard()">
    {{ 'topup.clipboard_button' | translate }}
    <ion-icon name="copy-outline" size="small"></ion-icon>
  </button>
</footer>


