<div class="News__container" *ngIf="news$ | async as news">
  <div class="News__container-content">
    <div class="News__container-top">
      <img [src]="news.backgroundImageUrl" [alt]="news.title">
      <div [innerHTML]="news.title"></div>
    </div>
    <div [innerHTML]="news.description" class="News__container-bottom"></div>
    <div *ngIf="news.url" class="News__container-bottom">
      Read more: <a target="_blank" href="//{{link$ | async}}"><b>{{ link$ | async }}</b></a>
    </div>
  </div>
  <ion-icon (click)="gotoLink(news)" name="news-arrow-right"></ion-icon>
</div>
