<app-is-loading
  [isLoading]="isLoading$ | async">
</app-is-loading>

<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-icon slot="start" name="back" (click)="backToMovementMoneyHistory()"></ion-icon>
      <ion-title>{{ 'modal.print_statement' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="PrintStatements">

      <div class="PrintStatements-content">

        <div class="PrintStatements-padding">

          <!--customHeight="550px"-->
          <app-client-select
            class="client-select-small"
            customClass="PrintStatements client-select-small"
            [operationType]="['PAYMENT_SEPA', 'TOPUP_BY_CARD']"
            [labelName]="'CryptoExchange.PayFrom.SelectAccount' | translate"
            [shortLabel]="true"
            [shortNameInList]="true"
            (selectAccount)="setAccount($event)">
          </app-client-select>

          <!--<div class="PrintStatements-currency">
            <ion-select interface="popover" interfaceOptions="popover">
              <ion-select-option value="EUR" selected>EUR</ion-select-option>
              <ion-select-option value="CHF">CHF</ion-select-option>
            </ion-select>
          </div>-->

          <div class="PrintStatements-info ion-text-center">
            <span>{{ 'print.print_transaction_history' | translate }}</span>
          </div>

        </div>

        <div class="PrintStatements-date-range">
          <div class="data-start">
            <small>{{ 'home.start_date' | translate }}:</small>
            <p>
              <span *ngIf="!dateFrom">{{ 'CryptoHistory.Filter.Period.Choose' | translate }}</span>
              <span *ngIf="dateFrom">{{ dateFrom | date:'dd.MM.yyyy' }}</span>
            </p>
            <ion-icon name="datetime" (click)="onSetDateStart($event)"></ion-icon>
          </div>
          <div class="data-end">
            <small>{{ 'home.end_date' | translate }}:</small>
            <p>
              <span *ngIf="!dateTo">{{ 'CryptoHistory.Filter.Period.Choose' | translate }}</span>
              <span *ngIf="dateTo">{{ dateTo | date:'dd.MM.yyyy' }}</span>
            </p>
            <ion-icon name="datetime" (click)="onSetDateEnd($event)"></ion-icon>
          </div>
        </div>

        <!--<div class="PrintStatements-all">
          <ion-button expand="full" shape="round" fill="outline" color="dark" size="small">
            Select All Period
          </ion-button>
        </div>-->

      </div>

      <div class="PrintStatements-buttons">
        <ion-button
          class="buttonCancel" expand="full" shape="round" fill="outline" color="dark" size="small"
          (click)="clearDate()">
            {{ 'templatesEdit.cancel_button' | translate }}
        </ion-button>
        <ion-button
          class="buttonPreview" expand="full" shape="round" fill="outline" color="success" size="small"
          [disabled]="!activeAccount || !dateFrom || !dateTo"
          (click)="onPreviewStatement(activeAccount, dateFrom, dateTo)">
            {{ 'modal.preview' | translate }}
        </ion-button>
      </div>

    </div>
  </ion-content>
</ion-app>
