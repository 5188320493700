<section class="page">
  <div class="page__content">
    <div class="top">
      <div class="stepper">
        <img class="stepper__img" ngSrc="../../../../assets/custom-icons/topup-binance-account-step3.svg" alt="step2"
             height="24"
             width="113">

        <span class="stepper__description">{{ 'topup.binance.connect_confirmation_text' | translate }}</span>
      </div>

      <div class="text">
        <h2 class="text__title">{{ 'topup.binance.connect_confirmation_text_area_header' | translate }}</h2>
      </div>

      <div class="details">
        <div class="details__row">
          <span
              class="details__label details__text_bold">{{ 'topup.binance.connect_confirmation_label_text1'| translate }}</span>
          <div class="value-wrapper">
            <span class="details__value details__text_light">{{ amount }}</span>
            <span class="details__currency details__text_light">EUR</span>
          </div>
        </div>

        <div class="details__row">
          <span
              class="details__label details__text_bold">{{ 'topup.binance.connect_confirmation_label_text2'| translate }}</span>
          <div class="value-wrapper">
            <span [ngClass]="{'details__text_err': higherLimit}"
                  class="details__value details__text_light">{{ exchangeAmount }}</span>
            <span [ngClass]="{'details__text_err': higherLimit}"
                  class="details__currency details__text_light">USDT</span>
          </div>
        </div>

        <div class="details__row">
          <span
              class="details__label details__text_bold">{{ 'topup.binance.connect_confirmation_label_text3'| translate }}</span>
          <div class="value-wrapper">
            <span class="details__value details__text_light">1,00 EUR = {{ rate }}</span>
            <span class="details__currency details__text_light">USDT</span>
          </div>
        </div>

        <div class="details__row">
          <span
              class="details__label details__text_bold">{{ 'topup.binance.connect_confirmation_label_text4'| translate }}</span>
          <div class="value-wrapper">
            <span class="details__value details__text_light">{{ fee }}</span>
            <span class="details__currency details__text_light">EUR</span>
          </div>
        </div>

        <div class="details__row details__row_total">
          <span
              class="details__text_extra-bold">{{ 'topup.binance.connect_confirmation_label_total'| translate }}</span>
          <div class="value-wrapper">
            <span class="details__text_extra-bold">{{ totalAmount }}</span>
            <span class="details__text_extra-bold">EUR</span>
          </div>
        </div>
      </div>

      <p *ngIf="higherLimit" class="higher_limit_error">{{ 'topup.binance.higher_limit_error' | translate }}</p>

      <div class="timer">
        <span class="timer__description">{{ 'topup.binance.connect_confirmation_countDownText' | translate }}</span>
        <span class="timer__countdown">{{ formatTimer() }}</span>
      </div>
    </div>

    <div class="bottom">
      <div class="terms">
        <span class="terms__text">{{ 'topup.binance.connect_confirmation_agreement_text1' | translate }}</span>
        <a href="https://blackcatcard.com/legal/terms-conditions/" target="_blank"
           class="terms__link">{{ 'topup.binance.connect_confirmation_agreement_text2' | translate }}</a>
      </div>

      <div class="buttons-container">
        <ion-button routerLink="/top-up-account" size="large" expand="full" shape="round" fill="outline" color="medium">
          {{ 'moneyRequestReceive.second_button' | translate }}
        </ion-button>

        <ion-button [disabled]="higherLimit" (click)="topUpConfirm()" size="large" expand="full" shape="round"
                    fill="outline" color="success">
          <ion-icon slot="start" name="arrow-green"></ion-icon>
          {{ 'topup.binance.connect_confirmation_accept' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</section>
