import { environment } from 'environments/environment'
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, tap } from 'rxjs';
import { CurrencyCode } from 'models/payment.interfaces';

export interface AccountDetailsDataItem {
  title: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  private readonly isLoadingSource$ = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.isLoadingSource$.asObservable();

  constructor(private http: HttpClient) {}

  getAccountById(id: string, currency?: CurrencyCode): Observable<AccountDetailsDataItem[]> {
    this.isLoadingSource$.next(true);
    let url = environment.baseApi + `/v1/accounts/${id}/details`;
    if (currency) {
      url+=`?currency=${currency}`;
    }
    return this.http.get<AccountDetailsDataItem[]>(url).pipe(
      finalize(() => this.isLoadingSource$.next(false)),
    );
  }
}
