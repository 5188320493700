import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from './permission.service';
import { ROLES } from '../users.roles';

import { intersection } from 'lodash';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective {

  hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissions: PermissionService) {
  }

  @Input()
  set appPermission(availableToRoles: Array<ROLES>) {
    this.permissions.getActiveRoles()
      .pipe(
        filter(roles => !!intersection(roles, availableToRoles).length)
      )
      .subscribe(() => {
        if (!this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

}
