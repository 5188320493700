import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '../../directives/directives.module';

import { TransferAmountComponent } from './transfer-amount/transfer-amount.component';
import { TransferAmountV2Component } from './transfer-amount-v2/transfer-amount-v2.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';

@NgModule({
  declarations: [
    TransferAmountComponent,
    TransferAmountV2Component
  ],
  exports: [
    TransferAmountComponent,
    TransferAmountV2Component
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    NgxMaskModule,
    PipesModule,
    DirectivesModule,
    InternationalizationModule
  ]
})
export class TransfersUiElementsModule { }
