<form
  [formGroup]="confirmationForm"
  (submit)="onSubmit(confirmationForm)"
  class="Modal ModalConfirmation__window ConfirmationAlert ion-text-center">
  <app-is-loading [isLoading]="isLoading"></app-is-loading>
    <div class="ConfirmationAlert__title" [class.error]="hasBackendError">
      <span *ngIf="!hasBackendError">{{ 'bankOfferStartConfirm.title' | translate }}</span>
      <span *ngIf="hasBackendError">{{ 'cashback.wrong_code' | translate }}</span>
    </div>
    <div class="ConfirmationAlert__text">
      <p *ngIf="confirmationByPIN">{{ 'smsConfirm.first_text' | translate }}</p>
      <p *ngIf="!confirmationByPIN">{{ 'enterPIN.operation_confirm' | translate }}</p>
    </div>
    <div class="ConfirmationAlert__field"
      [class.error]="displayError">
        <ion-item lines="none">
          <input
            #confirmCode
            formControlName="confirmationCode"
            (keydown)="(hasBackendError) ? hasBackendError = false : null"
            [mask]="(confirmationByPIN) ? '00-00' : '000-000'"
            type="text"
            [maxlength]="(confirmationByPIN) ? 5 : 7"
            [placeholder]="(confirmationByPIN) ? '◦ ◦  -  ◦ ◦' : '◦ ◦ ◦  -  ◦ ◦ ◦'"
          />
        </ion-item>
    </div>

    <div class="ConfirmationAlert__buttons">
      <ion-button
        expand="full" shape="round" fill="outline" color="dark"
        (click)="closeModal()">
            {{ 'logOut.first_button' | translate }}
      </ion-button>
      <ion-button
        [disabled]="confirmationForm.invalid"
        expand="full" shape="round" fill="outline" color="success"
        type="submit">
            {{ 'login.alert.button.ok' | translate }}
      </ion-button>
    </div>
</form>

<div *ngIf="confirmationByPIN" class="ModalConfirmation__resend ion-text-center">
    <span *ngIf="smsTimer > 0">
        {{ smsTimer | date:'mm:ss' }}
    </span>
    <ion-button
        expand="full" shape="round" fill="outline"
        [disabled]="timerIsActive"
        (click)="resendCode(confirmationForm)">
        <span *ngIf="!timerIsActive">{{ 'bankOfferStartConfirm.third_button' | translate }}</span>
        <app-countdown-timer
            *ngIf="timerIsActive"
            [timerSec]="300"
            [start]="timerIsActive"
            [reset]="!timerIsActive"
            (finished)="enableLimits()"
        ></app-countdown-timer>
    </ion-button>
</div>
