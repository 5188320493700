<ion-item class="History-item" (click)="showFullInfo(item)">
  <div class="History-item__header">
    <ion-label class="History-item__label">{{ item.name }}</ion-label>
  </div>
  <div class="History-item__values">
    <span class="History-item__value">
      {{ item.priceFrom > 0 ? '+' : '-' }}{{ getIntegerPart(item.priceFrom) }}<small>{{ getDecimalPart(item.priceFrom) }} {{ item.currency.codeFrom }}</small>
    </span>
    <p class="History-item__value green">
      {{ item.priceTo > 0 ? '+' : '-' }}{{ getIntegerPart(item.priceTo) }}<small>{{ getDecimalPart(item.priceTo) }} {{ item.currency.codeTo }}</small>
    </p>
  </div>
</ion-item>
<div class="History-item__full-info item-{{item.id}}" *ngIf="itemOpen">
  <div class="History-item__buttons">
    <ion-button class="buttonRepeat" expand="full" shape="round" fill="outline" color="dark" size="small">
      Repeat
      <ion-icon slot="end" name="repeat"></ion-icon>
    </ion-button>
  </div>
</div>
