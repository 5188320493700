// The file contents for the BCC PRODUCTION environment.

export const environment = {
  production: true,
  applicationId: 'BLACK_CAT_CARD',
  productId: 'BLACK_CAT_CARD',
  deviceId: 'BCC_DESKTOP',
  baseApi: 'https://bgate.star-bridge.lv/api',
  registrationUrl: 'https://registration.blackcatcard.com',
  authorizationType: 'fire-base',
  bankSettings: {
    bankLocationCode: 'MT',
    nationalBankCode: 'PAPY',
    defaultCurrency: 'EUR',
    transactionConfirmationType: 'SIGN'
  },
  countryFlagsUrl: '/assets/country-flags',
  cryptoService: {
    isActive: false,
    baseApi: 'https://ce-backend.star-bridge.lv',
    appUrl: 'https://desktop-crypto.bcc.scnetservices.ru'
  },
  supportChatSettings: {
    url: 'https://support-backend.blackcatcard.com/api/v1/',
    brokerURL: 'wss://support-rabbit.blackcatcard.com/ws',
    sessionConfig: {
      appId: 'BLACK_CAT_CARD',
      productId: 'BLACK_CAT_CARD',
    }
  },
  idScanConfig: {
    baseUrl: 'https://idscan-adapter-prod.star-bridge.lv'
  },
  idleTimeout: 900,
  analytics: {
    measurement_id: `G-6DPFJ8BNB5`
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
