import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { PAYMENT_TYPE, PaymentOperationTemplate } from '../../../../users/operations/operations.interface';
import { RightContainerService } from '../../../right-container.service';

@Component({
  selector: 'app-transfer-template-item',
  templateUrl: './transfer-template-item.component.html',
  styleUrls: ['./transfer-template-item.component.scss']
})
export class TransferTemplateItemComponent implements OnInit {

  @Input() item?: PaymentOperationTemplate;
  @Input() type?: PAYMENT_TYPE;

  @Output() repeatOperation = new EventEmitter<PaymentOperationTemplate>();
  @Output() mobileViewEdit = new EventEmitter<void>();

  isActive = false;

  constructor(
    private rightContainer: RightContainerService) { }

  ngOnInit() {
  }

  onSetActiveState() {
    this.isActive = !this.isActive;
  }

  onUseTemplate(item: PaymentOperationTemplate) {
    this.repeatOperation.emit(item);
  }

  onEditTemplate(template: PaymentOperationTemplate) {
    // this.rightContainer.setRightPanel('transfer-card-template-edit');
    this.rightContainer.setRightPanel('transfers-template-edit', template);
    // this.rightContainer.paymentReceiptItem = item;
    this.mobileViewEdit.emit();
  }

}
