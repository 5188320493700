<script src="top-up-account-binance-verified.component.ts"></script>
<section class="page">
  <div class="page__content">
    <div class="content-wrapper">
      <ng-container *ngIf="isQrLoading else qrCode">
        <div class="preloader">
          <ion-spinner></ion-spinner>
          <img ngSrc="../../../../assets/imgs/qr-preloader.svg" alt="qr" class="preloader__img" height="200"
               width="200">
        </div>
      </ng-container>

      <ng-template #qrCode>
        <qrcode [qrdata]="binanceData.deepLink" [width]="200" [margin]="0"
                [errorCorrectionLevel]="'M'"
                cssClass="qr-code"></qrcode>
      </ng-template>

      <div class="text">
        <div class="text__title-container">
          <img ngSrc="../../../../assets/custom-icons/check-green.svg" height="40" width="40" alt="check">
          <h2 class="text__title">{{ 'topup.binance.connect_verified_textParagraph_desktop' | translate }}</h2>
        </div>
      </div>

      <div class="hint">
        <img ngSrc="../../../../assets/custom-icons/bcc-logo.svg" height="20" width="7" alt="bcc-logo">

        <span class="hint__text">{{ 'topup.binance.connect_verify_notify_text' | translate }}</span>
      </div>
    </div>
  </div>
</section>
