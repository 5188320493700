import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IdleService } from 'app/users/idle/idle.service';

@Component({
  selector: 'app-navigation-logout-timer',
  templateUrl: './navigation-logout-timer.component.html',
  styleUrls: ['./navigation-logout-timer.component.scss']
})
export class NavigationLogoutTimerComponent implements OnInit, OnDestroy {
  sub: Subscription;
  timerIsActive = false;
  interval = this.idle.logoutInterval;

  constructor(private idle: IdleService) {}

  ngOnInit() {
    this.sub = this.idle.logoutTimerActive$.subscribe(isActive => (this.timerIsActive = isActive));
  }

  onTimerFinished() {
    this.timerIsActive = false;
  }

  ngOnDestroy() {
    this.timerIsActive = false;
    this.sub.unsubscribe();
  }
}
