import { environment } from 'environments/environment';

import { Injectable, ErrorHandler } from '@angular/core';
import { captureException, init } from '@sentry/browser';

@Injectable()
export class AppErrorHandlerService implements ErrorHandler {

  constructor() {
    if (environment.production) {
      init({
        dsn: 'https://8ff12dc23bce4336867aa9c4c153af1c@sentry.finbusiness.tech/1'
      });
    }
  }

  handleError(error) {
    if (environment.production) {
      captureException(error.originalError || error);
    }
    throw error;
  }
}
