import { Component, OnInit } from '@angular/core';

import { ModalsService } from '../../modals.service';

@Component({
  selector: 'app-logout-confirm',
  templateUrl: './logout-confirm.component.html',
  styleUrls: ['./logout-confirm.component.scss']
})
export class LogoutConfirmComponent implements OnInit {

  constructor(
    private modals: ModalsService) {
  }

  ngOnInit() {
  }

  closeModal(isLogout?: boolean) {
    this.modals.closeModal(isLogout || null);
  }

}
