import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpEvent } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { saveAs as fileSaveAs } from 'file-saver';

import { BehaviorSubject, Observable, timer, throwError } from 'rxjs';
import { filter, tap, map, switchMap, first, retryWhen, delayWhen, take, concat, finalize, catchError, pluck } from 'rxjs/operators';

import { AuthManagerService } from '../auth/auth-manager.service';
import { StorageService } from '../../services/storage/storage.service';
import { TransfersService } from '../../+transfers/transfers.service';

import { MoneyOperationRequest, MoneyOperation, PaymentOperationRepeat, BankPaymentType } from './operations.interface';
export type ContentType = 'application/pdf' | 'application/xml' | 'application/octet-stream';

const STORAGE_KEY = 'operations';
const FILE_TYPE_TABLE = {
  'application/pdf': 'pdf',
  'application/xml': 'xml',
  'application/octet-stream': 'csv'
};

export interface OperationRequestModel {
  accountsIds?: Array<string>;
  cardsIds?: Array<string>;
  dateFrom?: number;
  dateTo?: number;
  page?: number;
  size?: number;
  paymentType?: BankPaymentType;
}

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  private operationSource = new BehaviorSubject<MoneyOperation[]>(null);
  operations$: Observable<MoneyOperation[]> = this.operationSource.asObservable();

  private isLoadingSource = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSource.asObservable();

  static getFileNameByContentType(contentType: ContentType, fileName?: string): string {
    if (FILE_TYPE_TABLE[contentType]) {
      return `${(fileName ? fileName : 'bank-statement')}.${FILE_TYPE_TABLE[contentType]}`;
    } else {
      console.warn(`File type for content type: '${contentType}' was not found`);
      return null;
    }
  }

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private authManager: AuthManagerService,
    private storage: StorageService,
    private transfers: TransfersService,
    private domSanitizer: DomSanitizer) {

    this.authManager.bankToken$
      .pipe(
        filter(token => !!token),
        switchMap(() => this.getStorageOperationData())
      )
      .subscribe(value => this.setOperations(value));
  }

  getOperationList(): Observable<MoneyOperation[]> {
    return this.operations$.pipe(
      first()
    );
  }

  private setStorageOperationData(value: Array<MoneyOperation>) {
    return this.storage.set(STORAGE_KEY, value);
  }

  private getStorageOperationData(): Observable<MoneyOperation[]> {
    return this.storage.get(STORAGE_KEY);
  }

  setOperations(value: Array<MoneyOperation>): void {
    this.operationSource.next(value);
    this.setStorageOperationData(value)
      .subscribe();
  }

  repeatOperation(operation: PaymentOperationRepeat, route = null): void {
    this.transfers.repeatTransfer(operation, route);
  }

  private getParamsForRequestOperations(model: OperationRequestModel): HttpParams {
    let params = new HttpParams()
      .set('size', (!model.size || model.dateFrom || model.dateTo) ? '50' : model.size.toString())
      .set('page', (model.page) ? model.page.toString() : '0');

    if (model.accountsIds) {
      params = params.append('accounts', model.accountsIds.toString());
    }
    if (model.cardsIds) {
      params = params.append('cards', model.cardsIds.toString());
    }
    if (model.dateFrom) {
      params = params.append('from', this.datePipe.transform(model.dateFrom, 'yyyy-MM-dd'));
    }
    if (model.dateTo) {
      params = params.append('to', this.datePipe.transform(model.dateTo, 'yyyy-MM-dd'));
    }
    if (model.paymentType) {
      params = params.append('payment-type', model.paymentType.toString());
    }
    return params;
  }

  requestOperations(model: OperationRequestModel): Observable<MoneyOperationRequest> {
    const url = environment.baseApi + `/v2/operations`;
    const params = this.getParamsForRequestOperations(model);

    this.setLoadingStatus(true);
    return this.http.get<MoneyOperationRequest>(url, { params }).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  getModifiedOperations(operations: Array<MoneyOperation> = []): Array<{ date: string; operations: Array<any> }> {
    const modifiedOps = [];
    if (operations && operations.length) {
      operations.forEach(op => {
        const dateOperations = modifiedOps.find(v => {
          const d1 = new Date(v.date);
          const d2 = new Date(op.executedAt);
          return d1.getFullYear() === d2.getFullYear()
            && d1.getMonth() === d2.getMonth()
            && d1.getDate() === d2.getDate();
        });

        if (dateOperations) {
          dateOperations.operations.push(op);
        } else {
          modifiedOps.push({
            date: op.executedAt,
            operations: [op]
          });
        }
      });
    }
    return modifiedOps;
  }

  requestSingleOperation(referenceNumber: string): Observable<MoneyOperation> {
    const url = environment.baseApi + `/v2/operations/${referenceNumber}`;
    return this.http.get<MoneyOperation>(url).pipe(
      retryWhen(error =>
        error.pipe(
          delayWhen(() => timer(3000)),
          take(5),
          concat(
            throwError({ error: 'Sorry, there was an error (after 5 retries)' })
          )
        )
      ),
      // finalize(() => console.log(5555555))
    );
  }

  requestSingleOperationPdf(referenceNumber: string): Observable<HttpEvent<Blob>> {
    const url = environment.baseApi + `/v1/operations/${referenceNumber}/export/pdf`;
    const headers = new HttpHeaders({
      'Content-Type':  'application/octet-stream'
    });
    const req = new HttpRequest('GET', url, {
      headers,
      responseType: 'blob'
    });
    return this.http.request<Blob>(req).pipe(
      tap(event => console.log('requestSingleOperationPdf', event))
    );
  }

  downloadSingleOperation(referenceNumber: string): Observable<void> {
    this.setLoadingStatus(true);
    return this.requestSingleOperationPdf(referenceNumber).pipe(
      filter(response => response.type !== 0),
      map(response => fileSaveAs(
        response['body'], OperationsService.getFileNameByContentType('application/pdf')
      )),
      tap(
        () => this.setLoadingStatus(false),
        () => this.setLoadingStatus(false)
      )
    );
  }

  requestBankStatements<T extends {statements?: any}>(): Observable<T> {
    const url = environment.baseApi +  `/v1/statements`;
    return this.http.get<T>(url).pipe(map(data => data?.statements));
  }
  
  downloadStatement(reference: string): Observable<any> {
    const url = environment.baseApi +  `/v1/statements/file/${reference}`;
    const headers = new HttpHeaders({
      'Accept':  'application/pdf',
    });
    return this.http.get(url, { headers, responseType: 'blob' }).pipe();
  }

  requestBankStatement(accountId: string, dateFrom: number, dateTo: number, contentType: ContentType): Observable<HttpEvent<Blob>> {
    const url = environment.baseApi +  `/v1/operations/export`;
    const dateFromFormatted = this.datePipe.transform(dateFrom, 'dd.MM.yyyy');
    const dateToFormatted = this.datePipe.transform(dateTo, 'dd.MM.yyyy');
    const headers = new HttpHeaders({
      'Content-Type':  'application/octet-stream'
    });
    const params = new HttpParams()
      .set('account-id', accountId)
      .set('dateFrom', dateFromFormatted)
      .set('dateTo', dateToFormatted)
      .set('mime-type', contentType);

    const req = new HttpRequest('GET', url, {
      headers,
      params,
      responseType: 'blob'
    });

    this.setLoadingStatus(true);
    return this.http.request<Blob>(req).pipe(
      // tap(event => console.log(888, event)),
      filter(response => response.type !== 0),
      tap(
        () => this.setLoadingStatus(false),
        () => this.setLoadingStatus(false)
      ),
    );
  }

  getBankStatementPreview(accountId: string, dateFrom: number, dateTo: number, contentType: ContentType): Observable<SafeResourceUrl> {
    return this.requestBankStatement(accountId, dateFrom, dateTo, contentType).pipe(
      map(event => this.convertHttpEventToSafeResourceUrl(event, contentType))
    );
  }

  private setLoadingStatus(predicate: boolean): void {
    this.isLoadingSource.next(predicate);
  }

  private convertHttpEventToSafeResourceUrl(event: HttpEvent<Blob>, contentType: ContentType): SafeResourceUrl {
    const blob = new Blob([event['body']], { type: contentType });
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
  }

  /*concatOperations(history, operations) {
    if (!history.length) {
      return operations;
    }

    history.forEach((historyItem, historyIndex) => {
      operations.forEach((operation, opIndex) => {
        if (historyItem.id === operation.id) {
          history[historyIndex] = operation;
          operations.splice(opIndex, 1);
        }
      });
    });
    return [...operations, ...history];
  }

  finalSorting(operations) {
    operations.sort((el1, el2) => {
      return new Date(el2.date).getTime() - new Date(el1.date).getTime();
    });
    return operations;
  }*/

}
