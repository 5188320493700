import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

/* tslint:disable:max-line-length */

import { CryptoTransactionHistoryComponent } from './crypto-transaction-history/crypto-transaction-history.component';
import { CryptoTransactionHistoryItemComponent } from './crypto-transaction-history/crypto-transaction-history-item/crypto-transaction-history-item.component';
import { CryptoTransactionHistoryRecipeComponent } from './crypto-transaction-history/crypto-transaction-history-recipe/crypto-transaction-history-recipe.component';

import { CryptoExchangeTransactionHistoryComponent } from './crypto-exchange-transaction-history/crypto-exchange-transaction-history.component';
import { CryptoExchangeTransactionHistoryItemComponent } from './crypto-exchange-transaction-history/crypto-exchange-transaction-history-item/crypto-exchange-transaction-history-item.component';
import { PipesModule } from 'app/pipes/pipes.module';
import { InternationalizationModule } from '../../internationalization/internationalization.module';

@NgModule({
  declarations: [
    CryptoTransactionHistoryComponent,
    CryptoTransactionHistoryItemComponent,
    CryptoTransactionHistoryRecipeComponent,
    CryptoExchangeTransactionHistoryComponent,
    CryptoExchangeTransactionHistoryItemComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    InternationalizationModule
  ],
  exports: [
    CryptoTransactionHistoryComponent,
    CryptoTransactionHistoryRecipeComponent,
    CryptoExchangeTransactionHistoryComponent
  ]
})
export class TransactionHistoryModule { }
