<ion-header [ngClass]="{ multiAccount: accountList && accountList.length > 1 }" >
  <div appActiveBankClass>
    <ion-toolbar *appPermission="[ roles.BCC_INDIVIDUAL_PERSON, roles.BCC_LEGAL_PERSON ]" (click)="openAccountsFilter()">
      <ion-skeleton-text *appLocalizationNotLoaded [animated]="true" style="width: 60%"></ion-skeleton-text>
      <img *appLocalizationIsLoaded alt="bcc-logo" src="assets/bcc/logo-bcc-v2.svg">
      <!--<ion-title *appLocalizationIsLoaded>{{ 'enter.title' | translate }}</ion-title>-->
    </ion-toolbar>
    <ion-toolbar *appPermission="[ roles.GEKKARD ]">
      <img
          alt="gekkard-logo"
          src="/assets/gekkard/gekkard-title@3x.png"
          srcset="
            /assets/gekkard/gekkard-title@2x.png 2x,
            /assets/gekkard/gekkard-title.png 1x
          "
          sizes="(max-width: 1200px),
          (max-width: 1400px),
          1920px"
      >
    </ion-toolbar>
  </div>

  <div class="multiAccount-filter" appActiveBankClass>
    <form *ngIf="accountList && filterIsOpen" [formGroup]="accountsForm" [ngClass]="{ customPadding: (accountList && filterIsOpen) }">
      <ion-list formArrayName="list" lines="none">
        <ion-item *ngFor="let account of accountsArray.controls let i = index;" [formGroupName]="i">
          <!--<ion-checkbox [color]="account.color">-->
          <ion-checkbox
              formControlName="active"
              [attr.disabled]="activeCompanyCount === 1 && account.value.active">
          </ion-checkbox>
          <ion-label (click)="toggleCheckbox(account)">
            {{ (account.value.title) ? account.value.title : account.value.name }}
          </ion-label>
        </ion-item>
      </ion-list>
    </form>
    <div *ngIf="accountList && filterIsOpen" class="multiAccount-text">
      {{ 'money.one_client_must_selected' | translate }}!
    </div>
  </div>

</ion-header>

<ng-container *appPermission="[ roles.BCC_LEGAL_PERSON, roles.BCC_INDIVIDUAL_PERSON ]">
  <div *ngIf="accountList.length > 1" class="multiAccount-selected">
    <ion-skeleton-text *appLocalizationNotLoaded [animated]="true" style="width: 100%"></ion-skeleton-text>
    <span *appLocalizationIsLoaded>{{ 'money.selected' | translate }}: {{ activeCompanyCount }} Clients</span>
  </div>
</ng-container>
