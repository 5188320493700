import { Component, Input, OnInit } from '@angular/core';
import { RightContainerService } from '../../../right-container.service';

@Component({
  selector: 'app-crypto-transaction-history-recipe',
  templateUrl: './crypto-transaction-history-recipe.component.html',
  styleUrls: ['./crypto-transaction-history-recipe.component.scss']
})
export class CryptoTransactionHistoryRecipeComponent implements OnInit {

  @Input() operation;

  constructor(
    private rightContainer: RightContainerService) {
  }

  ngOnInit() { }

  goBack() {
    this.rightContainer.setRightPanel('crypto-transaction-history');
  }

}
