import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { TransferRecipeComponent } from './transfer-recipe/transfer-recipe.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { UsersModule } from '../../users/users.module';
import { RecipeSenderInfoComponent } from './transfer-recipe/components/recipe-sender-info/recipe-sender-info.component';
import { RecipeBeneficiaryInfoComponent } from './transfer-recipe/components/recipe-beneficiary-info/recipe-beneficiary-info.component';
import { RecipePaymentInfoComponent } from './transfer-recipe/components/recipe-payment-info/recipe-payment-info.component';

@NgModule({
  declarations: [
    TransferRecipeComponent,
    RecipeSenderInfoComponent,
    RecipeBeneficiaryInfoComponent,
    RecipePaymentInfoComponent
  ],
  exports: [
    TransferRecipeComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    InternationalizationModule,
    UsersModule
  ]
})
export class TransferRecipeModule { }
