<ion-app class="main-app" [ngClass]="{ 'full-size-app': !mainNavigationIsOpen, 'black-background': !(authToken$ | async)}">
  <ion-split-pane when="(min-width: 1280px)" contentId="main-content" [ngClass]="{ 'is-blur': modalIsOpen$ | async }">

    <ion-menu contentId="main-content" class="main-menu" >
      <app-main-navigation
        *ngIf="mainNavigationIsOpen">
      </app-main-navigation>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>
</ion-app>

<section id="recaptcha-wrapper">
  <div id="recaptcha-container" style="display: none;"></div>
</section>
<notifier-container></notifier-container>
