import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractStatusesType, IBinanceDeepLinkResponse, TOP_UP_PATH } from '../../top-up-account.interface';
import { interval, Subscription } from 'rxjs';
import { TopUpAccountService } from '../../top-up-account.service';
import { first, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-top-up-account-binance-verified',
  templateUrl: './top-up-account-binance-verified.component.html',
  styleUrls: ['./top-up-account-binance-verified.component.scss']
})
export class TopUpAccountBinanceVerifiedComponent implements OnInit, OnDestroy {
  binanceData!: IBinanceDeepLinkResponse;

  isQrLoading = true;

  private checkStatusIntervalSub$!: Subscription;
  private checkLinkIntervalSub$!: Subscription;

  constructor(
    private router: Router,
    private topUpAccountService: TopUpAccountService
  ) {
  }

  ngOnInit() {
    this.getExtras();
  }

  ngOnDestroy() {
    this.checkStatusIntervalSub$.unsubscribe();
    this.checkLinkIntervalSub$.unsubscribe();
  }

  private getExtras() {
    const {binanceData, appId} = this.router.getCurrentNavigation().extras.state;

    this.binanceData = binanceData;

    if (binanceData.status === ContractStatusesType.New) {
      this.checkLinkIntervalSub$ = interval(1000)
        .subscribe(() => this.checkQrCode(appId));
    } else if (binanceData.status === ContractStatusesType.Reject) {
      this.openErrorModal();
    }

    if (binanceData && appId) {
      this.checkBinanceStatus(appId);
    } else {
      console.error('state is empty');
      this.openErrorModal();
    }
  }

  private openErrorModal() {
    this.topUpAccountService.openErrModal().subscribe();
  }

  private checkQrCode(appId: number) {
    this.topUpAccountService.getBinanceDeeplink(appId)
      .subscribe((binanceData: IBinanceDeepLinkResponse) => {
        this.binanceData = binanceData;

        if (binanceData.status === ContractStatusesType.Waiting) {
          this.checkLinkIntervalSub$.unsubscribe();
          this.isQrLoading = false;
        }
      });
  }

  private checkBinanceStatus(appId: number) {
    this.checkStatusIntervalSub$ = interval(15000)
      .pipe(
        switchMap(() => this.topUpAccountService.getBinanceDeeplink(appId))
      )
      .subscribe((binanceData: IBinanceDeepLinkResponse) => {
        const contractStatus = binanceData.status;

        if (contractStatus === ContractStatusesType.Finish) {
          this.redirectToDetails();
        } else if (contractStatus === ContractStatusesType.Reject) {
          this.openErrorModal();
        }
      });
  }

  private redirectToDetails() {
    this.router.navigate([`/top-up-account/${ TOP_UP_PATH.binanceDetails }`]);
  }
}
