import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { mergeMap } from 'rxjs/operators';
import { IBinanceEmailConnect, IBinanceDeepLinkResponse, TOP_UP_PATH } from '../../top-up-account.interface';
import { TopUpAccountService } from '../../top-up-account.service';
import { Router } from '@angular/router';
import { emailPattern } from '../../../tools/regular-expressions';

@Component({
  selector: 'app-top-up-account-email',
  templateUrl: './top-up-account-email.component.html',
  styleUrls: ['./top-up-account-email.component.scss']
})
export class TopUpAccountEmailComponent implements OnInit {
  form: FormGroup;
  emailBinanceControl: AbstractControl;

  isLoading: boolean;

  private appId: any;

  constructor(
    private fb: FormBuilder,
    private topUpAccountService: TopUpAccountService,
    private router: Router
  ) {
  }

  get getErrorMessages(): string {
    const errors: ValidationErrors = this.emailBinanceControl.errors;

    if (errors) {
      if (errors.connectionError) {
        return 'topup.binance.connect_firstStep_error3';
      }
      if (errors.walletNotExist) {
        return 'topup.binance.connect_firstStep_error4';
      }
      if (errors.required) {
        return 'topup.binance.connect_firstStep_required';
      }
      if (errors.pattern) {
        return 'topup.binance.connect_firstStep_error1';
      }
      else {
        return '';
      }
    }
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = this.fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(255),
          Validators.pattern(emailPattern)
        ]
      ]
    });

    this.emailBinanceControl = this.form.get('email');
  }

  showError(controlName: string): boolean {
    const control: AbstractControl = this.form.get(controlName);

    return control.invalid && control.touched && control.dirty;
  }

  sendEmail() {
    if (this.emailBinanceControl.valid) {
      this.topUpAccountService.connectBinance(this.emailBinanceControl.value).pipe(
        mergeMap((result: IBinanceEmailConnect) => {
          if (result.applicationId) {
            this.appId = result.applicationId;
            return this.topUpAccountService.getBinanceDeeplink(result.applicationId);
          } else {
            this.emailBinanceControl.setErrors({walletNotExist: true});
          }
        })
      ).subscribe({
        next: (binanceData: IBinanceDeepLinkResponse | any) => {
          if (!binanceData?.errors) {
            this.router.navigate([`/top-up-account/${TOP_UP_PATH.binanceVerified}`], {
              state: {
                binanceData,
                appId: this.appId,
              }
            }).then(() => this.isLoading = false);
          }
        },
        error: err => {
          console.error('getBinanceDeeplink:', err);
          // TODO: Add err page
        }
      });
    }
  }
}
