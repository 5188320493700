<div class="err-modal">
  <img ngSrc="assets/imgs/error-icon.svg" alt="error" class="err-modal__err-icon" height="373" width="280">

  <div class="err-modal__content">

    <div class="text-wrapper">
      <h3 class="err-modal__title">Blackcatcard</h3>

      <p class="err-modal__description">Something went wrong. Try later</p>
    </div>

    <div class="bottom">
      <ion-button fill="outline" color="white" (click)="close()">OK</ion-button>
    </div>

  </div>
</div>
