import { Md5 } from 'ts-md5';

export const PROD_TEST_PHONE_LIST = [
  '18882222222'
];

export const DEV_TEST_PHONE_LIST = [
  '79111111111',
  '79222222222',
  '79333333333',
  '79444444444',
  '79555555555',
  '79666666666',
  '79000000001',
  '79000000002',
  '79000000003',
  '79000000004',
  '79000000005',
  '79000000007',
  '79000000008',
  '79000000009',
  '371284357643',
  '37444000000',
  '79061707277',
  '79900000001',
  '79900000002',
  '79900000003',
  '79900000004',
  '79900000005',
  '79900000006',
  '79900000007',
  '79900000008',
  '79900000009',
  '79900000006'
];

export function normalisePhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace('+', '');
}

export function getPasswordHash(phoneNumber: string, password: string): string {
  return Md5.hashStr(`${password}_${normalisePhoneNumber(phoneNumber)}`) as string;
}
