import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { LocalizationIsLoadedDirective } from './directives/localization-is-loaded.directive';
import { LocalizationNotLoadedDirective } from './directives/localization-not-loaded.directive';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const lang = (params.translateService) ? params.translateService.currentLang || params.translateService.defaultLang : 'en';
    // console.log(111, params, `gekkard.${params.key}`);
    return (params.translateService && params.translateService.translations[lang])
      ? params.translateService.translations[lang][`gekkard.${params.key}`]
      : params.key;
  }
}

@NgModule({
  declarations: [
    LocalizationIsLoadedDirective,
    LocalizationNotLoadedDirective,
  ],
  exports: [
    LocalizationIsLoadedDirective,
    LocalizationNotLoadedDirective,
    TranslatePipe,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    })
  ]
})
export class InternationalizationModule { }
