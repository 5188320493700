<form [formGroup]="searchForm" class="selectCountry">
	<div class="selectCountry-head">
		<p class="selectCountry-title">{{ 'login.country_select.title' | translate }}</p>
		<div class="selectCountry-search">
			<app-search-bar>
				<input #search
					class="search-bar-input"
					formControlName="searchValue"
					type="text"
					placeholder="{{ 'contactSelect.text' | translate }}"
				/>
			</app-search-bar>
		</div>
	</div>
	<app-country-select
    *ngIf="!locales"
		[findCountry]="searchValue.value"
		[showCountryCode]="showCountryCode"
		(activeCountry)="setActiveCountry($event)">
	</app-country-select>

	<app-language-select
      *ngIf="locales"
			[findCountry]="searchValue.value"
			(activeLanguage)="setActiveCountry($event)">
	</app-language-select>

	<div class="selectCountry-buttons">
		<ion-button
			(click)="closeModal()"
			type="button"
			expand="full" shape="round" fill="outline" color="dark">
				{{ 'language.first_button' | translate }}
		</ion-button>
		<ion-button
			(click)="closeModal(activeCountry, activeLanguage)"
			type="button"
			expand="full" shape="round" fill="outline" color="success">
				{{ 'login.alert.button.ok' | translate }}
		</ion-button>
	</div>
</form>
