import { Component } from '@angular/core';

@Component({
  selector: 'app-movement-money-skeleton',
  templateUrl: './movement-money-skeleton.component.html',
  styleUrls: ['./movement-money-skeleton.component.scss']
})
export class MovementMoneySkeletonComponent {
  constructor() {
  }
}
