import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crypto-send-internal-templates',
  templateUrl: './crypto-send-internal-templates.component.html',
  styleUrls: ['./crypto-send-internal-templates.component.scss']
})
export class CryptoSendInternalTemplatesComponent implements OnInit {

  templates = [
    {
      'phone': '+44 (131) 4962328',
      'name': 'John Snow'
    },
    {
      'phone': '+39 (333) 7251309',
      'name': 'To Designer'
    },
    {
      'phone': '+44 (772) 4922537',
      'name': 'Ann Swan'
    },
    {
      'phone': '+44 (776) 5240281',
      'name': ''
    },
    {
      'phone': '+49 (795) 9012788',
      'name': 'Stefan Mathias'
    },
    {
      'phone': '+44 (589) 0922537',
      'name': 'Kristian Thomas'
    },
    {
      'phone': '+49 (589) 0922500',
      'name': ''
    },
    {
      'phone': '+49 (589) 0772512',
      'name': 'Minna Karin'
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
