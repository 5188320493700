<div *ngIf="page == 'Code'" class="page TopUpCode">
  <div class="page__content ion-text-center">

    <div class="TopUpCode-panel" (click)="onWhereToBy()" *ngIf="!hideWhereToTopUp">
      <ion-icon name="icon-topUpPlace"></ion-icon>
      <h4>{{ 'topup.where_to_top_up' | translate }}?</h4>
    </div>

    <div
        *appPermission="[ roles.BCC_LEGAL_PERSON, roles.BCC_INDIVIDUAL_PERSON ]"
        class="TopUpCode-panel" (click)="onEnterTopUpCode()" [ngClass]="{'disableEnter': !accountNumber}">
      <ion-icon name="icon-topUpBarcode"></ion-icon>
      <h4>{{ 'topup.codeEnter_title' | translate }}</h4>
    </div>

  </div>
</div>

<app-top-up-map
  *ngIf="page == 'Buy'"
  [markers]="markers"
  [center]="center"
  [isLoading]="isLoading$"
  (goBackEmit)="page = $event">
</app-top-up-map>

<app-top-up-enter-code
  *ngIf="page == 'EnterCode'"
  [errorCode]="errorCode"
  [errorCodeUsed]="errorCodeUsed"
  (goBackEmit)="page = $event"
  (submitEmit)="enterTopupSubmit($event)">
</app-top-up-enter-code>

<app-top-up-confirm-code
  *ngIf="page == 'Confirmation'"
  [topupCode]="topupCode"
  [topupAmount]="topupAmount"
  [topupCurrency]="topupCurrency"
  [incorrectAccount]="incorrectAccount"
  (goBackEmit)="page = $event"
  (submitEmit)="confirmTopupSubmit($event)"
  ></app-top-up-confirm-code>

<app-top-up-success-code
  *ngIf="page == 'Succeess'"
  [topupAmount]="topupAmount"
  [topupCurrency]="topupCurrency"
  (goBackEmit)="page = $event"
  (submitEmit)="successTopupSubmit()"
  >
</app-top-up-success-code>
<app-top-up-reject-code
    *ngIf="page == 'Reject'"
    (goBackEmit)="page = $event"
    (submitEmit)="successRejectSubmit()"
></app-top-up-reject-code>
