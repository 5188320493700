<ion-content>
  <div class="Receipt">
    <div class="Receipt-content">
      <app-transfer-recipe [operation]="operation"></app-transfer-recipe>
    </div>
    <div class="Receipt-buttons">
      <ion-button
        expand="full" shape="round" fill="outline" color="dark"
        (click)="onClose()">
          {{ 'transaction.sixth_button' | translate }}
      </ion-button>
      <ion-button
        expand="full" shape="round" fill="outline" color="success"
        (click)="onExportReceipt(operation.referenceNumber)">
          <span *ngIf="!(isLoading$ | async)">
            {{ 'templatesEdit.save_button' | translate }}
          </span>
          <ion-spinner *ngIf="isLoading$ | async" name="lines-small" color="dark"></ion-spinner>
      </ion-button>
    </div>
  </div>
</ion-content>
