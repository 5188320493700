<div class="ConfirmDialog ion-text-center">
  <div class="ConfirmDialog__title">
    <span>{{ 'deleteAlert.text' | translate: {name: 'tempalte name here'} }}</span>
  </div>
  <div class="ConfirmDialog__buttons">
    <ion-button
            expand="full" shape="round" fill="outline" color="dark"
            (click)="closeModal()">
      {{ 'logOut.first_button' | translate }}
    </ion-button>
    <ion-button
            expand="full" shape="round" fill="outline" color="danger"
            (click)="closeModal(true)">
      {{ 'CryptoConfirm.ConfirmButton' | translate }}
    </ion-button>
  </div>
</div>
