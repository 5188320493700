import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { FormCustomElementsModule } from '../form-custom-elements/form-custom-elements.module';
import { PipesModule } from '../../pipes/pipes.module';

import { ClientSelectComponent } from './client-select/client-select.component';
import { ClientChipComponent } from './client-chip/client-chip.component';
import { ClientsBasePropertyPanelComponent } from './clients-base-property-panel/clients-base-property-panel.component';
import { ClientBasePropertyEditorComponent } from './client-base-property-editor/client-base-property-editor.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';

@NgModule({
  declarations: [
    ClientSelectComponent,
    ClientChipComponent,
    ClientsBasePropertyPanelComponent,
    ClientBasePropertyEditorComponent
  ],
  exports: [
    ClientSelectComponent,
    ClientsBasePropertyPanelComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FormCustomElementsModule,
    PipesModule,
    InternationalizationModule
  ]
})
export class MultiClientsModule { }
