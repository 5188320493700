import { environment } from 'environments/environment';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewChecked,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { AbstractControl, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ModalsService } from '../../modals.service';
import { ComingSoonModalsService } from '../../coming-soon-modals.service';
import { PaymentsService } from '../../../services/payments/payments.service';
import { regexpOnlyNumbers } from '../../../tools/regular-expressions';

import { NotificationServiceItem } from '../../../services/server-notifications/server-notifications.service';
import { PaymentSepaRequest, PaymentSwiftRequest, PaymentContact, BankPayment } from 'models/payment.interfaces';
import { OperationType } from '../../../users/organizations.interface';
import { ICommissionRequest } from '../../../+top-up-account/top-up-account.interface';

@Component({
  selector: 'app-transfer-confirm-code',
  templateUrl: './transfer-confirm-code.component.html',
  styleUrls: ['./transfer-confirm-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferConfirmCodeComponent implements OnInit, AfterViewChecked {

  @ViewChild('confirmCode', {static: true}) inputConfirmCode: ElementRef<HTMLInputElement>;

  @Input() transferData!: BankPayment | NotificationServiceItem | ICommissionRequest;
  @Input() transferToken!: string;
  @Input() operationType!: OperationType;

  confirmationByPIN: boolean = environment.bankSettings.transactionConfirmationType === 'PIN';

  smsTimer = 0;
  timerIsActive = false;

  isLoading = false;
  hasBackendError = false;

  confirmationForm = new UntypedFormGroup({
    confirmationCode: new UntypedFormControl(
      null,
      [
        Validators.required,
        Validators.minLength((this.confirmationByPIN) ? 4 : 6),
        Validators.pattern(regexpOnlyNumbers)
      ]
    )
  });

  get confirmCode(): AbstractControl {
    return this.confirmationForm.get('confirmationCode');
  }

  get displayError(): boolean {
    return this.hasBackendError
      || this.confirmCode.invalid && this.confirmCode.dirty && this.confirmCode.value.length > ((this.confirmationByPIN) ? 3 : 5);
  }

  private static isSepa(transferData: BankPayment | NotificationServiceItem | ICommissionRequest): transferData is PaymentSepaRequest {
    return (transferData as PaymentSepaRequest).iban !== undefined;
  }

  private static isSwift(transferData: BankPayment | NotificationServiceItem | ICommissionRequest): transferData is PaymentSwiftRequest {
    return (transferData as PaymentSwiftRequest).swiftCode !== undefined;
  }

  private static isContact(transferData: BankPayment | NotificationServiceItem | ICommissionRequest): transferData is PaymentContact {
    return (transferData as PaymentContact).cardNumber !== undefined;
  }

  private static isContactAny(
    transferData: BankPayment | NotificationServiceItem | ICommissionRequest
  ): transferData is NotificationServiceItem {
    return (transferData as NotificationServiceItem).uuid !== undefined;
  }

  constructor(
    private modals: ModalsService,
    private comingSoonModal: ComingSoonModalsService,
    private payments: PaymentsService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.timerIsActive = true;
  }

  ngAfterViewChecked() {
    this.inputConfirmCode.nativeElement.focus();
  }

  private setLoadingStatus(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.cdr.markForCheck();
  }

  onSubmit({value, valid}: { value: { confirmationCode: string }, valid: boolean }): void {
    if (valid) {
      this.isLoading = true;
      if (this.operationType === 'PAYMENT_SEPA') {
        this.payments.confirmBankTransfer(<PaymentSepaRequest>this.transferData, value.confirmationCode, this.transferToken)
          .pipe(
            finalize(() => this.setLoadingStatus(false))
          )
          .subscribe(
            response => this.closeModal(response),
            () => this.hasBackendError = true
          );
      }
      if (this.operationType === 'PAYMENT_SWIFT') {
        this.payments.confirmSwiftTransfer(<PaymentSwiftRequest>this.transferData, value.confirmationCode, this.transferToken)
          .pipe(
            finalize(() => this.setLoadingStatus(false))
          )
          .subscribe(
            response => this.closeModal(response),
            () => this.hasBackendError = true
          );
      }
      if (this.operationType === 'PAYMENT_CONTACT') {
        this.isLoading = true;
        this.payments.confirmContactTransfer(<PaymentContact>this.transferData, value.confirmationCode, this.transferToken)
          .pipe(
            finalize(() => this.setLoadingStatus(false))
          )
          .subscribe(
            response => this.closeModal(response),
            () => this.hasBackendError = true
          );
      }
      if (this.operationType === 'PAYMENT_CONTACT_ANY') {
        const paymentDetail = <NotificationServiceItem>this.transferData;
        this.payments.confirmAnyContactTransfer(paymentDetail.uuid, value.confirmationCode, this.transferToken)
          .pipe(
            finalize(() => this.setLoadingStatus(false))
          )
          .subscribe(
            response => this.closeModal(response),
            () => this.hasBackendError = true
          );
      }
      if (this.operationType === 'TOPUP_BY_BINANCE') {
        const topUpData = <ICommissionRequest>this.transferData;
        this.payments.confirmBinanceTopUp(topUpData, value.confirmationCode, this.transferToken)
          .pipe(
            finalize(() => this.setLoadingStatus(false))
          )
          .subscribe(
            {
              next: response => this.closeModal(response),
              error: () => this.hasBackendError = true
            }
          );
      }
    }
  }

  resendCode({value, valid}: { value: { confirmationCode: string }, valid: boolean }) {
    this.timerIsActive = false;
    this.confirmCode.setErrors({timeout: null});
    if (TransferConfirmCodeComponent.isSepa(this.transferData)) {
      this.isLoading = true;
      this.timerIsActive = true;
      this.payments.requestBankTransfer(this.transferData)
        .subscribe(response => {
          this.transferToken = PaymentsService.getTokenFromConfirmCodeResponse(response);
          this.isLoading = false;
        });
    }
    if (TransferConfirmCodeComponent.isSwift(this.transferData)) {
      this.isLoading = true;
      this.timerIsActive = true;
      this.payments.requestSwiftTransfer(this.transferData)
        .subscribe(response => {
          this.transferToken = PaymentsService.getTokenFromConfirmCodeResponse(response);
          this.isLoading = false;
        });
    }
    if (TransferConfirmCodeComponent.isContact(this.transferData)) {
      this.isLoading = true;
      this.timerIsActive = true;
      this.payments.requestContactTransfer(this.transferData)
        .subscribe(response => {
          this.transferToken = PaymentsService.getTokenFromConfirmCodeResponse(response);
          this.isLoading = false;
        });
    }
    if (TransferConfirmCodeComponent.isContactAny(this.transferData)) {
      this.isLoading = true;
      this.timerIsActive = true;
      this.payments.requestAnyContactTransfer(this.transferData.uuid)
        .subscribe(response => {
          this.transferToken = PaymentsService.getTokenFromConfirmCodeResponse(response);
          this.isLoading = false;
        });
    }
  }

  enableLimits(): void {
    this.timerIsActive = false;
    this.confirmCode.setErrors({timeout: true});
  }

  closeModal(value?: any): void {
    this.modals.closeModal(value || null);
  }

}
