<ion-item>
  <ion-badge class="account-color">
  </ion-badge>
  <div class="ion-text-wrap">

    <div class="HistoryItem">
      <div class="History-item__icon" slot="start">
        <!--    <ion-icon name="icon-{{ rateCurrency.toLocaleLowerCase() }}"></ion-icon>-->
      </div>
      <div class="HistoryItem-content">
        <div class="HistoryItem-header">
          <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
        </div>
        <div class="HistoryItem-values">
          <ion-skeleton-text [animated]="true" style="width: 100%;"></ion-skeleton-text>
        </div>
      </div>
    </div>
  </div>
</ion-item>
