import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TrustedClient } from '../../../users/organizations.interface';
import { Observable } from 'rxjs';
import { PaymentOperationRepeat } from '../../../users/operations/operations.interface';
import { RightContainerService } from '../../right-container.service';

@Component({
  selector: 'app-exchange-money-history-item',
  templateUrl: './exchange-money-history-item.component.html',
  styleUrls: ['./exchange-money-history-item.component.scss']
})
export class ExchangeMoneyHistoryItemComponent implements OnInit, OnChanges {

  @Input() item;
  @Input() trustedClients?: Array<TrustedClient>;
  @Input() isLoading?: Observable<boolean>;

  @Output() repeatOperation = new EventEmitter<PaymentOperationRepeat>();

  itemIsOpen = false;
  itemColor = null;

  sellValue?: string;
  sellCurrency?: string;
  buyValue?: string;
  rateCurrency?: string;
  rateValue?: string;

  constructor( private rightContainer: RightContainerService ) { }

  ngOnInit() {
      if (this.item.description) {
        const dataArr = this.item.description.split('  ');
        dataArr.forEach((item) => {
          (item.includes('Sell'))
            ? (this.sellValue = item.split(':')[1].trim(),
              this.sellCurrency = this.sellValue.split(' ')[1].trim())
            : (item.includes('Buy'))
            ? (this.buyValue = item.split(':')[1].trim(),
              this.rateCurrency = this.buyValue.split(' ')[1].trim())
            : (item.includes('Rate'))
              ? this.rateValue = item.split(':')[1].trim()
              : (
                this.rateValue = '',
                this.sellValue = '',
                this.sellCurrency = '',
                this.buyValue = '',
                this.rateCurrency = ''
              );
        });
        /*console.log({
          sellValue: this.sellValue,
          buyValue: this.buyValue,
          rateValue: this.rateValue
        });*/
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes', changes);
    if (changes.hasOwnProperty('trustedClients')) {
      const transactionClientId = this.item.clientId;
      const foundClient = this.trustedClients.find(a => a.clientId === transactionClientId);
      this.itemColor = (foundClient && foundClient.color && this.trustedClients.length > 1)
        ? foundClient.color.toLocaleLowerCase()
        : null;
    }
  }

  repeat(operation: PaymentOperationRepeat) {
    this.repeatOperation.emit(operation);
  }

  showFullInfo() {
    this.itemIsOpen = !this.itemIsOpen;
  }

}
