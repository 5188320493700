export function getApiByUrl(url: string): string {
  return url
    .split('?')[0]
    .split('//')[1]
    .split('/')
    .slice(2, url.length)
    .join('/');
}

export function checkEndpointOnExist(url: string, ignoredEndpointList: Array<string | RegExp>): boolean {
  // console.log('checkEndpointOnExist', { url, ignoredEndpointList });
  return !!ignoredEndpointList
    .find((endpoint) => (endpoint instanceof RegExp) ? endpoint.test(url) : endpoint === url);
}
