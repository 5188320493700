import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { concatAll, filter, finalize, flatMap, map, switchMap, toArray } from 'rxjs/operators';

import { AuthManagerService } from '../../users/auth/auth-manager.service';
import { AppConfigurationService } from '../../app.configuration.service';
import { AppCryptoService } from '../../app.crypto.service';

import {
  BankPayment, CurrencyCode, PaymentContact, PaymentSepaRequest, PaymentSuccessResponse, PaymentSwiftRequest,
  PaymentAmount, PaymentCommission, PaymentConfirmCodeResponse
} from 'models/payment.interfaces';
import { PaymentOperationTemplate, PAYMENT_TYPE } from '../../users/operations/operations.interface';
import { AccountCurrencyBalance } from '../../users/organizations.interface';
import { PAYMENTS_DETAILS } from './payments.constant';
import { SWIFTRate, SWIFTRatesResponse } from './payments.models';
import { ICommissionRequest, ITopUpBinanceResponse } from '../../+top-up-account/top-up-account.interface';

export interface VerifiedPaymentAmount {
  amount: number;
  currencyCode: CurrencyCode;
}

const CONFIRMATION_TYPE = environment.bankSettings.transactionConfirmationType;

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  private activePaymentStoreSource = new BehaviorSubject<BankPayment>(null);
  activePaymentStore$: Observable<BankPayment> = this.activePaymentStoreSource.asObservable();

  private isLoadingSource = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSource.asObservable();

  static getPaymentDetailList(): ReadonlyMap<string, string> {
    return PAYMENTS_DETAILS;
  }

  static getTokenFromConfirmCodeResponse(model: PaymentConfirmCodeResponse | any): string {
    return (model && model.errors && model.errors[0].code === 449) ? model.errors[0].properties.confirmationToken : null;
  }

  static getPaymentRepeatType(model: BankPayment): PAYMENT_TYPE {
    if ((<PaymentContact>model).phoneNumber) {
      return PAYMENT_TYPE.PaymentPhone;
    }
    if ((<PaymentContact>model).fromCardId || (<PaymentContact>model).cardNumber) {
      return PAYMENT_TYPE.PaymentCard;
    }
    if ((<PaymentSepaRequest>model).iban) {
      return PAYMENT_TYPE.PaymentSepa;
    }
    if ((<PaymentSwiftRequest>model).swiftCode) {
      return PAYMENT_TYPE.PaymentSwift;
    }
    return null;
  }

  static getPaymentOperationTemplate(model: BankPayment, name: string): PaymentOperationTemplate {
    return {
      // account: model.account,
      amount: (model && model.amount) ? model.amount.sum.value : null,
      beneficiaryName: model.beneficiaryName,
      currencyCode: (model && model.amount) ? model.amount.sum.currency.code : null,
      fromAccountId: model.account,
      fromCardId: ((<PaymentContact>model).fromCardId) ? (<PaymentContact>model).fromCardId : null,
      fromCardPan: null, // ??? o_O
      purpose: model.purpose,
      title: name,
      toIban: ((<PaymentSepaRequest>model).iban) ? (<PaymentSepaRequest>model).iban : null,
      toCardNumber: ((<PaymentContact>model).cardNumber) ? (<PaymentContact>model).cardNumber : null,
      toPhoneNumber: ((<PaymentContact>model).phoneNumber) ? (<PaymentContact>model).phoneNumber : null,
      transferDetails: ((<PaymentSepaRequest>model).transferDetails) ? (<PaymentSepaRequest>model).transferDetails : null,
      type: PaymentsService.getPaymentRepeatType(model),
      beneficiaryBank: ((<PaymentSwiftRequest>model).beneficiaryBank) ? (<PaymentSwiftRequest>model).beneficiaryBank : null,
      country: ((<PaymentSwiftRequest>model).country) ? (<PaymentSwiftRequest>model).country : null,
      city: ((<PaymentSwiftRequest>model).city) ? (<PaymentSwiftRequest>model).city : null,
      address: ((<PaymentSwiftRequest>model).address) ? (<PaymentSwiftRequest>model).address : null,
      swiftCode: ((<PaymentSwiftRequest>model).swiftCode) ? (<PaymentSwiftRequest>model).swiftCode : null,
      commissionType: ((<PaymentSwiftRequest>model).commissionType) ? (<PaymentSwiftRequest>model).commissionType : null,
      urgency: ((<PaymentSwiftRequest>model).urgency) ? (<PaymentSwiftRequest>model).urgency : null,
      beneficiaryAccount: ((<PaymentSwiftRequest>model).beneficiaryAccount) ? (<PaymentSwiftRequest>model).beneficiaryAccount : null
    };
  }

  static isSwiftPayment(payment: PaymentSepaRequest | PaymentSwiftRequest): payment is PaymentSwiftRequest {
    return (payment as PaymentSwiftRequest).swiftCode !== undefined;
  }

  static isContactPayment(payment: PaymentSepaRequest | PaymentSwiftRequest | PaymentContact): payment is PaymentContact {
    return (payment as PaymentContact).cardNumber !== undefined || (payment as PaymentContact).phoneNumber !== undefined;
  }

  static getBalanceByCurrencyCode(balances: Array<AccountCurrencyBalance>, currency: CurrencyCode): AccountCurrencyBalance {
    return (balances && balances.length && currency) ? balances.find(b => b.currency === currency) : null;
  }

  static getCurrencyFromPaymentAmount(data: PaymentAmount): CurrencyCode {
    return (data && data.sum && data.sum.currency) ? data.sum.currency.code : null;
  }

  static getAmountFromPaymentAmount(data: PaymentAmount): number {
    return (data && data.sum) ? data.sum.value : null;
  }

  static getTotalAmount(value: number, currency: CurrencyCode): VerifiedPaymentAmount {
    return {amount: value, currencyCode: currency};
  }

  constructor(
    private http: HttpClient,
    private auth: AuthManagerService,
    private appConfiguration: AppConfigurationService,
    private appCrypto: AppCryptoService) {
  }

  setActivePayment(value: BankPayment): void {
    this.activePaymentStoreSource.next(value);
  }

  resetActivePayment(): void {
    this.activePaymentStoreSource.next(null);
  }

  private getTransferConfirmHeadersPin(token: string, confirmCode: string): Observable<HttpHeaders> {
    return of(new HttpHeaders({
      'X-Confirmation-Type': 'PIN',
      'X-Confirmation-Code': confirmCode,
      'X-Confirmation-Token': token
    }));
  }

  private getTransferConfirmHeadersSign(token: string): Observable<HttpHeaders> {
    return forkJoin([
      this.appCrypto.appCryptoUuid$,
      this.appCrypto.appDecryptedPassword$
    ]).pipe(
      map(([appUuid, decryptedPassword]) => {
        const payload = {
          initiator: this.auth.authPhoneNumber,
          confirmationToken: token,
          exp: Date.now() + 0.5 * 60 * 1000 // + 30sec
        };
        return new HttpHeaders({
          'X-Confirmation-Type': 'SIGN',
          'X-Confirmation-Code': AppCryptoService.generateJWT(payload, decryptedPassword),
          'X-Confirmation-Token': token,
          'X-App-Uuid': appUuid
        });
      })
    );
  }

  private initTransferConfirmProcess(confirmationType: string, token: string, confirmCode: string): Observable<HttpHeaders> {
    if (!confirmationType) {
      console.warn('Confirmation type mot specified');
      return;
    }
    return (confirmationType === 'PIN')
      ? this.getTransferConfirmHeadersPin(token, confirmCode)
      : this.auth.verifyPassword(this.auth.authPhoneNumber, confirmCode).pipe(
        switchMap(() => this.getTransferConfirmHeadersSign(token))
      );
  }

  requestBankTransfer(body: PaymentSepaRequest): Observable<PaymentConfirmCodeResponse> {
    const url = environment.baseApi + `/v1/payment_sepa`;
    const headers = new HttpHeaders({
      'X-Confirmation-Type': CONFIRMATION_TYPE
    });
    const bodyRequest = {
      payment_sepa: body
    };
    this.setLoadingStatus(true);
    return this.http.post<PaymentConfirmCodeResponse>(url, bodyRequest, {headers}).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  confirmBankTransfer(body: PaymentSepaRequest, confirmCode: string, token: string): Observable<PaymentSuccessResponse> {
    const url = environment.baseApi + `/v1/payment_sepa`;
    // const headers = new HttpHeaders({
    //   'X-Confirmation-Code': smsCode,
    //   'X-Confirmation-Token': token
    // });
    const bodyRequest = {
      payment_sepa: body
    };
    this.setLoadingStatus(true);
    return this.initTransferConfirmProcess(CONFIRMATION_TYPE, token, confirmCode).pipe(
      switchMap((headers) => this.http.post<PaymentSuccessResponse>(url, bodyRequest, {headers})),
      finalize(() => this.setLoadingStatus(false))
    );
  }

  requestBinanceTopUp(body: ICommissionRequest): Observable<ITopUpBinanceResponse> {
    const url = environment.baseApi + `/v1/deals/conversion`;
    const headers = new HttpHeaders(
      {
        'productId': 'MANERIO',
        'X-Confirmation-Type': CONFIRMATION_TYPE
      }
    );

    this.setLoadingStatus(true);
    return this.http.post<ITopUpBinanceResponse>(url, body, {headers}).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  confirmBinanceTopUp(body: ICommissionRequest, confirmCode: string, token: string): Observable<ITopUpBinanceResponse> {
    const url = environment.baseApi + `/v1/deals/conversion`;

    this.setLoadingStatus(true);
    return this.initTransferConfirmProcess(CONFIRMATION_TYPE, token, confirmCode).pipe(
      switchMap((headers) => {
        headers.set('productId', 'MANERIO');
        return this.http.post<ITopUpBinanceResponse>(url, body, {headers});
      }),
      finalize(() => this.setLoadingStatus(false))
    );
  }

  requestSwiftTransfer(payment: PaymentSwiftRequest): Observable<any> {
    const url = environment.baseApi + `/v1/payment_swift`;
    const bodyRequest = {
      payment_swift: payment
    };
    this.setLoadingStatus(true);
    return this.http.post(url, bodyRequest).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  confirmSwiftTransfer(payment: PaymentSwiftRequest, confirmCode: string, token: string): Observable<PaymentSuccessResponse> {
    const url = environment.baseApi + `/v1/payment_swift`;
    const bodyRequest = {
      payment_swift: payment
    };
    this.setLoadingStatus(true);
    return this.initTransferConfirmProcess(CONFIRMATION_TYPE, token, confirmCode).pipe(
      switchMap((headers) => this.http.post<PaymentSuccessResponse>(url, bodyRequest, {headers})),
      finalize(() => this.setLoadingStatus(false))
    );
  }

  requestContactTransfer(body: PaymentContact): Observable<PaymentConfirmCodeResponse> {
    const url = environment.baseApi + `/v1/payment_contact`;
    const headers = new HttpHeaders({
      'X-Confirmation-Type': CONFIRMATION_TYPE
    });
    const bodyRequest = {
      payment_contact: body
    };
    this.setLoadingStatus(true);
    return this.http.post<PaymentConfirmCodeResponse>(url, bodyRequest, {headers}).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  confirmContactTransfer(body: PaymentContact, confirmCode: string, token: string): Observable<PaymentSuccessResponse> {
    const url = environment.baseApi + `/v1/payment_contact`;
    const bodyRequest = {
      payment_contact: body
    };
    this.setLoadingStatus(true);
    return this.initTransferConfirmProcess(CONFIRMATION_TYPE, token, confirmCode).pipe(
      switchMap((headers) => this.http.post<PaymentSuccessResponse>(url, bodyRequest, {headers})),
      finalize(() => this.setLoadingStatus(false))
    );
  }

  requestAnyContactTransfer(operationUuid: string): Observable<PaymentConfirmCodeResponse> {
    const url = environment.baseApi + `/v1/payment_any_contact`;
    const headers = new HttpHeaders({
      'X-Confirmation-Type': CONFIRMATION_TYPE
    });
    const bodyRequest = {
      operationUuid: operationUuid
    };
    this.setLoadingStatus(true);
    return this.http.post<PaymentConfirmCodeResponse>(url, bodyRequest, {headers}).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  confirmAnyContactTransfer(operationUuid: string, confirmCode: string, token: string): Observable<PaymentSuccessResponse> {
    const url = environment.baseApi + `/v1/payment_any_contact`;
    // const headers = new HttpHeaders({
    //   'X-Confirmation-Code': smsCode,
    //   'X-Confirmation-Token': token
    // });
    const bodyRequest = {
      operationUuid: operationUuid
    };
    this.setLoadingStatus(true);
    return this.initTransferConfirmProcess(CONFIRMATION_TYPE, token, confirmCode).pipe(
      switchMap((headers) => this.http.post<PaymentSuccessResponse>(url, bodyRequest, {headers})),
      finalize(() => this.setLoadingStatus(false))
    );
  }

  rejectAnyContactTransfer(operationUuid: string): Observable<PaymentSuccessResponse> {
    const url = environment.baseApi + `/v1/payment_any_contact`;
    const params = new HttpParams()
      .set('uuid', operationUuid);
    return this.http.delete<PaymentSuccessResponse>(url, {params});
  }

  checkPhoneNumberForAttachmentToBank(phoneNumber: string): Observable<boolean> {
    return this.filterPhoneListAttachedToBank([phoneNumber]).pipe(
      map(phoneList => !!phoneList.length)
    );
  }

  filterPhoneListAttachedToBank(phoneList: Array<string>): Observable<string[]> {
    const url = environment.baseApi + `/v1/users/filter/list`;
    const body = {
      users: phoneList
    };
    return this.http.post<{
      users: Array<string>
    }>(url, body).pipe(
      map(response => response.users)
    );
  }

  getSepaCommission(payment: PaymentSepaRequest): Observable<PaymentCommission> {
    return this.appConfiguration.getSepaFeeDisplayConfig().pipe(
      filter(predicate => predicate),
      flatMap(() => this.requestSepaCommission(payment))
    );
  }

  private requestSepaCommission(payment: PaymentSepaRequest): Observable<PaymentCommission> {
    const url = environment.baseApi + `/v1/payment_sepa/commission`;
    const bodyRequest = {
      payment_sepa: payment
    };
    return this.http.post<PaymentCommission>(url, bodyRequest);
  }

  getSwiftCommission(payment: PaymentSwiftRequest): Observable<PaymentCommission> {
    return this.appConfiguration.getSwiftFeeDisplayConfig().pipe(
      filter(predicate => predicate),
      flatMap(() => this.requestSwiftCommission(payment))
    );
  }

  requestSwiftCommission(payment: PaymentSwiftRequest): Observable<PaymentCommission> {
    const url = environment.baseApi + `/v1/payment_swift/commission`;
    const bodyRequest = {
      payment_swift: payment
    };
    return this.http.post<PaymentCommission>(url, bodyRequest);
  }

  getContactCommission(payment: PaymentContact): Observable<PaymentCommission> {
    const feeDisplayConfig = (payment.phoneNumber)
      ? this.appConfiguration.getContactFeeDisplayConfig()
      : this.appConfiguration.getCardFeeDisplayConfig();
    return feeDisplayConfig.pipe(
      filter(Boolean),
      flatMap(() => this.requestContactCommission(payment))
    );
  }

  private requestContactCommission(body: PaymentContact): Observable<PaymentCommission> {
    const url = environment.baseApi + `/v1/payment_contact/commission`;
    const bodyRequest = {
      payment_contact: body
    };
    return this.http.post<PaymentCommission>(url, bodyRequest);
  }

  private setLoadingStatus(predicate: boolean): void {
    this.isLoadingSource.next(predicate);
  }

  getSWIFTRatesByAccount(accountId: string): Observable<SWIFTRate[]> {
    const url = environment.baseApi + '/v1/deals/conversion/rates/' + accountId;
    return this.http.get<SWIFTRatesResponse>(url).pipe(
      map((response) => response.rates)
    );
  }

  getAvailableCurrencyForSWIFT(accountId: string, currencyCode: CurrencyCode = 'EUR'): Observable<SWIFTRate[]> {
    return this.getSWIFTRatesByAccount(accountId).pipe(
      concatAll(),
      filter((rate) => rate.currencyFrom === currencyCode),
      toArray()
    );
  }

}
