<ion-item *ngIf="item.amount" class="History-item" (click)="showFullInfo()">
  <ion-badge *ngIf="itemColor"
             [color]="itemColor"
             class="account-color">
  </ion-badge>
  <div class="History-item__icon" slot="start">
    <ion-icon name="currency-{{ rateCurrency?.toLocaleLowerCase() }}"></ion-icon>
  </div>
  <div *ngIf="sellCurrency" class="History-item__header">
    <ion-label class="History-item__label">1 {{ sellCurrency }}<small>=</small></ion-label>
    <ion-label class="History-item__label"><b>{{ rateValue }} {{ rateCurrency }}</b></ion-label>
  </div>
  <div class="History-item__values">
    <span class="History-item__value">
      - {{ sellValue }}
    </span>
    <p class="History-item__value green">
      + {{ buyValue }}
    </p>
  </div>
</ion-item>
<!--<div class="History-item__full-info item-{{item.id}}" *ngIf="itemIsOpen">-->
<!--  <div class="History-item__buttons">-->
<!--    <ion-button (click)="repeat(item)" class="buttonRepeat" expand="full" shape="round" fill="outline" color="dark" size="small">-->
<!--      Repeat-->
<!--      <ion-icon slot="end" name="icon-repeat"></ion-icon>-->
<!--    </ion-button>-->
<!--  </div>-->
<!--</div>-->
