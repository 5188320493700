import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { FIREBASE_CONFIG } from './auth/auth-fcm.service';

import { AuthGuard } from './auth/guards/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { PermissionDirective } from './permission/permission.directive';
import { ActiveBankClassDirective } from './permission/active-bank-class.directive';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
  ],
  declarations: [
    PermissionDirective,
    ActiveBankClassDirective
  ],
  exports: [
    PermissionDirective,
    ActiveBankClassDirective
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
})
export class UsersModule { }

