import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountIbanComponent } from './components/account-iban/account-iban.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { NoAccountsAvailable } from './components/no-accounts-available/no-accounts-available.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    AccountIbanComponent,
    NoAccountsAvailable
  ],
  imports: [
    CommonModule,
    IonicModule,
    InternationalizationModule
  ],
  exports: [
    AccountIbanComponent,
    NoAccountsAvailable
  ]
})
export class AccountsModule { }
