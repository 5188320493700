import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DecimalWrapperPipe } from './decimal-wrapper/decimal-wrapper.pipe';
import { StringClippingPipe } from './string-clipping/string-clipping.pipe';
import { HCurrecyPipe } from './hcurrency.pipe';
import { FilterPipe } from './filter/filter.pipe';

@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      DecimalWrapperPipe,
      StringClippingPipe,
      HCurrecyPipe,
      FilterPipe
   ],
   exports: [
      DecimalWrapperPipe,
      StringClippingPipe,
      HCurrecyPipe,
      FilterPipe
   ],
})
export class PipesModule {
}
