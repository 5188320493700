<ion-app>
    <ion-header class="ion-no-border">
        <ion-toolbar>
            <ion-title>Info</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-content class="Info ion-padding">

        <p>The "Minimum Deposit" for each digital asset/cryptocurrency reflects the minimum (lowest) amount required for deposited funds to be credited to your account. Minimums take into consideration the additional transaction costs in moving funds into our own wallets.</p>
        <p>If a deposit is below the required minimum, the funds will not be credited to your account.</p>
        <p>For Ethereum-based tokens (ETH, ETC, REP, ICN, MLN, GNO, and EOS), deposits below the listed minimum can still be credited to your account by depositing more funds into the same deposit address as the below-minimum deposit. The entire balance of that address will be credited to your account in a single, combined deposit when the balance reaches the deposit minimum. For other cryptocurrencies, deposits below the minimum amount will unfortunately not be credited to your account.</p>
        <p>Below is a list of the minimum deposit requirements for each digital asset/cryptocurrency on Kraken. Each cryptocurrency deposit page reflects this information too.</p>

        <!-- <ion-icon class="Info-cat" name="cat-info-crypto-confirmation"></ion-icon> -->

    </ion-content>
</ion-app>
