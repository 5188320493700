import { Component, OnInit } from '@angular/core';

import { RightContainerService } from '../right-container.service';
import { ROLES } from '../../users/users.roles';

@Component({
  selector: 'app-info-transfer-to-contact',
  templateUrl: './info-transfer-to-contact.component.html',
  styleUrls: ['./info-transfer-to-contact.component.scss']
})
export class InfoTransferToContactComponent implements OnInit {
  roles = ROLES;

  constructor(private rightContainer: RightContainerService) { }

  ngOnInit() {
  }

  goBack() {
    this.rightContainer.setRightPanel('transfer-contact-templates');
  }

}
