import { Component, OnInit } from '@angular/core';

import {RightContainerService} from '../right-container.service';

@Component({
  selector: 'app-info-crypto-receive',
  templateUrl: './info-crypto-receive.component.html',
  styleUrls: ['./info-crypto-receive.component.scss']
})
export class InfoCryptoReceiveComponent implements OnInit {

  constructor(private rightContainer: RightContainerService) { }

  ngOnInit() {
  }

}
