<ion-item [class.open]="itemIsOpen" (click)="showFullInfo()">
  <ion-badge *ngIf="itemColor"
    [color]="itemColor"
    class="account-color">
  </ion-badge>
  <div class="ion-text-wrap">

    <div class="HistoryItem">
      <ion-icon *ngIf="item.amount > 0" slot=start name="history-income"></ion-icon>
      <ion-icon *ngIf="item.amount <= 0" slot=start name="history-transfer"></ion-icon>
      <div class="HistoryItem-content">
        <div class="HistoryItem-header">
          <span *ngIf="isPaymentToContact && item.status === 'PROCESSING'" class="HistoryItem-title">
            {{
            (item.amount >= 0)
              ? ('From ' + ((item.fromName) ? item.fromName : item.paymentToRepeat.phoneNumber) + ((item.description) ? ((' : ' + item.description)) : ''))
              : ('To ' + ((item.toName) ? item.toName : item.paymentToRepeat.phoneNumber) + ((item.description) ? ((' : ' + item.description)) : ''))
            }}
          </span>
          <span *ngIf="isPaymentToContact && item.status !== 'PROCESSING'" class="HistoryItem-title">
            {{
              ( item.description.includes('To:') || item.description.includes('From:') )
                ? item.description
                : (item.amount >= 0)
                  ? ('From ' + ((item.fromName) ? item.fromName : item.paymentToRepeat.phoneNumber) + ((item.description) ? ((' : ' + item.description)) : ''))
                  : ('To ' + ((item.toName) ? item.toName : item.paymentToRepeat.phoneNumber) + ((item.description) ? ((' : ' + item.description)) : ''))
            }}
          </span>
          <span *ngIf="!isPaymentToContact" class="HistoryItem-title">
            {{ item.description }}
          </span>
          <!--<ion-label class="HistoryItem-label">
            Acc: **** {{
              (item.price > 0)
                ? (item.toNumber) ? item.toNumber.substring(item.fromNumber.length, item.fromNumber.length - 4) : '&mdash;'
                : item.fromNumber.substring(item.fromNumber.length, item.fromNumber.length - 4)
            }}
          </ion-label>-->
          <!--123-->
          <span
            class="HistoryItem-info"
            [class.green]="item.amount > 0"
            [class.gray]="item.amount <= 0"
            [class.red]="item.status === 'INSUFFICIENT_FUNDS' && item.paymentType !== 'HOLD'">
              <span *ngIf="item.status === 'PROCESSING'">{{ 'transaction.first_status' | translate }}</span>
              <span *ngIf="item.status === 'HOLD'">{{ 'transaction.second_status' | translate }}</span>
              <span *ngIf="item.status === 'INSUFFICIENT_FUNDS'">{{ 'transaction.fifth_status' | translate }}</span>
          </span>
        </div>
        <div class="HistoryItem-values">
          <ion-label class="HistoryItem-label">
            <!--Acc: **** {{ item.fromNumber.substring(item.fromNumber.length, item.fromNumber.length - 4) }}-->
            Acc: **** {{
            (item.amount > 0)
              ? (item.toNumber) ? item.toNumber.substring(item.fromNumber?.length, item.fromNumber?.length - 4) : '&mdash;'
              : item.fromNumber ? item.fromNumber.substring(item.fromNumber?.length, item.fromNumber?.length - 4) : ''
            }}
          </ion-label>
          <p class="HistoryItem-value"
            [class.green]="item.amount > 0"
            [class.gray]="item.amount <= 0">
              <!--{{ item.amount > 0 ? '+' : '-' }}{{ getIntegerPart() }}<small>{{ getDecimalPart() }} {{ item.currency.code }}</small>-->
            <span>{{ (item.amount > 0) ? '+' : '-' }}</span>
            <span [innerHTML]="itemAmount | decimalWrapper"></span>
            <small> {{ item.currency.code }}</small>
          </p>
        </div>
      </div>
    </div>

    <div class="HistoryItem-buttons">
      <ion-button
        *ngIf="item.paymentToRepeat && item.amount < 0"
        [disabled]="isLoading | async"
        (click)="repeat(item.paymentToRepeat)"
        class="buttonRepeat"
        expand="full" shape="round" fill="outline" color="dark" size="small">
          <ion-icon slot="start" name="repeat-new"></ion-icon>
          {{ 'transaction.second_button' | translate }}
      </ion-button>
      <ion-button
        (click)="openPaymentRecipe(item)"
        class="buttonReceipt"
        expand="full" shape="round" fill="outline" color="success" size="small">
          <ion-icon slot="start" name="receipt-green"></ion-icon>
          {{ 'transaction.third_button' | translate }}
      </ion-button>
    </div>
  </div>
</ion-item>
