import { HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';

type MonolitErrorResponse = HttpErrorResponse;
type OpeApiErrorResponse = HttpResponse<HttpErrors>;

export interface HttpErrors<K = number, M = string, T = any> {
  // errors: Array<HttpError<k, T>>;
  errors: [
    HttpError<K, M, T>
  ];
}

export interface HttpError<K = number, M = string, T = any> {
  code: K;
  message: M;
  properties: T;
  type?: string;
}

/*const testError: HttpError<200, 'Test MSG', { value: number }> = {
  code: 200,
  message: 'Test MSG',
  properties: { value: 200 },
  type: 'string',
};

export interface CurExchangeConfirmCodeResp extends HttpErrors {
  errors: [
    HttpError<449, 'Error', { confirmationCodeLength: number; confirmationToken: string; }>
  ];
}*/

// interface CustomError extends HttpError<200, 'Error'> {}

export function checkResponseForErrors(response: HttpEvent<any>): boolean {
  console.log('CHECK_ERROR');
  return isHttpResponse(response) && isOpenApiErrorResponse(response) && getOpenApiError(response).code !== 449;
}

function isHttpResponse(response: HttpEvent<any>): response is HttpResponse<any> {
  return response && (response as HttpResponse<any>).status !== undefined;
}

function isOpenApiErrorResponse(response: HttpResponse<any> | HttpErrorResponse): response is OpeApiErrorResponse {
  return response
    && response.status === 200
    && (response as HttpResponse<any>).body
    && ((response as HttpResponse<any>).body as HttpErrors).errors !== undefined;
}

function getOpenApiError(response: OpeApiErrorResponse): HttpError {
  return isOpenApiErrorResponse(response) ? response.body.errors[0] : null;
}

function isMonolitError(response: HttpResponse<any> | HttpErrorResponse): response is MonolitErrorResponse {
  return response
    && response.status !== 200
    && (response as HttpErrorResponse).error
    && ((response as HttpErrorResponse).error as HttpErrors).errors !== undefined;
}

function getMonolitError(response: MonolitErrorResponse): HttpError {
  return isMonolitError(response) ? response.error.errors[0] : null;
}

function getErrorFromResponse(response: HttpResponse<any> | HttpErrorResponse): HttpError {
  return (isOpenApiErrorResponse(response) || isMonolitError(response))
    ? (isOpenApiErrorResponse(response)) ? getOpenApiError(response) : getMonolitError(response)
    : getUnknownError(response);
}

export function getErrorCodeFromResponse(response: MonolitErrorResponse | OpeApiErrorResponse): number {
  return getErrorFromResponse(response).code;
}

export function getErrorMsgFromResponse(response: MonolitErrorResponse | OpeApiErrorResponse): string {
  return getErrorFromResponse(response).message;
}

function getUnknownError(response: HttpResponse<any> | HttpErrorResponse): HttpError {
  return {
    code: response.status,
    message: 'Unknown Error',
    properties: {}
  };
}
