import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { MapInfoWindow, GoogleMap } from '@angular/google-maps';

import { Observable } from 'rxjs';
import { ModalsService } from '../../modals/modals.service';
import { TopUpAccountService } from '../top-up-account.service';
import { MarkerPoint, RetrieveCode } from '../top-up-account.interface';
import { getErrorCodeFromResponse } from '../../tools/monolit-errors';
import { ROLES } from '../../users/users.roles';



@Component({
  selector: 'app-top-up-code',
  templateUrl: './top-up-code.component.html',
  styleUrls: ['./top-up-code.component.scss']
})
export class TopUpCodeComponent implements OnInit {
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  @Input() accountNumber?: string;
  @Input() hasActiveBonusProgram?: boolean;
  @Input() hideWhereToTopUp?: boolean;

  roles = ROLES;

  page = 'Code';

  markers = [];
  center: google.maps.LatLngLiteral;

  errorCode = false;
  errorCodeUsed = false;
  topupCode = '';
  topupAmount: number = null;
  topupCurrency = '';

  incorrectAccount = false;

  readonly isLoading$: Observable<boolean> = this.topup.isLoading$;

  constructor(
    private modals: ModalsService,
    private topup: TopUpAccountService,
    private router: Router,
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  onWhereToBy() {
    this.page = 'Buy';
    this.topup.getPoints().subscribe((points: Array<MarkerPoint>) => {
      points.forEach((point) => {
        this.markers.push({
          position: {
            lat: point.lat,
            lng: point.lng
          },
          name: point.name,
          description: point.description,
          address: point.address,
          website: point.website,
          phone: point.phone,
          workFrom: point.workFrom,
          workTo: point.workTo,
        });
      });
      this.center = {
        lat: this.markers[0].position.lat,
        lng: this.markers[0].position.lng,
      };
    });
  }

  onEnterTopUpCode() {
    if (!this.accountNumber) {
      return;
    } else {
      this.page = (this.hasActiveBonusProgram) ? 'EnterCode' : 'Reject';
    }
  }

  enterTopupSubmit(event) {
    this.errorCodeUsed = false;
    this.errorCode = false;
    this.topup.retrieveInfoCode(event.code)
      .subscribe(
        (info: RetrieveCode) => {
          this.topupCode = info.code;
          this.topupAmount = info.amount;
          this.topupCurrency = info.currency;
          this.page = event.page;
        },
        (error) => {
          if (getErrorCodeFromResponse(error) === 404) {
            this.errorCode = true;
          } else {
            if (getErrorCodeFromResponse(error) === 403) {
              this.errorCodeUsed = true;
            }
          }
        }
      );
  }

  confirmTopupSubmit(event) {
    if (this.accountNumber) {
      this.errorCode = false;
      this.errorCodeUsed = false;
      this.topup.topupByCode(this.topupCode, this.accountNumber)
        .subscribe(
          (response) => {
            this.page = event;
          },
          (error) => {
            if (getErrorCodeFromResponse(error) === 404) {
              this.errorCode = true;
            } else {
              if (getErrorCodeFromResponse(error) === 403) {
                this.errorCodeUsed = true;
              }
            }
          }
        );
    } else {
      this.incorrectAccount = true;
    }
  }

  successTopupSubmit() {
    this.modalController.dismiss();
    this.router.navigate(['./money']);
  }

  successRejectSubmit() {
    this.modalController.dismiss();
    this.router.navigate(['./cashback']);
  }

  async TopUpInactive() {
  }

}
