<div class="PrintStatement">
	<p class="PrintStatement-title">
		{{ 'modal.print_statement' | translate }}
	</p>
	<p class="PrintStatement-info">
		{{ 'modal.preview' | translate }} ({{ dateFrom | date:'dd.MM.yyyy' }} - {{ dateTo | date:'dd.MM.yyyy' }})
	</p>

	<div class="PrintStatement-iframe">
		<iframe id="pdf" width="100%" height="100%" [src]="pdf" type="application/pdf"></iframe>
		<!--<embed src="pdf" type="application/pdf" id="pdf" width="100%" height="100%" />-->
	</div>
	
	<div class="PrintStatement-buttons">
		<ion-button expand="full" shape="round" fill="outline" color="dark"
			(click)="closeModal()">
				{{ 'deleteAlert.cancelButton' | translate }}
		</ion-button>
		<ion-button expand="full" shape="round" fill="outline" color="success"
			(click)="onPrint()">
				{{ 'modal.print_button' | translate }}
		</ion-button>
	</div>
</div>
