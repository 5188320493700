import { Component, OnInit } from '@angular/core';
import { OrganizationsService } from '../../../users/organizations/organizations.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Account } from '../../../users/organizations.interface';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { validateAfterComma, validateBeforeComma } from '../../../validators/amount.validator';
import { Router } from '@angular/router';
import { minValueValidator } from '../../../validators/form-validators';
import { TOP_UP_PATH } from '../../top-up-account.interface';
import { TopUpAccountService } from '../../top-up-account.service';

@Component({
  selector: 'app-top-up-account-details',
  templateUrl: './top-up-account-details.component.html',
  styleUrls: ['./top-up-account-details.component.scss']
})
export class TopUpAccountDetailsComponent implements OnInit {
  readonly options = [
    {
      text: 'Euro',
      value: 'euro'
    }
  ];

  accountsList!: Account[];

  form: FormGroup;
  clientNameControl: AbstractControl;
  accountNumberControl: AbstractControl;
  amountControl: AbstractControl;

  constructor(
    private organizationsService: OrganizationsService,
    private fb: FormBuilder,
    private router: Router,
    private topUpAccountService: TopUpAccountService
  ) {
  }

  get getErrorMessages(): string {
    const errors: ValidationErrors = this.amountControl.errors;

    if (errors) {
      if (errors.validateAfterComma) {
        return 'topup.binance.connect_details_error_afterComa';
      }
      if (errors.validateBeforeComma) {
        return 'topup.binance.connect_details_error_beforeComa';
      }
      if (errors.required) {
        return 'topup.amount_error_required';
      }
      if (errors.pattern) {
        return 'topup.amount_error_pattern';
      }
      if (errors.maxlength) {
        return 'amount_base_field_err3';
      }
      if (errors.min) {
        return 'topup.amount_error_minimum';
      }
      if (errors.minValue) {
        return 'topup.amount_error_minValue';
      } else {
        return '';
      }
    }
  }

  ngOnInit() {
    this.initForm();
    this.getUserInfo();
    this.getClientAccounts();
  }

  showError(controlName: string): boolean {
    const control: AbstractControl = this.form.get(controlName);

    return control.invalid && control.touched && control.dirty;
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const formData = this.form.value;

    this.router.navigate(
      [`/top-up-account/${ TOP_UP_PATH.binanceConfirmation }`],
      {state: formData}
    );
  }

  removeSpacesCommasAndLetters() {
    let inputValue = this.amountControl.value;
    inputValue = inputValue.replace(/\s/g, '');
    inputValue = inputValue.replace(/,/g, '');
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    this.amountControl.setValue(inputValue);
  }

  private getUserInfo() {
    this.organizationsService.getClientInfo()
      .pipe(
        take(1)
      )
      .subscribe({
        next: (data) => {
          this.clientNameControl.setValue(data.name);
        },
        error: () => this.topUpAccountService.openErrModal().subscribe()
      });
  }

  private initForm() {
    this.form = this.fb.group({
      clientName: [{value: null, disabled: true}],
      accountNumber: [null, Validators.required],
      currency: [this.options[0].value, Validators.required],
      amount: [
        null,
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.min(1),
          validateAfterComma(2),
          validateBeforeComma(9),
          minValueValidator(1)
        ]
      ]
    });

    this.clientNameControl = this.form.get('clientName');
    this.accountNumberControl = this.form.get('accountNumber');
    this.amountControl = this.form.get('amount');
  }

  private getClientAccounts() {
    this.organizationsService.activeTrustedClients$
      .pipe(
        switchMap(clientList =>
          this.organizationsService.getAccountListForClients(clientList)),
        catchError((err) => {
          this.topUpAccountService.openErrModal().subscribe();
          return err;
        })
      )
      .subscribe((accounts: Account[]) => {
        this.accountsList = accounts;
        this.accountNumberControl.setValue(accounts[0]?.id);
      });
  }
}
