import { Injectable } from '@angular/core';
import { MonolitHttpError } from '@core/monolit';

import { Observable, BehaviorSubject, of } from 'rxjs';

import { AuthManagerService } from '../auth/auth-manager.service';

export interface TechnicalWorkTimeError {
  endOfflineDateTime: string;
}

/*
* Server error handling by Monolit.
* Set technical work status via error interceptor
* Reset technical work status in log-in component after success auth
* */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  private isTechnicalWorkSource = new BehaviorSubject<string>(null);
  isTechnicalWork$ = this.isTechnicalWorkSource.asObservable();

  constructor(
    private authManager: AuthManagerService) {
  }

  setTechnicalWorkStatus(error: MonolitHttpError<TechnicalWorkTimeError>): Observable<void> {
    // const error = ErrorHandlerService.getErrorFromHttpErrorResponse(response) as MonolitHttpError<TechnicalWorkTimeError>;
    // const workingHour = error && error.properties && error.properties.endOfflineDateTime;
    const workingHour = (error && error.code === 410) ? '1' : null;
    console.log('setTechnicalWorkStatus', error, workingHour);
    this.isTechnicalWorkSource.next(workingHour);
    return workingHour ? this.authManager.logout() : of();
  }

}
