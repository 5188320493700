<ion-app>
    <ion-header class="ion-no-border">
        <ion-toolbar>
            <ion-title>{{ 'currency-exchange.info_title' | translate }}</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-content class="Info ion-padding">
        <p>{{ 'currency-exchange.info_first_text' | translate }}</p>
        <p>{{ 'currency-exchange.info_second_text' | translate }}</p>
    </ion-content>
</ion-app>
