import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import Timer = NodeJS.Timer;

const TICK_MS = 1000;

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownTimerComponent implements OnInit, OnChanges {

  interval?: Timer;

  @Input() timerSec = 10;
  @Input() start = false;
  @Input() reset = false;

  @Output() started = new EventEmitter<boolean>();
  @Output() finished = new EventEmitter<boolean>();

  currentValueMs?: number;

  constructor(
    private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.hasOwnProperty('start') && changes['start'].currentValue) {
      console.log('START TIMER');
      this.currentValueMs = this.timerSec * 1000;
      this.started.emit(true);
      this.startInterval(TICK_MS);
    }
    if (changes.hasOwnProperty('reset') && changes['reset'].currentValue && !changes['reset'].firstChange) {
      console.log('RESET TIMER');
      this.currentValueMs = null;
      this.clearInterval(this.interval);
    }
  }

  private startInterval(tick: number) {
    this.interval = setInterval(() => {
      this.currentValueMs = this.currentValueMs - tick;
      this.cdRef.markForCheck();
      if (this.currentValueMs <= 0) {
        this.clearInterval(this.interval);
      }
    }, tick);
  }

  private clearInterval(interval) {
    clearInterval(interval);
    this.finished.emit(true);
  }

}
