import { HttpErrorResponse } from '@angular/common/http';

export interface MonolitHttpErrorResponse {
  errors: Array<MonolitHttpError>;
}

export interface MonolitHttpError<T = {}> {
  code: number;
  message: string;
  properties: T;
  type: string;
}

export class MonolitErrorParserService {

  static getErrorFromHttpErrorResponse(response: HttpErrorResponse): MonolitHttpError {
    const httpError = (response && response.error) as MonolitHttpErrorResponse;
    return (httpError && httpError.errors.length) ? httpError.errors[0] : null;
  }

  static checkResponseForErrors(response): boolean {
    const body = (response && response.hasOwnProperty('body')) ? response.body : null;
    /*console.log(
      (body) ? body.hasOwnProperty('errors') : null,
      (body && body.hasOwnProperty('errors')) ? body.errors.length : null,
      (body && body.hasOwnProperty('errors')) ? body.errors[0] : null
    );*/
    return (body && body.hasOwnProperty('errors') && body.errors.length !== 0 && body.errors[0].hasOwnProperty('code'))
      ? body.errors[0].code !== 449
      : false;
  }

  static getErrorMessageFromResponse(response: any): string {
    const body = (response && response.body) ? response.body : null;
    return (body && body.hasOwnProperty('errors') && body.errors.length !== 0 && body.errors[0].hasOwnProperty('message'))
      ? body.errors[0].message
      : (response.hasOwnProperty('error') && response.hasOwnProperty('message')) ? response.message : 'Unknown server error';
  }

  static getErrorCode(response: any): number {
    const content = (response.hasOwnProperty('body')) ? response.body : response.error;
    return (content && content.hasOwnProperty('errors') && content.errors.length)
      ? content.errors[0].code
      : 0;
  }

}
