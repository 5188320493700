import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, Input, HostListener, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';

import { ModalsService } from '../../modals.service';
import { Country } from '../../../services/country/country.service';
import { Locales, LocalesItem, LocalizationService } from '../../../internationalization/localization/localization.service';
// import { PermissionService } from '../../../users/permission/permission.service';
// import { switchMap } from 'rxjs/operators';
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-login-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss']
})
export class SelectCountryComponent implements OnInit, AfterViewInit {

  @Input() locales?: boolean;
  @Input() showCountryCode = true;
  @ViewChild('search', { static: true }) inputSearch: ElementRef<HTMLInputElement>;

  activeCountry?: Country;
  activeLanguage?: LocalesItem;

  searchForm = new UntypedFormGroup({
    searchValue: new UntypedFormControl(null)
  });

  get searchValue(): AbstractControl {
    return this.searchForm.get('searchValue');
  }

  private static isCountry(coutry: Country | LocalesItem): coutry is Country {
    return (coutry as Country).dialCode !== undefined;
  }

  @HostListener('document:keydown', ['$event'])
  keydown(e: KeyboardEvent) {
    if (e.key === 'Enter' && (this.activeCountry || this.activeLanguage)) {
      this.closeModal(this.activeCountry, this.activeLanguage);
    }
  }

  constructor(
    private modals: ModalsService,
    // private localization: LocalizationService,
    // private permission: PermissionService
  ) {
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputSearch?.nativeElement?.focus();
    }, 200);
  }

  setActiveCountry(data: Country | LocalesItem) {
    (SelectCountryComponent.isCountry(data))
      ? this.activeCountry = data
      : this.activeLanguage = data;
  }

  // setActiveLanguage(language: LocalesItem) {
  //   this.activeLanguage = language;
  // }

  closeModal(country?: Country, activeLanguage?: LocalesItem) {
    (country)
      ? this.modals.closeModal(country || null)
      : this.modals.closeModal(activeLanguage || null);
  }

}
