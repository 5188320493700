import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { clone } from 'lodash';
import { of, forkJoin, merge, Observable, Subscription } from 'rxjs';
import { switchMap, flatMap, map, tap, filter } from 'rxjs/operators';

import { RightContainerService } from '../../right-container.service';
import { OperationsService, OperationRequestModel } from '../../../users/operations/operations.service';
import { OrganizationsService } from '../../../users/organizations/organizations.service';
import { MoneyService } from '../../../+money/money.service';

import { TrustedClient, Account, Card } from '../../../users/organizations.interface';
import { MoneyOperation, MoneyOperationRequest, PaymentOperationRepeat } from '../../../users/operations/operations.interface';

// import { HistoryFilterComponent } from '../history-filter/history-filter.component';
import { CalendarSingleSelectComponent } from '../../../ui-elements/calendars/calendar-single-select/calendar-single-select.component';

@Component({
  selector: 'app-movement-money-history',
  templateUrl: './movement-money-history.component.html',
  styleUrls: ['./movement-money-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MovementMoneyHistoryComponent implements OnInit, OnDestroy {

  dateFrom!: number;
  dateTo!: number;

  filterIsOpen = false;

  /* expenses = 0; incomes = 0; balance = 0; */

  trustedClients: Array<TrustedClient> = [];

  private loadedPage = 0;
  private loadedPageMax = 0;

  private subscribe?: Subscription;

  readonly isLoading$: Observable<boolean> = this.organizations.isLoading$
    .pipe(
      switchMap(() => this.operations.isLoading$)
    );

  readonly operationList$ = this.operations.operations$
    .pipe(
      map(operations => this.operations.getModifiedOperations(operations)),
    );

  private card!: Card;
  activeCardNumber!: string;

  private static getOperationRequestModel(accounts: Array<Account>, dateFrom: number, dateTo: number, page = 0): OperationRequestModel {
    return {
      accountsIds: accounts.map(account => account.id),
      dateFrom: dateFrom,
      dateTo: dateTo,
      page: page
    };
  }

  private static getOperationRequestModelForCard(card: Card, dateFrom: number, dateTo: number, page = 0): OperationRequestModel {
    return {
      cardsIds: [ card.id ],
      dateFrom: dateFrom,
      dateTo: dateTo,
      page: page
    };
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private rightContainer: RightContainerService,
    private operations: OperationsService,
    private popoverController: PopoverController,
    private organizations: OrganizationsService) {
  }

  ngOnInit() {
    this.subscribe = merge(
      this.organizations.activeTrustedClients$
        .pipe(
          filter(trustedClients => !!trustedClients.length && !this.card),
          tap(trustedClients => this.trustedClients = clone(trustedClients)),
          switchMap(() => this.organizations.isLoading$),
          filter(isLoading => !isLoading),
          flatMap(() => this.organizations.getAccountListForClients(this.trustedClients)),
          map(accounts => MovementMoneyHistoryComponent.getOperationRequestModel(accounts, this.dateFrom, this.dateTo))
        ),
      this.rightContainer.getActiveData('movement-money-history')
        .pipe(
          tap(card => {
            this.card = card;
            this.activeCardNumber = (card) ? MoneyService.getHiddenCardNumber(card.number) : null;
          }),
          switchMap(card => {
            return (card)
              ? of(MovementMoneyHistoryComponent.getOperationRequestModelForCard(card, this.dateFrom, this.dateTo))
                .pipe(
                  tap(() => (!card.id) ? this.setOperations([]) : false),
                  filter(() => !!card.id)
                )
              : this.organizations.getActiveTrustedClientList()
                .pipe(
                  flatMap(trustedClients => this.organizations.getAccountListForClients(trustedClients)),
                  map(accounts => MovementMoneyHistoryComponent.getOperationRequestModel(accounts, this.dateFrom, this.dateTo))
                );
          })
        )
    )
      .pipe(
        switchMap(operationRequestModel => this.operations.requestOperations(operationRequestModel))
      )
      .subscribe(response => this.setOperations(response.content, response.maxPageNumber));
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  private setOperations(operations: Array<MoneyOperation>, maxPageNumber?: number) {
    // console.log('SET_OPERATIONS', operations);
    this.operations.setOperations(operations);
    this.loadedPageMax = maxPageNumber || 0;
    this.cdr.detectChanges();
  }

  openExportStatements() {
    this.rightContainer.setRightPanel('export-statements');
  }

  openPrintStatements() {
    this.rightContainer.setRightPanel('print-statements');
  }

  switchHistoryFilter() {
    this.filterIsOpen = !this.filterIsOpen;
  }

  clearHistoryFilter() {
    this.dateFrom = null;
    this.dateTo = null;
    this.loadedPageMax = 1;
    this.getOperationList(null, null)
      .subscribe(([ operationsData ]) => {
        this.loadedPageMax = operationsData.maxPageNumber;
        this.operations.setOperations(operationsData.content);
      });
  }

  setAccount(account: Account) {
    this.operations.requestOperations({ accountsIds: [ account.id ], dateFrom: this.dateFrom, dateTo: this.dateTo })
      .subscribe(operationsData => {
        this.loadedPageMax = operationsData.maxPageNumber;
        this.operations.setOperations(operationsData.content);
      });
  }

  async onSetDataStart(ev: Event) {
    const popover = await this.popoverController.create({
      cssClass: 'popover-history-filter popover-start',
      component: CalendarSingleSelectComponent,
      backdropDismiss: true,
      event: ev,
    });
    popover.onDidDismiss()
      .then(result => {
        if (result && result.data) {
          this.dateFrom = result.data;
          this.getOperationList(this.dateFrom, this.dateTo)
            .subscribe(([ operationsData ]) => {
              this.loadedPageMax = operationsData.maxPageNumber;
              this.operations.setOperations(operationsData.content);
            });
        }
      });
    return await popover.present();
  }

  async onSetDataEnd(ev: Event) {
    const popover = await this.popoverController.create({
      cssClass: 'popover-history-filter popover-end',
      component: CalendarSingleSelectComponent,
      backdropDismiss: true,
      event: ev,
    });
    popover.onDidDismiss()
      .then(result => {
        if (result && result.data) {
          this.dateTo = result.data;
          this.getOperationList(this.dateFrom, this.dateTo)
            .subscribe(([ operationsData ]) => {
              this.loadedPageMax = operationsData.maxPageNumber;
              this.operations.setOperations(operationsData.content);
            });
        }
      });
    return await popover.present();
  }

  repeatOperation(operation: PaymentOperationRepeat): void {
    this.operations.repeatOperation(operation);
  }

  doInfinite(event) {
    console.log('Begin sync operation');
    if (this.loadedPage <= this.loadedPageMax) {
      this.getOperationList(this.dateFrom, this.dateTo, this.loadedPage + 1)
        .subscribe(([ operationsData, currentOperationList ]) => {
          this.loadedPageMax = operationsData.maxPageNumber;
          this.loadedPage = operationsData.pageNumber;
          const operationList = currentOperationList.concat(operationsData.content);
          event.target.complete();
          this.operations.setOperations(operationList);
        });
    } else {
      event.target.complete();
    }
  }

  private getOperationList(dateFrom: number, dateTo: number, page?: number): Observable<[ MoneyOperationRequest, MoneyOperation[] ]> {
    return this.organizations.getAccountListForClients(this.trustedClients)
      .pipe(
        map(accounts => (this.card)
          ? MovementMoneyHistoryComponent.getOperationRequestModelForCard(this.card, dateFrom, dateTo, page)
          : MovementMoneyHistoryComponent.getOperationRequestModel(accounts, dateFrom, dateTo, page)
        ),
        switchMap(requestModel => forkJoin([
          this.operations.requestOperations(requestModel),
          this.operations.getOperationList()
        ]))
      );
  }

}
