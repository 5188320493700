import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';

@Component({
  selector: 'app-navigation-network-status',
  templateUrl: './navigation-network-status.component.html',
  styleUrls: ['./navigation-network-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationNetworkStatusComponent implements OnInit {

  isOffline = false;

  @HostListener('window:online')
  onOnline() {
    this.isOffline = false;
  }

  @HostListener('window:offline')
  onOffline() {
    this.isOffline = true;
  }

  constructor() { }

  ngOnInit() {
  }

}
