import { environment} from 'environments/environment';
import { Inject, Injectable } from '@angular/core';

import { WINDOW} from '../../window.providers';
import { intersection } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatAll, filter, map, switchMap, take, tap, toArray } from 'rxjs/operators';

import { BANK_NAME, BANK_ROLE_LIST, ROLES } from '../users.roles';
import { AuthManagerService } from '../auth/auth-manager.service';
import { AppConfigurationService } from '../../app.configuration.service';

const BANK_ROLE_TABLE = {
  'localhost': ROLES.BCC_LEGAL_PERSON,
  'desktopbank-dev.blackcatcard.com': ROLES.BCC_LEGAL_PERSON,
  'desktopbank-stage.blackcatcard.com': ROLES.BCC_LEGAL_PERSON,
  'webbank.blackcatcard.com': ROLES.BCC_LEGAL_PERSON,
  'desktopbank.blackcatcard.com': ROLES.BCC_LEGAL_PERSON,
  'desktop.gekkard.dev.scnetservices.ru': ROLES.GEKKARD,
  'desktop.gekkard.stage.scnetservices.ru': ROLES.GEKKARD,
  'desktop.gekkard.com': ROLES.GEKKARD
};

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  // [ ROLES.BCC_LEGAL_PERSON, ROLES.BCC_CRYPTO ] - Example default access role

  private activeRolesSource = new BehaviorSubject<ROLES[]>([]);
  activeRoles$: Observable<ROLES[]> = this.activeRolesSource.asObservable();

  constructor(
    @Inject(WINDOW) private window: Window,
    private authorization: AuthManagerService,
    private configuration: AppConfigurationService) {

      this.identifyBankRole()
        .pipe(
          tap(bankRole => this.activeRolesSource.next([ bankRole ])),
          switchMap(bankRole => this.authorization.bankToken$.pipe(
            filter<string>(Boolean),
            take(1),
            switchMap(bankToken => this.requestAvailableRoles(bankRole, bankToken)),
          )),
          concatAll(),
          filter<ROLES>(Boolean),
          toArray()
          /*scan((acc, cur) => { // without take(1)
            acc.push(cur);
            return acc;
          }, [])*/
        )
        .subscribe(roles => {
          const activeRoles = this.activeRolesSource.getValue().concat(roles);
          this.activeRolesSource.next(activeRoles);
        });
  }

  getActiveRoles(): Observable<ROLES[]> {
    return this.activeRoles$.pipe(
      take(1)
    );
  }

  getActiveBankRole(): Observable<ROLES> {
    return this.getActiveRoles().pipe(
      map(activeRoles => {
        return intersection(
          activeRoles,
          BANK_ROLE_LIST.map((role) => role[0])
        )[0];
      })
    );
  }

  getActiveBankName(): Observable<BANK_NAME> {
    return this.getActiveBankRole().pipe(
      map((bankRole) => BANK_ROLE_LIST.find((role) => role[0] === bankRole)),
      map((bankRole) => bankRole[1])
    );
  }

  private requestAvailableRoles(bankRole: ROLES, token: string): Observable<ROLES[]> {
    return forkJoin([
      // this.getBccCryptoAccess(token, bankRole),
      this.getGekkardCryptoAccess(bankRole),
      this.getCurrencyExchangeAccess(),
    ]);
  }

  private identifyBankRole(): Observable<ROLES> {
    const hostName = this.window.location.hostname;
    return of(BANK_ROLE_TABLE[hostName]);
  }

  private getBccCryptoAccess(token: string, bankRole: ROLES): Observable<ROLES.BCC_CRYPTO> {
    // TODO need prepare crypto service for production
    return (environment.production || !environment.cryptoService.isActive || bankRole === ROLES.GEKKARD)
      ? of(undefined)
      : this.configuration.requestCryptoServiceAccess(token, this.authorization.authPhoneNumber, this.authorization.authType)
          .pipe(
            map(access => (access.enabled) ? ROLES.BCC_CRYPTO : undefined),
            catchError(() => of(undefined))
          ) as Observable<ROLES.BCC_CRYPTO | undefined>;
  }

  private getGekkardCryptoAccess(bankRole: ROLES): Observable<ROLES.GEKKARD_CRYPTO> {
    return bankRole === ROLES.GEKKARD
      ? this.configuration.requestGekkoinServiceAccess()
        .pipe(
          map(access => (access.enabled) ? ROLES.GEKKARD_CRYPTO : undefined),
          catchError(() => of(undefined))
        ) as Observable<ROLES.GEKKARD_CRYPTO | undefined>
      : of(undefined);
  }

  private getCurrencyExchangeAccess(): Observable<ROLES> {
    return this.configuration.getExchangeConfig().pipe(
      map(predicate => predicate ? ROLES.CURRENCY_EXCHANGE : undefined)
    );
  }
}
