<app-is-loading
  [isLoading]="isLoading$ | async">
</app-is-loading>

<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-icon slot="secondary" class="Templates-icon" name="star-black"></ion-icon>
      <ion-title>{{ 'templates.title' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>

    <div class="customScrollbar ion-padding">

      <!-- if empty -->

      <div class="Templates-empty" *ngIf="!(templates$ | async).length && !(isLoading$ | async)">
        <svg width="120" height="120" viewBox="0 0 120 120">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h120v120H0z"/>
            <path fill="#596778" fill-rule="nonzero"
                  d="M31.719 108.61c-2.278 1.205-4.707-.548-4.268-3.125l5.4-31.723L9.974 51.296c-1.843-1.81-.939-4.679 1.63-5.054l31.618-4.629 14.14-28.862c1.14-2.325 4.128-2.345 5.276 0l14.14 28.862 31.619 4.629c2.546.372 3.488 3.23 1.63 5.054l-22.88 22.466 5.402 31.723c.435 2.555-1.971 4.34-4.269 3.124L60 93.632l-28.28 14.977zm28.28-20.565l27.037 14.319-5.164-30.328 21.873-21.478-30.228-4.425L60 18.54 46.481 46.133l-30.228 4.425 21.873 21.478-5.163 30.328 27.036-14.32z"/>
            <path fill="#77A45C"
                  d="M47 55.02A2.02 2.02 0 0 1 49.013 53h16.774c1.112 0 2.013.897 2.013 2.02a2.02 2.02 0 0 1-2.013 2.02H49.013A2.012 2.012 0 0 1 47 55.02zM57 65c0-1.116.906-2.02 2.026-2.02h11.948c1.119 0 2.026.897 2.026 2.02 0 1.116-.906 2.02-2.026 2.02H59.026A2.018 2.018 0 0 1 57 65zm-10 0c0-1.116.902-2.02 2.019-2.02h3.962c1.115 0 2.019.897 2.019 2.02 0 1.116-.902 2.02-2.019 2.02H49.02A2.014 2.014 0 0 1 47 65zm0 9.98c0-1.115.906-2.02 2.023-2.02h11.554c1.117 0 2.023.897 2.023 2.02 0 1.116-.906 2.02-2.023 2.02H49.023A2.017 2.017 0 0 1 47 74.98z"/>
          </g>
        </svg>
        <p>{{ 'templates.empty_text' | translate }}</p>
      </div>

      <!-- else -->

      <div *ngIf="(templates$ | async).length">
        <!--<ion-searchbar></ion-searchbar>-->
        <ion-list class="Templates-list" lines="full">
          <app-transfer-template-item
            *ngFor="let template of templates$ | async"
            [item]="template"
            [type]="paymentType.PaymentCard"
            (repeatOperation)="repeatOperation($event)"
            class="app-templates-item">
          </app-transfer-template-item>
        </ion-list>
      </div>

    </div>

  </ion-content>
</ion-app>
