<ion-list>
  <ion-list-header>
    <ion-label>{{ 'receipt.second_text' | translate }}</ion-label>
  </ion-list-header>

  <ion-item
      *ngIf="operationType === 'TOPUP' || operationType === 'SWIFT' || operationType === 'SEPA' || operationType === 'TO_CARD'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.fifth_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.toName }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'PURCHASE' || operationType === 'CASH_WITHDRAWAL'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.fifth_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.fromName }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'TOPUP' || operationType === 'SWIFT' || operationType === 'SEPA'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.second_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.toNumber }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'PURCHASE' || operationType === 'CASH_WITHDRAWAL'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.second_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.fromNumber }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'TO_CARD'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>Card number:</p>
      <p class="PaymentReceipt-value">{{ operation.openapiData.toCard }}</p>
    </ion-label>
  </ion-item>

  <ion-item lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.seventh_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.toBank }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'SWIFT'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>Country:</p>
      <p class="PaymentReceipt-value">{{ activeCountryName | async }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'SWIFT'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>Address:</p>
      <p class="PaymentReceipt-value">{{ operation.paymentToRepeat.address }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'SWIFT'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>SWIFT / BIC:</p>
      <p class="PaymentReceipt-value">{{ operation.paymentToRepeat.swiftCode }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'SWIFT'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>Intermediary SWIFT:</p>
      <p class="PaymentReceipt-value">{{ operation.paymentToRepeat.intermediarySwift }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'SWIFT'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>Intermediary bank:</p>
      <p class="PaymentReceipt-value">{{ operation.paymentToRepeat.intermediaryBank }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'SWIFT'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>Intermediary bank account:</p>
      <p class="PaymentReceipt-value">{{ operation.paymentToRepeat.intermediaryAccount }}</p>
    </ion-label>
  </ion-item>

</ion-list>

<!--operationId-->
<!--executedAt-->
<!--fromName-->
<!--cardNumber-->
<!--fromNumber-->
<!--type-->
<!--Amount-->
<!--Fee-->
<!--referenceNumber-->
<!--status-->


