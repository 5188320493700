import { Component, OnInit, Input } from '@angular/core';

import { ModalsService } from '../../modals.service';
import { OperationsService } from '../../../users/operations/operations.service';

import { MoneyOperation } from '../../../users/operations/operations.interface';

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss']
})
export class PaymentReceiptComponent implements OnInit {

  @Input() operation?: MoneyOperation;

  isLoading$ = this.operations.isLoading$;

  constructor(
    private modals: ModalsService,
    private operations: OperationsService) {
  }

  ngOnInit() {}

  onClose() {
    this.closeModal();
  }

  onExportReceipt(referenceNumber: string) {
    this.operations.downloadSingleOperation(referenceNumber)
      .subscribe(() => this.closeModal());
  }

  closeModal() {
    this.modals.closeModal();
  }

}
