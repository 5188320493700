import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-money-history',
  templateUrl: './send-money-history.component.html',
  styleUrls: ['./send-money-history.component.scss']
})
export class SendMoneyHistoryComponent implements OnInit {

  history = [
    {
      'date': '21.12.2018',
      'operations': [
        {
          'id': 'PPY12049',
          'currency': {
            'code': 'EUR'
          },
          'name': 'Anna',
          'price': 100.00,
          'status': 'Waiting',
          'statusText': 'Waiting for paymant'
        }, {
          'id': 'PPY12048',
          'currency': {
            'code': 'EUR'
          },
          'name': 'Juliet Monagan',
          'price': 250.00,
          'status': 'Paid',
          'statusText': 'Paid'
        }
      ]
    }, {
      'date': '19.12.2018',
      'operations': [
        {
          'id': 'PPY12047',
          'currency': {
            'code': 'EUR'
          },
          'name': 'Timothy Dor',
          'price': 300.00,
          'status': 'Paid',
          'statusText': 'Paid'
        }, {
          'id': 'PPY12046',
          'currency': {
            'code': 'EUR'
          },
          'name': 'Monica Samer',
          'price': 200.00,
          'status': 'Paid',
          'statusText': 'Paid'
        }, {
          'id': 'PPY12045',
          'currency': {
            'code': 'EUR'
          },
          'name': 'Claudia Vara',
          'price': 100.00,
          'status': 'Paid',
          'statusText': 'Paid'
        }
      ]
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
