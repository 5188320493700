<form
    [formGroup]="submitForm"
    (submit)="onCodeSubmit(submitForm)">
    <div class="TopUpEnterCode">
        <div class="TopUpEnterCode-title">
            <ion-icon name="back" (click)="goBack()"></ion-icon>
            <span>{{ 'topup.codeEnter_title' | translate }}</span>
        </div>
        <div class="TopUpEnterCode-enter ion-text-center">
            <span *ngIf="!errorCode && !errorCodeUsed">{{ 'topup.codeEnter_second_text' | translate }}</span>
            <span *ngIf="submitForm.hasError('incorrectCode')" class="error">{{ 'topup.codeEnter_errorText' | translate }}. </span>
            <span *ngIf="submitForm.hasError('codeUsed')" class="error">{{ 'topup.code_used' | translate }}</span>
            <ion-item lines="none" [class.error]="submitForm.hasError('incorrectCode') || submitForm.hasError('codeUsed')">
                <ion-input
                    formControlName="topupCode"
                    [maxlength]="8"
                    type="text"
                    placeholder="◦◦◦◦◦◦◦◦"
                    [autofocus]="true">
                </ion-input>
            </ion-item>
            <span *ngIf="submitForm.hasError('codeUsed')" class="TopUpEnterCode-enter__note">{{ 'topup.code_used_once' | translate }}</span>
        </div>
        <div class="TopUpEnterCode-button ion-text-center">
            <ion-button
                expand="full"
                shape="round"
                fill="outline"
                color="success"
                type="submit"
                [disabled]="submitForm.invalid">
                {{ 'topup.codeEnter_buttonConfirm' | translate }}
            </ion-button>
        </div>
    </div>
</form>
