import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { IsLoadingModule } from 'app/ui-elements/is-loading/is-loading.module';
import { PipesModule } from '../../pipes/pipes.module';

import { TransferCardTemplatesComponent } from './transfer-card-templates/transfer-card-templates.component';
import { TransferBankTemplatesComponent } from './transfer-bank-templates/transfer-bank-templates.component';
import { TransferContactTemplatesComponent } from './transfer-contact-templates/transfer-contact-templates.component';

/* tslint:disable:max-line-length */
import { TransferCardTemplateEditComponent } from './transfer-card-templates/transfer-card-template-edit/transfer-card-template-edit.component';

import { TransferTemplateItemComponent } from './components/transfer-template-item/transfer-template-item.component';
import { TransfersTemplateEditComponent } from './transfers-template-edit/transfers-template-edit.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    IonicModule,
    IsLoadingModule,
    PipesModule,
    InternationalizationModule
  ],
  declarations: [
    TransferCardTemplatesComponent,
    TransferCardTemplateEditComponent,
    TransferBankTemplatesComponent,
    TransferContactTemplatesComponent,
    TransferTemplateItemComponent,
    TransfersTemplateEditComponent
  ],
  exports: [
    TransferCardTemplatesComponent,
    TransferBankTemplatesComponent,
    TransferCardTemplateEditComponent,
    TransferContactTemplatesComponent,
    TransfersTemplateEditComponent
  ]
})
export class TransfersTemplatesModule { }
