<div class="top-up">
  <main class="top-up__main">
    <section class="top-up-instruction">
      <div class="top-up-instruction__item">
        <h3 class="top-up-instruction__title">
          {{ 'topup.instruction.step_1_title' | translate }}
        </h3>
        <p class="top-up-instruction__description">{{ 'topup_view-instruction_title1' | translate }}</p>
      </div>

      <div class="top-up-instruction__item">
        <h3 class="top-up-instruction__title">
          {{ 'topup.instruction.step_2_title' | translate }}
        </h3>
        <p class="top-up-instruction__description">{{ 'topup_view-instruction_title2' | translate }}</p>
      </div>
    </section>
  </main>
</div>

<footer class="top-up__footer">
  <button class="top-up__footer-button top-up__footer-button_back" (click)="goBack()">
    <ion-icon name="arrow-back-outline" size="small"></ion-icon>
    {{ 'topup.go_back_button' | translate }}
  </button>
</footer>
