import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, AbstractControl } from '@angular/forms';

import { OrganizationsService } from '../../../users/organizations/organizations.service';
import { TrustedClient } from '../../../users/organizations.interface';
import { ROLES } from '../../../users/users.roles';

interface ActiveTrustedClient extends TrustedClient {
  active: boolean;
}

interface AccountsForm {
  list: Array<ActiveTrustedClient>;
}

@Component({
  selector: 'app-navigation-accounts-select',
  templateUrl: './navigation-accounts-select.component.html',
  styleUrls: ['./navigation-accounts-select.component.scss']
})
export class NavigationAccountsSelectComponent implements OnInit, OnChanges {

  @Input() accountList: Array<TrustedClient>;

  filterIsOpen = false;
  roles = ROLES;

  accountsForm = this.fb.group({
    list: this.fb.array([])
  });

  get accountsArray(): UntypedFormArray {
    return this.accountsForm.get('list') as UntypedFormArray;
  }

  activeCompanyCount?: number;

  private static getActiveAccounts(list: Array<ActiveTrustedClient>): Array<TrustedClient> {
    return list.filter(client => client.active === true)
      .map(client => {
        return { name: client.name, title: client.title, clientId: client.clientId, color: client.color };
      });
  }

/*  private static getActiveAccountsIds(list: Array<ActiveTrustedClient>): Array<string> {
    return list.filter(client => client.active === true)
      .map(client => {
        return client.clientId;
      });
  }*/

  constructor(
    private fb: UntypedFormBuilder,
    private organizations: OrganizationsService) {
  }

  ngOnInit() {
    this.accountsForm.valueChanges
      .subscribe((value: AccountsForm) => {
        const activeClients = NavigationAccountsSelectComponent.getActiveAccounts(value.list);
        this.organizations.setActiveTrustedClients(activeClients);
        this.activeCompanyCount = activeClients.length;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('accountList')) {
      this.activeCompanyCount = this.accountList.length;
      this.updateForm(this.accountList);
    }
  }

  toggleCheckbox(account: AbstractControl) {
    account.patchValue({active: !(account.get('active').value)});
  }

  openAccountsFilter() {
    this.filterIsOpen = (this.accountList && this.accountList.length > 1) ? !this.filterIsOpen : false;
  }

  private updateForm(clientList: Array<TrustedClient>): void {
    const extendList: Array<ActiveTrustedClient> = clientList.map((a: ActiveTrustedClient) => {
      a.active = true;
      return a;
    });
    const accountsFGs = extendList.map(item => this.fb.group(item));
    const accountsFormArray = this.fb.array(accountsFGs);
    this.accountsForm.setControl('list', accountsFormArray);
  }

}
