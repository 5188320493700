import { NgModule } from '@angular/core';
import { GekkoinWalletConfirmComponent } from './gekkoin-wallet-confirm/gekkoin-wallet-confirm.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { CountdownTimerModule } from '../../ui-elements/countdown-timer/countdown-timer.module';
import { NgxMaskModule } from 'ngx-mask';
import { AttachWalletComponent } from './attach-wallet/attach-wallet.component';
import { IsLoadingModule } from '../../ui-elements/is-loading/is-loading.module';

@NgModule({
  declarations: [
    GekkoinWalletConfirmComponent,
    AttachWalletComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    InternationalizationModule,
    CountdownTimerModule,
    NgxMaskModule,
    IsLoadingModule
  ]
})
export class GekkardModalsModule { }
