<ion-item class="History-item">
  <div class="History-item__icon" slot="start">
<!--    <ion-icon name="icon-{{ rateCurrency.toLocaleLowerCase() }}"></ion-icon>-->
  </div>
  <div class="History-item__header">
    <ion-skeleton-text class="History-item__label" [animated]="true" style="width: 50%"></ion-skeleton-text>
    <ion-skeleton-text class="History-item__label" [animated]="true" style="width: 80%"></ion-skeleton-text>
  </div>
  <div class="History-item__values">
    <span class="History-item__value">
    </span>
    <p class="History-item__value green">
    </p>
  </div>
</ion-item>

