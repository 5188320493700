<div 
	#countries
	*ngFor="let country of countryList">
	<app-country-select-item 
		[class.selected]="activeCountryCode === country.dialCode"
		[iso2Code]="country.code"
		[countryCode]="country.dialCode"
		[countryName]="country.name"
		[showCountryCode]="showCountryCode"
		(click)="setActiveCountry(country)">
	</app-country-select-item>
</div>
