import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { PAYMENT_TYPE } from '../../users/operations/operations.interface';

export class OnlineBillPayment {
  amount = 0;
  currency: 'EUR' = 'EUR';
  idTransaction: string;
  iban: string;
  merchantName: string;
  comment = '';
  callbackURL: string;
  redirectUrl?: string; // TODO implement redirect logic after successful payment (waiting for business approve)
  originatorData: string; // JWT token from merchant without changes
  constructor(jwt?: string) {
    console.log('JWT', jwt);
    if (!!jwt) {
      const jwtComposition: Array<string> = jwt.split('.');
      if (!!jwtComposition && jwtComposition.length === 3) {
        const jwtBody = jwtComposition[1];
        const jwtBodyDecoded = atob(jwtBody);
        const paymentData = JSON.parse(jwtBodyDecoded);
        Object.assign(this, paymentData);
        this.originatorData = jwt;
      } else {
        console.warn('Provided data is not a token', jwtComposition);
      }
    } else {
      console.warn('JWT token not found');
    }
  }
}

export class OnlineBillIbanPayment extends OnlineBillPayment {
  iban: string;
  type: PAYMENT_TYPE.PaymentSepa = PAYMENT_TYPE.PaymentSepa;
  constructor(data: OnlineBillPayment) {
    super();
    Object.assign(this, data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class OnlineBillPaymentService {
  private activeBillPaymentSource = new ReplaySubject<OnlineBillPayment>(1);
  activeBillPayment$: Observable<OnlineBillPayment> = this.activeBillPaymentSource.asObservable();

  static isIbanTransfer(data: any): data is OnlineBillIbanPayment {
    return (data as OnlineBillIbanPayment).iban !== undefined;
  }

  // static getOnlineBillSepaTransfer(data: OnlineBillPayment): OnlineBillIbanPayment {
  //   return new OnlineBillIbanPayment(data);
  // }

  constructor() {}

  setActiveBillPayment(data: OnlineBillPayment) {
    console.log('setActiveBillPayment', data);
    this.activeBillPaymentSource.next(data);
  }

  resetActiveBillPayment() {
    const emptyPayment = new OnlineBillPayment();
    this.setActiveBillPayment(emptyPayment);
    console.log('resetBillPayment', emptyPayment);
  }
}
