import { InjectionToken, /*FactoryProvider*/ } from '@angular/core';

export const WINDOW = new InjectionToken<Window>(
  'window',
  { providedIn: 'root', factory: () => window }
);

// maybe use for AOT compilation
/*const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

export const WINDOW_PROVIDERS = [
  windowProvider
];*/
