import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { RightContainerService, RightComponentName, DateRange } from './right-container.service';

@Component({
  selector: 'app-right-container',
  templateUrl: './right-container.component.html',
  styleUrls: ['./right-container.component.scss']
})
export class RightContainerComponent implements OnInit, OnDestroy {

  @Input() componentName?: RightComponentName; // TODO need switch to Template projection
  @Input() initData?: any;
  @Input() transferBankMobile = null;

  subscribe?: Subscription;

  dateRange?: DateRange;

  constructor(
    public container: RightContainerService) {
  }

  ngOnInit() {
    if (this.componentName && this.initData) {
      this.container.setActiveData(this.componentName, this.initData);
    }
    this.subscribe = this.container.rightContainer$
      .subscribe(componentName => this.componentName = componentName);
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  applyDateFilter(dateRange: DateRange) {
    this.dateRange = dateRange;
  }

}
