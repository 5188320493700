import { Injectable } from '@angular/core';

import { merge, fromEvent, Observable, BehaviorSubject, timer, of } from 'rxjs';
import { bufferTime, filter, mapTo, tap, delayWhen, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private logoutTimerActiveSource = new BehaviorSubject(false);
  logoutTimerActive$ = this.logoutTimerActiveSource.asObservable();
  logoutInterval: number;

  private static getDocumentEvents() {
    return merge(
      // fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'touchstart'),
      fromEvent(document, 'scroll'),
    );
  }

  constructor() {
    // console.log('IDLE INIT TEST MODE');
  }

  private startLogoutTimer(): Observable<boolean> {
    this.logoutTimerActiveSource.next(true);
    return of(true)
      .pipe(
        delayWhen(() => timer(this.logoutInterval * 1000)),
        map(() => this.logoutTimerActiveSource.getValue()),
        filter((isActive) => isActive)
      );
  }

  stopLogoutTimer() {
    this.logoutTimerActiveSource.next(false);
  }

  idleStream(timerSec: number = 1000): Observable<any> {
    this.logoutInterval = Math.ceil(timerSec / 3);

    return IdleService.getDocumentEvents()
      .pipe(
        mapTo('e'),
        tap(() => this.stopLogoutTimer()),
        bufferTime((timerSec - this.logoutInterval) * 1000),
        filter(arr => arr.length === 0),
        switchMap(() => this.startLogoutTimer()),
        tap(() => this.stopLogoutTimer())
      );
  }

}
