import { environment } from '../../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getPasswordHash } from './auth.tools';

import { BankConfirmAuthResponse, BankSessionIdResponse } from '../users.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthUasService {

  constructor(private http: HttpClient) { }

  uasGetSessionId(phoneNumber: string, /*password?: string, fcmToken?: string*/): Observable<BankSessionIdResponse> {
    const url = environment.baseApi + `/v1/requestCode`;
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Authorization': phoneNumber,
      'dev-id': 'test',
      // 'fcm-token': fcmToken || 'fcm-token'
    });
    return this.http.post<BankSessionIdResponse>(url, null, { headers }).pipe(
      map(response => ({ ...response, ...{ authType: 'uas' } }))
    );
  }

  verifyLoginCode(phoneNumber: string, code: string, sessionId: string, password?: string): Observable<BankConfirmAuthResponse> {
    const url = environment.baseApi + `/v1/signin`;
    let headers = new HttpHeaders({
      'Authorization': phoneNumber,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    });

    if (password) {
      headers = headers.set('password', getPasswordHash(phoneNumber, password));
    }

    const body = {
      sessid: sessionId,
      code: code
    };
    return this.http.post<BankConfirmAuthResponse>(url, body, { headers });
  }
}
