import { CurrencyCode, SwiftCommission, SwiftUrgency } from '../../../models/payment.interfaces';

function isSwiftData(data: SepaTransferForm | SwiftTransferForm): data is SwiftTransferForm {
  return (data as SwiftTransferForm).swiftCode !== undefined && (data as SwiftTransferForm).beneficiaryBank !== undefined;
}

interface AccountTransferBaseData {
  accountId?: string;
  accountNumber: string;
  currencyCode: CurrencyCode;
  amount: number;
  amountEuro?: number;
}

export class AccountTransferForm implements Required<AccountTransferBaseData> {
  accountId: string;
  accountNumber: string;
  currencyCode: CurrencyCode;
  amount: number;
  amountEuro: number;
  sepaForm: SepaTransferForm;
  swiftForm: SwiftTransferForm;
  constructor(commonData: AccountTransferBaseData, formData: SepaTransferForm | SwiftTransferForm ) {
    Object.assign(this, commonData);
    if (isSwiftData(formData)) {
      this.swiftForm = formData;
    } else {
      this.sepaForm = formData;
    }
  }
}

export class SepaTransferForm {
  beneficiaryName: string;
  transferDetail: string;
  transferComment: string;
  constructor(data: SepaTransferForm) {
    Object.assign(this, data);
  }
}

export class SwiftTransferForm {
  beneficiaryName: string;
  country: string;
  city: string;
  address: string;
  swiftCode: string;
  beneficiaryBank: string;
  isIntermediaryBank = false;
  intermediarySwift?: string;
  intermediaryAccount?: string;
  commissionType: SwiftCommission;
  urgency: SwiftUrgency;
  transferDetail: string;
  transferComment: string;
  constructor(data: SwiftTransferForm) {
    Object.assign(this, data);
  }
}
