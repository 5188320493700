import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EnterCodeSubmit, FormValue } from '../../../top-up-account.interface';


@Component({
    selector: 'app-top-up-enter-code',
    templateUrl: './top-up-enter-code.component.html',
    styleUrls: ['./top-up-enter-code.component.scss']
})
export class TopUpEnterCodeComponent implements OnChanges {

    @Input() errorCode = false;
    @Input() errorCodeUsed = false;
    @Output() goBackEmit = new EventEmitter<string>();
    @Output() submitEmit = new EventEmitter<EnterCodeSubmit>();

    submitForm = new UntypedFormGroup({
        topupCode: new UntypedFormControl(null, {
            updateOn: 'change',
            validators: [Validators.required]
        })
    });

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.submitForm.setErrors({incorrectCode: false});
        this.submitForm.setErrors({codeUsed: false});
        if (changes.hasOwnProperty('errorCode')) {
            this.submitForm.setErrors({incorrectCode: true});
        }
        if (changes.hasOwnProperty('errorCodeUsed')) {
            this.submitForm.setErrors({codeUsed: true});
        }
    }

    goBack() {
        this.goBackEmit.emit('Code');
    }

    onCodeSubmit({value, valid}: { value: FormValue, valid: boolean }) {
        if (valid) {
            this.submitEmit.emit({page: 'Confirmation', code: value.topupCode});
        }
    }
}
