import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Locales, LocalesItem, LocalizationService } from '../../../internationalization/localization/localization.service';
import { Country, CountryService } from '../../../services/country/country.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['language-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectComponent implements OnInit, OnChanges {

  @Input() findCountry?: string;
  @Input() locales?: Array<LocalesItem>;
  @Output() activeLanguage = new EventEmitter<LocalesItem>();

  languagesList?: Array<LocalesItem>;
  activeLanguageName?: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private country: CountryService,
    private localization: LocalizationService
  ) {
  }

  ngOnInit(): void {
    console.log('findCountry', this.findCountry);
    console.log('locales', this.locales);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
    if (changes.hasOwnProperty('findCountry')) {
      if (changes['findCountry'].currentValue) {
        this.getLanguagesList(this.findCountry)
          .subscribe(languagesList => this.languagesList = languagesList);
      } else {
          this.localization.getLanguagesList()
            .subscribe(langList => {
              this.languagesList = langList;
              this.cdr.markForCheck();
            });
        }
    }
  }

  setActiveLanguage(language: LocalesItem) {
    this.activeLanguageName = language.name;
    console.log('activeLanguageName', this.activeLanguageName);
    this.activeLanguage.emit(language);
  }

  private getLanguagesList(value: string): Observable<Array<LocalesItem>> {
    const findValue = (Number.isNaN(+value)) ? value : +value;
    console.log('findValue', findValue);
    if (typeof findValue === 'string') {
      return this.localization.getCountryListByName(findValue);
    }
  }
}
