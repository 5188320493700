import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export const insufficientFundsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const amount = control.get('amount');
    const balance = control.get('balance');
    if (!amount || !balance) {
        throw { error: `Can't find amount or balance field in current form` };
    }
    return (amount.value > balance.value) ? { insufficientFunds: { max: balance.value, actual: amount.value } } : null;
};

export const minValueValidator = (minValue: number) => {
    return (control: AbstractControl) => {
        const amount = control.value;
        if (amount < minValue) {
            return {min: {min: 0.1, actual: 0}};
        }
        return null;
    };
}
