import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TopUpAccountIndexComponent } from './binance/top-up-account-index/top-up-account-index.component';
import { TopUpAccountStartComponent } from './binance/top-up-account-start/top-up-account-start.component';
import { TopUpAccountEmailComponent } from './binance/top-up-account-email/top-up-account-email.component';
import { TopUpAccountBinanceVerifiedComponent } from './binance/top-up-account-binance-verified/top-up-account-binance-verified.component';
import { TopUpAccountDetailsComponent } from './binance/top-up-account-details/top-up-account-details.component';
import { TopUpAccountConfirmationComponent } from './binance/top-up-account-confirmation/top-up-account-confirmation.component';
import { TopUpComponent } from './top-up-index/top-up-index.component';
import { TopUpInstructionComponent } from './top-up-instruction/top-up-instruction.component';

import { TOP_UP_PATH } from './top-up-account.interface';

const routes: Routes = [
  {
    path: '',
    component: TopUpAccountIndexComponent,
    children: [
      {
        path: '',
        redirectTo: TOP_UP_PATH.transfer,
        pathMatch: 'full'
      },
      {
        path: TOP_UP_PATH.binanceStart,
        component: TopUpAccountStartComponent
      },
      {
        path: TOP_UP_PATH.binanceEmail,
        component: TopUpAccountEmailComponent
      },
      {
        path: TOP_UP_PATH.binanceVerified,
        component: TopUpAccountBinanceVerifiedComponent
      },
      {
        path: TOP_UP_PATH.binanceDetails,
        component: TopUpAccountDetailsComponent
      },
      {
        path: TOP_UP_PATH.binanceConfirmation,
        component: TopUpAccountConfirmationComponent
      },
      {
        path: TOP_UP_PATH.transfer,
        component: TopUpComponent,
      },
      {
        path: TOP_UP_PATH.instruction,
        component: TopUpInstructionComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TopUpAccountRoutingModule {
}
