import { Component, Input, OnInit } from '@angular/core';

import { ModalsService } from '../../modals.service';
import { NotificationServiceItem } from '../../../services/server-notifications/server-notifications.service';

@Component({
  selector: 'transfer-confirm-request',
  templateUrl: './transfer-confirm-request.html',
  styleUrls: ['./transfer-confirm-request.scss']
})
export class TransferConfirmRequestComponent implements OnInit {

  @Input() notification?: NotificationServiceItem;

  constructor(
    private modals: ModalsService) {
  }

  ngOnInit() {
    console.log(this.notification);
  }

  reject() {
    this.closeModal('reject');
  }

  accept() {
    this.closeModal('accept');
  }

  private closeModal(value: 'accept' | 'reject') {
    this.modals.closeModal(value || null);
  }

}
