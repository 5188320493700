import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { NgxMaskModule } from 'ngx-mask';

import { PlasticCardComponent } from './plastic-card/plastic-card.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';

@NgModule({
  declarations: [
    PlasticCardComponent
  ],
  exports: [
    PlasticCardComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    NgxMaskModule,
    InternationalizationModule
  ]
})
export class PlasticCardModule { }
