import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-money-history-item',
  templateUrl: './send-money-history-item.component.html',
  styleUrls: ['./send-money-history-item.component.scss']
})
export class SendMoneyHistoryItemComponent implements OnInit {

  @Input() item;

  constructor() { }

  ngOnInit() {
  }

  getIntegerPart() {
    const price = Math.abs(this.item.price);
    if (this.item.price % 1 === 0) {
      return price;
    }
    const price1 = Math.abs(this.item.price).toString().replace('.', ',');
    return price1.substring(0, price1.indexOf(',') + 1);
  }

  getDecimalPart() {
    if (this.item.price % 1 === 0) {
      return '';
    }
    const price = this.item.price.toString();
    return price.substring(price.indexOf('.') + 1, price.length);
  }

}
