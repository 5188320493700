import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-language-select-item',
  templateUrl: './language-select-item.component.html',
  styleUrls: ['./language-select-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectItemComponent {

  @Input() languageName?: string;
  @Input() translateToEn?: string;

  url?: string;

  constructor() {}
}
