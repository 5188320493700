import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { NgxMaskModule } from 'ngx-mask';

import { CardLimitsSetComponent } from './card-limits-set/card-limits-set.component';
import { CardLimitsConfirmComponent } from './card-limits-confirm/card-limits-confirm.component';
import { CardLimitsDisableComponent } from './card-limits-disable/card-limits-disable.component';
import { CardInfoSendSmsComponent } from './card-info-send-sms/card-info-send-sms.component';
import { CardInfoComponent } from './card-info/card-info.component';
import { CardBlockComponent } from './card-block/card-block.component';
import { CardUnblockComponent } from './card-unblock/card-unblock.component';
import { CardUnblockConfirmComponent } from './card-unblock-confirm/card-unblock-confirm.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { CardOrderConfirmComponent } from './card-order-confirm/card-order-confirm.component';
import { CardOrderSuccessComponent } from './card-order-success/card-order-success.component';
import { CountdownTimerModule } from '../../ui-elements/countdown-timer/countdown-timer.module';
import { IsLoadingModule } from '../../ui-elements/is-loading/is-loading.module';


@NgModule({
  declarations: [
    CardLimitsSetComponent,
    CardLimitsConfirmComponent,
    CardLimitsDisableComponent,
    CardInfoSendSmsComponent,
    CardInfoComponent,
    CardBlockComponent,
    CardUnblockComponent,
    CardUnblockConfirmComponent,
    CardOrderConfirmComponent,
    CardOrderSuccessComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    InternationalizationModule,
    CountdownTimerModule,
    NgxMaskModule,
    IsLoadingModule
  ]
})
export class BankCardsModalsModule { }
