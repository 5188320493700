import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { NavigationNewsService } from './navigation-news.service';
import { LocalizationService } from '../../../internationalization/localization/localization.service';

export interface News {
  id: number;
  type: string;
  url: string;
  title: string;
  description: string;
  backgroundImageUrl: string;
}

@Component({
  selector: 'app-navigation-news',
  templateUrl: './navigation-news.component.html',
  styleUrls: ['./navigation-news.component.scss'],
  providers: [ NavigationNewsService ]
})
export class NavigationNewsComponent implements OnInit {

  news$: Observable<News> = this.localization.getActiveLanguage$().pipe(
    switchMap(locale => this.newsService.requestNews(locale)),
  );

  link$: Observable<string> = this.news$.pipe(
    filter(Boolean),
    map((news: News) => />([^<]+)</.exec(news?.description)),
    filter(Boolean),
    map(value => value[1])
  );

  constructor(
    private newsService: NavigationNewsService,
    private localization: LocalizationService) {
  }

  ngOnInit() {}

  gotoLink(news: News) {
    if (news.url) {
      window.open(news.url, '_blank');
      this.newsService.registerAsRead(news)
        .subscribe();
    }
  }
}
