<ion-app>
    <ion-header class="ion-no-border">
        <ion-toolbar>
            <ion-title>Info</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-content class="Info ion-padding">

        <p>In a <b>REPO</b>, one entity sells an asset (cryptocurrencies in this case) to another at a certain price at the start of the transaction and pledges to repurchase the asset from the initial buyer at a future date, at a different price. If the seller fails to buy back the asset, the buyer can put it on the open market, which makes the asset a kind of collateral during the REPO.</p>
        <p>Cryptocurrency holders would generate profits from lending their coins through BCC to a second party. In return, they would receive another coin as collateral until their initial token is returned. The second party would use the borrowed coin for short-term trading or transactions and will have to pay a fee as part of the deal.</p>

        <!-- <ion-icon class="Info-cat" name="cat-info-crypto-confirmation"></ion-icon> -->

    </ion-content>
</ion-app>
