/** need add <div id="recaptcha-container"></div> for recaptcha **/
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, from as observableFrom, throwError } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';


import { BankSessionIdResponse } from '../users.interface';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FirebaseError } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import auth = firebase.auth;
import User = firebase.User;

const RECAPTCHA_CONTAINER_ID = 'recaptcha-container';
export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAwQ4OC5eMrNvlJ3FKRCBvh8FPJcBEjwYg',
  authDomain: 'blackcationic.firebaseapp.com',
  databaseURL: 'https://blackcationic.firebaseio.com',
  projectId: 'blackcationic',
  storageBucket: 'blackcationic.appspot.com',
  messagingSenderId: '113434870661'
};

@Injectable({
  providedIn: 'root'
})
export class AuthFcmService {

  private recaptchaVerified?: auth.RecaptchaVerifier;
  private authConfirmationResult?: auth.ConfirmationResult;

  static isFirebaseError(error: HttpErrorResponse | FirebaseError): error is FirebaseError {
    return (error as FirebaseError).code !== undefined;
  }

  private static getFireBaseIdToken(userCredential: auth.UserCredential): Observable<string> {
    return observableFrom(
      userCredential.user.getIdToken()
    );
  }

  constructor(
    private afAuth: AngularFireAuth) {

    firebase.initializeApp(FIREBASE_CONFIG); // j
  }

  getLoginCode(phoneNumber: string): Observable<BankSessionIdResponse> {
    // console.log(auth);
    // auth().settings.appVerificationDisabledForTesting = true;
    this.resetReCaptcha();
    this.recaptchaVerified = new firebase.auth.RecaptchaVerifier(RECAPTCHA_CONTAINER_ID, {
      size: 'invisible',
      'callback': response => console.log(response),
      'error-callback': error => console.log(error)
    });
    console.log('RECAPTCHA', this.recaptchaVerified);
    return observableFrom(
      this.afAuth.signInWithPhoneNumber(phoneNumber, this.recaptchaVerified)
    ).pipe(
      tap(result => this.authConfirmationResult = result),
      map(result => {
        return { success: true, sessid: result.verificationId, authType: 'fire-base' };
      })
    );
  }

  verifyLoginCode(verificationCode: string): Observable<string> {
    return (!this.authConfirmationResult)
      ? throwError({ fbAuthError: 'Missing auth confirmation result' })
      : observableFrom(this.authConfirmationResult.confirm(verificationCode))
        .pipe(
          switchMap(userCredential => AuthFcmService.getFireBaseIdToken(userCredential)),
        );
  }

  getAuthState(): Observable<User> {
    return this.afAuth.authState;
  }

  logout(): void {
    this.resetReCaptcha();
    this.afAuth.signOut();
  }

  public resetReCaptcha() {
    if (this.recaptchaVerified) {
      this.recaptchaVerified.clear();
      document.getElementById('recaptcha-wrapper')
        .innerHTML = `<div id="${ RECAPTCHA_CONTAINER_ID }" style="display:none;"></div>`;
    }
  }

}
