import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { validateIf } from '../../../validators/validate-if.validator';
import { validateIBAN } from '../../../validators/iban.validator';
import { validatePapayaCardNumber } from '../../../validators/bank-card.validators';
import {
  regexpBeneficiaryName, regexpFloatingPointNumber, regexpTransferDescription, regexpOnlyNumbers, regexpNumbersAndLatin, regexpAddress
} from '../../../tools/regular-expressions';

import { RightContainerService } from '../../right-container.service';
import { PaymentsService } from '../../../services/payments/payments.service';
import { TransferTemplatesService } from '../../../services/transfer-templates/transfer-templates.service';

import { PaymentOperationTemplate } from '../../../users/operations/operations.interface';
import { NotStartedWithSpace } from '../../../validators/not-started-with-space.validator';
import { ModalData, ModalsService } from '../../../modals/modals.service';
import { Country } from '../../../services/country/country.service';
import { SelectCountryComponent } from '../../../modals/components/select-country/select-country.component';
import { ConfirmDeleteComponent } from '../../../modals/components/confirm-delete/confirm-delete.component';

const NAVIGATION_TABLE = {
  'PAYMENT_CARD': 'transfer-card-templates',
  'PAYMENT_SEPA': 'transfer-bank-templates',
  'PAYMENT_PHONE': 'transfer-contact-templates',
  'PAYMENT_SWIFT': 'transfer-bank-templates'
};

const CITY_REGEX = /^[a-zA-Z0-9\s-.,+:'()/?]+$/;
const BENEFICIARY_BANK_REGEX = /^[a-zA-Z0-9\s-_.,“”«»()&#/+"№]+$/;

@Component({
  selector: 'app-transfers-template-edit',
  templateUrl: './transfers-template-edit.component.html',
  styleUrls: ['./transfers-template-edit.component.scss']
})
export class TransfersTemplateEditComponent implements OnInit, OnDestroy {
  @Input() mobileView = false;
  @Output() closeEdit = new EventEmitter<void>();

  template?: PaymentOperationTemplate;
  transferDetails = PaymentsService.getPaymentDetailList();

  activeCountry: string;

  templateForm = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    amount: new UntypedFormControl(null, [
      Validators.required,
      Validators.maxLength(10),
      Validators.min(0.01),
      Validators.pattern(regexpFloatingPointNumber),
    ]),
    beneficiaryName: new UntypedFormControl(null, [
      validateIf(
        group => (group.get('type').value === 'PAYMENT_CARD' || group.get('type').value === 'PAYMENT_SEPA' || group.get('type').value === 'PAYMENT_SWIFT'),
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(128),
          Validators.pattern(regexpBeneficiaryName),
        ])
      )
    ]),
    currencyCode: new UntypedFormControl(null),
    fromAccountId: new UntypedFormControl(null),
    fromCardId: new UntypedFormControl(null),
    fromCardPan: new UntypedFormControl(null),
    purpose: new UntypedFormControl(null, [
      Validators.maxLength(105),
      Validators.pattern(regexpTransferDescription),
    ]),
    title: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(255),
    ]),
    toCardNumber: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_CARD',
        Validators.compose([
          Validators.required,
          validatePapayaCardNumber,
        ])
      )
    ]),
    toIban: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SEPA',
        Validators.compose([
          Validators.required,
          Validators.maxLength(34),
          validateIBAN,
        ])
      )
    ]),
    toPhoneNumber: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_PHONE',
        Validators.compose([
          Validators.required,
          Validators.maxLength(16),
          Validators.pattern(regexpOnlyNumbers)
        ])
      )
    ]),
    transferDetails: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    beneficiaryAccount: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SWIFT',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(34),
          Validators.pattern(regexpNumbersAndLatin),
          NotStartedWithSpace()
        ])
      )
    ]),
    country: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SWIFT',
        Validators.compose([
          Validators.required
        ])
      )
    ]),
    city: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SWIFT',
        Validators.compose([
          NotStartedWithSpace(),
          Validators.pattern(CITY_REGEX)
        ])
      )
    ]),
    address: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SWIFT',
        Validators.compose([
          NotStartedWithSpace(),
          Validators.pattern(regexpAddress),
        ])
      )
    ]),
    swiftCode: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SWIFT',
        Validators.compose([
          NotStartedWithSpace(),
          Validators.pattern(regexpNumbersAndLatin)
        ])
      )
    ]),
    beneficiaryBank: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SWIFT',
        Validators.compose([
          NotStartedWithSpace(),
          Validators.pattern(BENEFICIARY_BANK_REGEX),
        ])
      )
    ]),
    commissionType: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SWIFT',
        Validators.compose([
          Validators.required
        ])
      )
    ]),
    urgency: new UntypedFormControl(null, [
      validateIf(
        group => group.get('type').value === 'PAYMENT_SWIFT',
        Validators.compose([
          Validators.required
        ])
      )
    ])
  });

  optionsDescription: any = {
    cssClass: 'SelectTemplateDescription'
  };
  private destroyed$ = new Subject<void>();

  constructor(
    private rightContainer: RightContainerService,
    private transferTemplates: TransferTemplatesService,
    private modals: ModalsService) {
  }

  ngOnInit() {
    this.rightContainer.getActiveData('transfers-template-edit')
      .subscribe(template => {
        if (template.type === 'PAYMENT_SWIFT') {
          this.activeCountry = template.country;
        }
        this.template = template;
        this.templateForm.reset(template);
        console.log(123, template);
      });
    /*this.template = this.rightContainer.getActiveContainerData();
    this.templateForm.reset(this.template);*/
  }

  onSubmit({ value, valid }: { value: PaymentOperationTemplate, valid: boolean }) {
    if (valid) {
      this.transferTemplates.requestUpdateTemplate(value)
        .subscribe(template => {
          this.transferTemplates.replaceTemplateInTemplateList(template);
          this.onGoBack();
        });
    }
  }

  onGoBack() {
    if (this.mobileView) {
      this.closeEdit.emit();
      return;
    }
    this.rightContainer.setRightPanel(NAVIGATION_TABLE[this.template.type]);
  }

  confirmDelete(template: PaymentOperationTemplate) {
    return this.modals.openModal({
      component: ConfirmDeleteComponent,
      componentProps: {
        templateName: template.title
      },
      cssClass: 'ConfirmDialog',
    });
  }

  onDeleteTemplate(template: PaymentOperationTemplate) {
    this.confirmDelete(template)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data.hasResult()) {
            this.onDelete(template);
        }
    });
  }

  onDelete(template: PaymentOperationTemplate) {
    this.transferTemplates.requestDeleteTemplate(template)
      .pipe(
        filter(response => response.status === 'ok')
      )
      .subscribe(() => {
        this.transferTemplates.deleteTemplate(template);
        this.onGoBack();
      });
  }

  onChangeCountry() {
    this.openCountrySelectModal()
      .pipe(
        filter((modal: ModalData) => modal.hasResult() ? modal.getResult() : null )
      )
      .subscribe((data) => {
        this.activeCountry = data.getResult().name;
        this.templateForm.patchValue({country: data.getResult().code});
      });
  }

  noSort() {
    return 0;
  }

  private openCountrySelectModal(): Observable<ModalData<Country>> {
    return this.modals.openModal({
      component: SelectCountryComponent,
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
