<ion-header class="ion-no-border">
  <div class="client-select">
    <app-client-select
        [operationType]="operationType"
        customClass="select_top-up"
        customHeight="220px"
        [shortLabel]="true"
        (selectAccount)="setActiveClient($event)"
        (emptyAccounts)="onEmptyAccounts($event)">
    </app-client-select>

    <app-no-accounts-available *ngIf="accountIsEmpty"></app-no-accounts-available>
  </div>

  <ion-buttons *ngIf="!activeAccountIsBlocked">
    <ion-button expand="full"
                [ngClass]="activePage === 'Iternal_payment' ? 'active-button': null"
                (click)="setActivePage('Iternal_payment')">
      {{ 'topup.tab.iternal_payment' | translate }}
    </ion-button>

    <ion-button expand="full"
                [ngClass]="activePage === 'Incoming_SEPA' ? 'active-button': null"
                (click)="setActivePage('Incoming_SEPA')">
      {{ 'topup_incoming_sepa' | translate }}
    </ion-button>

    <ion-button expand="full"
                [ngClass]="activePage === 'Code' ? 'active-button': null"
                (click)="setActivePage('Code')">
      {{ 'topup.tab.topup_code' | translate }}
    </ion-button>
  </ion-buttons>
</ion-header>

<ion-content *ngIf="!activeAccountIsBlocked" [ngSwitch]="activePage" class="ion-padding">
  <app-top-up-iternal-payment
      *ngSwitchCase="'Iternal_payment'"
      [activeAccount]="activeAccount"
  />

  <app-top-up-incoming-sepa
      *ngSwitchCase="'Incoming_SEPA'"
      [account]="accountTransferData$ | async"
      [accountId]="activeAccount.id"
      [availableState]="isSEPAAvailable()"
  />

  <app-top-up-code
      *ngSwitchCase="'Code'"
      [accountNumber]="activeAccount?.number"
      [hasActiveBonusProgram]="activeAccountHasBonusProgram"
      [hideWhereToTopUp]="hideCard"
  />
</ion-content>

<section *ngIf="activeAccountIsBlocked" class="accountIsBlocked">
  <ion-icon class="accountIsBlocked-icon" name="account-is-blocked"></ion-icon>
  <p class="accountIsBlocked-title">{{ 'topup.account_blocked' | translate }}</p>
  <p>{{ 'topup.choose_another_account' | translate }}.</p>
</section>
