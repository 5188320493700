<ion-app>
    <ion-header class="ion-no-border">
        <ion-toolbar>
            <ion-title>{{ 'howItWorks.title' | translate }}</ion-title>
            <ion-icon (click)="goBack()" slot="start" name="back-small"></ion-icon>
        </ion-toolbar>
    </ion-header>

    <ion-content class="Info ion-padding">

        <p>{{ 'info_transfer_conf.third_text' | translate }}.</p>
        <p *appPermission="[ roles.BCC_INDIVIDUAL_PERSON, roles.BCC_LEGAL_PERSON ]">{{ 'info_transfer_conf.fourth_text' | translate }}.</p>

    </ion-content>
</ion-app>
