import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonolitErrorParserService, MonolitHttpError } from '@core/monolit';
import { checkEndpointOnExist, getApiByUrl } from '@core/tools';

import { Observable, throwError, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationsService } from '../../services/notifications/notifications.service';
import { ErrorHandlerService, TechnicalWorkTimeError} from './error.handler.service';

interface ExceptionItem {
  endpoint: string | RegExp;
  codes: Array<number>;
}

const EXCEPTION_ENDPOINT_LIST: Array<ExceptionItem> = [
  {
    endpoint: /v2\/operations\/[^\/]+/,
    codes: [ 500 ]
  },
  {
    endpoint: 'v1/password/verify',
    codes: [ 406 ]
  },
  {
    endpoint: 'v1/binance/binance-contract-availability',
    codes: [ 500 ]
  }
];
const EXCEPTION_CODE_LIST: Array<number> = [
  401
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private notification: NotificationsService,
    private errorHandler: ErrorHandlerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      /*tap(response => {
        console.log('RESPONSE', ErrorInterceptor.getApiByUrl(req.url), response);
        console.log('CHECK_ON_ERROR', req.url, ErrorInterceptor.checkResponseForErrors(response));
      }),*/
      switchMap(response => (MonolitErrorParserService.checkResponseForErrors(response)) ? throwError(response) : of(response)),
      catchError((response) => {
        if (!this.checkAnException(response) && !this.checkAnExceptionByCode(MonolitErrorParserService.getErrorCode(response))) {
          this.notification.notifyServerError(MonolitErrorParserService.getErrorMessageFromResponse(response));
        }
        if (MonolitErrorParserService.getErrorCode(response) === 410) {
          const monolitError
            = MonolitErrorParserService.getErrorFromHttpErrorResponse(response) as MonolitHttpError<TechnicalWorkTimeError>;
          this.errorHandler.setTechnicalWorkStatus(monolitError)
            .subscribe();
        }
        return throwError(response);
      })
    );
  }

  private checkAnExceptionByCode(code: number): boolean {
    return EXCEPTION_CODE_LIST.some(c => c === code);
  }

  private checkAnException(response: HttpErrorResponse): boolean {
    const api: string = (response.url) ? getApiByUrl(response.url) : null;
    if (!!api && checkEndpointOnExist(api, EXCEPTION_ENDPOINT_LIST.map(e => e.endpoint))) {
      const foundExistEndpoint = EXCEPTION_ENDPOINT_LIST.find(e => e.endpoint === api);
      const errorCode = MonolitErrorParserService.getErrorCode(response);
      return foundExistEndpoint.codes.some(code => code === errorCode);
    }
    return false;
  }
}
