<div class="SettingsLogOut ion-text-center">
  <div class="SettingsLogOut__icon">
    <ion-icon name="sign-out"></ion-icon>
  </div>
  <div class="SettingsLogOut__title">
    <span>{{ 'logOut.title' | translate }}</span>
  </div>
  <div class="SettingsLogOut__text">
    <p>{{ 'logOut.text' | translate }}</p>
  </div>
  <div class="SettingsLogOut__buttons">
    <ion-button
      expand="full" shape="round" fill="outline" color="dark"
      (click)="closeModal()">
        {{ 'logOut.first_button' | translate }}
    </ion-button>
    <ion-button
      expand="full" shape="round" fill="outline" color="danger"
      (click)="closeModal(true)">
        {{ 'logOut.second_button' | translate }}
    </ion-button>
  </div>
</div>
