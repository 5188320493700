import { Component, Input, OnInit } from '@angular/core';
import { RightContainerService } from '../../../right-container.service';

@Component({
  selector: 'app-crypto-transaction-history-item',
  templateUrl: './crypto-transaction-history-item.component.html',
  styleUrls: ['./crypto-transaction-history-item.component.scss']
})
export class CryptoTransactionHistoryItemComponent implements OnInit {

  @Input() item;

  itemOpen = false;

  constructor(private rightContainer: RightContainerService) { }

  ngOnInit() {
  }

  openPaymentRecipe(): void {
    this.rightContainer.setRightPanel('crypto-transaction-history-recipe');
    this.rightContainer.paymentReceiptItem = this.item;
  }

  getIntegerPart() {
    const price = Math.abs(this.item.price);
    if (this.item.price % 1 === 0) {
      return price;
    }
    const price1 = Math.abs(this.item.price).toString().replace('.', ',');
    return price1.substring(0, price1.indexOf(',') + 1);
  }

  getDecimalPart() {
    if (this.item.price % 1 === 0) {
      return '';
    }
    const price = this.item.price.toString();
    return price.substring(price.indexOf('.') + 1, price.length);
  }

  repeat() {
    console.log('repeat !!!');
  }

  openReceipt() {
    console.log('open receipt !!!');
    this.rightContainer.setRightPanel('crypto-transaction-history-recipe');
    this.rightContainer.paymentReceiptItem = this.item;
  }

  showFullInfo(e) {
    if (this.itemOpen) this.itemOpen = false;
    else this.itemOpen = true;
  }

}
