<div class="TopUpSucceess ion-text-center">

    <ion-icon class="TopUpSucceess-icon" name="succeess"></ion-icon>

    <b>{{ 'topup.codeSuccess_title' | translate }}</b>

    <p>{{topupAmount}} {{topupCurrency}} {{ 'topup.codeSuccess_text_first' | translate }} {{ 'topup.codeSuccess_text_second' | translate }}</p>

    <div class="TopUpSucceess-button">
        <ion-button expand="full" shape="round" fill="outline" color="success" (click)="onSubmit()">{{ 'topup.codeSuccess_buttonOk' | translate }}</ion-button>
    </div>

</div>
