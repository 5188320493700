import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { LanguageSelectItemComponent } from './language-select-item/language-select-item.component';

@NgModule({
  declarations: [
    LanguageSelectComponent,
    LanguageSelectItemComponent
  ],
  exports: [
    LanguageSelectComponent
  ],
  imports: [
    CommonModule
  ]
})
export class LanguageSelectModule { }
