import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IBinanceEmailConnect, IBinanceDeepLinkResponse, ICreateBinanceContractResponse, TOP_UP_PATH } from '../../top-up-account.interface';
import { TopUpAccountService } from '../../top-up-account.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { ModalsService } from '../../../modals/modals.service';
import { ErrorModalComponent } from '../../../modals/binance-modals/error-modal/error-modal.component';

@Component({
  selector: 'app-top-up-account-start',
  templateUrl: './top-up-account-start.component.html',
  styleUrls: ['./top-up-account-start.component.scss']
})
export class TopUpAccountStartComponent implements OnInit {
  readonly stepsData: {img: string, title: string, description: string, alt: string}[] = [
    {
      img: 'assets/custom-icons/topup-listIcon-first.svg',
      title: 'STEP 1',
      description: 'topup.binance.info_howItWorks_text1',
      alt: 'link'
    },
    {
      img: 'assets/custom-icons/topup-listIcon-second.svg',
      title: 'STEP 2',
      description: 'topup.binance.info_howItWorks_text2',
      alt: 'euro'
    },
    {
      img: 'assets/custom-icons/topup-listIcon-third.svg',
      title: 'STEP 3',
      description: 'topup.binance.info_howItWorks_text3',
      alt: 'arrows'
    },
    {
      img: 'assets/custom-icons/topup-listIcon-fourth.svg',
      title: 'STEP 4',
      description: 'topup.binance.info_howItWorks_text4',
      alt: 'check'
    }
  ];

  private appId!: number;

  constructor(
    private router: Router,
    private topUpAccountService: TopUpAccountService,
  ) {
  }

  ngOnInit() {
    this.checkBinanceContract();
  }
  startBinanceConnect() {
    this.topUpAccountService.connectBinance().pipe(
      mergeMap((result: ICreateBinanceContractResponse) => {
        if (result.applicationId) {
          this.appId = result.applicationId;
          return this.topUpAccountService.getBinanceDeeplink(result.applicationId);
        }
      })
    ).subscribe({
      next: (binanceData: IBinanceDeepLinkResponse) => {
        this.router.navigate(
          [`/top-up-account/${ TOP_UP_PATH.binanceVerified }`],
          {
            state: {
              binanceData,
              appId: this.appId
            }
          }
        );
      },
      error: err => {
        console.error('getBinanceDeeplink:', err);
        this.topUpAccountService.openErrModal().subscribe();
      }
    });
  }

  private checkBinanceContract() {
    const hasContract = this.router.getCurrentNavigation().extras.state?.binance;

    if (hasContract) {
      this.router.navigate(
        [`/top-up-account/${ TOP_UP_PATH.binanceDetails }`]
      );
    }
  }
}
