<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-title>{{ 'template.edit_field' | translate }}</ion-title>
      <ion-icon (click)="onGoBack()" slot="start" name="back"></ion-icon>
    </ion-toolbar>
  </ion-header>

  <ion-content class="ion-padding">
    <form [formGroup]="templateForm" (submit)="onSubmit(templateForm)" class="TemplatesEdit">

      <ion-list class="TemplatesEdit-list">
        <ion-item class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'template.name_field' | translate }}</ion-label>
          <ion-input
            formControlName="title"
            type="text">
          </ion-input>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SEPA' || template.type === 'PAYMENT_CARD' || template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'templatesEdit.fourth_field' | translate }}</ion-label>
          <ion-input
              formControlName="beneficiaryName"
              type="text">
          </ion-input>
        </ion-item>
        <!--<ion-item *ngIf="template.type === 'PAYMENT_CARD'" class="TemplatesEdit-item">
          <ion-label position="stacked">From your card</ion-label>
          <ion-input
              formControlName="fromCardPan"
              type="text">
          </ion-input>
        </ion-item>-->
        <ion-item *ngIf="template.type === 'PAYMENT_CARD'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'templates.fourteenth_field' | translate }}</ion-label>
          <ion-input
            formControlName="toCardNumber"
            type="text">
          </ion-input>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'templatesEdit.fifth_field' | translate }}</ion-label>
          <ion-input formControlName="beneficiaryAccount" type="text" [maxlength]="34"></ion-input>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'templatesEdit.seventh_field' | translate }}</ion-label>
          <div class="country-data" (click)="onChangeCountry()">
            <div>{{ activeCountry }}</div>
            <ion-input formControlName="country" style="display: none;"></ion-input>
            <div class="country-data-icon"></div>
          </div>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'orderCard.third_field' | translate }}</ion-label>
          <ion-input formControlName="city" type="text" [maxlength]="20"></ion-input>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'templatesEdit.eighth_field' | translate }}</ion-label>
          <ion-input formControlName="address" type="text" [maxlength]="500"></ion-input>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'templatesEdit.sixth_field' | translate }}</ion-label>
          <ion-input formControlName="swiftCode" [minlength]="8" [maxlength]="11"></ion-input>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'bankTransfer.field_beneficiary_bank' | translate }}</ion-label>
          <ion-input formControlName="beneficiaryBank" [maxlength]="34"></ion-input>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'bankTransfer.field_fee_type' | translate }}</ion-label>
          <ion-select formControlName="commissionType" interface="popover" placeholder="{{ 'transfer.choose_fee_type' | translate }}" style="max-width: 100%;">
            <ion-select-option value="SHARED">{{ 'transfer.shared' | translate }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'bankTransfer.field_urgency' | translate }}</ion-label>
          <ion-select formControlName="urgency" interface="popover" placeholder="{{ 'transfer.choose_urgency' | translate }}" style="max-width: 100%;">
            <ion-select-option value="NORMAL">{{ 'transfer.urgency_normal	' | translate }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SEPA'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'templatesEdit.fifth_field' | translate }}</ion-label>
          <ion-input
            formControlName="toIban"
            type="text">
          </ion-input>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_SEPA' || template.type === 'PAYMENT_SWIFT'" class="TemplatesEdit-item ion-text-wrap ion-no-padding">
          <div class="TemplatesEdit-item-wrapper">
            <ion-label position="stacked">{{ 'bankTransfer.fifth_field_default' | translate }}</ion-label>
            <ion-select
              formControlName="transferDetails"
              slot="end" interface="popover"
              [interfaceOptions]="optionsDescription"
              placeholder="{{ 'bankTransfer.tenth_field' | translate }}"
              style="max-width: 100%;">
              <ion-select-option *ngFor="let item of transferDetails | keyvalue : noSort" [value]="item.value">
                  {{ item.key | translate}}
              </ion-select-option>
            </ion-select>
          </div>
        </ion-item>
        <ion-item *ngIf="template.type === 'PAYMENT_PHONE'" class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'contactTransfer.phone_number' | translate }}</ion-label>
          <ion-input
            formControlName="toPhoneNumber"
            type="text">
          </ion-input>
        </ion-item>
        <ion-item class="TemplatesEdit-item">
          <ion-label position="stacked">{{ 'contactTransfer.comment_field' | translate }}</ion-label>
          <ion-input
            formControlName="purpose"
            type="text">
          </ion-input>
        </ion-item>
      </ion-list>

      <div class="TemplatesEdit-bottom">
        <ion-item class="page-group transfer-amount">
          <ion-label slot="start">{{ 'bankTransferBill.sixth_field' | translate }}</ion-label>
          <div slot="end" class="page-group__value">
            <ion-input formControlName="amount" placeholder="0.00"></ion-input>
            <span>EUR</span>
            <!-- <ion-select [interfaceOptions]="optionsCurrency" interface="popover">
            <ion-select-option value="EUR" selected>EUR</ion-select-option>
            </ion-select> -->
          </div>
        </ion-item>

        <div class="TemplatesEdit-buttons">
          <ion-button
            (click)="onDeleteTemplate(template)"
            type="button" class="buttonDelete"
            expand="full" shape="round" fill="outline" color="danger" size="small">
              {{ 'deleteTemplate.title' | translate }}
          </ion-button>
          <ion-button
            [disabled]="templateForm.pristine || templateForm.invalid"
            type="submit" class="buttonSave"
            expand="full" shape="round" fill="outline" color="success" size="small">
              {{ 'templatesEdit.save_button' | translate }}
          </ion-button>
        </div>
      </div>

    </form>
  </ion-content>
</ion-app>
