<ion-item class="History-item" (click)="showFullInfo(item)">
  <ion-badge class="account-color" *ngIf="item.color != ''" color="{{item.color}}"></ion-badge>
  <ion-icon slot=start name="history-income" *ngIf="item.price > 0"></ion-icon>
  <ion-icon slot=start name="history-transfer" *ngIf="item.price <= 0"></ion-icon>
  <div class="History-item__content">
    <div class="History-item__header">
      <span class="History-item__title">{{item.description}}</span>
      <span class="History-item__info"
            [class.green]="item.price > 0"
            [class.gray]="item.price <= 0"
            [class.red]="item.status == 'Insufficient_funds' && item.paymentType!='HOLD'"
            *ngIf="item.status !== 'COMPLETED'">Insufficient funds</span>
    </div>
    <div class="History-item__values">
      <ion-label class="History-item__label">Acc: **** {{ item.fromNumber.substring(item.fromNumber.length, item.fromNumber.length - 4) }}</ion-label>
      <p class="History-item__value"
         [class.green]="item.price > 0"
         [class.gray]="item.price <= 0">
        {{ item.price > 0 ? '+' : '-' }}{{ getIntegerPart() }}<small>{{ getDecimalPart() }} {{ item.currency.code }}</small>
      </p>
    </div>
  </div>
</ion-item>
<div class="History-item__full-info item-{{item.id}}" *ngIf="itemOpen">
  <span *ngIf="item.moreinfo">{{item.moreinfo}}</span>
  <div class="History-item__buttons">
    <ion-button *ngIf="item.price < 0"
                class="buttonRepeat" expand="full" shape="round" fill="outline" color="dark" size="small">
      Repeat
      <ion-icon slot="end" name="repeat"></ion-icon>
    </ion-button>
    <ion-button
            class="buttonReceipt" expand="full" shape="round" fill="outline" color="success" size="small"
            (click)="openPaymentRecipe()">
      Receipt
      <ion-icon slot="start" name="arrow-right-small-green"></ion-icon>
    </ion-button>
    <!--TODO-->
    <!--<ion-button class="buttonReceipt" expand="full" shape="round" fill="outline" color="success" size="small" [routerLink]="['/', { outlets: { rightBox: 'payment-receipt' } }]">-->
    <!--Receipt-->
    <!--<ion-icon slot="start" name="icon-arrow-right-small-green"></ion-icon>-->
    <!--</ion-button>-->
  </div>
</div>
