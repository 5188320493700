import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import {
  IBinanceContractResponse,
  IBinanceRate,
  IBinanceDeepLinkResponse,
  ICommissionRequest, ICreateBinanceContractResponse, INSTITUTION_PRODUCT_ID
} from './top-up-account.interface';
import {
  IntermediaryBankDetailsResponse,
  TopUpAmount,
  TopUpFromCard,
  TopUpProcess,
  TopUpProcessResponse,
  TopUpTransfer
} from '../../models/top-up.interfaces';
import { OrganizationsService } from '../users/organizations/organizations.service';
import { Account } from '../users/organizations.interface';
import { finalize, map, switchMap } from 'rxjs/operators';
import { PaymentCommission } from '../../models/payment.interfaces';
import { ModalsService } from '../modals/modals.service';
import { ErrorModalComponent } from '../modals/binance-modals/error-modal/error-modal.component';

const CRYPTO_PRODUCT_ID: INSTITUTION_PRODUCT_ID = INSTITUTION_PRODUCT_ID.manerio;

@Injectable({
  providedIn: 'root'
})
export class TopUpAccountService {

  private isLoadingSource = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSource.asObservable();

  private headers: HttpHeaders = new HttpHeaders().set('productId', INSTITUTION_PRODUCT_ID.manerio);

  constructor(
    private http: HttpClient,
    private organizations: OrganizationsService,
    private modals: ModalsService
  ) {
  }

  static getTopUpFromCardModel(number: string, date: string, cvv: number, isHolder?: boolean): TopUpFromCard {
    return {
      pan: number,
      expireDate: date,
      cvv2: cvv,
      isHolder: isHolder || true
    };
  }

  static getTopUpProcessModel(model: TopUpFromCard, amount: TopUpAmount, accountNumber: string): TopUpProcess {
    return {
      amount: amount.value,
      currency: amount.currency,
      topupFromCard: model,
      topupToAccountId: accountNumber
    };
  }

  openErrModal() {
    return this.modals.openModal({
      component: ErrorModalComponent,
      cssClass: 'TopUpErrModal'
    });
  }

  getTopUpTransferModel(account: Account): Observable<TopUpTransfer> {
    const {partnersAccounts} = account;
    const accEUR = partnersAccounts.find(acc => acc.currency === 'EUR');
    return this.organizations.getTrustedClientByAccountId(account.id)
      .pipe(
        map(client => {
          return {
            accountNumber: accEUR?.accountNumber || null,
            recipient: accEUR ? client.name : null,
            bankName: accEUR?.partnerName || null
          };
        })
      );
  }

  topUpProcessRequest(model: TopUpProcess): Observable<TopUpProcessResponse> {
    const url = environment.baseApi + `/v1/topup/process`;
    this.setLoadingStatus(true);
    return this.http.post<TopUpProcessResponse>(url, model).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  getPoints() {
    const url = environment.baseApi + `/v1/shop-points`;
    this.setLoadingStatus(true);
    return this.http.get(url).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  requestCryptoBalances(accountIds: string[]): Observable<any> {
    const url = environment.baseApi + '/v1/accounts/crypto/balances';
    const headers = new HttpHeaders({
      productId: CRYPTO_PRODUCT_ID
    });

    this.setLoadingStatus(true);

    return this.http.post<any>(url, {accountIds}).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  retrieveInfoCode(code: string) {
    const url = environment.baseApi + `/v1/topup/code/info`;

    this.setLoadingStatus(true);

    return this.http.post(url, {code}).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  topupByCode(code: string, account: string) {
    const url = environment.baseApi + `/v1/topup/code`;

    this.setLoadingStatus(true);

    return this.http.post(url, {code, accountNumberToTopUp: account}).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  getBinanceAvailability(): Observable<IBinanceContractResponse> {
    this.setLoadingStatus(true);

    return this.http.get<IBinanceContractResponse>(
      `${ environment.baseApi }/v1/binance/binance-contract-availability`,
      {headers: this.headers}
    ).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  connectBinance(email: string | null = null): Observable<ICreateBinanceContractResponse> {
    this.setLoadingStatus(true);

    return this.http.post<ICreateBinanceContractResponse>(
      `${ environment.baseApi }/v1/binance/create/binance-contract`,
      {email},
      {headers: this.headers}
    ).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  getBinanceDeeplink(appId: number): Observable<IBinanceDeepLinkResponse> {
    this.setLoadingStatus(true);

    return this.http.get<IBinanceDeepLinkResponse>(
      `${ environment.baseApi }/v1/binance/binance-contract/${ appId }`,
      {headers: this.headers}
    ).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  getBinanceRate(from: string = 'EUR', to: string = 'USDT'): Observable<IBinanceRate> {
    this.setLoadingStatus(true);

    return this.http.get<IBinanceRate>(
      `${ environment.baseApi }/v1/binance/exchange/rate?currencyFrom=${ from }&currencyTo=${ to }`,
      {headers: this.headers}
    ).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  getCommission(data: ICommissionRequest): Observable<PaymentCommission> {
    this.setLoadingStatus(true);

    return this.http.post<PaymentCommission>(
      `${ environment.baseApi }/v1/deals/conversion/commission`,
      data,
      {headers: this.headers}
    ).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  deleteBinanceConnect(contractId: string) {
    this.setLoadingStatus(true);

    return this.http.post(
      `${ environment.baseApi }/v1/binance/terminate/binance-contract`,
      {contractId},
      {headers: this.headers}
    ).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  getIntermediaryBankDetails(accountId: string, direction: string): Observable<IntermediaryBankDetailsResponse> {
    this.setLoadingStatus(true);

    return this.http.get<IntermediaryBankDetailsResponse>(
      `${environment.baseApi}/v1/banxe/accounts/intermediary-bank-details`,
      {params: {accountId: accountId, direction: direction}}
    ).pipe(
      finalize(
        () => this.setLoadingStatus(false)
      )
    );
  }

  private setLoadingStatus(value: boolean): void {
    const currentValue = this.isLoadingSource.getValue();

    if (value !== currentValue) {
      this.isLoadingSource.next(value);
    }
  }
}
