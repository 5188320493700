<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-title>{{ 'history.title' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>

    <div class="History-panel customScrollbar ion-padding">

      <ng-container *ngFor="let h of history">
        <div class="History-data">
          <span>{{h.date}}</span>
        </div>
        <ion-list class="History-list" lines="full">
          <app-send-money-history-item class="app-history-item" *ngFor="let item of h.operations" [item]="item"></app-send-money-history-item>
        </ion-list>
      </ng-container>

    </div>

  </ion-content>
</ion-app>
