<ion-item [class.open]="isActive">
  <div class="ion-text-wrap">
    <div (click)="onSetActiveState()" class="TemplatesItem">
      <div class="TemplatesItem-icon">
        <ion-icon name="star-dark"></ion-icon>
      </div>
      <div class="TemplatesItem-content">
        <div class="TemplatesItem-header">
          {{ item.title }}
        </div>
        <div class="TemplatesItem-info">
          <ion-label [ngSwitch]="type" class="Templates-item-card">
            <span *ngSwitchCase="'PAYMENT_PHONE'">
              + {{ item.toPhoneNumber }}
            </span>
            <span *ngSwitchCase="'PAYMENT_SEPA'">
              {{ (item.toIban ? item.toIban : item.beneficiaryAccount) | stringClipping:'2:13:20' }}
            </span>
            <span *ngSwitchCase="'PAYMENT_CARD'">
              {{ 'card.title' | translate }}: {{ item.toCardNumber }}
            </span>
          </ion-label>
          <ion-label class="Templates-item-value">
            -{{ item.amount }} <small>{{ item.currencyCode }}</small>
          </ion-label>
        </div>
      </div>
    </div>

    <div class="TemplatesItem-buttons">
      <ion-button
          (click)="onUseTemplate(item)"
          class="buttonUse"
          expand="full" shape="round" fill="outline" color="success" size="small">
        <ion-icon slot="start" name="arrow-right-small-green"></ion-icon>
        <span>{{ 'touchID.second_button' | translate }}</span>
      </ion-button>
      <ion-button
          (click)="onEditTemplate(item)"
          class="buttonEdit"
          expand="full" shape="round" fill="outline" color="dark" size="small">
        <ion-icon slot="start" name="edit-small"></ion-icon>
        <span>{{ 'editModal.btn_ok' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-item>
