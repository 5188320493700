<ion-content>
  <div class="ComingSoonAlert ion-text-center">
    <span>{{ 'bankOffer.order_button' | translate }}…</span>
    <div class="ComingSoonAlert-button">
      <ion-button expand="full" shape="round" fill="outline" color="success"
                  (click)="closeModal()">
                  {{ 'bonusCard.success-confirm.ok' | translate }}
      </ion-button>
    </div>
  </div>
</ion-content>
