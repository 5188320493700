<ion-app>
    <ion-header class="ion-no-border">
        <ion-toolbar>
            <ion-title>Info</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-content class="Info ion-padding">

        <p>To receive Bitcoin into your BCC wallet, you will need to provide your Bitcoin address to the sending party.</p>

        <!-- <ion-icon class="Info-cat" name="cat-info-crypto-receive"></ion-icon> -->

    </ion-content>
</ion-app>
