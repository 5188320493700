<ion-list>
  <ion-list-header>
    <ion-label>{{ 'receipt.first_text' | translate }}</ion-label>
  </ion-list-header>
  <ion-item *ngIf="operationType === 'TOPUP'" lines="none">
    <ion-label class="ion-text-wrap">
      <p>Card number:</p>
      <p class="PaymentReceipt-value">*** {{ operation.paymentToRepeat.fromCardPan.split('*')[6].split('-')[1] }}</p>
    </ion-label>
  </ion-item>
  <ion-item
      *ngIf="operationType === 'TO_CARD' || operationType === 'SWIFT' || operationType === 'SEPA' || operationType === 'PURCHASE' || operationType === 'CASH_WITHDRAWAL'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.fifth_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.fromName }}</p>
    </ion-label>
  </ion-item>
  <ion-item
      *ngIf="operationType === 'TO_CARD' || operationType === 'SWIFT' || operationType === 'SEPA' || operationType === 'PURCHASE' || operationType === 'CASH_WITHDRAWAL'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.second_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.fromNumber }}</p>
    </ion-label>
  </ion-item>
  <ion-item
      *ngIf="operationType === 'TO_CARD' || operationType === 'SWIFT' || operationType === 'SEPA'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.seventh_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.fromBank }}</p>
    </ion-label>
  </ion-item>
</ion-list>
