<div class="Fail ion-text-center">
	<div class="Fail-icon">
		<ion-icon name="fail"></ion-icon>
	</div>
	<p class="Fail-title">{{ 'CryptoHistory.Status.Error' | translate }}</p>
	<p class="Fail-text">
		{{ message }}
	</p>
	<!-- add a two-buttons class for fail-buttons for two buttons -->
	<div class="Fail-buttons"> 
		<ion-button (click)="closeModal()" expand="full" shape="round" fill="outline" color="success">
			{{ 'topup.codeSuccess_buttonOk' | translate }}
		</ion-button>
		<!-- <ion-button expand="full" shape="round" fill="outline" color="dark">
			Cancel
		</ion-button>
		<ion-button expand="full" shape="round" fill="outline" color="success">
			Retry
		</ion-button> -->
	</div>
</div>
