import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions as IonicModalOptions, OverlayEventDetail } from '@ionic/core';

import { Observable, Subject, from as observableFrom } from 'rxjs';
import { tap, first, switchMap, map } from 'rxjs/operators';

export interface ModalData<T = any> extends OverlayEventDetail {
  hasResult(): boolean;
  getResult(): T;
}

/*export interface ModalResult<T = any> {
  result: T;
}*/

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  private modalIsOpenSource = new Subject<boolean>();
  modalIsOpen$: Observable<boolean> = this.modalIsOpenSource.asObservable();

  constructor(
    private modalController: ModalController) {
  }

  openModal(options: IonicModalOptions): Observable<ModalData> {
    return this.createModal(options)
      .pipe(
        first(),
        tap(modal => {
          this.setModalOpenStatus('isOpen');
          modal.present();
          modal.onWillDismiss()
            .then(() => this.setModalOpenStatus('isClose'));
        }),
        switchMap(modal => observableFrom(modal.onDidDismiss())),
        map(modalData => this.setAdditionalMethods(modalData))
      );
  }

  closeModal(value?: any): Promise<boolean> {
    return this.modalController.dismiss({
      result: value
    });
  }

  private createModal(options: IonicModalOptions): Observable<HTMLIonModalElement> {
    options.keyboardClose = false;
    return observableFrom(this.modalController.create(options));
  }

  private setModalOpenStatus(status: 'isOpen' | 'isClose'): void {
    this.modalIsOpenSource.next(status === 'isOpen');
  }

  private setAdditionalMethods(modal: OverlayEventDetail): ModalData {
    const result: ModalData = <ModalData>modal;
    result.hasResult = () => !!modal && !!modal.data && !!modal.data.result;
    result.getResult = () => (result.hasResult()) ? modal.data.result : null;
    return result;
  }

}
