<ion-app>
    <ion-header class="ion-no-border">
        <ion-toolbar>
            <ion-title>Info</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-content class="Info ion-padding">

        <p>To send, it’s quite simple. In your BCC account, you simply select ‘send’, enter the recipient’s bitcoin address (you can also or scan your recipient’s QR code). You’ll need to send add the amount you’d like to send and hit send. You’ll also need to confirm the transaction via email (this is a security measure to make sure no one else is accessing your account).</p>
        <p>Once the recipient has received his or her bitcoins, which should arrive within the hour, he or she can then exchange the bitcoins into local currency on a local bitcoin exchange online or spend the bitcoins using their BCC card.</p>
        <p>The BCC cards expand your horizons by enabling you to spend your bitcoin. With a Wirex card, you can spend bitcoin online, transferring it into EUR, GBP, and USD or withdrawing at an ATM into your local currency.</p>

        <!-- <ion-icon class="Info-cat" name="cat-info-crypto-confirmation"></ion-icon> -->

    </ion-content>
</ion-app>
