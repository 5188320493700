import { ChangeDetectionStrategy, Component, computed, Inject, Input, OnDestroy, OnInit, signal } from '@angular/core';

import { IntermediaryBankDetailsResponse, TopUpTransfer } from 'models/top-up.interfaces';
import { Subject } from 'rxjs';
import { TopUpAccountService } from '../top-up-account.service';
import { WINDOW } from '../../window.providers';

@Component({
  selector: 'app-top-up-incoming-sepa',
  templateUrl: './top-up-incoming-sepa.component.html',
  styleUrls: ['./top-up-incoming-sepa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopUpIncomingSepaComponent implements OnInit, OnDestroy {
  readonly translationKeys = {
    'Beneficiary\'s Name': 'topup.incoming_payment.name',
    'Beneficiary\'s address': 'topup.incoming_SEPA.address',
    'Beneficiary\'s Bank': 'topup.transferTab_second_field',
    'Beneficiary\'s BIC Code': 'topup.incoming_SEPA.BIC_code',
    'Beneficiary\'s IBAN': 'topup.incoming_payment.iban',
    'Mandatory reference*': 'topup.incoming_SEPA.mandatory_reference'
  };

  @Input() account?: TopUpTransfer;
  @Input() accountId?: string;
  @Input() availableState = false;
  itWasCopied = signal(false);
  intermediaryBankDetails = signal<IntermediaryBankDetailsResponse>(null);
  warningBannerTranslateKey = computed(() => this.availableState ? 'topup.incoming_SEPA.notice' : 'topup.transferTab_warning');
  private readonly destroy$ = new Subject<void>();

  constructor(
    private topUpService: TopUpAccountService,
    @Inject(WINDOW) private window: Window
  ) {
  }

  ngOnInit(): void {
    if (this.accountId) {
      const cleanedId = this.accountId.replace(/^PPY/, '');

      this.topUpService.getIntermediaryBankDetails(cleanedId, 'DOMESTIC')
        .subscribe(data => {
          this.intermediaryBankDetails.set(data);
        });
    }
  }

  copyToClipboard() {
    if (this.intermediaryBankDetails()) {
      const details = this.intermediaryBankDetails().bankDetails
        .map(detail => `${detail.name}: ${detail.value}`).join('\n');

      this.window.navigator['clipboard'].writeText(details)
        .then(() => {
          this.itWasCopied.set(true);
          setTimeout(() => {
            this.itWasCopied.set(false);
          }, 3000);
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
