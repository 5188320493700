import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TransferTemplatesService } from '../../../services/transfer-templates/transfer-templates.service';
import { PAYMENT_TYPE, PaymentOperationTemplate } from '../../../users/operations/operations.interface';

@Component({
  selector: 'app-transfer-card-templates',
  templateUrl: './transfer-card-templates.component.html',
  styleUrls: ['./transfer-card-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferCardTemplatesComponent implements OnInit {
  paymentType: typeof PAYMENT_TYPE = PAYMENT_TYPE;

  isLoading$: Observable<boolean> = this.transferTemplates.isLoading$;
  templates$: Observable<PaymentOperationTemplate[]> = this.transferTemplates.transferTemplates$
    .pipe(
      map(templates => TransferTemplatesService.getTemplatesByType(templates, PAYMENT_TYPE.PaymentCard))
    );

  constructor(
    private transferTemplates: TransferTemplatesService) {
  }

  ngOnInit() {}

  repeatOperation(template: PaymentOperationTemplate) {
    this.transferTemplates.restoreOperationFromTemplate(template);
  }

}
