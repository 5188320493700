import { environment } from '../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location  } from '@angular/common';
import { MenuController } from '@ionic/angular';

import { switchMap, shareReplay, first, delay, filter, mergeMap } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { getParser, Parser } from 'bowser';

import { AuthManagerService } from './users/auth/auth-manager.service';
import { UsersService } from './users/users.service';
import { IdleService } from './users/idle/idle.service';

import { AppConfigurationService } from './app.configuration.service';
import { LocalizationService } from './internationalization/localization/localization.service';
import { ModalsService } from './modals/modals.service';
import { OnlineBillPaymentService } from './integrations/online-bill-payment/online-bill-payment.service';

// import { AppGtmService } from './tools/app.gtm.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public authToken$: Observable<string> = this.authManager.bankToken$.pipe(
    shareReplay(1)
  );

  public mainNavigationIsOpen = false;
  public modalIsOpen$: Observable<boolean> = this.modals.modalIsOpen$;

  private idleSubscribe!: Subscription;

  private userAgent = getParser(window.navigator.userAgent);
  private userAgentPlatform = this.userAgent.getPlatform();

  constructor(
    private authManager: AuthManagerService,
    private modals: ModalsService,
    private router: Router,
    private appConfiguration: AppConfigurationService,
    private userSettings: UsersService,
    private localization: LocalizationService,
    private idle: IdleService,
    private menuCtrl: MenuController,
    private location: Location,
    private onlineBillPayments: OnlineBillPaymentService
  ) {
    /* check for mobile devices and redirect on store/market */
    if (this.isMobileDevice(this.userAgentPlatform)) {
      console.log(11111);
      const redirectUrl = (this.userAgentPlatform.vendor === 'Apple')
        ? 'https://itunes.apple.com/ru/app/blackcatcard/id1449352913?mt=8'
        : 'https://play.google.com/store/apps/details?id=com.papaya.blackcatcard';
      window.location.replace(redirectUrl);
    }
    this.checkForUglyBrowser(this.userAgent);
  }

  private isMobileDevice(userAgentPlatform: Parser.PlatformDetails): boolean {
    const isRegistrationRoute = this.location.path().includes('registration');
    const isIntegrationRoute = this.location.path().includes('integrations');
    const isHelpCenterRoute = this.location.path().includes('help-center');
    // environment.production
    return userAgentPlatform.type === 'mobile'
      && !isRegistrationRoute && !isIntegrationRoute && !isHelpCenterRoute;
  }

  private checkForUglyBrowser(parser: Parser.Parser) {
    const browser = parser.getBrowserName();
    if (browser.indexOf('Edge') >= 0 || browser.indexOf('Explorer') >= 0) {
      this.router.navigate(['/failure/browser-warning']);
    }
  }

  ngOnInit() {
    this.authToken$
      .pipe(
        filter(Boolean),
        // first((token) => !!token),
        switchMap(() => this.onlineBillPayments.activeBillPayment$),
        first(Boolean),
        delay(1000),
      )
      .subscribe((billPayment) => {
        if (OnlineBillPaymentService.isIbanTransfer(billPayment)) {
          if (this.isMobileDevice(this.userAgentPlatform)) {
            this.router.navigate(['transfer/bank-mobile']);
          } else {
            this.router.navigate(['transfer/bank']);
          }
        }
      });

    this.authToken$
      .subscribe(token => {
        console.log('BANK_TOKEN', token);
        this.mainNavigationIsOpen = !!token && !location.pathname.split('/').includes('registration');
        this.menuCtrl.enable(this.mainNavigationIsOpen);
        if (!!token) {
          this.appConfiguration.requestBaseConfiguration()
            .subscribe(config => this.appConfiguration.setConfiguration(config));
          this.userSettings.requestGetUserSettings()
            .pipe(
              switchMap(userSettings => this.userSettings.setSettings(userSettings)),
              switchMap(() => this.userSettings.getLocalization())
            )
            .subscribe(locale => this.localization.setDefaultLocalization(locale));
          if (environment.production) {
            this.idleSubscribe = this.initIdleService().subscribe();
          } else if (this.idleSubscribe) {
            this.idleSubscribe.unsubscribe();
          }
        } else {
          this.localization.setDefaultLocalization();
          // this.authManager.navigateToSignInPage()
        }
      });
  }

  private initIdleService(): Observable<void> {
    return this.idle.idleStream(environment.idleTimeout)
      .pipe(
        mergeMap(() => this.authManager.logout())
      );
  }

}
