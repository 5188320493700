import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { NgxMaskModule } from 'ngx-mask';

import { SettingsModalsModule } from './settings-modals/settings-modals.module';
import { BankCardsModalsModule } from './bank-cards-modals/bank-cards-modals.module';

import { CountrySelectModule } from '../ui-elements/country-select/country-select.module';
import { LanguageSelectModule } from '../ui-elements/language-select/language-select.module';
import { FormCustomElementsModule } from '../ui-elements/form-custom-elements/form-custom-elements.module';

import { SelectCountryComponent } from './components/select-country/select-country.component';

import { TransferConfirmCodeComponent } from './components/transfer-confirm-code/transfer-confirm-code.component';
import { TransferSuccessComponent } from './components/transfer-success/transfer-success.component';
import { TemplateEnterNameComponent } from './components/template-enter-name/template-enter-name.component';
import { TransferConfirmRequestComponent } from './components/transfer-confirm-request/transfer-confirm-request';

import { PrintStatementPreviewComponent } from './components/print-statement-preview/print-statement-preview.component';
import { LogoutConfirmComponent } from './components/logout-confirm/logout-confirm.component';

import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';

import { PaymentReceiptComponent } from './components/payment-receipt/payment-receipt.component';

import { TransferRecipeModule } from '../ui-elements/transfer-recipe/transfer-recipe.module';
import { StopComponent } from './cashback-modals/stop/stop.component';
import { SuccessCashbackComponent } from './cashback-modals/success-cashback/success-cashback.component';
import { ConfirmCashbackComponent } from './cashback-modals/confirm-cashback/confirm-cashback.component';
import { InsufficientFundsComponent } from './cashback-modals/insufficient-funds/insufficient-funds.component';
import { RegIndividualStatusComponent } from '../+registration/+registration-individual/reg-individual-status/reg-individual-status.component';
import { InformationComponent } from './components/information/information.component';
import { CameraCaptureComponent } from './components/camera-capture/camera-capture.component';

import { IsLoadingModule } from '../ui-elements/is-loading/is-loading.module';
import { CurrencyExchangeSuccessComponent } from './currency-exchange-success/currency-exchange-success.component';
import { CountdownTimerModule } from '../ui-elements/countdown-timer/countdown-timer.module';
import { InternationalizationModule } from '../internationalization/internationalization.module';
import { GekkardModalsModule } from './gekkard/gekkard-modals.module';
import {ConfirmDeleteComponent} from './components/confirm-delete/confirm-delete.component';
import { ErrorModalComponent } from './binance-modals/error-modal/error-modal.component';
import { RemoveContractModalComponent } from './binance-modals/remove-contract-modal/remove-contract-modal.component';


@NgModule({
  declarations: [
    SelectCountryComponent,

    TransferConfirmCodeComponent,
    TransferSuccessComponent,
    TemplateEnterNameComponent,
    TransferConfirmRequestComponent,
    PrintStatementPreviewComponent,
    LogoutConfirmComponent,
    ComingSoonComponent,
    PaymentReceiptComponent,
    ConfirmDeleteComponent,

    StopComponent,
    SuccessCashbackComponent,
    ConfirmCashbackComponent,
    InsufficientFundsComponent,
    RegIndividualStatusComponent,
    InformationComponent,
    CameraCaptureComponent,
    CurrencyExchangeSuccessComponent,

    ErrorModalComponent,
    RemoveContractModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    SettingsModalsModule,
    BankCardsModalsModule,
    GekkardModalsModule,
    CountrySelectModule,
    LanguageSelectModule,
    FormCustomElementsModule,
    TransferRecipeModule,
    IsLoadingModule,
    NgxMaskModule.forRoot(),
    CountdownTimerModule,
    InternationalizationModule,
    NgOptimizedImage
  ]
})
export class ModalsModule { }
