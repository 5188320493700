import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
// import { tap, map, filter } from 'rxjs/operators';

import { AuthManagerService } from '../auth-manager.service';
import { NavController } from '@ionic/angular';

/*const EXCEPTION_LIST = [
  '/login'
];*/

@Injectable()
export class AuthGuard  {

  constructor(
    private navCtrl: NavController,
    private authManager: AuthManagerService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    /*if (EXCEPTION_LIST.some(a => a === state.url) && this.authManager.getBankToken()) {
       this.router.navigate(['money']);
       return false;
    }*/

    /*if (route) {
      /!*return this.authManager.userIsAuth()
        .toPromise()
        .then(predicate => {
          if (!predicate) {
            this.router.navigate(['/login']);
          }
          return predicate;
        });*!/
      /!*return this.authManager.userIsAuth()
        .pipe(
          tap(predicate => {
            console.log('GUARD CHECK');
            if (!predicate) {
              this.router.navigate(['/login']);
            }
          })
        );*!/
    }*/

    if (route) {
      return this.authManager.getIsStoredToken()
        .then(token => {
          if (!token) {
            this.navCtrl.navigateRoot('/login', {
              animated: true
            });
          }
          return !!token; // TODO ????
        });
    }
  }

}
