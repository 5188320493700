<section [ngSwitch]="componentName">

  <app-movement-money-history
      *ngSwitchCase="'movement-money-history'">
  </app-movement-money-history>

  <app-movement-money-history-recipe
      *ngSwitchCase="'movement-money-history-recipe'"
      [operation]="container.paymentReceiptItem">
  </app-movement-money-history-recipe>

  <app-send-money-history
      *ngSwitchCase="'send-money-history'">
  </app-send-money-history>

  <app-exchange-money-history
      *ngSwitchCase="'exchange-money-history'">
  </app-exchange-money-history>

  <app-transfer-card-templates
      *ngSwitchCase="'transfer-card-templates'">
  </app-transfer-card-templates>

  <app-transfer-card-template-edit
      *ngSwitchCase="'transfer-card-template-edit'">
  </app-transfer-card-template-edit>

  <app-transfer-bank-templates
      [fromBankTransferMobile]="transferBankMobile"
      *ngSwitchCase="'transfer-bank-templates'">
  </app-transfer-bank-templates>

  <app-transfer-contact-templates
      *ngSwitchCase="'transfer-contact-templates'">
  </app-transfer-contact-templates>

  <app-transfers-template-edit
      *ngSwitchCase="'transfers-template-edit'">
  </app-transfers-template-edit>

  <app-export-statements
      *ngSwitchCase="'export-statements'">
  </app-export-statements>

  <app-print-statements
      *ngSwitchCase="'print-statements'">
  </app-print-statements>

  <app-crypto-transaction-history
      *ngSwitchCase="'crypto-transaction-history'">
  </app-crypto-transaction-history>
  <app-crypto-transaction-history-recipe
      *ngSwitchCase="'crypto-transaction-history-recipe'"
      [operation]="container.paymentReceiptItem">
  </app-crypto-transaction-history-recipe>

  <app-crypto-exchange-transaction-history *ngSwitchCase="'crypto-exchange-transaction-history'"></app-crypto-exchange-transaction-history>

  <app-crypto-send-internal-templates *ngSwitchCase="'crypto-send-internal-templates'"></app-crypto-send-internal-templates>

  <app-info-crypto-confirmation *ngSwitchCase="'info-crypto-confirmation'"></app-info-crypto-confirmation>
  <app-info-crypto-receive *ngSwitchCase="'info-crypto-receive'"></app-info-crypto-receive>
  <app-info-crypto-deposit *ngSwitchCase="'info-crypto-deposit'"></app-info-crypto-deposit>
  <app-info-crypto-repo *ngSwitchCase="'info-crypto-repo'"></app-info-crypto-repo>
  <app-info-crypto-repo-confirmation *ngSwitchCase="'info-crypto-repo-confirmation'"></app-info-crypto-repo-confirmation>

  <app-info-transfer-to-contact *ngSwitchCase="'info-transfer-to-contact'"></app-info-transfer-to-contact>
  <app-info-transfer-confirmation *ngSwitchCase="'info-transfer-confirmation'"></app-info-transfer-confirmation>
  <app-info-currency-exchange-confirmation *ngSwitchCase="'info-currency-exchange-confirmation'"></app-info-currency-exchange-confirmation>
  <app-crypto-skeleton *ngSwitchCase="'app-crypto-skeleton'"></app-crypto-skeleton>

</section>
