import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MainNavigationComponent } from './main-navigation.component';
import { NavigationAccountsSelectComponent } from './components/navigation-accounts-select/navigation-accounts-select.component';
import { NavigationLogoutTimerComponent } from './components/navigation-logout-timer/navigation-logout-timer.component';
import { InternationalizationModule } from '../internationalization/internationalization.module';
import { UsersModule } from '../users/users.module';
import { NavigationNetworkStatusComponent } from './components/navigation-network-status/navigation-network-status.component';
import { CountdownTimerModule } from 'app/ui-elements/countdown-timer/countdown-timer.module';
import { NavigationNewsComponent } from './components/navigation-news/navigation-news.component';
import { TopUpAccountModule } from '../+top-up-account/top-up-account.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    UsersModule,
    ReactiveFormsModule,
    TopUpAccountModule,
    InternationalizationModule,
    CountdownTimerModule,
    UsersModule
  ],
  declarations: [
    MainNavigationComponent,
    NavigationAccountsSelectComponent,
    NavigationLogoutTimerComponent,
    NavigationNetworkStatusComponent,
    NavigationNewsComponent
  ],
  exports: [
    MainNavigationComponent,
    NavigationNetworkStatusComponent
  ]
})
export class MainNavigationModule { }
