import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { SettingsIframeContentComponent } from './settings-iframe-content/settings-iframe-content.component';

import { IdentityStatusComponent } from './identity-status/identity-status.component';
import { MyTariffComponent } from './my-tariff/my-tariff.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProtectionPolicyComponent } from './protection-policy/protection-policy.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { CountdownTimerModule } from 'app/ui-elements';

@NgModule({
  declarations: [
    SettingsIframeContentComponent,
    IdentityStatusComponent,
    MyTariffComponent,
    ChangePasswordComponent,
    ProtectionPolicyComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    InternationalizationModule,
    CountdownTimerModule
  ]
})
export class SettingsModalsModule { }
