import { environment } from 'environments/environment';
import { Injectable, Inject } from '@angular/core';
import { ParamMap, Params, Router } from '@angular/router';
import { EMPTY, forkJoin, from, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { getParser } from 'bowser';

import { WINDOW } from 'app/window.providers';
import { AuthManagerService } from 'app/users/auth/auth-manager.service';
import { LocalizationService } from 'app/internationalization/localization/localization.service';
import { StorageService } from '../storage/storage.service';

const CUSTOM_REGISTRATION_URL = 'https://webreg.blackcatcard.com';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private marketplaceQueryParams!: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private authManager: AuthManagerService,
    private router: Router,
    private locale: LocalizationService,
    private storage: StorageService
  ) {}

  public setMarketPlaceData(params: ParamMap): void {
    const marketplaceQueryParams = params.keys.map(key => `${key}=${params.get(key)}`);
    this.marketplaceQueryParams = marketplaceQueryParams.join('&');
  }

  private getRouteParams(sendDocuments: boolean): string {
    const productParams = `
      applicationId=${environment.applicationId}
      &productId=${environment.productId}
      &deviceId=${environment.deviceId}
      &authType=${this.authManager.authType}
      &documents=${sendDocuments}
      &clientId=${this.authManager.authPhoneNumber}
      &locale=${this.locale.getActiveLanguageSync()}
    `;
    return (this.marketplaceQueryParams) ? productParams + `&${this.marketplaceQueryParams}` : productParams;
  }

  private openLink(link: string): Observable<boolean> {
    const userAgent = getParser(window.navigator.userAgent);
    const userAgentPlatform = userAgent.getPlatform();

    const url = link.replace(/\s/g, '');

    if (userAgentPlatform.type === 'mobile') {
      window.location.replace(url);
      return EMPTY;
    } else {
      this.window.open(url, 'blank');
      return from(this.router.navigate(['/']));
    }
  }

  start(legal: boolean, sendDocuments: boolean): Observable<boolean> {
    if (!legal && environment.productId === 'GEKKARD') {
      return from(this.router.navigateByUrl(`/registration/personal/process?documents=${sendDocuments}`));
    }
    return forkJoin([
      this.authManager.getBankToken().pipe(map(token => !!token)),
      this.storage.get('reg-query-params')
    ]).pipe(
      map(([isAuth, params]: [boolean, Params]) => {
        // let url = `${environment.registrationUrl}/${legal && isAuth ? 'legal' : 'individual'}?${this.getRouteParams(sendDocuments)}`;
        // TODO this is temporary solutions
        let url = (legal && isAuth)
          ? `${environment.registrationUrl}/legal?${this.getRouteParams(sendDocuments)}`
          : CUSTOM_REGISTRATION_URL;
        // TODO need return back
        // if (legal && !isAuth) {
        //   url += '&type=legal';
        // }

        if (!!params) {
          for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
              const element = params[key];
              url += `&${key}=${element}`;
            }
          }
        }

        return url;
      }),
      switchMap(url => this.openLink(url)),
      switchMap(() => this.storage.remove('reg-query-params'))
    );
  }
}
