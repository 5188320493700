import { Injectable } from '@angular/core';

// @ts-ignore
import creditCardType, { getTypeInfo, types as CardType } from 'credit-card-type';
// CreditCardType - creditCardType.CreditCardTypeInfo
export type CreditCardTypeInfo = typeof CardType;

@Injectable({
  providedIn: 'root'
})
export class BankCardsService {

  static formatCardNumber(value: string): string /* format: 0000 0000 0000 0000 */ {
    return (typeof value !== 'string') ? null : value
      .replace(/\s+/g, '')
      .replace(/\D/g, '')
      .slice(0, 16)
      .replace(/(\d{4})(?!$)/g, '$1 ');
  }

  static replaceSpaces(value: string): string {
    return (value) ? value.replace(/\s+/g, '') : null;
  }

  static getCardType(clearCardNumber: string): CreditCardTypeInfo {
    const cardTypes = creditCardType(clearCardNumber);
    return (cardTypes.length && clearCardNumber.length >= 4) ? cardTypes[0] : null;
  }

}
