import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../../modals.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent  implements OnInit {

  constructor(
    private modals: ModalsService,
    private router: Router
  ) { }

  ngOnInit() {}

  close() {
    of(this.modals.closeModal()).subscribe(() => {
      this.router.navigate(['/top-up-account']);
    });
  }
}
