import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-top-up-reject-code',
  templateUrl: './top-up-reject-code.component.html',
  styleUrls: ['./top-up-reject-code.component.scss']
})
export class TopUpRejectCodeComponent {
  @Output() goBackEmit = new EventEmitter<string>();
  @Output() submitEmit = new EventEmitter<string>();

  constructor() {
  }

  goBack() {
    this.goBackEmit.emit('Code');
  }

  onSubmit() {
    this.submitEmit.emit('Succeess');
  }
}
