import { AbstractControl, ValidatorFn } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';


export function validateIf(conditional: Function, validator: ValidatorFn): ValidatorFn {
  return function (control: AbstractControl) {
    reValidateOnChanges(control);
    if (control && control.parent) {
      if (conditional(control.parent)) {
        return validator(control);
      }
    }
  };
}

function reValidateOnChanges(control: AbstractControl): void {
  if (control && control.parent && !control['_reValidateOnChanges']) {
    control['_reValidateOnChanges'] = true;
    control.parent
      .valueChanges
      .pipe(
        distinctUntilChanged((a, b) => compareObjects(a, b))
      )
      .subscribe(() => control.updateValueAndValidity());
  }
}

function compareObjects(a: Object, b: Object): boolean {
  // These will always be plain objects coming from the form, do a simple comparison
  // need switch on lodash methods
  if (a && !b || !a && b) {
    return false;
  } else if (a && b && Object.keys(a).length !== Object.keys(b).length) {
    return false;
  } else if (a && b) {
    for (const i in a) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
  }
  return true;
}
