import { Component, OnInit } from '@angular/core';

import {RightContainerService} from '../right-container.service';

@Component({
  selector: 'app-info-crypto-repo',
  templateUrl: './info-crypto-repo.component.html',
  styleUrls: ['./info-crypto-repo.component.scss']
})
export class InfoCryptoRepoComponent implements OnInit {

  constructor(private rightContainer: RightContainerService) { }

  ngOnInit() {
  }

}
