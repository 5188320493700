import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsLoadingComponent } from './is-loading/is-loading.component';
import { IsUpdatingComponent } from './is-updaiting/is-updating.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';

@NgModule({
  declarations: [
    IsLoadingComponent,
    IsUpdatingComponent
  ],
  exports: [
    IsLoadingComponent,
    IsUpdatingComponent
  ],
  imports: [
    CommonModule,
    InternationalizationModule
  ]
})
export class IsLoadingModule { }
