import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';

import { OnlineBillPayment, OnlineBillPaymentService } from './online-bill-payment/online-bill-payment.service';
import { AuthManagerService } from '../users/auth/auth-manager.service';

// tslint:disable-next-line:max-line-length
// const TEST_OLD_LINK = 'http://localhost:4200/integrations?data=eyJhbW91bnQiOiIxMC4wMCIsImNsaWVudE5hbWUiOiJFbGl6YWJldGggQ2FydGllciIsImNhbGxiYWNrVXJsIjoiaHR0cHM6Ly9vZmZzaWRlZ2FtaW5nLmNvbS9kZXBvc2l0L3BlbmRpbmciLCJjb21tZW50IjoiUGF5bWVudCBmb3IgYWNjb3VudCAxOTU1NDYxIiwiY3VycmVuY3kiOiJFVVIiLCJpYmFuIjoiTVQ2N1BBUFkzNjgzNjAwMDAwMjY1NjM3MDAwMDEzMSIsImlkVHJhbnNhY3Rpb24iOiIyMjk4NDkxMjgifQ==';
// tslint:disable-next-line:max-line-length
// const TEST_LINK = 'http://localhost:4200/integrations?apn=com.papaya.blackcatcard&amv=3&ibi=com.papaya.blackcatcard&isi=1449352913&imv=11&efr=1&utm_source=bankTransfer&utm_medium=MEDIUM&utm_content=CONTENT&utm_campaign=CAMPAIGN&utm_term=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJibGFja2NhdGNhcmQiLCJpYXQiOjE2ODU2OTYwOTAsImV4cCI6MTcxNzMxODQ3NSwiYXVkIjoiaHR0cHM6Ly9kZXNrdG9wYmFuay5ibGFja2NhdGNhcmQuY29tIiwic3ViIjoiTVQ0NVBBUFkzNjgzNjAwMDAwMjY3NjM3MDAxNjgyMiIsImliYW4iOiJNVDEzUEFQWTM2ODM2MDAwMDAyNjU2MzcwMDAwOTA5IiwiY3VycmVuY3kiOiJFVVIiLCJhbW91bnQiOjEuODUsImNvbW1lbnQiOiIxMTU2NywgUGF5bWVudCBmb3IgdG9wIHVwIiwiaWRUcmFuc2FjdGlvbiI6IjExNTY3IiwiY2FsbGJhY2tVUkwiOiJodHRwczovL3d3dy5zb21lY2FsbGJhY2t1cmwuY29tIiwibWVyY2hhbnROYW1lIjoiU29tZSBNZXJjaGFudCBOYW1lIn0.BVxfpRa7S6IpU5z_nlFtO6toRTjTzsoU7MXOPSn3c0w';

// utm_source=bankTransfer = признак, по которому должен открываться экран банковского перевода, с заполненными значениями
// (utm_term = JWT с параметрам

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.css'],
})
export class IntegrationsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private onlineBillPayment: OnlineBillPaymentService,
    private authManager: AuthManagerService) {
  }

  ngOnInit() {
    // this logic started for JWT payment
    this.route.queryParamMap
      .pipe(
        filter((paramMap) => paramMap.has('utm_term')),
        map((paramMap) => paramMap.get('utm_term')),
        map((jwt) => new OnlineBillPayment(jwt)),
        map((result) => this.onlineBillPayment.setActiveBillPayment(result)), // app.component is watching this data);
        switchMap(() => this.authManager.bankToken$),                                          // redirect user if not have auth data
        filter((token) => !token),
      )
      .subscribe(() => this.authManager.navigateToSignInPage());
  }

}

/*const validTransactionModel = {
  amount: '10.00',
  clientName: 'Elizabeth Cartier',
  callbackUrl: 'https://offsidegaming.com/deposit/pending',
  comment: 'Payment for account 1955461',
  currency: 'EUR',
  iban: 'MT67PAPY36836000002656370000131',
  idTransaction: '229849128'
};*/

/*const currentTransactionModel = {
  'iss': 'blackcatcard',
  'iat': 1673429853,
  'exp': 1673689053,
  'aud': 'https://desktopbank-dev.blackcatcard.com',
  'Iban': 'MT13PAPY36836000002656370000909',
  'Currency': 'EUR',
  'Amount': '20',
  'Comment': 'cd91bbef-c39b-41c6-881c-812893569e89, Payment for top up',
  'idTransaction': 'cd91bbef-c39b-41c6-881c-812893569e89',
  'callbackURL': 'http://whitebit.localhost/payment/black-cat-card/callback'
};*/
