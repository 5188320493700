<div class="TopUpConfirmation">
    <div class="TopUpConfirmation-title">
        <ion-icon name="back" (click)="goBack()"></ion-icon>
        <span>{{ 'topup.codeConfirm_text' | translate }}</span>
    </div>
    <div class="TopUpConfirmation-content ion-text-center">
        <span *ngIf="incorrectAccount">{{ 'topup.choose_account' | translate }}</span>
        <p class="TopUpConfirmation-code"><span>{{topupCode}}</span></p>
        <div class="TopUpConfirmation-amount">
            <span>{{ 'topup.codeConfirm_amount' | translate }}</span>
            <p class="TopUpConfirmation-amount__sum">{{topupAmount}} <small>{{topupCurrency}}</small></p>
        </div>
    </div>
    <div class="TopUpEnterCode-button ion-text-center">
        <ion-button
            expand="full"
            shape="round"
            color="success"
            type="submit"
            (click)="onSubmit()">{{ 'topup.codeConfirm_button' | translate }}</ion-button>
    </div>
</div>

