<ion-list>
  <ion-list-header>
    <ion-label>{{ 'receipt.third_text' | translate }}</ion-label>
  </ion-list-header>

  <ion-item lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.eighth_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.paymentType }}</p>
    </ion-label>
  </ion-item>

  <ion-item lines="none" class="PaymentReceipt-amount">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.ninth_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ absOperationAmount }} {{ operation.currency.code }}</p>
    </ion-label>
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.tenth_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.fee }} {{ operation.currency.code }}</p>
    </ion-label>
  </ion-item>

  <ion-item
      *ngIf="operationType === 'SEPA' || operationType === 'SWIFT'"
      lines="none">
    <ion-label class="ion-text-wrap">
      <p>Urgency:</p>
      <p class="PaymentReceipt-value">{{ operation.paymentToRepeat?.urgency || '—' }}</p>
    </ion-label>
  </ion-item>

  <ion-item lines="none">
    <ion-label class="ion-text-wrap">
      <p>{{ 'receipt.twelfth_field' | translate }}</p>
      <p class="PaymentReceipt-value">{{ operation.referenceNumber }}</p>
    </ion-label>
  </ion-item>

</ion-list>
