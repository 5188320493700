import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { ModalsService } from '../../modals.service';

@Component({
  selector: 'app-template-enter-name',
  templateUrl: './template-enter-name.component.html',
  styleUrls: ['./template-enter-name.component.scss']
})
export class TemplateEnterNameComponent implements OnInit {

  hasError = false;

  form = new UntypedFormGroup({
    name: new UntypedFormControl(
      null,
      [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255),
      ]
    )
  });

  constructor(
    private modals: ModalsService) {
  }

  ngOnInit() {
  }

  onSubmit({ value, valid }: { value: { name: string }, valid: boolean }) {
    if (valid) {
      this.closeModal(value.name);
    } else {
      this.hasError = true;
    }
  }

  closeModal(value?: string) {
    this.modals.closeModal(value || null);
  }

}
