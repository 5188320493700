import { environment } from 'environments/environment';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BccChatSupportModule } from 'bcc-sdk/dist/bcc-chat-support';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BccChatSupportModule.withConfig({
      brokerURL: environment.supportChatSettings.brokerURL
    })
  ],
  exports: [
    BccChatSupportModule
  ]
})
export class BccSupportModule { }
