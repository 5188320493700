import { environment } from 'environments/environment';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-country-select-item',
  templateUrl: './country-select-item.component.html',
  styleUrls: ['./country-select-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectItemComponent implements OnInit {

  @Input() iso2Code?: string;
  @Input() countryName?: string;
  @Input() countryCode?: number;
  @Input() showCountryCode = true;

  url?: string;

  constructor() { }

  ngOnInit() {
    const countryIso2Code = this.iso2Code.toLowerCase();
    this.url = `url(${ environment.countryFlagsUrl }/${ countryIso2Code }.png)`;
  }

}
