import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PopoverController } from '@ionic/angular';
import { saveAs as fileSaveAs } from 'file-saver';

import { RightContainerService } from '../../right-container.service';
import { OperationsService, ContentType } from '../../../users/operations/operations.service';
import { CalendarSingleSelectComponent } from '../../../ui-elements/calendars/calendar-single-select/calendar-single-select.component';
import { Account } from '../../../users/organizations.interface';

interface DateRange {
  from: number;
  to: number;
}

@Component({
  selector: 'app-export-statements',
  templateUrl: './export-statements.component.html',
  styleUrls: ['./export-statements.component.scss']
})
export class ExportStatementsComponent implements OnInit {

  @Input() from?: string;
  @Input() to?: string;

  @Output() setFilters = new EventEmitter();

  isLoading$ = this.operations.isLoading$;

  contentType: ContentType = 'application/pdf';
  activeAccount?: Account;
  selectedRange: DateRange = {
    from: null,
    to: null,
  };

  dateRange = {
    from: new Date(/*'1 Sep 2018'*/),
    to: new Date(/*'20 Dec 2018'*/)
  };

  calendarType: 'string';
  optionsRange = {
    pickMode: 'range',
    showMonthPicker: false,
    showToggleButtons: true,
    from: new Date(2010, 1, 1),
    to: new Date(),
    monthFormat: 'MMMM YYYY',
    weekdays: ['S', 'M', 'T', 'W', 'TH', 'F', 'SA'],
    monthPickerFormat: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  };

  isActiveCurrentMonth = false;
  isActiveNextMonth = false;
  isActivePrevMonth = false;
  isSameYear: Boolean;
  currentMonth: number;
  currentYear: number;

  constructor(
    private rightContainer: RightContainerService,
    private datePipe: DatePipe,
    private operations: OperationsService,
    private popoverController: PopoverController,
    ) {
  }

  ngOnInit() {
    if (this.from) {
      this.dateRange.from = new Date(this.datePipe.transform(this.from, 'd LLL yyyy'));
      this.dateRange.to = new Date(this.datePipe.transform(this.to, 'd LLL yyyy'));
    }

    if (this.dateRange && this.dateRange.from) {
      this.currentYear = this.dateRange.from.getFullYear();
      this.currentMonth = this.dateRange.from.getMonth() + 1;
    } else {
      this.currentYear = (new Date()).getFullYear();
      this.currentMonth = (new Date()).getMonth() + 1;
    }

    this.selectedRange.from = this.dateRange.from ? this.dateRange.from.getTime() : 0;
    this.selectedRange.to = this.dateRange.to ? this.dateRange.to.getTime() : 0;

    this.calcMonths();
  }

  monthChange(month) {
    this.currentMonth = month.newMonth.months;
    this.currentYear = month.newMonth.years;
    this.calcMonths();
  }

  onSelect(date) {
    this.selectedRange.to = date.time;
    this.selectedRange.from = date.time;
    this.isSameYear = false;
    this.calcMonths();
  }

  // TODO remove if new implementation of calendar is correct
  // onChange(date) {
  //   console.log('date', date);
  //   this.selectedRange.from = +date.from.format('x');
  //   this.selectedRange.to = +date.to.format('x');
  //   this.isSameYear = date.to.year() === date.from.year();
  //   this.setFilters.emit(this.selectedRange);
  //   this.calcMonths();
  //   this.sendFilters();
  // }

  calcMonths() {

    const endOfMonth = new Date(this.currentYear, this.currentMonth, 0, 23, 59, 59, 999).getTime();
    const startOfMonth = new Date(this.currentYear, this.currentMonth - 1, 1).getTime();

    this.isActiveCurrentMonth = (startOfMonth <= this.selectedRange.to && endOfMonth >= this.selectedRange.from);

    const endOfNextMonth = new Date(this.currentYear, this.currentMonth + 1, 0, 23, 59, 59, 999).getTime();
    const startOfNextMonth = new Date(this.currentYear, this.currentMonth, 1).getTime();
    this.isActiveNextMonth = (startOfNextMonth <= this.selectedRange.to && endOfNextMonth >= this.selectedRange.from);

    const endOfPrevMonth = new Date(this.currentYear, this.currentMonth - 1, 0, 23, 59, 59, 999).getTime();
    const startOfPrevMonth = new Date(this.currentYear, this.currentMonth - 2, 1).getTime();
    this.isActivePrevMonth = (startOfPrevMonth <= this.selectedRange.to && endOfPrevMonth >= this.selectedRange.from);

  }

  clear() {
    this.dateRange = null;
    this.selectedRange.from = 0;
    this.selectedRange.to = 0;
    this.isActiveCurrentMonth = false;
    this.isActiveNextMonth = false;
    this.isActivePrevMonth = false;
    this.sendFilters();
  }

  sendFilters() {
    this.setFilters.emit(this.selectedRange);
  }

  cancel() {
    this.clear();
    this.openMovementMoneyHistory();
  }

  segmentChanged(segment) {
    this.contentType = segment.detail.value;
  }

  setAccount(account: Account) {
    this.activeAccount = account;
  }

  selectAllDates() {
    this.selectedRange = {
      from: new Date(2010, 0, 1).getTime(),
      to: new Date().getTime()
    };
  }

  downloadStatement(account: Account, dateRange: DateRange, contentType: ContentType) {
    this.operations.requestBankStatement(account.id, dateRange.from, dateRange.to, contentType)
      .subscribe(
        response => fileSaveAs(response['body'], OperationsService.getFileNameByContentType(contentType)),
        error => console.log('err', error)
      );
  }

  openMovementMoneyHistory() {
    this.rightContainer.setRightPanel('movement-money-history');
  }

  async onSetDataStartEnd(ev: Event, typeFlag) {
    const popover = await this.popoverController.create({
      cssClass: 'popover-history-filter popover-start',
      component: CalendarSingleSelectComponent,
      backdropDismiss: true,
      event: ev,
    });
    popover.onDidDismiss()
        .then(result => {
          console.log('result', result);
          if (result.data) {
            typeFlag === 'from' ? this.selectedRange.from =  result.data :
                this.selectedRange.to = result.data;

            this.isSameYear = false;
            this.calcMonths();
          }
        });
    return await popover.present();
  }
}
