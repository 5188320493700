import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './users/auth/guards/auth.guard';
import { AppPreloadingStrategy } from './app.preloading.strategy';
import { AntiAuthGuard } from './users/auth/guards/anti-auth.guard';

import { IntegrationsComponent } from './integrations/integrations.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'money',
    pathMatch: 'full'
  },

  {
    path: 'login',
    loadChildren: () => import('./+login/login.module').then(m => m.LoginModule),
    canActivate: [ AntiAuthGuard ],
    data: { preload: true, delay: true }
  },
  {
    path: 'registration',
    loadChildren: () => import('./+registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'failure',
    loadChildren: () => import('./+failure/failure.module').then(m => m.FailureModule),
    data: { preload: true, delay: true }
  },
  {
    path: 'info',
    loadChildren: () => import('./+info/info.module').then(m => m.InfoModule),
  },
  {
    path: 'integrations',
    component: IntegrationsComponent
  },

  {
    path: 'money',
    loadChildren: () => import('./+money/money.module').then(m => m.MoneyModule),
    canActivate: [ AuthGuard ],
    data: { preload: true, delay: true }
  },
  {
    path: 'transfer',
    loadChildren: () => import('./+transfers/transfers.module').then(m => m.TransfersModule),
    canActivate: [ AuthGuard ],
    data: { preload: true, delay: true }
  },
  {
    path: 'cashback',
    loadChildren: () => import('./+cashback/cashback.module').then(m => m.CashbackModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'send-money',
    loadChildren: () => import('./+send-money/send-money.module').then(m => m.SendMoneyModule),
    canActivate: [ AuthGuard ],
  },
  {
    path: 'currency-exchange',
    loadChildren: () => import('./+currency-exchange/currency-exchange.module').then(m => m.CurrencyExchangeModule),
    canActivate: [ AuthGuard ],
  },
  {
    path: 'crypto',
    loadChildren: () => import('./+crypto/crypto.module').then(m => m.CryptoModule),
    canActivate: [ AuthGuard ],
  },
  {
    path: 'settings',
    loadChildren: () => import('./+settings/settings.module').then(m => m.SettingsModule),
    data: { preload: true, delay: true },
    canActivate: [ AuthGuard ]
  },
  {
    path: 'support',
    loadChildren: () => import('./+support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'help-center',
    loadChildren: () => import('./+help-center/help-center.module').then(m => m.HelpCenterModule)
  },
  {
    path: 'gekkard',
    loadChildren: () => import('./+gekkoin-wallet/gekkard.module').then(m => m.GekkardModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'reports',
    loadChildren: () => import('./+reports/reports.module').then(m => m.ReportsModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'top-up-account',
    loadChildren: () => import('./+top-up-account/top-up-account.module').then(m => m.TopUpAccountModule),
    canActivate: [ AuthGuard ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: AppPreloadingStrategy
})
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
