import { environment } from '../../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { first, filter, map, tap } from 'rxjs/operators';
import { Locales } from '../../internationalization/localization/localization.service';

export class Country {
  name: string;
  code: string;
  dialCode: number;
  phoneMask?: string;
}

export const DEFAULT_PHONE_MASK = '(000) 000000000000';

export const EURO_COUNTRY_LIST = [
  'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'AX', 'FR', 'GF', 'GP', 'MQ', 'YT', 'BL', 'MF', 'PM', 'DE', 'GI', 'GR', 'HU',
  'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'MC', 'NL', 'NO', 'PL', 'PT', 'RO', 'SM', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB'
];

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private countryListSource = new BehaviorSubject<Country[]>(null);
  countryList$: Observable<Country[]> = this.countryListSource.asObservable();

  static getCountryFlagUrlByCode(countryIso2Code: string): string {
    return `${ environment.countryFlagsUrl }/${ countryIso2Code.toLowerCase() }.png`;
  }

  static getFullPhoneMask(country: Country): string {
    // TODO async problems
    const baseMask = (country && country.phoneMask) ? country.phoneMask : DEFAULT_PHONE_MASK;
    const phonePrefix = (country && country.dialCode)
      ? country.dialCode.toString().replace(/[0-9]/g, '0')
      : null;
    return `+ ${phonePrefix} ${baseMask}`;
  }

  constructor(
    private http: HttpClient) {

    this.requestCountryList()
      .subscribe(countryList => this.setCountryList(countryList));
  }

  getCountryList(): Observable<Country[]> {
    return this.countryList$.pipe(
      filter(countryList => countryList && !!countryList.length),
      first()
    );
  }

  getCountryListByName(countryName: string): Observable<Country[]> {
    const countryNameLowerCase = countryName.toLowerCase();
    return this.getCountryList().pipe(
      map(countryList => {
        return countryList
          .filter(country => country.name.toLowerCase()
          .indexOf(countryNameLowerCase) !== -1);
      })
    );
  }

  getCountryListByDialCode(countryDialCode: number): Observable<Country[]> {
    return this.getCountryList().pipe(
      map(countryList => {
        return countryList
          .filter(country => country.dialCode.toString()
          .indexOf(countryDialCode.toString()) !== -1);
      })
    );
  }

  getCountryByIso2Code(iso2Code?: string): Observable<Country> {
    return this.getCountryList().pipe(
      map(countryList => countryList.find(country => country.code === iso2Code))
    );
  }

  getCountryByIso2CodeList(locales?: any): Observable<Country[]> {
    return this.getCountryList().pipe(
      map(countryList => countryList.filter(list => locales.locales.some((locale) => locale.acronym === list.code))),
      filter(countryList => countryList && !!countryList.length),
      tap(countryList => this.setCountryList(countryList)),
      first()
    );
  }

  private setCountryList(list: Array<Country>): void {
    this.countryListSource.next(list);
  }

  private requestCountryList(): Observable<Country[]> {
    const url = '/assets/helpers/country-list.json';
    return this.http.get<Country[]>(url);
  }
}
