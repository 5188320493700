import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'hcurrency'
})
@Injectable()
export class HCurrecyPipe extends CurrencyPipe implements PipeTransform {
    transform(
        value: string | number,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | boolean,
        digits?: string,
        locale?: string): any {
        let formattedByCurrencyPipe = super.transform(value, currencyCode, 'code', '1.2-2', 'de-DE');
        if (formattedByCurrencyPipe) {
            if (formattedByCurrencyPipe.charAt(0) !== '-'){
                formattedByCurrencyPipe = '+' + formattedByCurrencyPipe;
            }
            return formattedByCurrencyPipe
                .replace(currencyCode, '')
                .replace('.', ' ')
                .replace(',', '<small>,') + `${currencyCode}</small>`;
        } else {
            return '';
        }
    }
}
