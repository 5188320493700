import { Component, OnInit } from '@angular/core';

import { RightContainerService } from '../../../right-container.service';

@Component({
  selector: 'app-transfer-card-template-edit',
  templateUrl: './transfer-card-template-edit.component.html',
  styleUrls: ['./transfer-card-template-edit.component.scss']
})
export class TransferCardTemplateEditComponent implements OnInit {
  optionsCurrency: any = {
    cssClass: 'SelectCurrency'
  };

  constructor(private rightContainer: RightContainerService) { }

  ngOnInit() {
  }

  goBack() {
    this.rightContainer.setRightPanel('transfer-card-templates');
  }

}
