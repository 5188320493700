import { environment } from '../../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export interface Locales {
  locales: Array<LocalesItem>;
}

export interface LocalesItem {
  name: string;
  acronym: string;
  translationToEn: string;
}

const LOCALES_LIST = [
  {
    locale: 'ru',
    name: 'Русский'
  }, {
    locale: 'en',
    name: 'English'
  }, {
    locale: 'de',
    name: 'Deutsch'
  }, {
    locale: 'it',
    name: 'Italiano'
  }, {
    locale: 'tr',
    name: 'Türkçe'
  }, {
    locale: 'fr',
    name: 'Français'
  }, {
    locale: 'es',
    name: 'Español'
  }, {
    locale: 'zh',
    name: '中文'
  }
];


@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  private localizationIsLoadedSource = new BehaviorSubject<boolean>(false);
  localizationIsLoaded$ = this.localizationIsLoadedSource.asObservable();

  constructor(
    private http: HttpClient,
    private translate: TranslateService) {
  }

  setDefaultLocalization(language?: string): void {
    const browserLang = this.translate.getBrowserLang();
    const defaultLang = LOCALES_LIST.find(a => a.locale === browserLang)?.locale || 'en';
    this.translate.setDefaultLang(language || defaultLang);
    this.translate
      .get('enter.first_button')
      .subscribe(text => this.localizationIsLoadedSource.next(true));
  }

  getActiveLanguage$(): Observable<string> {
    return this.localizationIsLoaded$.pipe(
      filter<boolean>(Boolean),
      map(() => (this.translate.currentLang) ? this.translate.currentLang : this.translate.defaultLang)
    );
  }

  getActiveLanguageSync(): string {
    return (this.translate.currentLang) ? this.translate.currentLang : this.translate.defaultLang;
  }

  getLanguagesList(): Observable<Array<LocalesItem>> {
    return this.getPossibleLocales().pipe(
      map(langList => langList.locales)
    );
  }

  useTranslate(acronym: string): Observable<Object> {
    this.localizationIsLoadedSource.next(false);
    return this.translate.use(acronym).pipe(
      tap(() => this.localizationIsLoadedSource.next(true))
    );
  }

  getCountryListByName(langName: string): Observable<Array<LocalesItem>> {
    const langNameLowerCase = langName.toLowerCase();
    return this.getLanguagesList().pipe(
      map(langList => {
        return langList
          .filter(lang => lang.name.toLowerCase()
          .indexOf(langNameLowerCase) !== -1);
      })
    );
  }

  getPossibleLocales(): Observable<Locales> {
    const url = environment.baseApi + '/v1/locales';
    return this.http.get<Locales>(url);
  }

  getTranslate(key: string | Array<string>): Observable<string | any> {
    return this.translate.get(key);
  }

  getBrowserLang(): string {
    return this.translate.getBrowserLang();
  }

  getLocaleData(locale: string): { locale: string; name: string } {
    return LOCALES_LIST.find(a => a.locale === locale.toLowerCase());
  }

  /*setUserLocale(acronym: string) {
    const url = environment.baseApi + '/v1/settings/locale';
    const payload = { acronym: acronym };
    return this.http.post(url, payload);
  }

  getLocalization() {
    const url = environment.baseApi + '/v1/localizations';
    return this.http.get(url);
  }*/
}
