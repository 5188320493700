import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { getApiByUrl, checkEndpointOnExist } from '@core/tools';

import { Observable, switchMap, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { AuthManagerService, AuthConfirmResult } from './auth-manager.service';

const TRUST_DOMAIN_LIST = [
  environment.baseApi,
  environment.cryptoService.baseApi // TODO it's deprecated logic
];

const EXCEPTION_ENDPOINT_LIST: Array<any> = [
  {
    endpoint: 'v1/password/verify',
    code: [ 401 ]
  }
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private static getUserAuthData(token: string, phoneNumber: string): AuthConfirmResult {
    return {
      token: token,
      phone: phoneNumber
    };
  }

  private static getDomainFromUrl(url: string): string { // url: string or undefined
    const parsedUrl = (!!url)
      ? url.split('?')[0].split('//')
      : '';
    const endpoint = (parsedUrl.length === 1) ? parsedUrl[0] : parsedUrl[1];
    return endpoint
      .split('/')
      .slice(0, 1)
      .join('');
  }

  private static checkDomainRequest(request: HttpRequest<any>): boolean {
    const requestApi = (request.url) ? AuthInterceptor.getDomainFromUrl(request.url) : null;
    return TRUST_DOMAIN_LIST
      .map(a => AuthInterceptor.getDomainFromUrl(a))
      .some(a => a === requestApi);
  }

  private static checkErrorResponseAnException(response: HttpErrorResponse): boolean {
    const api = (response.url) ? getApiByUrl(response.url) : false;
    return (api) ? checkEndpointOnExist(api, EXCEPTION_ENDPOINT_LIST.map(a => a.endpoint)) : false;
  }

  constructor(
    private authManager: AuthManagerService) {
  }

  getPatchedRequest(req: HttpRequest<any>, authToken: string, phoneNumber: string): HttpRequest<any> {
    const tokenType = AuthManagerService.getAuthTokenType(this.authManager.authType);
    const appHeaders = req.headers
      .set('applicationId', environment.applicationId)
      .set('deviceId', environment.deviceId)
      .set('productId', (req.headers.has('productId') ? req.headers.get('productId') : environment.productId) );
    return req.clone({
      headers: (authToken)
        ? appHeaders
          .set('Authorization', phoneNumber)
          .set(tokenType, authToken)
        : appHeaders
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const phoneNumber: string = this.authManager.authPhoneNumber;
    return this.authManager.getBankToken().pipe(
      switchMap(token => next.handle(
        (AuthInterceptor.checkDomainRequest(req))
          ? this.getPatchedRequest(req, token, phoneNumber)
          : req
      )),
      catchError(error =>  {
        // console.warn('AUTH_ERROR', { authType: this.authManager.authType, error: error });
        if (!AuthInterceptor.checkErrorResponseAnException(error)) {
          if (this.authManager.authType === 'fire-base' && error.status === 401) {
            return this.refreshFbTokenForeRequest(req, next);
          }
          if (this.authManager.authType === 'uas' && error.status === 401) {
            this.authManager.logout();
          }
        }
        return throwError(error);
      })
    );
  }

  private refreshFbTokenForeRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const phoneNumber: string = this.authManager.authPhoneNumber;
    return this.authManager.generateToken(this.authManager.authType).pipe(
      filter<string>(Boolean),
      switchMap(token => {
        this.authManager.setAuthData(AuthInterceptor.getUserAuthData(token, phoneNumber));
        return next.handle(
          this.getPatchedRequest(req, token, phoneNumber)
        );
      })
    );
    /*return fbAuth.getAuthState().pipe(
      filter(fbUser => {
        if (!fbUser) {
          this.authManager.logout()
            .subscribe(() => console.warn('Not found fbUser model'));
        }
        return !!fbUser;
      }),
      flatMap(fbUser => fbUser.getIdToken(true)),
      flatMap(token => {
        this.authManager.setAuthData(
          AuthInterceptor.getUserAuthData(token, phoneNumber)
        );
        return next.handle(
          this.getPatchedRequest(req, token, phoneNumber)
        );
      })
    );*/
  }

}
