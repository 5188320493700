import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { clone } from 'lodash';
import { Observable, ReplaySubject, of } from 'rxjs';
import { first, pluck, map, switchMap } from 'rxjs/operators';

import { getPasswordHash } from './auth/auth.tools';
import { AuthSuccessResponse } from './auth/auth-manager.service';

interface ChangePasswordProcessResponse {
  status: string;
}

interface UserSettingsResponse {
  locale: string;
  jsonData: string;
}

interface UserSettings extends UserBaseSettings {
  customSettings: UserCustomSettings;
}

interface UserBaseSettings {
  locale: string;
}

interface UserCustomSettings {
  gekkoinWallet?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private userSettingsSource = new ReplaySubject<UserSettings>(1);
  userSettings$ = this.userSettingsSource.asObservable();

  constructor(
    private http: HttpClient) {
  }

  getUserSettings(): Observable<UserSettings> {
    return this.userSettings$.pipe(
      first(),
      map(settings => clone(settings))
    );
  }

  getLocalization(): Observable<string> {
    return this.getUserSettings().pipe(
      pluck('locale'),
      map(locale => locale.toLowerCase())
    );
  }

  /*updateLocalization(locale: string): Observable<string> {
    return this.updateBaseSettings('locale', locale).pipe(
      pluck('locale')
    );
  }*/

  updateBaseSettings(settingItem: keyof UserBaseSettings, value: UserBaseSettings[typeof settingItem]): Observable<UserSettings> {
    return (settingItem === 'locale')
      ? this.requestSaveUserLocale(value).pipe(
          switchMap(() => this.getUserSettings()),
          map(settings => {
            (settings[settingItem] as typeof value) = value;
            return settings;
          }),
          switchMap(settings => this.updateSettings(settings))
        )
      : this.getUserSettings();
  }

  updateCustomSettings(settingItem: keyof UserCustomSettings, value: UserCustomSettings[typeof settingItem]) {
    return this.getUserSettings().pipe(
      map(settings => {
        const customSettings = clone(settings.customSettings);
        (customSettings[settingItem] as typeof value) = value;
        settings.customSettings = customSettings;
        return settings;
      }),
      switchMap(settings => this.updateSettings(settings))
    );
  }

  private updateSettings(settings: UserSettings): Observable<UserSettings> {
    this.userSettingsSource.next(settings);
    return of(settings);
  }

  setSettings(settings: UserSettingsResponse): Observable<UserSettings> {
    const data: UserSettings = {
      locale: settings.locale,
      customSettings: <UserCustomSettings>JSON.parse(settings.jsonData)
    };
    console.log('SET SETTINGS', data);
    this.userSettingsSource.next(data);
    return of(data);
  }

  requestGetUserSettings(): Observable<UserSettingsResponse> {
    const url = environment.baseApi + '/v1/settings';
    return this.http.get<UserSettingsResponse>(url);
  }

  private requestSetUserCustomSettings(settings: UserCustomSettings): Observable<void> {
    const url = environment.baseApi + '/v1/settings';
    const body = {
      jsonData: JSON.stringify(settings)
    };
    return this.http.post<void>(url, body);
  }

  requestSaveUserLocale(locale: string): Observable<void> {
    const url = environment.baseApi + '/v1/settings/locale';
    const body = {
      acronym: locale
    };
    return this.http.post<void>(url, body);
  }

  requestChangeUserPassword(oldPassword: string, newPassword: string,
    phoneNumber: string, token: string): Observable<ChangePasswordProcessResponse> {
    const url = environment.baseApi + `/v1/users/password/update`;
    const headers = new HttpHeaders({
      'token': token
    });
    const model = {
      newPassword: getPasswordHash(phoneNumber, newPassword),
      oldPassword: getPasswordHash(phoneNumber, oldPassword)
    };
    return this.http.post<ChangePasswordProcessResponse>(url, model, { headers });
  }

  requestSetPassword(password: string, phoneNumber: string): Observable<{ phoneNumber: string; password: string; }> {
    const url = environment.baseApi + `/v1/users/password/create`;
    const body = { password: getPasswordHash(phoneNumber, password) };
    return this.http.post<AuthSuccessResponse>(url, body).pipe(
      map(() => {
        return {
          phoneNumber: phoneNumber,
          password: getPasswordHash(phoneNumber, password)
        };
      })
    );
  }

  passwordVerify(password: string, phoneNumber: string): Observable<ChangePasswordProcessResponse> {
    const url = environment.baseApi + `/v1/users/password/verify`;
    return this.http.post<ChangePasswordProcessResponse>(url, { password: getPasswordHash(phoneNumber, password) });
  }

}
