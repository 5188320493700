import { Component, Input, OnInit } from '@angular/core';
import { ROLES } from '../../../users/users.roles';
import { PermissionService } from '../../../users/permission/permission.service';

const BANK_PRELOADING: { [key in ROLES]?: string } = {
  [ROLES.BCC_INDIVIDUAL_PERSON]: 'assets/bcc/base-preloader/base-preloader.gif',
  [ROLES.BCC_LEGAL_PERSON]: 'assets/bcc/base-preloader/base-preloader.gif',
  [ROLES.GEKKARD]: 'assets/gekkard/base-preloader/base-preloader.svg',
};

@Component({
  selector: 'app-is-updating',
  templateUrl: './is-updating.component.html',
  styleUrls: ['./is-updating.component.scss']
})
export class IsUpdatingComponent implements OnInit {

  @Input() isLoading?: boolean;

  src: string;

  constructor(
    private permission: PermissionService
  ) { }

  ngOnInit() {
    this.permission.getActiveBankRole()
      .subscribe(res => this.src = BANK_PRELOADING[res]);
  }

}
