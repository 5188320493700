import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

import { TrustedClient } from '../../../users/organizations.interface';

@Component({
  selector: 'app-client-chip',
  templateUrl: './client-chip.component.html',
  styleUrls: ['./client-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientChipComponent implements OnChanges {

  @Input() client: TrustedClient;

  clientColor?: string;
  clientName?: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('client')) {
      this.clientName = (this.client && this.client.title) ? this.client.title : this.client.name;
      this.clientColor = (this.client && this.client.color) ? this.client.color.toLowerCase() : 'none';
    }
  }

}
