import { environment } from '../../../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { News } from './navigation-news.component';

export interface NewsReadResp {
  status: 'ok' | 'error';
}

@Injectable()
export class NavigationNewsService {
  constructor(
    private http: HttpClient
  ) {
  }

  requestNews(locale: string): Observable<News> {
    const url = environment.baseApi + '/v1/news';
    const headers = new HttpHeaders({
      'locale': locale
    });
    return this.http.get<News>(url, { headers });
  }

  registerAsRead(news: News): Observable<NewsReadResp> {
    const url = environment.baseApi + `/v1/news/${news.id}`;
    const body = { read: true };
    return this.http.patch<NewsReadResp>(url, body);
  }
}
