export const VALID_CARD_BIN = ['527760', '525981', '512570'];

export function isCardBINValid(value: string, pattern = VALID_CARD_BIN): number {
    for (const item of pattern) {
        if (value.startsWith(item)) {
            return 0;
        }
    }
    return -1;
}
