import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChatLocaleStrings, SupportSessionConfig } from 'bcc-sdk/dist/bcc-chat-support';

import { ReplaySubject, Observable } from 'rxjs';
import { first, map, pluck } from 'rxjs/operators';

import { AuthorizationType, AuthManagerService } from './users/auth/auth-manager.service';
import { AppCryptoService } from './app.crypto.service';
import { LocalizationService } from './internationalization/localization/localization.service';
import { GekkoinWalletsResponse } from './+gekkoin-wallet/gekkoin-wallet.interfaces';

interface AppConfigurationSettings {
  idScanConfig: {
    host: string;
    login: string;
    password: string;
  };
  cryptoConfig: {
    showCryptoInMobile: boolean;
    showCryptoInWeb: boolean;
  };
  topupConfig: {
    topupByCodeEnabled: boolean;
  };
  exchangeConfig: {
    enabledInGekkard: boolean;
    enabledInBCC: boolean;
  };
  clientConfig: ClientConfig;
  feeDisplayConfig: FeeDisplayConfig;
  paymentConfig: PaymentConfig;
}

interface FeeDisplayConfig {
  showSwiftFee: boolean;
  showSepaFee: boolean;
  showContactFee: boolean;
  showCardFee: boolean;
}

interface CryptoConfigResponse {
  status: 'success';
  config: { enabled: boolean };
}

export interface IdScanConfig {
  host: string;
  login: string;
  password: string;
}

interface PaymentConfig {
  availableCurrenciesForSwift: Array<string>;
}

interface ClientConfig {
  supportChats: boolean;
  whatsApp: boolean;
}

interface AppCryptoConfig {
  appUuid: string;
  secretePassword: string;
  salt: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService {

  private appConfigurationSource = new ReplaySubject<AppConfigurationSettings>(1);
  appConfiguration$: Observable<AppConfigurationSettings> = this.appConfigurationSource.asObservable();

  static getSupportChatConfig(token: string, phoneNumber: string, authType: AuthorizationType, chatConfig): SupportSessionConfig {
    return {
      token: token,
      phone: phoneNumber,
      authType: authType,
      baseUrl: chatConfig.url,
      appId: chatConfig.sessionConfig.appId,
      productId: chatConfig.sessionConfig.productId,
      appVersion: '1',
      locale: 'en'
    };
  }

  constructor(
    private http: HttpClient,
    private cryptoService: AppCryptoService,
    private localization: LocalizationService) {
  }

  requestBaseConfiguration(): Observable<AppConfigurationSettings> {
    const url = environment.baseApi + `/v1/config`;
    return this.http.get<AppConfigurationSettings>(url);
  }

  setConfiguration(value: AppConfigurationSettings): void {
    this.appConfigurationSource.next(value);
  }

  requestCryptoServiceAccess(token: string, phoneNumber: string, authType: AuthorizationType): Observable<{ enabled: boolean }> {
    // const url = environment.baseApi + '/v1/crypto/config'; monolit config for crypto service
    const url = environment.cryptoService.baseApi + '/user/config';
    const tokenType = AuthManagerService.getAuthTokenType(authType);
    const headers = new HttpHeaders({
      'authorization': phoneNumber,
      'applicationId': environment.applicationId,
      'productId': environment.productId,
      'deviceId': environment.deviceId,
      [tokenType]: token
    });
    return this.http.get<CryptoConfigResponse>(url, { headers }).pipe(
      map(response => response.config)
    );
  }

  requestGekkoinServiceAccess(): Observable<{ enabled: boolean }> {
    const url = environment.baseApi + '/v1/gekkard';
    return this.http.get<GekkoinWalletsResponse>(url).pipe(
      map(wallet => {
        return { enabled: !!(wallet.wallets && wallet.wallets.length) };
      })
    );
  }

/*  requestIdScanConfig(): Observable<IdScanConfig> {
    const url = environment.baseApi + '/v1/id_scan/config';
    return this.http.get<{ idscan: IdScanConfig }>(url).pipe(
      map(response => response.idscan)
    );
  }*/

  private getAppConfiguration(): Observable<AppConfigurationSettings> {
    return this.appConfiguration$.pipe(
      first()
    );
  }

  private getPaymentConfig(): Observable<PaymentConfig> {
    return this.getAppConfiguration().pipe(
      pluck('paymentConfig')
    );
  }

  getClientConfig(): Observable<ClientConfig> {
    return this.getAppConfiguration().pipe(
      pluck('clientConfig')
    );
  }

  getAvailableCurrenciesForSwift(): Observable<string[]> {
    return this.getPaymentConfig().pipe(
      pluck('availableCurrenciesForSwift')
    );
  }

  /* Chat Configuration */

  getSupportChatTranslates(): Observable<ChatLocaleStrings> {
    return this.localization.getTranslate(
      [
        'your_message',
        'validation_space_message',
        'today',
        'yesterday'
      ]);
  }

  /* Exchange Configuration */

  getExchangeConfig(): Observable<boolean> {
    return this.getAppConfiguration().pipe(
      pluck('exchangeConfig'),
      pluck((environment.applicationId === 'BLACK_CAT_CARD') ? 'enabledInBCC' : 'enabledInGekkard')
    );
  }

  /* Fee Display Configuration */

  private getFeeDisplayConfig(): Observable<FeeDisplayConfig> {
    return this.appConfiguration$.pipe(
      first(),
      map(config => config.feeDisplayConfig)
    );
  }

  getSepaFeeDisplayConfig(): Observable<boolean> {
    return this.getFeeDisplayConfig().pipe(
      map(config => config.showSepaFee)
    );
  }

  getSwiftFeeDisplayConfig(): Observable<boolean> {
    return this.getFeeDisplayConfig().pipe(
      map(config => config.showSepaFee)
    );
  }

  getContactFeeDisplayConfig(): Observable<boolean> {
    return this.getFeeDisplayConfig().pipe(
      map(config => config.showContactFee)
    );
  }

  getCardFeeDisplayConfig(): Observable<boolean> {
    return this.getFeeDisplayConfig().pipe(
      map(config => config.showCardFee)
    );
  }

}
