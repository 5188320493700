<section class="page">
  <div class="page__content">
    <div class="top">
      <div class="stepper">
        <img class="stepper__img" ngSrc="../../../../assets/custom-icons/topup-binance-account-step1.svg" alt="step1" height="24"
             width="113">

        <span class="stepper__description">{{ 'topup.binance.connect_firstStep_text' | translate }}</span>
      </div>

      <div class="text">
        <h2 class="text__title">{{ 'topup.binance.connect_firstStep_text_area_header' | translate }}</h2>

        <p class="text__description">{{ 'topup.binance.connect_firstStep_text_area_paragraph' | translate }}</p>
      </div>

      <form [formGroup]="form" class="form">
        <ion-list>
          <ion-item class="form__item" lines="none">
            <ion-input formControlName="email" type="mail"
                       [label]="'topup.binance.connect_firstStep_Email_label'  | translate"
                       [placeholder]="'topup.binance.connect_firstStep_Email_placeholder'  | translate"
                       labelPlacement="stacked"></ion-input>
          </ion-item>

          <span
              *ngIf="showError('email')"
              class="form__err-message">{{ getErrorMessages | translate }}</span>

          <div class="TopUpBinance-email_error" *ngIf="((emailBinanceControl.invalid && emailBinanceControl.dirty)
                    || (emailBinanceControl.invalid && emailBinanceControl.touched))">
              <span class="label-error" *ngIf="emailBinanceControl.hasError('connectionError')">
                {{ 'topup.binance.connect_firstStep_error3' | translate }}</span>
            <span class="label-error" *ngIf="emailBinanceControl.hasError('walletNotExist')">
                {{ 'topup.binance.connect_firstStep_error4' | translate }}</span>
          </div>
        </ion-list>
      </form>
    </div>

    <div class="bottom">
      <div class="hint">
        <img ngSrc="../../../../assets/custom-icons/binance_logo%202.svg" alt="binance" class="hint__img" height="24" width="25">
        <span class="hint__text">{{ 'topup.binance.connect_firstStep_notify_text' | translate }}</span>
      </div>

      <div class="buttons-container">
        <ion-button routerLink="/top-up-account" size="large" expand="full" shape="round" fill="outline" color="medium">
          {{ 'topup.binance.connect_cancelButton' | translate }}
        </ion-button>

        <ion-button (click)="sendEmail()" size="large" expand="full" shape="round" fill="outline" color="success">
          <ion-icon slot="start" name="arrow-green"></ion-icon>
          {{ 'topup.binance.connect_nextButton' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</section>
