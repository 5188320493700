<ion-item class="History-item">
  <div class="History-item__header">
    <ion-label class="History-item__label">{{ item.name }}</ion-label>
  </div>
  <div class="History-item__values">
    <span class="History-item__info"
          [class.green]="item.status == 'Paid'"
          [class.warning]="item.status == 'Waiting'">{{ item.statusText }}</span>
    <p class="History-item__value">
      {{ item.price > 0 ? '+' : '-' }}{{ getIntegerPart() }}<small>{{ getDecimalPart() }}{{ item.currency.code }}</small>
    </p>
  </div>
</ion-item>