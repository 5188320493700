<ion-header class="ion-no-border">
	<ion-toolbar>
		<ion-title>{{ 'bankOfferStartConfirm.title' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center ion-padding">
	<div class="Banner">
		<div class="Banner-info">
			<p>{{ 'confirmationBankTransfer.first_text' | translate }}</p>
		</div>
		<div class="Banner-sum">
			<span>{{ notification.amount }}<small>EUR</small></span>
		</div>
		<div class="Banner-message">
			<span>{{ 'confirmationBankTransfer.second_text' | translate }}</span>
		</div>
		<div class="Banner-account">
			<b>{{ notification.fullName }}</b>
			<p>IBAN {{ notification.iban }}</p>
		</div>
		<div class="Banner-buttons">
			<ion-button expand="full" shape="round" fill="outline" color="danger" (click)="reject()">
				<!-- <ion-icon name="icon-close-small-red"></ion-icon> -->
				{{ 'confirmationBankTransfer.second_button' | translate }}
			</ion-button>
			<ion-button expand="full" shape="round" fill="outline" color="success" (click)="accept()">
				<ion-icon name="arrow-right-small-green"></ion-icon>
				{{ 'confirmationBankTransfer.first_button' | translate }}
			</ion-button>
		</div>
	</div>
</ion-content>
