<app-is-loading
    [isLoading]="(isLoading$ | async)">
</app-is-loading>

<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-title>
        {{ 'receipt.title' | translate }}
      </ion-title>
      <ion-icon (click)="goBack()" slot="start" name="back"></ion-icon>
      <ion-icon (click)="onSaveReceipt(operation.referenceNumber)" slot="end" name="full"></ion-icon>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <app-transfer-recipe
      [operation]="operation">
    </app-transfer-recipe>
  </ion-content>
</ion-app>
