import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthManagerService } from '../../users/auth/auth-manager.service';
import { OperationsService } from '../../users/operations/operations.service';
import { PaymentOperationTemplate, PaymentOperationRepeat, PAYMENT_TYPE } from '../../users/operations/operations.interface';

@Injectable({
  providedIn: 'root'
})
export class TransferTemplatesService {

  private transferTemplatesSource = new BehaviorSubject<PaymentOperationTemplate[]>([]);
  transferTemplates$: Observable<PaymentOperationTemplate[]> = this.transferTemplatesSource.asObservable();

  private isLoadingSource = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSource.asObservable();

  static getTemplatesByType(templates: Array<PaymentOperationTemplate>, type: PAYMENT_TYPE): Array<PaymentOperationTemplate> {
    return templates.filter(template => template.type === type);
  }

  static getTemplatesByTypes(templates: Array<PaymentOperationTemplate>, types: Array<PAYMENT_TYPE>): Array<PaymentOperationTemplate> {
    return templates.filter(template => types.includes(template.type));
  }

  static getPaymentOperationRepeat(model: PaymentOperationTemplate): PaymentOperationRepeat {
    return {
      account: model.fromAccountId,
      amount: model.amount,
      beneficiaryName: model.beneficiaryName,
      cardNumber: model.toCardNumber,
      currencyCode: model.currencyCode,
      iban: model.toIban,
      fromCardId: model.fromCardId,
      fromCardPan: model.fromCardPan,
      phoneNumber: model.toPhoneNumber,
      purpose: model.purpose,
      transferDetails: model.transferDetails,
      type: model.type,
      beneficiaryAccount: model.beneficiaryAccount,
      country: model.country,
      city: model.city,
      address: model.address,
      swiftCode: model.swiftCode,
      beneficiaryBank: model.beneficiaryBank,
      commissionType: model.commissionType,
      urgency: model.urgency,
    };
  }

  private static getTemplateIndex(templateList, template): number {
    return templateList.indexOf(
      templateList.find(t => t.id === template.id)
    );
  }

  constructor(
    private http: HttpClient,
    private operations: OperationsService,
    private authManager: AuthManagerService) {

    this.authManager.bankToken$
      .subscribe(bankToken => (bankToken) ? this.updateTemplateList() : this.clearTemplateList());
  }

  clearTemplateList() {
    this.setTemplates([]);
  }

  updateTemplateList() {
    this.requestTemplatesV2()
      .subscribe(templates => this.setTemplates(templates));
  }

  restoreOperationFromTemplate(template: PaymentOperationTemplate, route = null): void {
    const repeatOperation = TransferTemplatesService.getPaymentOperationRepeat(template);
    this.operations.repeatOperation(repeatOperation, route);
  }

  saveTemplate(data: PaymentOperationTemplate): Observable<PaymentOperationTemplate> {
    return this.createTemplate(data);
  }

  deleteTemplate(template: PaymentOperationTemplate): void {
    const templateList = this.transferTemplatesSource.getValue();
    const templateIndex = TransferTemplatesService.getTemplateIndex(templateList, template);
    templateList.splice(templateIndex, 1);
    this.setTemplates(templateList);
  }

  replaceTemplateInTemplateList(template: PaymentOperationTemplate): void {
    const templateList = this.transferTemplatesSource.getValue();
    const templateIndex = TransferTemplatesService.getTemplateIndex(templateList, template);
    templateList[templateIndex] = template;
    this.setTemplates(templateList);
  }

  private setTemplates(value: Array<PaymentOperationTemplate>) {
    this.transferTemplatesSource.next(value);
  }

  private requestTemplatesV2(): Observable<PaymentOperationTemplate[]> {
    const url = environment.baseApi + `/v2/templates`;
    this.isLoadingSource.next(true);
    return this.http.get<PaymentOperationTemplate[]>(url).pipe(
      tap(
        () => this.isLoadingSource.next(false),
        () => this.isLoadingSource.next(false)
      )
    );
  }

  private createTemplate(model: PaymentOperationTemplate): Observable<PaymentOperationTemplate> {
    const url = environment.baseApi + `/v2/templates`;
    return this.http.post<PaymentOperationTemplate>(url, model);
  }

  requestUpdateTemplate(template: PaymentOperationTemplate): Observable<PaymentOperationTemplate> {
    const url = environment.baseApi + `/v2/templates/${template.id}`;
    return this.http.put<PaymentOperationTemplate>(url, template);
  }

  requestDeleteTemplate(template: PaymentOperationTemplate): Observable<{ status: 'ok' }> {
    const url = environment.baseApi + `/v2/templates/${template.id}`;
    return this.http.delete<{ status: 'ok' }>(url);
  }

}
