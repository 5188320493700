import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// import { SwiperModule } from 'swiper/angular';


import { CardsSliderComponent } from './cards-slider/cards-slider.component';
import { CardComponent } from './card/card.component';
import { CardCompanyInfoComponent } from './card-company-info/card-company-info.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { UsersModule } from '../../users/users.module';

@NgModule({
  declarations: [
    CardsSliderComponent,
    CardComponent,
    CardCompanyInfoComponent
  ],
  exports: [
    CardsSliderComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    // SwiperModule,
    InternationalizationModule,
    UsersModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CardsModule { }
