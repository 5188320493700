import { Component, Input, OnInit } from '@angular/core';
import { HistoryOperationType, MoneyOperation } from '../../../../../users/operations/operations.interface';

@Component({
  selector: 'app-recipe-payment-info',
  templateUrl: './recipe-payment-info.component.html',
  styleUrls: ['./recipe-payment-info.component.scss']
})
export class RecipePaymentInfoComponent implements OnInit {

  @Input() operation?: MoneyOperation;
  @Input() operationType?: HistoryOperationType;

  absOperationAmount: number;

  constructor() { }

  ngOnInit() {
    if (this.operation) {
      this.absOperationAmount = Math.abs(this.operation.amount);
    }
  }

}
