import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { clone } from 'lodash';

import { TransferTemplatesService } from '../../../services/transfer-templates/transfer-templates.service';
import { PAYMENT_TYPE, PaymentOperationTemplate } from '../../../users/operations/operations.interface';

@Component({
  selector: 'app-transfer-contact-templates',
  templateUrl: './transfer-contact-templates.component.html',
  styleUrls: ['./transfer-contact-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferContactTemplatesComponent implements OnInit {
  paymentType: typeof PAYMENT_TYPE = PAYMENT_TYPE;

  isLoading$: Observable<boolean> = this.transferTemplates.isLoading$;
  templates$: Observable<PaymentOperationTemplate[]> = this.transferTemplates.transferTemplates$
    .pipe(
      map(templates => TransferTemplatesService.getTemplatesByType(templates, PAYMENT_TYPE.PaymentPhone)),
      map(templates => TransferContactTemplatesComponent.cropPhone(templates)),
    );

  private static cropPhone(templates: Array<PaymentOperationTemplate>): Array<PaymentOperationTemplate> {
    return templates.map(template => {
      const temp = clone(template);
      if (temp && temp.toPhoneNumber) {
        temp.toPhoneNumber = temp.toPhoneNumber.replace(/\+/g, '');
      }
      return temp;
    });
  }

  constructor(
    private transferTemplates: TransferTemplatesService) {
  }

  ngOnInit() {}

  repeatOperation(template: PaymentOperationTemplate) {
    this.transferTemplates.restoreOperationFromTemplate(template);
  }

}
