import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocalizationService } from '../localization/localization.service';

@Directive({
  selector: '[appLocalizationNotLoaded]'
})
export class LocalizationNotLoadedDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private localizations: LocalizationService) {

    localizations.localizationIsLoaded$
      .subscribe(predicate => {
        if (!predicate) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

}
