import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthManagerService } from '../auth-manager.service';
import { NavController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class AntiAuthGuard  {

  constructor(
    private navcCtrl: NavController,
    private authManager: AuthManagerService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (route) {
      return this.authManager.getIsStoredToken()
        .then(token => {
          if (token) {
            this.navcCtrl.navigateRoot('/money', {
              animated: true
            });
            return false;
          }
          return true;
        });
    }
  }

}
