import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { IsLoadingModule } from '../../ui-elements/is-loading/is-loading.module';

import { CryptoSendInternalTemplatesComponent } from './crypto-send-internal-templates/crypto-send-internal-templates.component';
import { CryptoSendInternalTemplatesItemComponent } from './crypto-send-internal-templates/crypto-send-internal-templates-item/crypto-send-internal-templates-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    IsLoadingModule
  ],
  declarations: [
    CryptoSendInternalTemplatesComponent,
    CryptoSendInternalTemplatesItemComponent
  ],
  exports: [
    CryptoSendInternalTemplatesComponent
  ]
})
export class CryptoTemplatesModule { }
