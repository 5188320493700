import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {intersection, uniqBy} from 'lodash';
import {forkJoin, Observable} from 'rxjs';
import {concatAll, filter, map, switchMap, tap, toArray} from 'rxjs/operators';


import { LogoutConfirmComponent } from '../modals/components/logout-confirm/logout-confirm.component';
import { TransferConfirmRequestComponent } from '../modals/components/transfer-confirm-request/transfer-confirm-request';
import { TransferConfirmCodeComponent } from '../modals/components/transfer-confirm-code/transfer-confirm-code.component';
import { TransferSuccessComponent } from '../modals/components/transfer-success/transfer-success.component';

import { ModalData, ModalsService } from '../modals/modals.service';
import { ROLES } from '../users/users.roles';
import { PermissionService } from '../users/permission/permission.service';
import { OrganizationsService } from '../users/organizations/organizations.service';
import { AuthManagerService } from '../users/auth/auth-manager.service';
import { PaymentsService } from '../services/payments/payments.service';
import { NotificationServiceItem, ServerNotificationsService } from '../services/server-notifications/server-notifications.service';

import { BccChatSupportService } from 'bcc-sdk/dist/bcc-chat-support';

import {Account, TrustedClient} from '../users/organizations.interface';
import { RegistrationService } from 'app/services/registration/registration.service';
import { TopUpComponent } from '../+top-up-account/top-up-index/top-up-index.component';

const NAVIGATE_ITEM_LIST = [
  {
    title: 'home.first_icon',
    url: '/money/detail',
    iconName: 'menu-money',
    acronym: 'home.first_icon',
    acl: [ ROLES.BCC_LEGAL_PERSON, ROLES.BCC_INDIVIDUAL_PERSON, ROLES.GEKKARD ]
  },
  {
    title: 'home.second_button',
    url: '/transfer/card',
    iconName: 'menu-card-to-card-transfer',
    acronym: 'home.second_button',
    acl: [ ROLES.BCC_LEGAL_PERSON, ROLES.BCC_INDIVIDUAL_PERSON, ROLES.GEKKARD ]
  },
  {
    title: 'home.fourth_button',
    url: '/transfer/bank',
    iconName: 'menu-bank-transfer',
    acronym: 'home.fourth_button',
    acl: [ ROLES.BCC_LEGAL_PERSON, ROLES.BCC_INDIVIDUAL_PERSON, ROLES.GEKKARD ]
  },
  {
    title: 'home.fifth_button',
    url: '/transfer/contact',
    iconName: 'menu-transfer-to-contact',
    acronym: 'home.fifth_button',
    acl: [ ROLES.BCC_LEGAL_PERSON, ROLES.BCC_INDIVIDUAL_PERSON, ROLES.GEKKARD ]
  },
  {
    title: 'Gekkoin Wallet',
    url: '/gekkard',
    iconName: 'menu-transfer-to-contact',
    acronym: 'gekkard.gekkoin.wallet_title',
    acl: [ ROLES.GEKKARD_CRYPTO ]
  },
  {
    title: 'Cashback',
    url: '/cashback',
    iconName: 'menu-cashback',
    acronym: 'Cashback.Title',
    acl: [ ROLES.BCC_LEGAL_PERSON, ROLES.BCC_INDIVIDUAL_PERSON ]
  },
  {
    title: 'Report',
    url: '/reports',
    iconName: 'menu-reports',
    acronym: 'Report_title',
    acl: [ ROLES.BCC_LEGAL_PERSON, ROLES.BCC_INDIVIDUAL_PERSON ]
  },
  {
    title: 'Crypto Service',
    url: '/crypto',
    acronym: 'home.eighth_button',
    iconName: 'menu-crypto-service',
    // acl: [ ROLES.BCC_LEGAL_PERSON, ROLES.BCC_INDIVIDUAL_PERSON, ROLES.GEKKARD ]
    acl: [ ROLES.BCC_CRYPTO ]
  },
  // {
  //   title: 'Send Money Request',
  //   url: '/send-money',
  //   iconName: 'menu-send-money-request'
  // },
  {
    title: 'Currency Exchange',
    url: '/currency-exchange',
    iconName: 'menu-currency-exchange',
    acronym: 'home.sixth_button',
    acl: [ ROLES.CURRENCY_EXCHANGE ]
  },
];

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit {
  roles = ROLES;

  clientList$: Observable<Array<TrustedClient>> = this.organization.getTrustedClientList().pipe(
    tap(value => console.log(333, value)),
  );

  notifications?: Array<NotificationServiceItem>;

  unreadSupportMsg$ = this.chat.unreadMsgCount$;

  mainNavItemList = [];

  static getTrustedClientFormAccount(account: Account): TrustedClient {
    return {
      clientId: account.clientId,
      name: account.accountName,
      title: account.title,
      color: account.color,
    };
  }

  constructor(
    private permission: PermissionService,
    private router: Router,
    private modals: ModalsService,
    private authManager: AuthManagerService,
    private organization: OrganizationsService,
    private notification: ServerNotificationsService,
    private payments: PaymentsService,
    private chat: BccChatSupportService,
    private registration: RegistrationService) {
  }

  ngOnInit() {
    this.permission.activeRoles$
      .pipe(
        map(roleList => NAVIGATE_ITEM_LIST.filter(item => intersection(item.acl, roleList).length)),
      )
      .subscribe(navigationList => this.mainNavItemList = navigationList);
    this.notification.postponedNotifications$
      .subscribe(response => this.notifications = response);
  }

  onLogout() {
    this.openConfirmLogoutModal()
      .pipe(
        filter(data => data.hasResult()),
        switchMap(() => this.authManager.logout())
      )
      .subscribe();
  }

  onOpenTopUp() {
    this.openTopUpModal().subscribe();
  }

  onConfirmTransferToAnyRequest() {
    const notification = this.notifications[0];
    this.openTransferConfirmRequestModal(notification)
      .pipe(
        filter(modalResponse => modalResponse.hasResult()),
        switchMap(modalResponse => {
          if (modalResponse.getResult() === 'accept') {
            return this.payments.requestAnyContactTransfer(notification.uuid)
              .pipe(
                switchMap(response => this.openTransferConfirmationModal(
                  notification,
                  PaymentsService.getTokenFromConfirmCodeResponse(response))
                ),
                filter(modalData => modalData.hasResult()),
                switchMap(() => this.openTransferSuccessModal())
              );
          }
          if (modalResponse.getResult() === 'reject') {
            this.openIndexPage();
            return this.payments.rejectAnyContactTransfer(notification.uuid);
          }
        }),
        switchMap(() => this.notification.requestNotifications())
      )
      .subscribe(response => this.notification.setNotifications(response));
  }

  private openIndexPage() {
    this.router.navigate(['/money']);
  }

  private openTopUpModal() {
    return this.modals.openModal({
      component: TopUpComponent,
      cssClass: 'TopUp',
    });
  }

  private openTransferConfirmRequestModal(item: NotificationServiceItem): Observable<ModalData<'accept' | 'reject'>> {
    return this.modals.openModal({
      component: TransferConfirmRequestComponent,
      componentProps: { notification: item },
      cssClass: 'SendMoneyRequestBanner'
    });
  }

  private openTransferConfirmationModal(data: NotificationServiceItem, token: string) {
    const modalOpts = {
      component: TransferConfirmCodeComponent,
      componentProps: { operationType: 'PAYMENT_CONTACT_ANY', transferData: data, transferToken: token },
      cssClass: 'TransferConfirmationModal ModalConfirmation',
    };
    return this.modals.openModal(modalOpts);
  }

  private openTransferSuccessModal() {
    return this.modals.openModal({
      component: TransferSuccessComponent,
      componentProps: { transferType: 'PAYMENT_CONTACT_ANY' },
      cssClass: 'ModalFullScreen',
    });
  }

  private openConfirmLogoutModal() {
    return this.modals.openModal({
      component: LogoutConfirmComponent,
      cssClass: 'SettingsLogOut',
    });
  }

  openBusinessAccount() {
    this.registration.start(true, false)
      .subscribe();
  }

}
