import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { SafeResourceUrl } from '@angular/platform-browser';

import { switchMap } from 'rxjs/operators';

import { PrintStatementPreviewComponent } from '../../../modals/components/print-statement-preview/print-statement-preview.component';

import { ModalsService } from '../../../modals/modals.service';
import { RightContainerService } from '../../right-container.service';
import { OperationsService } from '../../../users/operations/operations.service';

import { Account } from '../../../users/organizations.interface';

import { CalendarSingleSelectComponent } from '../../../ui-elements/calendars/calendar-single-select/calendar-single-select.component';

/*interface DateRange {
  from: number;
  to: number;
}*/

@Component({
  selector: 'app-print-statements',
  templateUrl: './print-statements.component.html',
  styleUrls: ['./print-statements.component.scss']
})
export class PrintStatementsComponent implements OnInit {

  @Input() dateFrom?: number;
  @Input() dateTo?: number;

  @Output() setFilters = new EventEmitter();

  isLoading$ = this.operations.isLoading$;

  activeAccount?: Account;
  /*selectedRange: DateRange = {
    from: null,
    to: null,
  };*/

  /*dateRange = {
    from: new Date(),
    to: new Date()
  };*/

  /*calendarType: 'string';
  optionsRange = {
    pickMode: 'range',
    showMonthPicker: false,
    showToggleButtons: true,
    from: new Date(2010, 1, 1),
    to: new Date(),
    monthFormat: 'MMMM YYYY',
    weekdays: ['S', 'M', 'T', 'W', 'TH', 'F', 'SA'],
    monthPickerFormat: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  };*/

  /*fromText: string;
  toText: string;
  isActiveCurrentMonth = false;
  isActiveNextMonth = false;
  isActivePrevMonth = false;
  isSameYear: Boolean;
  currentMonth: any;
  currentYear: any;*/

  constructor(
    private rightContainer: RightContainerService,
    private datePipe: DatePipe,
    private modals: ModalsService,
    private popoverController: PopoverController,
    private operations: OperationsService) {
  }

  ngOnInit() {
    /*if (this.dateFrom) {
      this.dateRange.from = new Date(this.datePipe.transform(this.dateFrom, 'd LLL yyyy'));
      this.dateRange.to = new Date(this.datePipe.transform(this.dateTo, 'd LLL yyyy'));
    }

    if (this.dateRange && this.dateRange.from) {
      this.currentYear = this.dateRange.from.getFullYear();
      this.currentMonth = this.dateRange.from.getMonth() + 1;
    } else {
      this.currentYear = (new Date()).getFullYear();
      this.currentMonth = (new Date()).getMonth() + 1;
    }

    this.selectedRange.from = this.dateRange.from ? this.dateRange.from.getTime() : 0;
    this.selectedRange.to = this.dateRange.to ? this.dateRange.to.getTime() : 0;

    this.calcMonths();*/
  }

  async onSetDateStart(ev: Event) {
    const popover = await this.popoverController.create({
      cssClass: 'popover-history-filter popover-start',
      component: CalendarSingleSelectComponent,
      backdropDismiss: true,
      event: ev,
    });
    popover.onDidDismiss()
      .then(result => {
        if (result.data) {
          this.dateFrom = result.data;
        }
      });
    return await popover.present();
  }

  async onSetDateEnd(ev: Event) {
    const popover = await this.popoverController.create({
      cssClass: 'popover-history-filter popover-end',
      component: CalendarSingleSelectComponent,
      backdropDismiss: true,
      event: ev,
    });
    popover.onDidDismiss()
      .then(result => {
        if (result.data) {
          this.dateTo = result.data;
        }
      });
    return await popover.present();
  }

  setAccount(account: Account) {
    this.activeAccount = account;
  }

  clearDate() {
    this.dateFrom = null;
    this.dateTo = null;
  }

  backToMovementMoneyHistory(): void {
    this.rightContainer.setRightPanel('movement-money-history');
  }

  onPreviewStatement(account: Account, dateFrom: number, dateTo: number) {
    this.operations.getBankStatementPreview(account.id, dateFrom, dateTo, 'application/pdf')
      .pipe(
        switchMap(response => this.openPrintStatementModal(response, dateFrom, dateTo))
      )
      .subscribe(result => console.log(result));
  }

  private openPrintStatementModal(dataLocalUrl: SafeResourceUrl, dateFrom: number, dateTo: number) {
    const modalOpts = {
      component: PrintStatementPreviewComponent,
      componentProps: { pdf: dataLocalUrl, dateFrom: dateFrom, dateTo: dateTo },
      cssClass: 'PrintStatementPreview',
    };
    return this.modals.openModal(modalOpts);
  }

/*  monthChange(month) {
    this.currentMonth = month.newMonth.months;
    this.currentYear = month.newMonth.years;
    this.calcMonths();
  }*/

/*  onSelect(day: CalendarDay) {
    const localeData = moment.localeData();
    const longFormat = localeData.longDateFormat('LL');
    this.fromText = moment(day.time).format(longFormat);
    this.selectedRange.to = day.time;
    this.selectedRange.from = day.time;
    this.isSameYear = false;
    this.calcMonths();
  }*/

/*  onChange(value: Object) {
    const date = value as { from: moment.Moment; to: moment.Moment };
    const localeData = moment.localeData();
    const longFormat = localeData.longDateFormat('LL');
    this.fromText = date.from.format(longFormat);
    this.toText = date.to.format(longFormat);
    this.selectedRange.from = Number(date.from.format('x'));
    this.selectedRange.to = Number(date.to.format('x'));
    this.isSameYear = date.to.year() === date.from.year();
    this.setFilters.emit(this.selectedRange);
    this.calcMonths();
    this.sendFilters();
  }*/

/*  calcMonths() {
    const endOfMonth = parseInt(
      moment(this.currentYear + '-' + this.currentMonth, 'YYYY-MM').endOf('month').format('x'),
      10
    );
    const startOfMonth = parseInt(
      moment(this.currentYear + '-' + this.currentMonth + '-01', 'YYYY-MM-DD').format('x'),
      10
    );
    this.isActiveCurrentMonth = (startOfMonth <= this.selectedRange.to && endOfMonth >= this.selectedRange.from);

    const endOfNextMonth = parseInt(
      moment(startOfMonth, 'x').add(1, 'months').endOf('month').format('x'),
      10
    );
    const startOfNextMonth = parseInt(
      moment(startOfMonth, 'x').add(1, 'months').format('x'),
      10
    );
    this.isActiveNextMonth = (startOfNextMonth <= this.selectedRange.to && endOfNextMonth >= this.selectedRange.from);

    const endOfPrevMonth = parseInt(
      moment(startOfMonth, 'x').subtract(1, 'months').endOf('month').format('x'),
      10
    );
    const startOfPrevMonth = parseInt(
      moment(startOfMonth, 'x').subtract(1, 'months').format('x'),
      10
    );
    this.isActivePrevMonth = (startOfPrevMonth <= this.selectedRange.to && endOfPrevMonth >= this.selectedRange.from);
  }*/

  /*clear() {
    this.dateRange = null;
    this.selectedRange.from = null;
    this.selectedRange.to = null;
    this.isActiveCurrentMonth = false;
    this.isActiveNextMonth = false;
    this.isActivePrevMonth = false;
    this.sendFilters();
    this.selected.prevFrom = 0;
    this.selected.prevTo = 0;
  }*/

  /*sendFilters() {
    this.setFilters.emit(this.selectedRange);
  }

  cancel() {
    this.clear();
    this.sendFilters();
    this.openMovementMoneyHistory();
  }*/

  /*openMovementMoneyHistory(): void {
    this.rightContainer.setRightPanel('movement-money-history');
  }*/

}
