<div class="TopUpBuy">
  <!--<app-is-loading [isLoading]="isLoading"></app-is-loading>-->

  <div class="TopUpBuy-title">
    <ion-icon name="back" (click)="goBack()"></ion-icon>
    <span>{{ 'topup.where_to_top_up' | translate }}?</span>
  </div>

  <google-map
      height="350px"
      width="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="options">
    <map-marker
      #marker="mapMarker"
      *ngFor="let marker of markers"
      [position]="marker.position"
      [options]="markerOptions"
      (mapClick)="openInfo(marker, marker)"
    >
      <map-info-window>
        <div class="TopUpBuy-window">
          <p class="TopUpBuy-window__title">{{ currentMarker?.name }}</p>
          <span class="TopUpBuy-window__info">{{ currentMarker?.description }}</span>
          <ion-list lines="none">
            <ion-item>
              <ion-icon name="map-pin"></ion-icon>
              <ion-label>{{ currentMarker?.address }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="map-link"></ion-icon>
              <ion-label><a href="{{ currentMarker?.website }}">{{ currentMarker?.website }}</a></ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="map-phone"></ion-icon>
              <ion-label><a href="tel:{{ currentMarker?.phone }}">{{ currentMarker?.phone }}</a></ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="map-time"></ion-icon>
              <ion-label>
                {{ 'topup.open_from' | translate }} {{ currentMarker?.workFrom }} {{ 'topup.till' | translate }} {{ currentMarker?.workTo }}
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
      </map-info-window>
    </map-marker>
  </google-map>
</div>
