import { Component, OnInit, Input } from '@angular/core';
import { ModalsService } from '../../modals/modals.service';



@Component({
  selector: 'app-top-up-fail',
  templateUrl: './top-up-fail.component.html',
  styleUrls: ['./top-up-fail.component.scss']
})
export class TopUpFailComponent implements OnInit {

  @Input() message?: string;

  constructor(
    private modals: ModalsService) {
  }

  ngOnInit() {}

  closeModal() {
    this.modals.closeModal();
  }

}
