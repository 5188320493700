<div class="PaymentReceipt customScrollbar">

  <ion-list class="PaymentReceipt-header">
    <ion-list-header>
      <ion-label *appPermission="[ roles.BCC_LEGAL_PERSON, roles.BCC_INDIVIDUAL_PERSON ]" class="PaymentReceipt-logo">{{ 'bonusCard.cachback.app_name' | translate }}</ion-label>
      <ion-label *appPermission="[ roles.GEKKARD ]" class="PaymentReceipt-logo">Gekkard</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-label class="ion-text-wrap">
        <p>№ {{ operation.id }}</p>
        <p>{{ operation.executedAt | date: 'dd.MM.yyyy' }}</p>
      </ion-label>
    </ion-item>
  </ion-list>

  <app-recipe-sender-info
    [operation]="operation"
    [operationType]="operationType">
  </app-recipe-sender-info>

  <app-recipe-beneficiary-info
    [operation]="operation"
    [operationType]="operationType">
  </app-recipe-beneficiary-info>

  <app-recipe-payment-info
    [operation]="operation"
    [operationType]="operationType">
  </app-recipe-payment-info>
  
  <ion-list>
    <ion-list-header>
      <ion-label>{{ 'receipt.thirteenth_field' | translate }}</ion-label>
    </ion-list-header>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap">
        <p class="PaymentReceipt-value">{{ operation.description }}</p>
      </ion-label>
    </ion-item>
  </ion-list>

  <div class="PaymentReceipt-status PaymentReceipt-status--completed">
    <span>{{ operation.status }}</span>
  </div>

  <!--<div class="PaymentReceipt-buttons">
    <ion-button expand="full" shape="round" fill="outline" color="dark">Save</ion-button>
  </div>-->

</div>
