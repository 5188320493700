import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-crypto-exchange-transaction-history-item',
  templateUrl: './crypto-exchange-transaction-history-item.component.html',
  styleUrls: ['./crypto-exchange-transaction-history-item.component.scss']
})
export class CryptoExchangeTransactionHistoryItemComponent implements OnInit {

  @Input() item;

  itemOpen = false;

  constructor() { }

  ngOnInit() {
  }

  getIntegerPart(param_price) {
    const price = Math.abs(param_price);
    if (param_price % 1 === 0) {
      return price;
    }
    const price1 = Math.abs(param_price).toString().replace('.', ',');
    return price1.substring(0, price1.indexOf(',') + 1);
  }

  getDecimalPart(param_price) {
    if (param_price % 1 === 0) {
      return '';
    }
    const price = param_price.toString();
    return price.substring(price.indexOf('.') + 1, price.length);
  }

  showFullInfo(e) {
    if (this.itemOpen) this.itemOpen = false;
    else this.itemOpen = true;
  }

}
