import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { DisableControlDirective } from './disable-control/disable-control.directive';
import { NumbersOnlyDirective } from './numbers-only/numbers-only.directive';
import { AnchorHostDirective } from './anchor-host/anchor-host.directive';

@NgModule({
   imports: [
      CommonModule,
      IonicModule,
   ],
   declarations: [
      DisableControlDirective,
      NumbersOnlyDirective,
      AnchorHostDirective
   ],
   exports: [
      DisableControlDirective,
      NumbersOnlyDirective,
      AnchorHostDirective
   ]
})
export class DirectivesModule {
}
