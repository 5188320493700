import { environment } from '../environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, pluck, shareReplay, switchMap } from 'rxjs/operators';
import { JSEncrypt } from 'jsencrypt';
import { AES, enc, PBKDF2, mode, format, pad, HmacSHA256 } from 'crypto-js';

interface ResponsePublicKey {
  publicKey: string;
}

interface ResponseRegisterApp {
  appUuid: string;
  secretePassword: string;
  salt: string;
}

interface Keypair {
  publicKey: string;
  privateKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppCryptoService {
  private serverPublicKey$: Observable<string> = this.requestPublicKey().pipe(
    pluck('publicKey'),
    shareReplay(1)
  );

  private localKeyPair$: Observable<Keypair> = of(AppCryptoService.getLocalRsaKeyPair()).pipe(
    shareReplay(1)
  );

  appCryptoConfiguration$: Observable<ResponseRegisterApp> = this.getPublicKeys().pipe(
    map(([serverKey, localKey]) => AppCryptoService.encryptRsaMessage(serverKey, localKey)),
    switchMap(encryptPublicKey => this.requestRegisterApp(encryptPublicKey)),
    shareReplay(1)
  );

  appDecryptedPassword$: Observable<string> = this.getDecryptedPassword(this.localKeyPair$, this.appCryptoConfiguration$);
  appCryptoUuid$: Observable<string> = this.getAppUuid(this.appCryptoConfiguration$, this.appDecryptedPassword$);

  private static encryptRsaMessage(key: string, message: string): string | false {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(key);
    return encrypt.encrypt(message);
  }

  private static decryptRsaMessage(message: string, key: string): string | false {
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(key);
    return decrypt.decrypt(message);
  }

  static ab2str(buf: ArrayBuffer): string {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  }

  static str2ab(str: string): ArrayBuffer {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  static getLocalRsaKeyPair(): Keypair {
    const encrypt = new JSEncrypt({ default_key_size: '1024' });
    const rsaLocalPrivateKey = encrypt.getPrivateKey()
      .replace('-----BEGIN RSA PRIVATE KEY-----\n', '')
      .replace('-----END RSA PRIVATE KEY-----', '')
      .replace(/\r?\n|\r/g, '');
    const rsaLocalPublicKey = encrypt.getPublicKey()
      .replace('-----BEGIN PUBLIC KEY-----\n', '')
      .replace('-----END PUBLIC KEY-----', '')
      .replace(/\r?\n|\r/g, '');
    return {
      publicKey: rsaLocalPublicKey,
      privateKey: rsaLocalPrivateKey
    };
  }

  static decryptAESv2(encryptedMessage: string, password: string, _salt: string): string {
    // console.log('decryptAESv2', { encryptedMessage, password, _salt });
    const salt = enc.Hex.parse(_salt);
    const key = PBKDF2(password, salt, {
      keySize: 256 / 32,
      iterations: 1024
    });
    const iv = enc.Hex.parse(encryptedMessage.substr(0, 32));
    const encrypted = enc.Hex.parse(encryptedMessage.substr(32, encryptedMessage.length));

    const decrypted =  AES.decrypt(encrypted.toString(), key, {
      iv,
      mode: mode.CBC,
      padding: pad.Pkcs7,
      format: format.Hex
    });
    // console.log({ encryptedMessage, encrypted, key, iv, decrypted });
    return decrypted.toString(enc.Utf8);
  }

  static generateJWT(data: any, key: string): string {
    const header = {
      'alg': 'HS256',
      'typ': 'JWT',
    };
    const encodedHeader = AppCryptoService.base64url(enc.Utf8.parse(JSON.stringify(header)));
    const encodedData = AppCryptoService.base64url(enc.Utf8.parse(JSON.stringify(data)));
    const token = encodedHeader + '.' + encodedData;
    const signature = AppCryptoService.base64url(HmacSHA256(token, key));
    return token + '.' + signature;
  }

  private static base64url(source): string {
    let encodedSource = enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
    return encodedSource;
  }

  constructor(private http: HttpClient) {}

  getPublicKeys(): Observable<[string, string]> {
    return forkJoin([
      this.serverPublicKey$,
      this.localKeyPair$.pipe(
        pluck('publicKey')
      )
    ]);
  }

  decryptAppSecretePassword(appCryptoConfig: ResponseRegisterApp): Observable<string> {
    return this.localKeyPair$.pipe(
      pluck('privateKey'),
      map(rsaPrivateKey => AppCryptoService.decryptRsaMessage(appCryptoConfig.secretePassword, rsaPrivateKey)),
      filter(value => value !== false),
      map(orgId => orgId as string),
    );
  }

  // decryptAppSecretePassword2(secretePassword: string, privateLocalKey: string): string {
  //   return AppCryptoService.decryptRsaMessage(secretePassword, privateLocalKey);
  // }

  // decryptAESv2Async(encryptedMessage: string, password: string, _salt: string): Observable<string> {
  //   return this.localKeyPair$.pipe(
  //     pluck('privateKey'),
  //     map(rsaPrivateKey => {
  //       const decryptedSecretKey = AppCryptoService.decryptRsaMessage(password, rsaPrivateKey);
  //       return AppCryptoService.decryptAESv2(encryptedMessage, decryptedSecretKey as string, _salt);
  //     })
  //   );
  // }

  getDecryptedPassword(localKeyPair$: Observable<Keypair>, appCryptoConfiguration$: Observable<ResponseRegisterApp>): Observable<string> {
    return forkJoin([
      localKeyPair$.pipe(
        pluck('privateKey')
      ),
      appCryptoConfiguration$.pipe(
        pluck('secretePassword')
      )
    ]).pipe(
      map(([rsaPrivateKey, password]) => AppCryptoService.decryptRsaMessage(password, rsaPrivateKey)),
      filter(value => value !== false),
      map(orgId => orgId as string),
    );
  }

  getAppUuid(cryptoConfiguration$: Observable<any>, secretPassword$: Observable<any>): Observable<string> {
    return forkJoin([
      cryptoConfiguration$,
      secretPassword$
    ]).pipe(
      map(([config, secretPassword]) => {
        return AppCryptoService.decryptAESv2(config.appUuid, secretPassword, config.salt);
      })
    );
  }

  requestPublicKey(): Observable<ResponsePublicKey> {
    const url = environment.baseApi + '/v1/public_key';
    return this.http.get<ResponsePublicKey>(url);
  }

  requestRegisterApp(publicKey): Observable<ResponseRegisterApp> {
    const url = environment.baseApi + '/v1/register_app';
    const body = { publicKey: publicKey };
    return this.http.post<ResponseRegisterApp>(url, body);
  }

  /* Native implementation */

  startCryptoLocalRsa(key: string): Observable<string> {
    let serverPublicKey!: any;
    return of(key).pipe(
      switchMap((serverCryptoKey) => {
        serverPublicKey = serverCryptoKey;
        return this.generateRSAKeyPair();
      }),
      switchMap(cryptoKeyPair => {
        return this.exportCryptoKey(cryptoKeyPair.publicKey);
      }),
      map(cryptoKey => {
        return this.exportCryptoKeyString(cryptoKey);
      }),
    );
  }

  generateRSAKeyPair(): PromiseLike<CryptoKeyPair> {
    console.log(777, new Uint8Array([1, 0, 1]));
    return window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 256, // Consider using a 4096-bit key for systems that require long-term security
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt']
    );
  }

  exportCryptoKey(key: CryptoKey): PromiseLike<ArrayBuffer> {
    return window.crypto.subtle.exportKey(
      'spki',
      key
    );
  }

  exportCryptoKeyString(key: ArrayBuffer): string {
    const exportedAsString = AppCryptoService.ab2str(key);
    const exportedAsBase64 = window.btoa(exportedAsString);
    // const pemExported = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;

    console.log({exportedAsString, exportedAsBase64});
    return exportedAsBase64;
  }

  encryptMessage(message: string, key: CryptoKey): PromiseLike<ArrayBuffer> {
    const encodedMessage = new TextEncoder().encode(message);
    return window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP'
      },
      key,
      encodedMessage
    );
  }

  importRsaKey(keyString: string): PromiseLike<CryptoKey> {
    // const pemHeader = '-----BEGIN PUBLIC KEY-----';
    // const pemFooter = '-----END PUBLIC KEY-----';
    // const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length);
    // console.log(666, pemContents);
    const binaryDerString = window.atob(keyString);
    // convert from a binary string to an ArrayBuffer
    const binaryDer = AppCryptoService.str2ab(binaryDerString);
    console.log(888, binaryDer);
    return window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256'
      },
      true,
      ['encrypt']
    );
  }
}
