import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

export type NotificationType = 'default' | 'info' | 'success' | 'warning' | 'error';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private notifier: NotifierService) {
  }

  notify(type: NotificationType, message: string): void {
    this.notifier.notify( type, message );
  }

  notifyServerError(message?: string): void {
    this.notify('error', message);
  }

}
