<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-title>History</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="History">
      <div class="History-panel customScrollbar ion-padding">
        <ng-container>
          <ion-list class="History-list" lines="full">
            <div class="History-data">
              <span>
                <ion-skeleton-text [animated]="true" style="width: 88px; margin: 0; height: 20px;"></ion-skeleton-text>
              </span>
            </div>
            <ion-item class="History-item">
              <div class="History-item__icon" slot="start"></div>
              <div class="History-item__header">
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-first" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-second" [animated]="true"></ion-skeleton-text>
                </div>
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-third" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-forth" [animated]="true"></ion-skeleton-text>
                </div>
              </div>
            </ion-item>
            <ion-item class="History-item">
              <div class="History-item__icon" slot="start"></div>
              <div class="History-item__header">
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-first" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-second" [animated]="true"></ion-skeleton-text>
                </div>
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-third" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-forth" [animated]="true"></ion-skeleton-text>
                </div>
              </div>
            </ion-item>
            <ion-item class="History-item">
              <div class="History-item__icon" slot="start"></div>
              <div class="History-item__header">
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-first" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-second" [animated]="true"></ion-skeleton-text>
                </div>
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-third" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-forth" [animated]="true"></ion-skeleton-text>
                </div>
              </div>
            </ion-item>
            <ion-item class="History-item">
              <div class="History-item__icon" slot="start"></div>
              <div class="History-item__header">
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-first" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-second" [animated]="true"></ion-skeleton-text>
                </div>
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-third" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-forth" [animated]="true"></ion-skeleton-text>
                </div>
              </div>
            </ion-item>
            <div class="History-data">
              <span>
                <ion-skeleton-text [animated]="true" style="width: 88px; margin: 0; height: 20px;"></ion-skeleton-text>
              </span>
            </div>
            <ion-item class="History-item">
              <div class="History-item__icon" slot="start"></div>
              <div class="History-item__header">
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-first" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-second" [animated]="true"></ion-skeleton-text>
                </div>
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-third" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-forth" [animated]="true"></ion-skeleton-text>
                </div>
              </div>
            </ion-item>
            <ion-item class="History-item">
              <div class="History-item__icon" slot="start"></div>
              <div class="History-item__header">
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-first" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-second" [animated]="true"></ion-skeleton-text>
                </div>
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-third" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-forth" [animated]="true"></ion-skeleton-text>
                </div>
              </div>
            </ion-item>
            <ion-item class="History-item">
              <div class="History-item__icon" slot="start"></div>
              <div class="History-item__header">
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-first" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-second" [animated]="true"></ion-skeleton-text>
                </div>
                <div class="History-item__header-content">
                  <ion-skeleton-text class="History-item__header-content-third" [animated]="true"></ion-skeleton-text>
                  <ion-skeleton-text class="History-item__header-content-forth" [animated]="true"></ion-skeleton-text>
                </div>
              </div>
            </ion-item>
          </ion-list>
        </ng-container>
      </div>
    </div>
  </ion-content>
</ion-app>

