import { environment } from 'environments/environment';

import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BccChatSupportService } from 'bcc-sdk/dist/bcc-chat-support';

import { BehaviorSubject, Observable, forkJoin, zip, of, throwError } from 'rxjs';
import { map, switchMap, filter, first, tap, flatMap, catchError } from 'rxjs/operators';

import { StorageService } from '../../services/storage/storage.service';
import { getPasswordHash, normalisePhoneNumber, DEV_TEST_PHONE_LIST, PROD_TEST_PHONE_LIST } from './auth.tools';
import { AuthFcmService } from './auth-fcm.service';
import { AuthUasService } from './auth-uas.service';

import { BankSessionIdResponse } from '../users.interface';
import { AppGtmService } from 'app/tools/app.gtm.service';

export type AuthorizationType = 'uas' | 'fire-base';
type AuthorizationTokenType = 'Token' | 'Token-firebase';

export class AuthConfirmResult {
  constructor(public phone: string, public token: string) {}
}

export interface AuthSuccessResponse {
  status: 'ok';
}

export const STORAGE_TOKEN_KEY = 'authToken';
const STORAGE_PHONE_KEY = 'authPhoneNumber';

@Injectable({
  providedIn: 'root'
})
export class AuthManagerService {
  private bankTokenSource = new BehaviorSubject<string>(null);
  bankToken$: Observable<string> = this.bankTokenSource.asObservable();

  authType: AuthorizationType = environment.authorizationType as AuthorizationType;
  authPhoneNumber?: string;

  private entryPoint!: ActivatedRouteSnapshot | null;

  static getAuthTokenType(authType: AuthorizationType): AuthorizationTokenType {
    return (authType === 'uas') ? 'Token' : 'Token-firebase';
  }

  private static checkPhoneForTestAccount(phoneNumber: string, testPhoneList: Array<string>): boolean {
    return testPhoneList.some(testPhoneNumber => +phoneNumber === +testPhoneNumber);
  }

  private static forcedSetEnvironmentSetting(): void {
    const devBaseApi = 'https://bgate-dev.star-bridge.lv/api';
    const devSupportChatSettings = {
      url: 'https://backend.support.stage.scnetservices.ru/api/v1/',
      brokerURL: 'wss://rabbit-tmp.support.stage.scnetservices.ru/ws',
    };
    environment.baseApi = devBaseApi;
    environment.supportChatSettings.url = devSupportChatSettings.url;
    environment.supportChatSettings.brokerURL = devSupportChatSettings.brokerURL;
    console.warn('System switched to dev API. Details you can see in AUTH MANAGER');
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService,
    private fbAuth: AuthFcmService,
    private uasAuth: AuthUasService,
    private zone: NgZone,
    private navCtrl: NavController,
    private chatService: BccChatSupportService,
    private gtmService: AppGtmService) {

    this.getStorageAuthData()
      .pipe(
        filter(([token, phone]) => !!token && !!phone)
      )
      .subscribe(
        ([token, phone]) => {
          this.setAuthorizationType(phone, this.authType);
          this.setAuthPhone(phone);
          this.setBankToken(token);
        },
        error => console.error(error)
      );
  }

  userIsAuth(): Observable<boolean> {
    return this.bankToken$.pipe(
      first(),
      map(token => !!token)
    );
  }

  setAuthData(value: AuthConfirmResult): void {
    this.setAuthPhone(value.phone);
    this.setBankToken(value.token);
    this.setStorageAuthData(value.token, value.phone) // TODO need review many call
      .subscribe((val) => {
        console.log('User Authorized!', val);
        this.gtmService.sendGtag('event', 'login');
      });
  }

  getBankToken(): Observable<string> {
    return this.bankToken$.pipe(
      first()
    );
  }

  generateToken(authType: AuthorizationType): Observable<string> {
    console.log('Start token generation process');
    if (authType === 'fire-base') {
      return this.fbAuth.getAuthState().pipe(
        filter(fbUser => {
          if (!fbUser) {
            this.logout().subscribe(() => console.warn('Not found fbUser model'));
          }
          return !!fbUser;
        }),
        flatMap(fbUser => fbUser.getIdToken(true)),
      );
    } else {
      of(null);
    }
  }

  private setBankToken(token: string): void {
    this.bankTokenSource.next(token);
  }

  private setAuthPhone(phoneNumber: string): void {
    this.authPhoneNumber = (phoneNumber) ? normalisePhoneNumber(phoneNumber) : null;
  }

  private setAuthorizationType(phoneNumber: string, type: AuthorizationType): AuthorizationType {
    const isTestedAccount = (
      AuthManagerService.checkPhoneForTestAccount(phoneNumber, DEV_TEST_PHONE_LIST)
      || AuthManagerService.checkPhoneForTestAccount(phoneNumber, PROD_TEST_PHONE_LIST)
    );
    this.authType = isTestedAccount ? 'uas' : type;
    return this.authType;
  }

  initAuthorization(phoneNumber: string, password: string, authType: AuthorizationType): Observable<BankSessionIdResponse> {
    if (environment.production && AuthManagerService.checkPhoneForTestAccount(phoneNumber, PROD_TEST_PHONE_LIST)) {
      AuthManagerService.forcedSetEnvironmentSetting();
    }
    return this.checkPassword(phoneNumber, password).pipe(
      filter(response => response.status === 'ok'),
      switchMap(() => this.startAuth(phoneNumber, password, authType))
    );
  }

  checkPassword(phoneNumber: string, password: string): Observable<AuthSuccessResponse> {
    const url = environment.baseApi + `/v1/password/check`;
    const body = {
      phone: phoneNumber,
      password: getPasswordHash(phoneNumber, password)
    };
    return this.http.post<AuthSuccessResponse>(url, body);
  }

  verifyPassword(phoneNumber: string, password: string, authToken?: string): Observable<AuthSuccessResponse> {
    const url = environment.baseApi + '/v1/password/verify';
    const body = {
      password: getPasswordHash(phoneNumber, password)
    };
    let headers = new HttpHeaders();
    if (authToken) {
      const tokenType = AuthManagerService.getAuthTokenType(this.authType);
      headers = headers.set('Authorization', phoneNumber.replace('+', ''));
      headers = headers.set(tokenType, authToken);
    }
    return this.http.post<AuthSuccessResponse>(url, body, { headers });
  }

  startAuth(phoneNumber: string, password?: string, authType?: AuthorizationType): Observable<BankSessionIdResponse> {
    const authorizationType = this.setAuthorizationType(phoneNumber, authType);
    this.setAuthPhone(phoneNumber);
    return this.getLoginCode(phoneNumber, password, authorizationType);
  }

  getLoginCode(phoneNumber: string, password?: string, authType?: AuthorizationType): Observable<BankSessionIdResponse> {
    if (!authType) { authType = this.authType; }
    return (authType === 'fire-base')
      ? this.fbAuth.getLoginCode(phoneNumber)
      : this.uasAuth.uasGetSessionId(normalisePhoneNumber(phoneNumber));
  }

  confirmAuth(phoneNumber: string, code: string, sessionId?: string, password?: string, authType?: string): Observable<AuthConfirmResult> {
    if (!authType) {
      authType = this.authType;
    }

    return (authType === 'fire-base')
      ? this.fbAuth.verifyLoginCode(code).pipe(
          switchMap((generatedToken) => {
            return this.verifyPassword(phoneNumber, password, generatedToken).pipe(
              map(() => new AuthConfirmResult(phoneNumber, generatedToken)),
              catchError((error) => {
                if (error.status === 406) {
                  return of(new AuthConfirmResult(phoneNumber, generatedToken));
                }
                return throwError(error);
              })
            );
          }),
          catchError((error) => throwError(error)),
        )
      : this.uasAuth.verifyLoginCode(normalisePhoneNumber(phoneNumber), code, sessionId, password).pipe(
          map(response => new AuthConfirmResult(response.phone, response.token))
        );
  }

  authCompletion(value: AuthConfirmResult) {
    this.setAuthData(value);
    (this.entryPoint)
      ? this.navCtrl.navigateRoot([this.entryPoint.routeConfig.path], { queryParams: { ...this.entryPoint.queryParams } })
      : this.navigateToHomePage();
  }

  navigateToLoginConfirm(phoneNumber: string, sessionId: string, password: string): Promise<void> {
    return this.zone.run(async () => {
      await this.router.navigate(['/login/confirm'], {
        queryParams: {
          phone: phoneNumber,
          session: sessionId,
        },
        state: { password }
      });
    });
  }

  navigateToHomePage(): Promise<boolean> {
    return this.navCtrl.navigateRoot(['/money/detail']);
  }

  navigateToPinPage(phoneNumber: string, password: string): Promise<boolean> {
    return this.navCtrl.navigateForward(['/login/pin'], { queryParams: { phone: phoneNumber }, state: { password } });
  }

  navigateToSignInPage(): Promise<boolean> {
    return this.navCtrl.navigateBack(['login/signin']);
  }

  private getStorageAuthData(): Observable<[string, string]> {
    return zip(
      this.storage.get(STORAGE_TOKEN_KEY),
      this.storage.get(STORAGE_PHONE_KEY)
    );
  }

  private setStorageAuthData(token: string, phoneNumber: string): Observable<[string, string]> {
    return forkJoin([
      this.storage.set(STORAGE_TOKEN_KEY, token),
      this.storage.set(STORAGE_PHONE_KEY, phoneNumber)
    ]);
  }

  getIsStoredToken(): Promise<string> {
    return this.storage.getValueAsPromise(STORAGE_TOKEN_KEY);
  }

  logout(): Observable<void> {
    return this.storage.resetStorage().pipe(
      tap(() => {
        if (this.authType === 'fire-base') {
          this.fbAuth.logout();
        }
        this.setBankToken(null);
        this.setAuthPhone(null);
        this.setEntryPoint(null);
        // Also need clear organization and operation items in local Storage
        this.chatService.logout();
        this.navCtrl.navigateRoot(['/login']); // temporary solution, need integrate angular native outlet for first level pages
      })
    );
  }

  setEntryPoint(point: ActivatedRouteSnapshot | null): ActivatedRouteSnapshot {
    this.entryPoint = point;
    return point;
  }

}
