import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { MarkerPoint } from '../../../top-up-account.interface';

@Component({
  selector: 'app-top-up-map',
  templateUrl: './top-up-map.component.html',
  styleUrls: ['./top-up-map.component.scss']
})
export class TopUpMapComponent {
  @ViewChild(GoogleMap, { static: true }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  @Input() markers: any;
  @Input() center!: google.maps.LatLngLiteral;
  @Input() isLoading!: Observable<boolean>;

  @Output() goBackEmit = new EventEmitter<string>();

  zoom = 12;
  markerOptions = {
    draggable: false,
    icon: '/assets/icon/icon-marker.png'
  };
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    zoomControl: true
  };
  currentMarker!: MarkerPoint;

  constructor() {}

  openInfo(marker: MapMarker, currentMarker: MarkerPoint) {
    this.currentMarker = currentMarker;
    this.infoWindow.open(marker);
  }

  goBack() {
    this.goBackEmit.emit('Code');
  }
}
