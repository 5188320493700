/**
 * Only numbers
 */
export const regexpOnlyNumbers = /^[0-9]*$/;

/**
 * Latin letters
 * Symbols: space
 */
export const regexpLatinLettersWithSpace = /^[a-zA-z\s]*$/;

/**
 * Latin letters
 * Symbols: space
 * Numbers 0-9
 */
export const regexpNumbersAndLatinLettersWithSpace = /^[0-9a-zA-z\s]*$/;

export const regexpFloatingPointNumber = /^\d+(?:[\.]\d{1,2})?$/;

export const regexpNumbersAndLatin = /^[a-z0-9]*$/i;
// export const regexpNumbersAndLatin = /^[a-zA-z0-9]*$/;

/**
 * Latin letters
 * Numbers 0-9
 * Symbols: space _ . , - “ ” ‘ ` ' ’ ( ) & # / + «» " №
 */
export const regexpAddress = /^[a-zA-Z0-9/\-\s_.,“”‘`'’()&#+space«»"№]+$/;

/**
 * Latin letters
 * Numbers 0-9
 * Symbols: space \ - ? : ( ) . , + ; ' " ‘ “
 */
export const regexpTransferDescription = /^[a-zA-Z0-9/\-?:().,‘+;"“”’ ]+$/;

/**
 * Latin letters
 * Symbols: space
 * Numbers
 */
export const regexpBeneficiaryName = /^(?!\s)[a-zA-Z0-9\-\s:().,+;]+$/;

/**
 * Latin letters
 * Symbols: space . -
 * Numbers
 */
export const regexpCardHolder = /^(?!\s)[a-zA-Z0-9\s-.]+$/;

/**
 * Phone number
 */
export const regexPhoneNumber = /^\+?[0-9]*$/;

/**
 * Email
 */
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
