import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TOP_UP_PATH } from '../top-up-account.interface';

@Component({
  selector: 'app-top-up-instruction',
  templateUrl: './top-up-instruction.component.html',
  styleUrls: ['./top-up-instruction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopUpInstructionComponent {
  readonly topUpPath = TOP_UP_PATH;

  constructor(private router: Router) {}

  goBack() {
    this.router.navigate(['/','top-up-account', this.topUpPath.transfer], {queryParams: {fromInstruction: 1}})
  }
}
