import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PopoverController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar-single-select',
  templateUrl: './calendar-single-select.component.html',
  styleUrls: ['./calendar-single-select.component.scss']
})
export class CalendarSingleSelectComponent implements OnInit {
  from = '2015-02-01';
  to = this.transformDate(new Date());
  selectedDate: string;

  constructor(
    private datePipe: DatePipe,
    private popoverController: PopoverController,
    private translate: TranslateService) {
  }

  ngOnInit() {
  }

  onSelect() {
    const date = this.transformDate(this.selectedDate);
    this.popoverController.dismiss(date);
  }

  onCancel() {
    this.popoverController.dismiss();
  }

  private transformDate(date: string | Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

}
