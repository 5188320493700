import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MoneyOperation } from 'app/users/operations/operations.interface';

export type RightComponentName = 'movement-money-history'
  | 'movement-money-history-recipe'
  | 'send-money-history'
  | 'exchange-money-history'
  | 'transfer-card-templates'
  | 'transfer-card-template-edit'
  | 'transfer-bank-templates'
  | 'transfer-contact-templates'
  | 'transfers-template-edit'
  | 'export-statements'
  | 'print-statements'
  | 'history-filter'
  | 'crypto-transaction-history'
  | 'crypto-transaction-history-recipe'
  | 'crypto-exchange-transaction-history'
  | 'crypto-send-internal-templates'
  | 'info-crypto-confirmation'
  | 'info-crypto-receive'
  | 'info-crypto-deposit'
  | 'info-crypto-repo'
  | 'info-crypto-repo-confirmation'
  | 'info-transfer-to-contact'
  | 'info-transfer-confirmation'
  | 'currency-exchange-confirmation'
  | 'info-currency-exchange-confirmation'
  | 'app-crypto-skeleton';

export interface DateRange {
  from: number;
  to: number;
}

export interface ContainerDataModel {
  component: RightComponentName;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class RightContainerService {

  private readonly rightContainerSource = new Subject<RightComponentName>();
  rightContainer$: Observable<RightComponentName> = this.rightContainerSource.asObservable();

  private readonly activeDataSource = new BehaviorSubject<ContainerDataModel>(null);
  activeData$: Observable<ContainerDataModel> = this.activeDataSource.asObservable();

  paymentReceiptItem: MoneyOperation; // temp property, need switch on async activeData

  constructor() { }

  setRightPanel(componentName: RightComponentName, data?: any): void {
    if (typeof data !== 'undefined') {
      this.setActiveData(componentName, data);
    }
    this.rightContainerSource.next(componentName);
  }

  setActiveData(componentName: RightComponentName, data: any): void {
    // console.log('setActiveData', data);
    this.activeDataSource.next({ component: componentName, data: data });
  }

  getActiveData(componentName: RightComponentName): Observable<any> {
    return this.activeData$.pipe(
      filter(activeData => activeData && activeData.component === componentName),
      map(activeData => activeData.data)
    );
  }

}
