import { Component, Input, OnInit } from '@angular/core';
import { HistoryOperationType, MoneyOperation } from '../../../../../users/operations/operations.interface';
import { Country, CountryService } from '../../../../../services/country/country.service';
import { concatAll, filter, map, pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-recipe-beneficiary-info',
  templateUrl: './recipe-beneficiary-info.component.html',
  styleUrls: ['./recipe-beneficiary-info.component.scss']
})
export class RecipeBeneficiaryInfoComponent implements OnInit {

  @Input() operation?: MoneyOperation;
  @Input() operationType?: HistoryOperationType;

  activeCountryName = this.country.getCountryList().pipe(
    concatAll(),
    filter(country => country.code === this.operation?.paymentToRepeat.country),
    pluck('name')
  );

  constructor(
    private country: CountryService
  ) { }

  ngOnInit() {}

}
