<section class="wrapper" [ngClass]="customClass">
  <div *ngIf="accountListIsEmpty" class="empty-curtain"></div>

  <app-select-custom
    [customHeight]="customHeight"
    [customClass]="customClass"
    [isDisable]="disableClientSelector"
    [hasErrors]="hasErrors"
    [class.isSelected]="selectedAccountClient">
      <label>
        <app-client-chip
          *ngIf="selectedAccountClient"
          [client]="selectedAccountClient">
        </app-client-chip>
        <span *ngIf="!selectedAccountClient">
          {{ labelName }}
        </span>
      </label>
      <div class="placeholder">
        <span>
          {{ (selectedAccount) ? (selectedAccount.number | stringClipping:clippingArgumentForLabel) : 'CryptoExchange.PayFrom.SelectAccount' | translate }}
        </span>
      </div>
      <div *ngFor="let client of activeClientList" class="select-group">
        <div class="select-group-chip">
          <app-client-chip
            *ngIf="client.accounts.length"
            [client]="client">
          </app-client-chip>
        </div>
        <div *ngFor="let account of client.accounts"
          (click)="onSetAccount(account)"
          class="select-item"
          [class.select-item--blocked]="account.status === 'BLOCKED'">
            <span>{{ account.number | stringClipping:clippingArgumentForList }}</span>
            <span *ngIf="account && account.currency" class="currency" >
              <span *ngIf="account.status === 'BLOCKED'">({{ 'account.third_text' | translate }})</span>
              <span *ngFor="let balance of account.balances; last as isLast">
                <ng-container>{{ balance.currency }}</ng-container>
                <ng-container *ngIf="!isLast">, </ng-container>
              </span>
            </span>
        </div>
      </div>
  </app-select-custom>

</section>
