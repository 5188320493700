import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-top-up-success-code',
    templateUrl: './top-up-success-code.component.html',
    styleUrls: ['./top-up-success-code.component.scss']
})
export class TopUpSuccessCodeComponent {
    @Input() topupAmount?: number;
    @Input() topupCurrency?: string;
    @Output() goBackEmit = new EventEmitter<string>();
    @Output() submitEmit = new EventEmitter<string>();

    constructor() {
    }

    goBack() {
        this.goBackEmit.emit('Code');
    }

    onSubmit() {
        this.submitEmit.emit('Succeess');
    }
}
