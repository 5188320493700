import { Injectable } from '@angular/core';
import { Storage as IonicStorage } from '@ionic/storage-angular';

import { Observable, from as observableFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private ionicStorage: IonicStorage) {
    this.ionicStorage.create();
  }

  set(key: string, value: any): Observable<any> {
    return observableFrom(
      this.ionicStorage.set(key, value)
    );
  }

  get(key: string): Observable<any> {
    return observableFrom(
      this.ionicStorage.get(key)
    );
  }

  getValueAsPromise(key: string): Promise<any> {
    return this.ionicStorage.get(key);
  }

  remove(key: string): Observable<any> {
    return observableFrom(
      this.ionicStorage.remove(key)
    );
  }

  resetStorage(): Observable<void> {
    return observableFrom(
        this.ionicStorage.clear()
    );
  }

}
