import {
  Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter,
  ChangeDetectionStrategy, ChangeDetectorRef, HostListener, QueryList, ViewChildren, AfterViewInit, ElementRef
} from '@angular/core';

import { Observable } from 'rxjs';

import { CountryService, Country } from '../../../services/country/country.service';
@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectComponent implements OnInit, OnChanges {

  @Input() findCountry?: string;
  @Input() showCountryCode = true;
  @Output() activeCountry = new EventEmitter<Country>();
  @ViewChildren('countries') countries: QueryList<ElementRef>;

  countryList?: Array<Country>;
  activeCountryCode?: number;
  selectedCountry: Country;

  @HostListener('document:keydown', ['$event'])
  keydown(e: KeyboardEvent) {
    if (e.key === 'ArrowDown') {
      this.selectNextItem(true);
    }
    if (e.key === 'ArrowUp') {
      this.selectNextItem(false);
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private country: CountryService) {
  }

  ngOnInit() {
  }

  selectNextItem(forward: boolean) {

    if (!this.countryList) {
      return;
    }

    let nextCountry: Country;

    if (!this.selectedCountry) {
      nextCountry = this.countryList[0];
    } else {

      const index = this.countryList.findIndex(x => x.code === this.selectedCountry.code);

      if (index < this.countryList.length && index >= 0) {
        nextCountry = this.countryList[index + (forward ? 1 : -1)];

        const element = this.countries.toArray()[index].nativeElement;
        element.scrollIntoView(forward);
      }
    }

    if (nextCountry) {
      this.setActiveCountry(nextCountry);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('findCountry')) {
      if (changes['findCountry'].currentValue) {
        this.getCountryList(this.findCountry)
          .subscribe(countryList => this.countryList = countryList);
      } else {
        this.country.getCountryList()
          .subscribe(countryList => {
            this.countryList = countryList;
            this.cdr.markForCheck();
          });
      }
    }
  }

  setActiveCountry(country: Country) {
    this.activeCountryCode = country.dialCode;
    this.activeCountry.emit(country);
    this.selectedCountry = country;
  }

  private getCountryList(value: string): Observable<Country[]> {
    const findValue = (Number.isNaN(+value)) ? value : +value;
    return (typeof findValue === 'string')
      ? this.country.getCountryListByName(findValue)
      : this.country.getCountryListByDialCode(findValue);
  }

}
