import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { CalendarSingleSelectComponent } from './calendar-single-select/calendar-single-select.component';

@NgModule({
  declarations: [
    CalendarSingleSelectComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
})
export class CalendarsModule {

}
