import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CardStatusType, CardLimitType, Card } from '../users/organizations.interface';
import { ConfirmWithCodeResponse, SetCardLimitRequest, CardUnmaskResponse, SetStatusCardRequest } from 'models/card.interfaces';

@Injectable({
  providedIn: 'root'
})
export class MoneyService {

  private isLoadingSource = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSource.asObservable();

  static getHiddenCardNumber(cardNumber: string): string {
    const separatedValue = cardNumber
      .replace(/\s+/g, '')
      .split('_');
    const [ firstParts, lastPart ]: [ RegExpMatchArray, string ] = [ separatedValue[0].match(/.{1,4}/g), separatedValue[1] ];
    const result = firstParts.concat(lastPart);

    return [ result[0], result[1] + '**', '****', result[2] ]
      .join(' ');
  }

  private static getSetCardLimitRequestBody(limitType: CardLimitType, value: string): SetCardLimitRequest {
    return {
      card: {
        limits: [ { type: limitType, maxValue: +value } ]
      }
    };
  }

  constructor(
    private http: HttpClient) {
  }

  getCardInfo(cardId: string): Observable<CardUnmaskResponse> {
    const url = environment.baseApi + `/v1/cards/${cardId}/unmask`;
    this.setLoadingStatus(true);
    return this.http.get<CardUnmaskResponse>(url).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }


  setLimitForCard(cardId: string, limitType: CardLimitType, value: string): Observable<ConfirmWithCodeResponse | Card> {
    const url = environment.baseApi + `/v2/cards/${cardId}`;
    const body: SetCardLimitRequest = MoneyService.getSetCardLimitRequestBody(limitType, value);
    this.setLoadingStatus(true);
    return this.http.patch<ConfirmWithCodeResponse | Card>(url, body).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  confirmLimitForCard(token: string, smsCode: number, cardId: string, limitType: CardLimitType, value: string): Observable<Card> {
    console.log(token, smsCode, cardId, limitType, value);
    const url = environment.baseApi + `/v2/cards/${cardId}`;
    const headers = new HttpHeaders({
      'X-Confirmation-Code': smsCode.toString(),
      'X-Confirmation-Token': token
    });
    const body: SetCardLimitRequest = MoneyService.getSetCardLimitRequestBody(limitType, value);
    this.setLoadingStatus(true);
    return this.http.patch<Card>(url, body, { headers }).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  disableLimitsForCard(cardId: string): Observable<ConfirmWithCodeResponse> {
    const url = environment.baseApi + `/v2/cards/${cardId}`;
    const body = {
      card: {
        options: {
          limits: { disable: true }
        }
      }
    };
    this.setLoadingStatus(true);
    return this.http.patch<ConfirmWithCodeResponse>(url, body).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  confirmDisableLimitsForCard(cardId: string, token: string, smsCode: number): Observable<Card> {
    const url = environment.baseApi + `/v2/cards/${cardId}`;
    const headers = new HttpHeaders({
      'X-Confirmation-Code': smsCode.toString(),
      'X-Confirmation-Token': token
    });
    const body = {
      card: {
        options: {
          limits: { disable: true }
        }
      }
    };
    this.setLoadingStatus(true);
    return this.http.patch<Card>(url, body, { headers }).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  setCardBlockStatus(cardId: string): Observable<Card> {
    return this.setCardStatus(cardId, 'LOCKED') as Observable<Card>;
  }

  setCardUnblockStatus(cardId: string): Observable<ConfirmWithCodeResponse> {
    return this.setCardStatus(cardId, 'ACTIVE') as Observable<ConfirmWithCodeResponse>;
  }

  confirmCardUnblockStatus(cardId: string, token: string, smsCode: string): Observable<Card> {
    const headers = new HttpHeaders({
      'X-Confirmation-Code': smsCode,
      'X-Confirmation-Token': token
    });
    return this.setCardStatus(cardId, 'ACTIVE', headers) as Observable<Card>;
  }

  private setCardStatus(cardId: string, status: CardStatusType, headers?: HttpHeaders): Observable<Card | ConfirmWithCodeResponse> {
    const url = environment.baseApi + `/v2/cards/${cardId}`;
    const body: SetStatusCardRequest = {
      card: { status: status }
    };
    this.setLoadingStatus(true);
    return this.http.patch<Card>(url, body, (headers) ? { headers } : {}).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  private setLoadingStatus(predicate: boolean): void {
    this.isLoadingSource.next(predicate);
  }

}
