import { Component, OnInit } from '@angular/core';

import { ModalsService } from '../../modals.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  constructor(
    private modals: ModalsService) {
  }

  ngOnInit() {
  }

  closeModal(confirm?: boolean) {
    this.modals.closeModal(confirm || null);
  }

}
