import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DatePipe } from '@angular/common';

import { MultiClientsModule } from '../ui-elements/multi-clients/multi-clients.module';

import { TransfersTemplatesModule } from './transfers-templates/transfers-templates.module';
import { CryptoTemplatesModule } from './crypto-templates/crypto-templates.module';
import { TransactionHistoryModule } from './transaction-history/transaction-history.module';
import { TransfersHistoryModule } from './transfers-history/transfers-history.module';

import { RightContainerComponent } from './right-container.component';

import { InfoCryptoConfirmationComponent } from './info-crypto-confirmation/info-crypto-confirmation.component';
import { InfoCryptoReceiveComponent } from './info-crypto-receive/info-crypto-receive.component';
import { InfoCryptoDepositComponent } from './info-crypto-deposit/info-crypto-deposit.component';
import { InfoCryptoRepoComponent } from './info-crypto-repo/info-crypto-repo.component';
import { InfoCryptoRepoConfirmationComponent } from './info-crypto-repo-confirmation/info-crypto-repo-confirmation.component';

import { InfoTransferToContactComponent } from './info-transfer-to-contact/info-transfer-to-contact.component';
import { InfoTransferConfirmationComponent } from './info-transfer-confirmation/info-transfer-confirmation.component';

// tslint:disable:max-line-length
import { InfoCurrencyExchangeConfirmationComponent } from './info-currency-exchange-confirmation/info-currency-exchange-confirmation.component';
import { InternationalizationModule } from '../internationalization/internationalization.module';
import { UsersModule } from '../users/users.module';
import { ComponentsModule } from 'app/+crypto/components/components.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    IonicModule,
    MultiClientsModule,
    TransfersTemplatesModule,
    TransactionHistoryModule,
    CryptoTemplatesModule,
    TransfersHistoryModule,
    InternationalizationModule,
    UsersModule,
    ComponentsModule
  ],
  declarations: [
    RightContainerComponent,
    InfoCryptoConfirmationComponent,
    InfoCryptoReceiveComponent,
    InfoCryptoDepositComponent,
    InfoCryptoRepoComponent,
    InfoCryptoRepoConfirmationComponent,
    InfoTransferToContactComponent,
    InfoTransferConfirmationComponent,
    InfoCurrencyExchangeConfirmationComponent
  ],
  exports: [
    RightContainerComponent
  ],
  providers: [
    DatePipe
  ]
})
export class RightContainerModule { }
