import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { IsLoadingModule } from '../../ui-elements/is-loading/is-loading.module';
import { MultiClientsModule } from '../../ui-elements/multi-clients/multi-clients.module';
import { TransferRecipeModule } from '../../ui-elements/transfer-recipe/transfer-recipe.module';
import { CalendarsModule } from '../../ui-elements/calendars/calendars.module';

/* tslint:disable:max-line-length */
import { SendMoneyHistoryComponent } from './send-money-history/send-money-history.component';
import { SendMoneyHistoryItemComponent } from './send-money-history/send-money-history-item/send-money-history-item.component';

import { MovementMoneyHistoryComponent } from './movement-money-history/movement-money-history.component';
import { MovementMoneyHistoryItemComponent } from './movement-money-history/movement-money-history-item/movement-money-history-item.component';
import { MovementMoneyHistoryRecipeComponent } from './movement-money-history/movement-money-history-recipe/movement-money-history-recipe.component';
import { MovementMoneySkeletonComponent } from './movement-money-history/movement-money-skeleton/movement-money-skeleton.component';

import { ExchangeMoneyHistoryComponent } from '../exchange-money-history/exchange-money-history.component';
import { ExchangeMoneyHistoryItemComponent } from '../exchange-money-history/exchange-money-history-item/exchange-money-history-item.component';

import { PrintStatementsComponent } from './print-statements/print-statements.component';
import { ExportStatementsComponent } from './export-statements/export-statements.component';
import { ExchangeMoneySkeletonComponent } from '../exchange-money-history/exchange-money-skeleton/exchange-money-skeleton.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
// import { HistoryFilterComponent } from './history-filter/history-filter.component';

@NgModule({
  declarations: [
    SendMoneyHistoryComponent,
    SendMoneyHistoryItemComponent,
    MovementMoneyHistoryComponent,
    MovementMoneyHistoryItemComponent,
    MovementMoneyHistoryRecipeComponent,
    MovementMoneySkeletonComponent,
    ExchangeMoneyHistoryComponent,
    ExchangeMoneyHistoryItemComponent,
    PrintStatementsComponent,
    ExportStatementsComponent,
    ExchangeMoneySkeletonComponent,
    // HistoryFilterComponent
  ],
  exports: [
    SendMoneyHistoryComponent,
    MovementMoneyHistoryComponent,
    MovementMoneyHistoryRecipeComponent,
    MovementMoneySkeletonComponent,
    ExchangeMoneyHistoryComponent,
    PrintStatementsComponent,
    ExportStatementsComponent,
    // HistoryFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    IsLoadingModule,
    MultiClientsModule,
    TransferRecipeModule,
    CalendarsModule,
    InternationalizationModule
  ]
})
export class TransfersHistoryModule { }
