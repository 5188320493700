import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validateBeforeComma(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const regex = new RegExp(`^[^,\\.]{0,${maxLength}}(?=[,\\.]|$)`);
    return regex.test(value) ? null : { validateBeforeComma: { valid: false } };
  };
}

export function validateAfterComma(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const regex = new RegExp(/[.,]\d{3,}/);
    return regex.test(value) ?  { validateAfterComma: true } : null;
  };
}
