import { Component, Input, Inject, ChangeDetectionStrategy, signal } from '@angular/core';
import { Account } from '../../users/organizations.interface';
import { WINDOW } from '../../window.providers';

@Component({
  selector: 'app-top-up-iternal-payment',
  templateUrl: './top-up-iternal-payment.component.html',
  styleUrls: ['./top-up-iternal-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopUpIternalPaymentComponent {
  @Input() activeAccount!: Account;

  itWasCopied = signal(false);

  constructor(
    @Inject(WINDOW) private window: Window,
  ) {
  }


  copyToClipboard() {
    if (this.activeAccount) {
      const details =
        `
        Beneficiary's Name: ${ this.activeAccount.clientName || this.activeAccount.clientTitle}
        Beneficiary's bank: Papaya Ltd
        Beneficiary's IBAN: ${this.activeAccount.number}
        `;

      this.window.navigator['clipboard'].writeText(details).then(() => {
        this.itWasCopied.set(true);
        setTimeout(() => {
          this.itWasCopied.set(false);
        }, 3000)
      })
    }
  }
}
