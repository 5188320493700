import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { RightContainerService } from '../../right-container.service';
import { OperationsService } from '../../../users/operations/operations.service';


@Component({
  selector: 'app-crypto-transaction-history',
  templateUrl: './crypto-transaction-history.component.html',
  styleUrls: ['./crypto-transaction-history.component.scss']
})
export class CryptoTransactionHistoryComponent implements OnInit, OnDestroy {

  /*@Input() history;
  @Input() operations;
  @Input() prevFrom;
  @Input() prevTo;
  @Output() sendDateRange = new EventEmitter();
  @Output() sendHistory = new EventEmitter();
  @Output() sendOperations = new EventEmitter();*/
  @Input() from;
  @Input() to;
  @Input() fromAsTimestamp;
  @Input() toAsTimestamp;

  activeFilter = false;
  balanceOpen = false;
  loading = false;
  pageNumber = 0;
  expenses = 0;
  incomes = 0;
  balance = 0;
  history = [];
  operations = [];
  operationsSubscription: Subscription;
  routeParamsSubscription: Subscription;

  optionsSelectTotal: any = {
    cssClass: 'HistoryFilterTotal'
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rightContainer: RightContainerService,
    private opService: OperationsService
  ) { }

  ngOnInit() {
    this.routeParamsSubscription = this.route.queryParams.subscribe((params) => {
      if (params.filterOpen) {
        this.activeFilter = true;
      }
    });

    if (this.from || this.to) {
      this.activeFilter = true;
    }

    // this.loadHistory();
  }

  ngOnDestroy() {
    /*this.sendDateRange.emit({ from: this.from, to: this.to });
    this.sendHistory.emit(this.history);
    this.sendOperations.emit(this.operations);*/

    if (this.operationsSubscription) {
      this.operationsSubscription.unsubscribe();
    }

    this.routeParamsSubscription.unsubscribe();
  }

  openExportStatements(): void {
    this.rightContainer.setRightPanel('export-statements');
  }

  openHistoryFilter(): void {
    this.rightContainer.setRightPanel('history-filter');
  }

  /*loadHistory() {
    /!*if (this.prevFrom && this.prevTo && this.prevFrom === this.from && this.prevTo === this.to) {
      return;
    }*!/
    this.loading = true;
    if (this.from && this.to) {
      this.pageNumber = 0;
      this.operationsSubscription = this.opService.requestOperations(this.from, this.to, this.pageNumber, 100)
        .subscribe({
          next: v => {
            this.loading = false;
            this.operations = v;
            this.calculateBalance(v);
            this.history = v ? this.opService.getModifiedOperations(v) : this.history;
          },
          error: e => {
            console.log(e);
            this.loading = false;
          }
        });
      return;
    }
    this.operationsSubscription = this.opService.operations$.subscribe({
      next: v => {
        this.operations = v;
        this.history = v ? this.opService.getModifiedOperations(v) : [];
        this.loading = false;
      },
      error: e => {
        console.log(e);
        this.loading = false;
      }
    });
  }*/

  /*loadMore(infiniteScroll) {
    if (this.loading) {
      return;
    }
    if (this.operationsSubscription) {
      this.operationsSubscription.unsubscribe();
    }
    this.loading = true;
    ++this.pageNumber;
    this.operationsSubscription = this.opService.requestOperations(
      this.from ? this.from : '', this.to ? this.to : '', this.pageNumber, 100
    ).subscribe({
      next: ops => {
        this.operations = this.opService.concatOperations([...this.operations], [...ops]);
        this.history = this.opService.getModifiedOperations(this.operations);
        this.history = this.opService.finalSorting(this.history);
        this.loading = false;
        infiniteScroll.target.complete();
      },
      error: e => {
        console.log(e);
        --this.pageNumber;
        this.loading = false;
        infiniteScroll.target.complete();
      }
    });
  }*/

  calculateBalance(ops) {
    /*ops.forEach(o => { o.price < 0 ? this.expenses += o.price : this.incomes += o.price});
    this.balance = this.incomes + this.expenses;*/
  }

  openBalancePanel() {
    this.balanceOpen = !this.balanceOpen;
  }

}
