import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crypto-exchange-transaction-history',
  templateUrl: './crypto-exchange-transaction-history.component.html',
  styleUrls: ['./crypto-exchange-transaction-history.component.scss']
})
export class CryptoExchangeTransactionHistoryComponent implements OnInit {

  history = [
    {
      'date': '21.12.2018',
      'operations': [
        {
          'id': 'PPY12049',
          'currency': {
            'codeFrom': 'EUR',
            'codeTo': 'CHF'
          },
          'name': '1 EUR ≈ 1,16 CHF',
          'priceFrom': -1000.00,
          'priceTo': +1163.10
        }, {
          'id': 'PPY12048',
          'currency': {
            'codeFrom': 'EUR',
            'codeTo': 'CHF'
          },
          'name': '1 EUR ≈ 1,16 CHF',
          'priceFrom': -1000300.00,
          'priceTo': +1163153.06
        }, {
          'id': 'PPY12047',
          'currency': {
            'codeFrom': 'EUR',
            'codeTo': 'CHF'
          },
          'name': '1 CHF ≈ 0,86 EUR',
          'priceFrom': -12000.00,
          'priceTo': +10337.06
        }
      ]
    }, {
      'date': '19.12.2018',
      'operations': [
        {
          'id': 'PPY12046',
          'currency': {
            'codeFrom': 'EUR',
            'codeTo': 'CHF'
          },
          'name': '1 EUR ≈ 1,16 CHF',
          'priceFrom': -1000.00,
          'priceTo': +1163.10
        }, {
          'id': 'PPY12045',
          'currency': {
            'codeFrom': 'EUR',
            'codeTo': 'CHF'
          },
          'name': '1 EUR ≈ 1,16 CHF',
          'priceFrom': -1000300.00,
          'priceTo': +1163153.06
        }
      ]
    }, {
      'date': '21.12.2018',
      'operations': [
        {
          'id': 'PPY12044',
          'currency': {
            'codeFrom': 'EUR',
            'codeTo': 'CHF'
          },
          'name': '1 EUR ≈ 1,16 CHF',
          'priceFrom': -1000.00,
          'priceTo': +1163.10
        }, {
          'id': 'PPY12043',
          'currency': {
            'codeFrom': 'EUR',
            'codeTo': 'CHF'
          },
          'name': '1 EUR ≈ 1,16 CHF',
          'priceFrom': -1000300.00,
          'priceTo': +1163153.06
        }, {
          'id': 'PPY12042',
          'currency': {
            'codeFrom': 'EUR',
            'codeTo': 'CHF'
          },
          'name': '1 CHF ≈ 0,86 EUR',
          'priceFrom': -12000.00,
          'priceTo': +10337.06
        }
      ]
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
