import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TopUpAccountService } from '../../top-up-account.service';
import { take } from 'rxjs/operators';
import { TOP_UP_PATH } from '../../top-up-account.interface';

@Component({
  selector: 'app-top-up-account-index',
  templateUrl: './top-up-account-index.component.html',
  styleUrls: ['./top-up-account-index.component.scss']
})
export class TopUpAccountIndexComponent {
  readonly selectOptions = [
    // {
    //   name: 'Binance Pay',
    //   value: 'binance-start',
    //   route: '/binance-start'
    // },
    {
      name: 'Transfer & Top Up Code',
      value: 'transfer',
      route: '/transfer'
    }
  ];

  isLoading$ = this.topUpService.isLoading$;

  constructor(
    private router: Router,
    private topUpService: TopUpAccountService
  ) {
  }

  onSelectChange(event: any) {
    const value: TOP_UP_PATH.binanceStart | TOP_UP_PATH.transfer = event.target.value;

    if (value === 'transfer') {
      this.router.navigate([`/top-up-account/${ TOP_UP_PATH.transfer }`]);
    } else if (value === 'binance-start') {
      this.topUpService.getBinanceAvailability()
        .pipe(
          take(1)
        )
        .subscribe(
          {
            next: (data) => {
              if (data?.contractId) {
                this.router.navigate([`/top-up-account/${ TOP_UP_PATH.binanceDetails }`]);
              }
            },
            error: err => {
              this.router.navigate([`/top-up-account/${ TOP_UP_PATH.binanceStart }`]);
            }
          }
        );
    }
  }
}
