import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { PaymentReceiptComponent } from '../payment-receipt/payment-receipt.component';
import { TemplateEnterNameComponent } from '../template-enter-name/template-enter-name.component';

import { ModalData, ModalsService } from '../../modals.service';
import { ComingSoonModalsService } from '../../coming-soon-modals.service';
import { OperationsService } from '../../../users/operations/operations.service';
import { PaymentsService } from '../../../services/payments/payments.service';
import { TransfersService } from '../../../+transfers/transfers.service';
import { TransferTemplatesService } from '../../../services/transfer-templates/transfer-templates.service';

// import { OperationType } from '../../../users/organizations.interface';
import { MoneyOperation, PAYMENT_TYPE } from '../../../users/operations/operations.interface';
import { BankPayment } from 'models/payment.interfaces';

/*const SHOW_SUCCESS_INFO = [
  PaymentType.PaymentSepa,
  PaymentType.PaymentSwift,
  PaymentType.PaymentCard,
  PaymentType.PaymentPhone
];

const SHOW_ADDITIONAL_BUTTONS = [
  PaymentType.PaymentSepa,
  PaymentType.PaymentCard,
  PaymentType.PaymentPhone
];*/

@Component({
  selector: 'app-transfer-success',
  templateUrl: './transfer-success.component.html',
  styleUrls: ['./transfer-success.component.scss']
})
export class TransferSuccessComponent implements OnInit {

  @Input() transferType!: PAYMENT_TYPE; // ??? OperationType switch to PaymentType
  @Input() transferReferenceNumber!: string;
  @Input() activePayment!: BankPayment;
  @Input() saveTemplate!: boolean;

  receiptIsLoading = false;

  showSuccessInfo?: boolean;
  showAdditionalButtons?: boolean;

  constructor(
    private modals: ModalsService,
    private comingSoonModal: ComingSoonModalsService,
    private operations: OperationsService,
    private payments: PaymentsService,
    private transfers: TransfersService,
    private templates: TransferTemplatesService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.showSuccessInfo = (
      this.transferType === 'PAYMENT_CARD' ||
      this.transferType === 'PAYMENT_SEPA' ||
      this.transferType === 'PAYMENT_SWIFT' ||
      this.transferType === 'PAYMENT_PHONE' ||
      this.transferType === 'PAYMENT_BINANCE'
      // this.transferType === 'PAYMENT_CONTACT_ANY'
    );
    this.showAdditionalButtons = (
      this.transferType === 'PAYMENT_CARD' ||
      this.transferType === 'PAYMENT_SEPA' ||
      this.transferType === 'PAYMENT_PHONE' ||
      this.transferType === 'PAYMENT_SWIFT'
    );
  }

  openSaveTemplateModal() {
    this.openTemplateEnterNameModal()
      .pipe(
        filter(modalData => modalData.hasResult()),
        map(modalData => modalData.getResult()),
        map(templateName => PaymentsService.getPaymentOperationTemplate(this.activePayment, templateName)),
        switchMap(template => this.templates.saveTemplate(template))
      )
      .subscribe(() => {
        this.templates.updateTemplateList();
        this.closeModal();
      });
  }

  openRecipeModal() {
    if (this.receiptIsLoading === false) {
      this.receiptIsLoading = true;
      this.operations.requestSingleOperation(this.transferReferenceNumber)
        .pipe(
          switchMap(operation => this.openTransferReceiptModal(operation)),
          finalize(() => this.receiptIsLoading = false)
        )
        .subscribe(() => this.closeModal());
    }
  }

  closeModal() {
    this.modals.closeModal(null)
      .then(() => {
        this.payments.resetActivePayment();
        this.transfers.resetRestoredPaymentOperation(); // needs some work ???
        if (this.transferType === PAYMENT_TYPE.PaymentBinance) {
          this.router.navigate(['/top-up-account']);
        }
      });
  }

  private openTransferReceiptModal(receipt: MoneyOperation) {
    return this.modals.openModal({
      component: PaymentReceiptComponent,
      componentProps: {operation: receipt},
      cssClass: 'PaymentReceipt'
    });
  }

  private openTemplateEnterNameModal(): Observable<ModalData<string>> {
    return this.modals.openModal({
      component: TemplateEnterNameComponent,
      cssClass: 'TemplateEnterNameModal'
    });
  }

}
