<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar (click)="goBack()">
      <ion-title>Payment Receipt</ion-title>
      <ion-icon slot="start" name="back-small"></ion-icon>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="PaymentReceipt customScrollbar">
      <div class="PaymentReceipt-list">
        <div class="PaymentReceipt-item PaymentReceipt-item--main-info">
          <ion-list lines="none">
            <ion-item>
              <ion-label>Document No.</ion-label>
              <ion-label class="ion-text-right">№ {{ operation.id }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>Date</ion-label>
              <ion-label class="ion-text-right">
                {{ operation.date ? (operation.date | date: 'dd.MM.yyyy') : (operation.executedAt | date: 'dd.MM.yyyy') }}
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>Amount and currency</ion-label>
              <ion-label class="ion-text-right">{{ operation.amount }} {{ operation.currency.code }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>Type of transfer</ion-label>
              <ion-label class="ion-text-right">{{ operation.paymentType }}</ion-label>
            </ion-item>
            <!--<ion-item>
              <ion-label>Charge for</ion-label>
              <ion-label class="ion-text-right">OUR</ion-label>
            </ion-item>-->
            <ion-item>
              <ion-label>Status</ion-label>
              <ion-label class="ion-text-right">{{ operation.status }}</ion-label>
            </ion-item>
          </ion-list>
        </div>

        <hr>

        <div class="PaymentReceipt-item PaymentReceipt-item--customer">
          <div class="PaymentReceipt-title">Customer information:</div>
          <ion-list lines="none">
            <ion-item>
              <ion-label>Customer account number</ion-label>
              <ion-label class="ion-text-right">{{ operation.fromNumber }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>Customer</ion-label>
              <ion-label class="ion-text-right">{{ operation.fromName }}</ion-label>
            </ion-item>
            <!--<ion-item>
              <ion-label>Reg.No./Pers. ID No.</ion-label>
              <ion-label class="ion-text-right">120573-12709</ion-label>
            </ion-item>-->
          </ion-list>
        </div>

        <hr>

        <div class="PaymentReceipt-item PaymentReceipt-item--beneficiary">
          <div class="PaymentReceipt-title">Beneficiary Information:</div>
          <ion-list lines="none">
            <ion-item>
              <ion-label>Beneficiary account number (IBAN)</ion-label>
              <ion-label class="ion-text-right">{{ operation.referenceNumber }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>Beneficiary</ion-label>
              <ion-label class="ion-text-right">{{ operation.toName }}</ion-label>
            </ion-item>
            <!--<ion-item>
              <ion-label>Reg.No./Pers. ID No.</ion-label>
              <ion-label class="ion-text-right">120573-12709</ion-label>
            </ion-item>-->
          </ion-list>
        </div>

        <div class="PaymentReceipt-item PaymentReceipt-item--beneficiary-bank">
          <div class="PaymentReceipt-title">Beneficiary bank information:</div>
          <ion-list lines="none">
            <ion-item>
              <ion-label>Beneficiary bank name</ion-label>
              <ion-label class="ion-text-right">{{ operation.toBank }}</ion-label>
            </ion-item>
            <!--<ion-item>
              <ion-label>Bank Code</ion-label>
              <ion-label class="ion-text-right">UNLALV2X</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>Address</ion-label>
              <ion-label class="ion-text-right">Abonēšanas maksa, Spilves 25-24, Klienta kods CC19215</ion-label>
            </ion-item>-->
          </ion-list>
        </div>

      </div>

      <div class="PaymentReceipt-status PaymentReceipt-status--completed">
        <span>completed</span>
      </div>

      <div class="PaymentReceipt-buttons">
        <ion-button expand="full" shape="round" fill="outline" color="dark">Save</ion-button>
      </div>

    </div>
  </ion-content>
</ion-app>
