export type BANK_NAME = 'Bcc' | 'Gekkard';

export enum ROLES {
  BCC_LEGAL_PERSON,
  BCC_INDIVIDUAL_PERSON,
  // BCC_BASE,
  // BCC_CORPORATE_OWNER,
  // BCC_CORPORATE_CUSTOMER,
  BCC_CRYPTO,
  GEKKARD,
  GEKKARD_CRYPTO,
  // GEKKARD_BASE,
  CURRENCY_EXCHANGE,
}

export const BANK_ROLE_LIST: Array<[ ROLES, BANK_NAME ]> = [
  [ ROLES.BCC_LEGAL_PERSON, 'Bcc' ],
  [ ROLES.BCC_INDIVIDUAL_PERSON, 'Bcc' ],
  [ ROLES.GEKKARD, 'Gekkard' ]
];

/*export const BANK_ROLE_LIST = [
  ROLES.BCC_LEGAL_PERSON,
  ROLES.BCC_INDIVIDUAL_PERSON,
  ROLES.GEKKARD
];*/
