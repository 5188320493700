import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-select-custom',
  templateUrl: './select-custom.component.html',
  styleUrls: ['./select-custom.component.scss']
})
export class SelectCustomComponent implements OnInit {

  @Input() customHeight?: string;
  @Input() customClass?: string;
  @Input() isDisable = false;
  @Input() hasErrors = false;

  dropDawnIsOpen = false;

  constructor() { }

  @HostListener('click')
  toggleOpenState() {
    this.dropDawnIsOpen = (this.isDisable) ? false : !this.dropDawnIsOpen;
  }

  ngOnInit() {
  }

}
