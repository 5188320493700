import { AbstractControl } from '@angular/forms';
import { number, expirationDate, cvv } from 'card-validator';
import { isCardBINValid } from '../+transfers/+card-transfer/card-transfer.const';

export function validateCardNumber(control: AbstractControl): { cardNumber: 'Invalid card number' } {
  const value = (control.value) ? normalizeCardNumber(control.value) : null;
  return (number(value).isValid) ? null : { cardNumber: 'Invalid card number' };
}

export function validatePapayaCardNumber(control: AbstractControl): { cardNumberPapaya: 'Invalid card number' } {
  const value = (control.value) ? normalizeCardNumber(control.value) : null;
  const searchValue = (value) ? isCardBINValid(value) : -1;
  return (searchValue === 0 && validateCardNumber(control) === null ) ? null : { cardNumberPapaya: 'Invalid card number' };
}

export function validateExpireDate(control: AbstractControl): { expireDate: 'Invalid expire date' } {
  return (expirationDate(control.value).isValid) ? null : { expireDate: 'Invalid expire date' };
}

export function validateCvv(control: AbstractControl): { cvv: 'Invalid cvv' } {
  return (cvv(control.value).isValid) ? null : { cvv: 'Invalid cvv' };
}

function normalizeCardNumber(value: string): string {
  return value
    .replace(/\s+/g, '')
    .replace(/\D/g, '');
}
