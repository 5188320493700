import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { AccountsModule, IsLoadingModule, MultiClientsModule } from '../ui-elements';
import { RightContainerModule } from '../right-container/right-container.module';
import { QRCodeModule } from 'angularx-qrcode';
import { GoogleMapsModule } from '@angular/google-maps';
import { PlasticCardModule } from '../ui-elements/plastic-card/plastic-card.module';
import { NgxMaskModule } from 'ngx-mask';
import { DirectivesModule } from '../directives/directives.module';
import { UsersModule } from '../users/users.module';

import { InternationalizationModule } from '../internationalization/internationalization.module';
import { TopUpAccountRoutingModule } from './top-up-account.routing.module';
import { TopUpAccountStartComponent } from './binance/top-up-account-start/top-up-account-start.component';
import { TopUpAccountIndexComponent } from './binance/top-up-account-index/top-up-account-index.component';
import { TopUpAccountEmailComponent } from './binance/top-up-account-email/top-up-account-email.component';
import { TopUpAccountBinanceVerifiedComponent } from './binance/top-up-account-binance-verified/top-up-account-binance-verified.component';
import { TopUpAccountDetailsComponent } from './binance/top-up-account-details/top-up-account-details.component';
import { TopUpCodeComponent } from './top-up-code/top-up-code.component';
import { TopUpConfirmCodeComponent } from './top-up-code/components/top-up-confirm-code/top-up-confirm-code.component';
import { TopUpEnterCodeComponent } from './top-up-code/components/top-up-enter-code/top-up-enter-code.component';
import { TopUpMapComponent } from './top-up-code/components/top-up-map/top-up-map.component';
import { TopUpRejectCodeComponent } from './top-up-code/components/top-up-reject-code/top-up-reject-code.component';
import { TopUpSuccessCodeComponent } from './top-up-code/components/top-up-success-code/top-up-success-code.component';
import { TopUpFailComponent } from './top-up-fail/top-up-fail.component';
import { TopUpComponent } from './top-up-index/top-up-index.component';
import { TopUpSuccessComponent } from './top-up-success/top-up-success.component';
import { TopUpIncomingSepaComponent } from './top-up-incoming-sepa/top-up-incoming-sepa.component';
import { TopUpAccountConfirmationComponent } from './binance/top-up-account-confirmation/top-up-account-confirmation.component';
import { TopUpInstructionComponent } from './top-up-instruction/top-up-instruction.component';
import { TopUpIternalPaymentComponent } from './top-up-iternal-payment/top-up-iternal-payment.component';

@NgModule({
  declarations: [
    TopUpAccountIndexComponent,
    TopUpAccountStartComponent,
    TopUpAccountEmailComponent,
    TopUpAccountBinanceVerifiedComponent,
    TopUpAccountDetailsComponent,
    TopUpAccountConfirmationComponent,
    TopUpIternalPaymentComponent,
    TopUpCodeComponent,
    TopUpConfirmCodeComponent,
    TopUpEnterCodeComponent,
    TopUpMapComponent,
    TopUpRejectCodeComponent,
    TopUpSuccessCodeComponent,
    TopUpFailComponent,
    TopUpComponent,
    TopUpSuccessComponent,
    TopUpIncomingSepaComponent,
    TopUpInstructionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    IonicModule,
    PipesModule,
    IsLoadingModule,
    RightContainerModule,
    TopUpAccountRoutingModule,
    InternationalizationModule,
    NgOptimizedImage,
    QRCodeModule,
    GoogleMapsModule,
    MultiClientsModule,
    PlasticCardModule,
    IsLoadingModule,
    NgxMaskModule.forRoot(),
    DirectivesModule,
    InternationalizationModule,
    AccountsModule,
    UsersModule
  ]
})
export class TopUpAccountModule { }
