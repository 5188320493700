<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <!--AoT <ion-icon *ngIf="!filterOpen" slot="primary" class="History-filter__icon" name="icon-filter"
                (click)="openFilter()">
      </ion-icon>
      <ion-icon *ngIf="filterOpen" slot="primary" class="History-filter__icon" name="icon-filter-active"></ion-icon> -->
      <ion-title>{{ 'currency_exchange.exchange_history' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>

    <div class="History-panel customScrollbar ion-padding">

      <ng-container *ngFor="let h of history">
        <div class="History-data">
          <span>{{h.date}}</span>
        </div>
        <ion-list class="History-list" lines="full">
          <app-crypto-exchange-transaction-history-item class="app-history-item" *ngFor="let item of h.operations" [item]="item"></app-crypto-exchange-transaction-history-item>
        </ion-list>
      </ng-container>

    </div>

  </ion-content>
</ion-app>
