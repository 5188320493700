import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject, interval, Observable } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';

import { AuthManagerService } from '../../users/auth/auth-manager.service';

const TIMEOUT = (environment.production) ? 30000 : 60000;

export interface NotificationServiceItem {
  fullName: string;
  iban: string;
  address: string;
  email: string;
  uuid: string;
  amount: number;
  currency: 'EUR';
}

/*
*  Start timer Payment detail request
*/
@Injectable({
  providedIn: 'root'
})
export class ServerNotificationsService {

  public postponedNotifications$: Subject<NotificationServiceItem[]> = new Subject();

  constructor(
    private http: HttpClient,
    private auth: AuthManagerService) {

    interval(TIMEOUT)
      .pipe(
        switchMap(() => this.auth.userIsAuth()),
        filter(value => !!value),
        switchMap(() => this.requestNotifications())
      )
      .subscribe((notifications) => this.setNotifications(notifications));
  }

  requestNotifications(): Observable<NotificationServiceItem[]> {
    const url = environment.baseApi + `/v1/payment_details`;
    return this.http.get<NotificationServiceItem[]>(url);
  }

  setNotifications(notifications: Array<NotificationServiceItem>): void {
    console.log(notifications, 'payment_details');
    this.postponedNotifications$.next(notifications);
  }

}
