import { environment } from 'environments/environment';

import { Inject, Injectable } from '@angular/core';
// import { Router, NavigationEnd } from '@angular/router';

import { WINDOW } from 'app/window.providers';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AppGtmService {

  constructor(
    @Inject(WINDOW) private window: Window) {
  }

  private getDataLayer() {
    return (this.window.hasOwnProperty('dataLayer')) ? this.window['dataLayer'] : [];
  }

  pushOnDataLayer(obj: object) {
    this.getDataLayer().push(obj);
  }

  sendGtag(action: 'config' | 'set' | 'event', target: string, data?: any) {
    if (!environment.production) {
      return;
    }

    gtag(action, target, data);
  }

  /*trackRouterEvents(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pushTag({
          event: 'page-view',
          pagePath: event.urlAfterRedirects
        });
      }
    });
  }
  */

/*  init() {
    this.pushTag({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });

    const gtmScript = document.createElement('script');
    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src = `//www.googletagmanager.com/gtm.js?id=${environment.gtmId}`;
    document.head.insertBefore(gtmScript, document.head.firstChild);

    const ifrm = document.createElement('iframe');
    ifrm.setAttribute(
      'src', `//www.googletagmanager.com/ns.html?id=${environment.gtmId}`
    );
    ifrm.style.width = '0';
    ifrm.style.height = '0';
    ifrm.style.display = 'none';
    ifrm.style.visibility = 'hidden';

    const noscript = document.createElement('noscript');
    noscript.id = 'GTMiframe';
    noscript.appendChild(ifrm);

    document.body.insertBefore(noscript, document.body.firstChild);
    this.isLoaded = true;
  }*/


}
