import { Component, OnInit } from '@angular/core';

import { ModalsService } from '../../modals.service';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor(
    private modals: ModalsService) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.modals.closeModal();
  }

}
