<form [formGroup]="form"
  (submit)="onSubmit(form)"
  class="Modal ModalConfirmation__window ConfirmationAlert ion-text-center">
    <div class="ConfirmationAlert__title" [class.error]="hasError">
      <span>{{ 'saveAsTemplate.title' | translate }}</span>
    </div>
    <div class="ConfirmationAlert__text">
      <p>{{ 'saveAsTemplate.text' | translate }}</p>
    </div>
    <div class="ConfirmationAlert__field" [class.error]="hasError">
      <ion-input
          formControlName="name"
          type="text"
          placeholder="Template_1">
      </ion-input>
    </div>
    <div class="ConfirmationAlert__buttons">
      <ion-button
          expand="full" shape="round" fill="outline" color="dark"
          (click)="closeModal()">
        {{ 'logOut.first_button' | translate }}
      </ion-button>
      <ion-button
          expand="full" shape="round" fill="outline" color="success"
          type="submit">
        {{ 'login.alert.button.ok' | translate }}
      </ion-button>
    </div>
</form>
