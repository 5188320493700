import { Component, Input, OnInit } from '@angular/core';
import { HistoryOperationType, MoneyOperation } from '../../../../../users/operations/operations.interface';

@Component({
  selector: 'app-recipe-sender-info',
  templateUrl: './recipe-sender-info.component.html',
  styleUrls: ['./recipe-sender-info.component.scss']
})
export class RecipeSenderInfoComponent implements OnInit {

  @Input() operation?: MoneyOperation;
  @Input() operationType?: HistoryOperationType;

  constructor() { }

  ngOnInit(): void {}

}
