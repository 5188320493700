import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-crypto-skeleton',
  templateUrl: './crypto-skeleton.component.html',
  styleUrls: ['./crypto-skeleton.component.scss']
})
export class CryptoSkeletonComponent {
  @Input() data: any;
  constructor() {
  }
}
