<div class="top-up">
  <main class="top-up__main">
    <div class="top-up__warning-banner">
      {{ 'topup.internal_notice' | translate }}
      <ion-icon name="alert-circle-outline" color="danger"></ion-icon>
    </div>

    <div class="top-up-list" *ngIf="activeAccount as account">
      <div class="top-up-list__item">
        <label for="name" class="top-up-list__label">{{ 'topup.incoming_payment.name' | translate }}</label>
        <span id="name" class="top-up-list__value">{{ account.clientName || account.clientTitle }}</span>
      </div>

      <div class="top-up-list__item">
        <label for="bank" class="top-up-list__label">{{ 'topup.incoming_payment.bank' | translate }}</label>
        <span id="bank" class="top-up-list__value">Papaya Ltd</span>
      </div>

      <div class="top-up-list__item">
        <label for="iban" class="top-up-list__label">{{ 'topup.incoming_payment.iban' | translate }}</label>
        <span id="iban" class="top-up-list__value">{{ account.number }}</span>
      </div>
    </div>

  </main>
</div>

<footer class="top-up__footer">
  <div *ngIf="itWasCopied()" class="top-up__footer-copy-message">{{ 'topup.info_copied' | translate }}</div>

  <button class="top-up__footer-button top-up__footer-button_copy"
          [disabled]="!activeAccount"
          (click)="copyToClipboard()">
    {{ 'topup.clipboard_button' | translate }}
    <ion-icon name="copy-outline" size="small"></ion-icon>
  </button>
</footer>


