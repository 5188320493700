import { environment } from 'environments/environment';

import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
// import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, /*IonicRouteStrategy*/ } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { UsersModule } from './users/users.module';
import { InternationalizationModule } from './internationalization/internationalization.module';
import { DirectivesModule } from './directives/directives.module';
import { MainNavigationModule } from './main-navigation/main-navigation.module';
import { RightContainerModule } from './right-container/right-container.module';
import { ModalsModule } from './modals/modals.module';
import { IntegrationsModule } from './integrations/integrations.module';

import { AppErrorHandlerService } from './app.error.handler.service';

// TODO - Do we really need Fr for decimal pipe?
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { BccSupportModule } from './bcc-support.module';
registerLocaleData(localeFr, 'fr');

import { CustomMissingTranslationHandler } from './internationalization/internationalization.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, environment.baseApi + '/v1/localizations/', '');
}

const notifierConfig: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
};

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    NotifierModule.withConfig(notifierConfig),
    UsersModule,
    InternationalizationModule,
    DirectivesModule,
    MainNavigationModule,
    RightContainerModule,
    ModalsModule,
    BccSupportModule,
    IntegrationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      },
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandlerService },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule {}
