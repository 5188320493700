import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { ModalsService } from '../../modals.service';

@Component({
  selector: 'app-print-statement-preview',
  templateUrl: './print-statement-preview.component.html',
  styleUrls: ['./print-statement-preview.component.scss']
})
export class PrintStatementPreviewComponent implements OnInit {

  @Input() pdf?: SafeResourceUrl;
  @Input() dateFrom?: number;
  @Input() dateTo?: number;

  constructor(
    private modals: ModalsService) {
  }

  ngOnInit() {
  }

  onPrint() {
    const iFrame = document['frames'] ? document['frames']['pdf'] : document.getElementById('pdf');
    const ifWin = iFrame.contentWindow || iFrame;
    iFrame.focus();
    ifWin.print();
    return false;
  }

  closeModal() {
    this.modals.closeModal(null);
  }

}
