<app-is-loading
  [isLoading]="isLoading$ | async">
</app-is-loading>

<ion-app>

  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-icon slot="start" name="back-small"
        (click)="openMovementMoneyHistory()">
      </ion-icon>
      <ion-title>{{ 'export_statement.export_statement' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>

    <div class="ExportStatements">

      <div class="ExportStatements-content">

        <app-client-select
          customClass="client-select-small"
          [operationType]="['PAYMENT_SEPA', 'TOPUP_BY_CARD']"
          [labelName]="'CryptoExchange.PayFrom.SelectAccount' | translate"
          [shortLabel]="true"
          [shortNameInList]="true"
          (selectAccount)="setAccount($event)">
        </app-client-select>

        <!-- <div class="ExportStatements-currency">
          <ion-select [interfaceOptions]="optionsSelectTotal" interface="popover" interfaceOptions="popover">
            <ion-select-option value="EUR" selected>Account EUR</ion-select-option>
            <ion-select-option value="CHF">Account CHF</ion-select-option>
          </ion-select>
        </div> -->

        <div class="ExportStatements-info ion-text-center">
          <span>{{ 'export_statement.choose_dates' | translate }}</span>
        </div>

        <div *ngIf="selectedRange.from || selectedRange.to" class="ExportStatements-date-range">
          <span *ngIf="selectedRange.from === selectedRange.to">
            {{ selectedRange.from | date:'d LLL yyyy' }}
          </span>
          <span *ngIf="selectedRange.from !== selectedRange.to">
            {{ selectedRange.from | date:'d LLL yyyy' }} – {{ selectedRange.to | date:'d LLL yyyy' }}
          </span>
          <ion-icon (click)="clear()" name="close-small"></ion-icon>
        </div>
        <div class="ExportStatements-Select-period">
          <div class="date-range">
            <div class="data-start">
              <small>{{ 'home.start_date' | translate }}:</small>
              <p>
                <span *ngIf="!selectedRange.from">{{ 'CryptoHistory.Filter.Period.Choose' | translate }}</span>
                <span *ngIf="selectedRange.from">{{ selectedRange.from | date:'dd.MM.yyyy' }}</span>
              </p>
              <ion-icon name="datetime" (click)="onSetDataStartEnd($event, 'from')"></ion-icon>
            </div>
            <div class="data-end">
              <small>{{ 'home.end_date' | translate }}:</small>
              <p>
                  <span *ngIf="!selectedRange.to">{{ 'CryptoHistory.Filter.Period.Choose' | translate }}</span>
                  <span *ngIf="selectedRange.to">{{ selectedRange.to | date:'dd.MM.yyyy' }}</span>
              </p>
              <ion-icon name="datetime" (click)="onSetDataStartEnd($event, 'to')"></ion-icon>
            </div>
          </div>
        </div>

        <ion-button (click)="selectAllDates()" expand="full" shape="round" fill="outline" color="dark" size="small">Select All Dates</ion-button>

        <div class="ExportStatements-info ion-text-center">
          <span>{{ 'export_statement.export_format' | translate }}</span>
        </div>
        
        <ion-segment class="export-segment" (ionChange)="segmentChanged($event)">
          <ion-segment-button value="application/pdf" checked>
            <ion-label>.pdf</ion-label>
          </ion-segment-button>
          <ion-segment-button value="application/octet-stream">
            <ion-label>.csv</ion-label>
          </ion-segment-button>
          <ion-segment-button value="application/xml">
            <ion-label>.xml</ion-label>
          </ion-segment-button>
        </ion-segment>

      </div>

      <div class="ExportStatements-buttons">
        <ion-button
          class="buttonCancel" expand="full" shape="round" fill="outline" color="dark" size="small"
          (click)="clear()">
            {{ 'templatesEdit.cancel_button' | translate }}
        </ion-button>
        <ion-button
          class="buttonChooseAll" expand="full" shape="round" fill="outline" color="success" size="small"
          [disabled]="!activeAccount"
          (click)="downloadStatement(activeAccount, selectedRange, contentType)">
            {{ 'templatesEdit.save_button' | translate }}
        </ion-button>
      </div>

    </div>

  </ion-content>

</ion-app>
