import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelectCustomComponent } from './select-custom/select-custom.component';
import { SearchBarComponent } from './search-bar/search-bar.component';

@NgModule({
  declarations: [
    SelectCustomComponent,
    SearchBarComponent
  ],
  exports: [
    SelectCustomComponent,
    SearchBarComponent
  ],
  imports: [
    CommonModule
  ]
})
export class FormCustomElementsModule { }
