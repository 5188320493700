import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocalizationService } from '../localization/localization.service';

@Directive({
  selector: '[appLocalizationIsLoaded]'
})
export class LocalizationIsLoadedDirective {

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private localizations: LocalizationService) {

    localizations.localizationIsLoaded$
      .subscribe(predicate => {
        if (!!predicate && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!predicate && !!this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

}
