import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { isNull, omitBy } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';

import { TransferCardForm } from './+card-transfer/components/card-transfer-form/card-transfer-form.component';
import { BankCardsService } from '../services/bank-cards/bank-cards.service';
import { PAYMENT_TYPE, PaymentOperationRepeat } from '../users/operations/operations.interface';
import { CurrencyCode, PaymentContact } from '../../models/payment.interfaces';
import { TransferBaseForm } from './transfers.interfaces';
import { AccountTransferForm, SepaTransferForm, SwiftTransferForm } from './+bank-transfer/bank-transfer.models';
import { environment } from '../../environments/environment';
import { EURO_COUNTRY_LIST } from '../services/country/country.service';

const TRANSFER_REDIRECT_TABLE: Array<{ paymentRepeatType: PAYMENT_TYPE, route: string }> = [
  {
    paymentRepeatType: PAYMENT_TYPE.PaymentSepa,
    route: '/transfer/bank'
  }, {
    paymentRepeatType: PAYMENT_TYPE.PaymentSwift,
    route: '/transfer/bank'
  }, {
    paymentRepeatType: PAYMENT_TYPE.PaymentCard,
    route: '/transfer/card'
  }, {
    paymentRepeatType: PAYMENT_TYPE.PaymentPhone,
    route: '/transfer/contact'
  }, {
    paymentRepeatType: PAYMENT_TYPE.PaymentByLink,
    route: '/transfer/contact/link'
  }
];

export interface TransferContactForm extends TransferBaseForm {
  accountId: string;
  phoneNumber?: string;
  purpose: string;
}

export interface VerifiedPaymentAmount {
  amount: number;
  currency: CurrencyCode;
}

@Injectable({
  providedIn: 'root'
})
export class TransfersService {

  private restoredPaymentOperationSource = new ReplaySubject<PaymentOperationRepeat>(1);
  restoredPaymentOperation$: Observable<PaymentOperationRepeat> = this.restoredPaymentOperationSource.asObservable();

  static getPaymentContactData(data: TransferContactForm): PaymentContact {
    return {
      account: data.accountId,
      beneficiaryName: null,
      cardNumber: null,
      phoneNumber: data.phoneNumber,
      purpose: (data.purpose) ? data.purpose : 'Other',
      amount: {
        sum: {
          currency: { code: data.currencyCode },
          value: +data.amount
        }
      }
    };
  }

  static getTransferSepaForm(operation: PaymentOperationRepeat): AccountTransferForm {
    const baseFormData = {
      accountId: operation.account,
      accountNumber: operation.iban,
      currencyCode: operation.currencyCode,
      amount: operation.amount
    };
    const sepaData = new SepaTransferForm({
      beneficiaryName: operation.beneficiaryName,
      transferDetail: operation.transferDetails,
      transferComment: operation.purpose
    });
    return new AccountTransferForm(baseFormData, sepaData);
  }

  static getTransferSwiftForm(operation: PaymentOperationRepeat): AccountTransferForm {
    const baseFormData = {
      accountId: operation.account,
      accountNumber: operation.beneficiaryAccount,
      currencyCode: operation.currencyCode,
      amount: operation.amount
    };
    const model = {
      beneficiaryName: operation.beneficiaryName,
      accountNumber: operation.beneficiaryAccount,
      accountId: operation.account,
      amount: operation.amount,
      swiftCode: operation.swiftCode,
      address: operation.address,
      transferComment: operation.purpose,
      // description: operation.transferDetails,
      currencyCode: operation.currencyCode,
      activeCountry: operation.country,
      city: operation.city,
      beneficiaryBank: operation.beneficiaryBank,
      commissionType: operation.commissionType,
      urgency: operation.urgency,
      transferDetail: operation.transferDetails,
      country: operation.country,
      isIntermediaryBank: operation.isIntermediaryBank
    };
    const swiftData = new SwiftTransferForm(model);
    return <AccountTransferForm>omitBy(new AccountTransferForm(baseFormData, swiftData), isNull);
  }

  static getTransferCardForm(operation: PaymentOperationRepeat): TransferCardForm {
    return {
      cardOwner: operation.beneficiaryName,
      cardNumber: BankCardsService.formatCardNumber(operation.cardNumber),
      amount: operation.amount,
      currencyCode: operation.currencyCode,
      transferDetail: operation.transferDetails,
      description: operation.purpose,
      accountId: operation.account,
      fromCardId: operation.fromCardId
    };
  }

  static getTransferContactByPhoneForm(operation: PaymentOperationRepeat): TransferContactForm {
    const model = {
      phoneNumber: operation.phoneNumber.replace(/[^+\d]/g, ''),
      purpose: operation.purpose,
      amount: operation.amount,
      currencyCode: operation.currencyCode,
      accountId: operation.account
    };
    return <TransferContactForm>omitBy(model, isNull);
  }

  static getPositiveBalance(balance: number): number {
    return (balance > 0) ? balance : 0;
  }

  static getTotalAmount(value: number, currency: CurrencyCode): VerifiedPaymentAmount {
    return { amount: value, currency: currency };
  }

  public static isSwiftTransaction(accountNumber: string, currency?: string): boolean {
    const isEuroAccount = TransfersService.isEuropeIBAN(accountNumber);
    const isInternalAccount = (isEuroAccount) ? TransfersService.isInternalBankAccount(accountNumber) : false;
    return (isInternalAccount || !accountNumber)
      ? false
      : !isEuroAccount || (isEuroAccount && currency !== environment.bankSettings.defaultCurrency);
  }

  public static isInternalBankAccount(iban: string): boolean {
    const bankIban = (iban && iban.length >= 2) ? `${iban[0] + iban[1]}` === environment.bankSettings.bankLocationCode : false;
    const nationalBankCode = iban.includes(environment.bankSettings.nationalBankCode);
    return bankIban && nationalBankCode;
  }

  public static isEuropeIBAN(iban: string): boolean {
    const accountCountryCode = (iban && iban.length >= 2) ? `${iban[0] + iban[1]}` : null;
    return EURO_COUNTRY_LIST.some(a => a === accountCountryCode);
  }

  constructor(private router: Router) {}

  private setRestoredPaymentOperation(operation: PaymentOperationRepeat) {
    this.restoredPaymentOperationSource.next(operation);
  }

  repeatTransfer(operation: PaymentOperationRepeat, navigateToRoute = null) {
    const transfer = TRANSFER_REDIRECT_TABLE.find(a => a.paymentRepeatType === operation.type);
    if (transfer) {
      const route = navigateToRoute ? navigateToRoute : transfer.route;
      this.setRestoredPaymentOperation(operation);
      this.router.navigate([ route ]);
    }
  }

  resetRestoredPaymentOperation() {
    const value = new PaymentOperationRepeat();
    this.setRestoredPaymentOperation(value);
  }
}
