import { ChangeDetectorRef, Component, DestroyRef, signal } from '@angular/core';
import { TopUpFailComponent } from '../top-up-fail/top-up-fail.component';
import { CurrencyExchangeService } from 'app/+currency-exchange/currency-exchange.service';
import { BehaviorSubject, catchError, combineLatest, map, of } from 'rxjs';
import { CurrencyCode } from 'models/payment.interfaces';
import { AccountsService } from 'app/services/accounts/accounts.service';
import { ROLES } from 'app/users/users.roles';
import { Account, OperationType } from '../../users/organizations.interface';
import { TopUpTransfer } from '../../../models/top-up.interfaces';
import { ModalsService } from '../../modals/modals.service';
import { TopUpAccountService } from '../top-up-account.service';
import { TopupOperationType } from '../top-up-account.interface';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type AvailablePages = 'Iternal_payment' | 'Incoming_SEPA' | 'Code';

const TOP_UP_BY_TRANSFER: OperationType = 'TOPUP_BY_TRANSFER';

@Component({
  selector: 'app-top-up',
  templateUrl: './top-up-index.component.html',
  styleUrls: ['./top-up-index.component.scss']
})
export class TopUpComponent {

  hideCard = true;
  roles = ROLES;

  activePage!: AvailablePages;
  operationType = TOP_UP_BY_TRANSFER;

  activeAccount?: Account;
  activeAccountIsBlocked?: boolean;
  activeAccountHasBonusProgram?: boolean;

  isSEPAAvailable = signal(false);
  /**
   * Every time we set another account, we have to fetch exchange rates and then extract currencies from them
   * See deprecation notes {@link TopUpComponent.getAccountCurrencyData}
   */
  currencies$: BehaviorSubject<CurrencyCode[]> = new BehaviorSubject([]);
  accountTransferData$: BehaviorSubject<TopUpTransfer> = new BehaviorSubject(null);
  isLoading$ = combineLatest([
    this.topUpService.isLoading$,
    this.accountsService.isLoading$
  ]).pipe(map(([topUpServiceLoading, accountsServiceLoading]) => topUpServiceLoading || accountsServiceLoading));

  topUpByCardIsNotAvailable = false;
  accountIsEmpty = false;

  constructor(
    private modals: ModalsService,
    private topUpService: TopUpAccountService,
    private currencyExchangeService: CurrencyExchangeService,
    private accountsService: AccountsService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private destroyRef: DestroyRef
  ) {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        this.activePage = !!params['fromInstruction'] ? 'Incoming_SEPA' : 'Iternal_payment';
      });
  }

  setActiveClient(account: Account) {
    if (this.activeAccount?.id === account.id) {
      return;
    }

    this.activeAccount = account;
    this.activeAccountIsBlocked = (account.status === 'BLOCKED');
    this.topUpByCardIsNotAvailable = !account.availableOperations.some(a => a === TOP_UP_BY_TRANSFER);
    this.activeAccountHasBonusProgram = !!account.activeBonusProgram;
    this.isSEPAAvailable.set(!!account.partnersAccounts.length)
    if (!this.activeAccountIsBlocked && this.topUpByCardIsNotAvailable && this.activePage === 'Iternal_payment') {
      this.openAccessDeniedModal()
        .subscribe(() => this.activePage = 'Incoming_SEPA');
    }
    // this.topUpService.getTopUpTransferModel(account)
    //   .subscribe(topUpTransferModel => {
    //     this.accountTransferData$.next({
    //       ...this.accountTransferData$.getValue(),
    //       ...topUpTransferModel
    //     });
    //   });

    this.currencyExchangeService.requestExchangeRates(this.activeAccount?.id).pipe(
      map(rates => rates.reduce<Set<CurrencyCode>>((acc, rate) => acc.add(rate.currencyTo), new Set<CurrencyCode>())),
      map(ratesSet => Array.from(ratesSet))
    ).subscribe(rates => this.currencies$.next(rates));
  }

  setActivePage(pageName: AvailablePages) {
    this.activePage = pageName;
    this.operationType = TopupOperationType[pageName];
  }

  onEmptyAccounts(event: boolean) {
    this.accountIsEmpty = event;
  }

  closeTopUpModal() {
    this.modals.closeModal();
  }

  /**
   * Get account SWIFT data by chosen currency.
   * For now, it also filters redundant fields.
   *
   * @deprecated Back should send all available accounts and currencies at once. Change it when back change API.
   * Also, { title: string, value: string } is not a pleasant response format, especially when some fields are need to be
   * filtered. Otherwise, these two ugly filters will stay and if anything changes, it'll be bad.
   *
   * @param currency - currencyCode to fetch
   */
  getAccountCurrencyData(currency: CurrencyCode): void {
    // If there are no 'PAYMENT_SWIFT' in available options, we just disable SWIFT top up possibility.
    if (!this.activeAccount?.availableOperations?.includes('PAYMENT_SWIFT')) {
      this.accountTransferData$.next({
        ...this.accountTransferData$.getValue(),
        swift: null
      });

      this.cdr.detectChanges();
      return;
    }

    this.accountsService.getAccountById(this.activeAccount.id, currency).pipe(
      map(accountData => ({
        ...this.accountTransferData$.getValue(),
        swift: accountData
          .filter(item =>
            !item.title.includes('payment system')
            && item.title !== 'Beneficiary Name'
            && item.title !== 'The Beneficiary Bank'
            && item.title !== 'The Beneficiary Bank Bic')
          .filter(item => currency === 'EUR' || item.title !== 'Account number' || !item.value.startsWith('MT'))
          .map(item => {
            if (item.title === 'Account number') {
              item.title = 'Account for top up';
            }
            let replaced = item.title.replace('Currency cloud ', '');
            return {
              title: replaced[0].toUpperCase() + replaced.slice(1),
              value: item.value
            };
          })
      })),
      catchError(() => of({
        ...this.accountTransferData$.getValue(),
        swift: null
      }))
    ).subscribe(accountTransferData => this.accountTransferData$.next(accountTransferData));

    this.cdr.detectChanges();
  }

  private openAccessDeniedModal() {
    return this.modals.openModal({
      component: TopUpFailComponent,
      componentProps: {message: 'This operation is not available for the selected account'},
      cssClass: 'TopUpFail'
    });
  }

}
