import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-crypto-send-internal-templates-item',
  templateUrl: './crypto-send-internal-templates-item.component.html',
  styleUrls: ['./crypto-send-internal-templates-item.component.scss']
})
export class CryptoSendInternalTemplatesItemComponent implements OnInit {

  @Input() item;

  constructor() { }

  ngOnInit() {
  }

  showFullInfo(item: any): void {
    console.log('showFullInfo', item);
  }

}
