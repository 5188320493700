export enum TOP_UP_PATH {
  home = '',
  binanceStart = 'binance-start',
  binanceEmail = 'binance-email',
  binanceVerified = 'binance-verified',
  binanceDetails = 'binance-details',
  binanceConfirmation = 'binance-confirmation',
  transfer = 'transfer',
  instruction = 'instruction',
}

export enum INSTITUTION_PRODUCT_ID {
  blackCatCard = 'BLACK_CAT_CARD',
  manerio = 'MANERIO'
}

export interface IExtraState {
  clientName: string;
  accountNumber: string; // id
  currency: string;
  amount: string;
}

export interface ICreateBinanceContractResponse {
  applicationId: number;
  errorMessage: string | null;
  success: boolean;
}

export interface ITopUpBinanceResponse {
  referenceNumber: 'string';
  status: 'string';
}

export interface IBinanceRate {
  rateValue: number;
}

export interface IBinanceDeepLinkResponse {
  email: string;
  merchantContractCode: string;
  qrcodeContent: string;
  qrcodeLink: string;
  deepLink: string;
  preContractId: number | null;
  status: string;
  chatId: number | null;
  messageId: number;
  binanceContractId: number | null;
}

export interface IBinanceContractResponse {
  binanceContractAppId: string | null;
  contractId: string | null;
  errorMessage: string | null;
  success: boolean;
}

export interface IBinanceEmailConnect {
  applicationId: number;
}

export interface MarkerPoint {
  id: number;
  name: string;
  description: string;
  address: string;
  website: string;
  phone: string;
  workFrom: string;
  workTo: string;
  lat: number;
  lng: number;
}

export interface RetrieveCode {
  code: string;
  amount: number;
  currency: string;
}

export interface EnterCodeSubmit {
  page: string;
  code: string;
}

export interface FormValue {
  topupCode: string;
}

export interface ICommissionRequest {
  debitAccountNumber: string;
  creditAccountNumber: null;
  amountSell: number | string;
  currencyCodeSell: 'XDT';
  amountBuy: number | string;
  currencyCodeBuy: 'EUR';
  rate: number | string;
  conversionDealType: 'BINANEXC';
}

export enum TopupOperationType {
  Card = 'TOPUP_BY_CARD',
  Transfer = 'TOPUP_BY_TRANSFER',
  Code = 'TOPUP_BY_CODE',
  Binance = 'TOPUP_BY_BINANCE',
  SEPA = 'TOPUP_BY_SEPA',
}

export enum ContractStatusesType {
  New = 'NEW',
  Waiting = 'WAITING_BINANCE_AUTHORIZATION',
  Finish = 'FINISH',
  Reject = 'REJECT'
}
