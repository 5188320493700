import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CryptoSettingsComponent } from './crypto-settings/crypto-settings.component';
import { CryptoSkeletonComponent } from 'app/right-container/crypto-skeleton/crypto-skeleton.component';
import { CryptoCurrencySliderComponent } from './crypto-currency-slider/crypto-currency-slider.component';
import { SvgLineChartComponent } from './svg-line-chart/svg-line-chart.component';
import { CryptoActionsComponent } from './crypto-actions/crypto-actions.component';
import { CryptoBalanceComponent } from './crypto-balance/crypto-balance.component';
import { CryptoMoreInfoComponent } from './crypto-more-info/crypto-more-info.component';
import { CryptoAddressComponent } from './crypto-address/crypto-address.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { CryptoAmountComponent } from './crypto-amount/crypto-amount.component';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode'; TODO is not compatible with Angular Ivy
import { IonicModule } from '@ionic/angular';
import { TransfersUiElementsModule } from 'app/ui-elements/transfer-amount/transfers-ui-elements.module';
import { TranslateModule } from '@ngx-translate/core';
import { CryptoConfirmationComponent } from './crypto-confirmation/crypto-confirmation.component';
import { CountdownTimerModule } from 'app/ui-elements/countdown-timer/countdown-timer.module';


@NgModule({
  declarations: [
    CryptoSkeletonComponent,
    CryptoCurrencySliderComponent,
    SvgLineChartComponent,
    CryptoActionsComponent,
    CryptoBalanceComponent,
    CryptoMoreInfoComponent,
    CryptoAddressComponent,
    QrCodeComponent,
    CryptoAmountComponent,
    CryptoSettingsComponent,
    CryptoConfirmationComponent
  ],
  imports: [
    CommonModule,
    // NgxQRCodeModule, TODO is not compatible with Angular Ivy
    IonicModule,
    TransfersUiElementsModule,
    TranslateModule,
    CountdownTimerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CryptoSkeletonComponent,
    // CryptoCurrencySliderComponent,
    SvgLineChartComponent,
    CryptoActionsComponent,
    CryptoBalanceComponent,
    CryptoMoreInfoComponent,
    CryptoAddressComponent,
    QrCodeComponent,
    CryptoAmountComponent,
    CryptoSettingsComponent,
    CryptoConfirmationComponent
  ]
})
export class ComponentsModule { }
