<section [ngStyle]="{'display': 'flex'}">
  <section [ngStyle]="{'position': 'relative', 'width': '100%'}">

    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>

        <ion-title>{{ 'topup.title' | translate }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-item class="select" lines="none">
      <ion-select
          [interfaceOptions]="{size: 'cover'}"
          class="always-flip"
          interface="popover"
          justify="space-between"
          toggleIcon="chevron-down-sharp"
          value="transfer"
          [placeholder]="'topup.binance.connect_details_currency_placeholder' | translate"
          (ionChange)="onSelectChange($event)"
      >
        <ion-select-option *ngFor="let option of selectOptions"
                           [value]="option.value">{{ option.name }}</ion-select-option>
      </ion-select>
    </ion-item>

    <app-is-loading [isLoading]="isLoading$ | async"></app-is-loading>

    <ion-router-outlet [ngStyle]="{'margin-top': '112px'}"></ion-router-outlet>

  </section>

  <app-right-container
      componentName="movement-money-history">
  </app-right-container>
</section>
