import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { RightContainerService } from '../../../right-container.service';
import { OperationsService } from '../../../../users/operations/operations.service';

import { MoneyOperation } from '../../../../users/operations/operations.interface';

@Component({
  selector: 'app-movement-money-history-recipe',
  templateUrl: './movement-money-history-recipe.component.html',
  styleUrls: ['./movement-money-history-recipe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MovementMoneyHistoryRecipeComponent implements OnInit {

  @Input() operation?: MoneyOperation;

  isLoading$ = this.operations.isLoading$;

  constructor(
    private rightContainer: RightContainerService,
    private operations: OperationsService) {
  }

  ngOnInit() { }

  goBack() {
    this.rightContainer.setRightPanel('movement-money-history');
  }

  onSaveReceipt(referenceNumber: string) {
    this.operations.downloadSingleOperation(referenceNumber)
      .subscribe();
  }

}
