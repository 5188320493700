import { Component, OnInit } from '@angular/core';
import { ROLES } from '../../users/users.roles';
// import { ParamsService } from '../../../shared/services/params.service';

@Component({
  selector: 'app-info-transfer-confirmation',
  templateUrl: './info-transfer-confirmation.component.html',
  styleUrls: ['./info-transfer-confirmation.component.scss']
})
export class InfoTransferConfirmationComponent implements OnInit {
  roles = ROLES;
  transferType: string;

  constructor(/*private paramService: ParamsService*/) {
    // this.transferType = this.paramService.getTransferInfo();
  }

  ngOnInit() {}

}
