<div class="TopUpSucceess ion-text-center">

  <b>{{ 'identityStatus.warning_title' | translate }}!</b>

  <p>{{ 'topup_scan.title' | translate }}</p>

  <div class="TopUpSucceess-button">
    <ion-button expand="full" shape="round" fill="outline" (click)="goBack()">{{ 'topup_scan.cancelBtnText' | translate }}</ion-button>
    <ion-button expand="full" shape="round" fill="outline" color="success" (click)="onSubmit()">{{ 'topup_scan.okBtnText' | translate }}</ion-button>
  </div>

</div>
