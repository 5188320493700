import { CurrencyModel } from '../organizations.interface';
import { CurrencyCode, SwiftCommission, SwiftUrgency } from 'models/payment.interfaces';

type OperationStatus = 'PROCESSING' | 'COMPLETED' | 'BANK_CANCELLED' | 'INSUFFICIENT_FUNDS' | 'REFUND' | 'HOLD' | 'UNKNOWN';
export type BankPaymentType = 'TRANSFER' | 'CARD_OPERATION' | 'HOLD' | 'CONVERSION' | 'SEPA' | 'SWIFT' | 'UNKNOWN';

export enum PAYMENT_TYPE {
  PaymentSepa = 'PAYMENT_SEPA',
  PaymentSwift = 'PAYMENT_SWIFT',
  PaymentCard = 'PAYMENT_CARD',
  PaymentPhone = 'PAYMENT_PHONE',
  PaymentByLink = 'PAYMENT_BY_LINK',
  PaymentBinance = 'PAYMENT_BINANCE'
}

export type OpenApiDataType = 'TO_CARD' | 'INTERNAL';
export type HistoryOperationType = 'TOPUP' | 'TO_CARD' | 'SWIFT' | 'SEPA' | 'PURCHASE' | 'CASH_WITHDRAWAL';

export interface OpenApiData {
  externalOwnerID: string;
  fromCardPan: string;
  subType: string;
  toCard: string;
  toPhone: string;
  type: OpenApiDataType;
}

export interface MoneyOperationRequest {
  content: Array<MoneyOperation>;
  maxPageNumber: number;
  pageSize: number;
  pageNumber: number;
}

export interface MoneyOperation {
  id: string;
  amount: number;
  description: string;
  fee: number;
  status: OperationStatus;
  paymentType: BankPaymentType;
  fromNumber: string; // IBAN
  fromName: string;
  fromBank: string;
  toNumber: string; // IBAN
  toName: string;
  toBank: null;
  currency: CurrencyModel;
  clientId: string;
  executedAt: string;
  referenceNumber: string;
  paymentToRepeat: PaymentOperationRepeat;
  openapiData: OpenApiData;
  operationType: string;
}

export class PaymentOperationRepeat {
  account: string;
  amount: number;
  beneficiaryName: string;
  cardNumber: string;
  currencyCode: CurrencyCode;
  iban: string;
  fromCardId: string;
  fromCardPan: string;
  phoneNumber: string;
  purpose: string;
  transferDetails: string;
  type: PAYMENT_TYPE;
  swiftCode?: string;
  country?: string;
  city?: string;
  address?: string;
  beneficiaryAccount?: string;
  beneficiaryBank?: string;
  commissionType?: SwiftCommission;
  urgency?: SwiftUrgency;
  transferDetail?: string;
  isIntermediaryBank?: boolean;
  intermediarySwift?: string;
  intermediaryAccount?: string;
  intermediaryBank?: string;
}

export interface PaymentOperationTemplate {
  id?: number;
  amount: number;
  beneficiaryName?: string;
  currencyCode: CurrencyCode;
  fromAccountId?: string;
  fromCardId?: string;
  fromCardPan?: string;
  purpose: string;
  title: string;
  toCardNumber?: string;
  toIban?: string;
  toPhoneNumber?: string;
  transferDetails?: string;
  type: PAYMENT_TYPE;
  country?: string;
  city?: string;
  address?: string;
  swiftCode?: string;
  beneficiaryBank?: string;
  intermediaryBank?: string;
  intermediarySwift?: string;
  intermediaryAccount?: string;
  commissionType?: SwiftCommission;
  urgency?: SwiftUrgency;
  beneficiaryAccount: string;
}
