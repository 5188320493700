import { Component, OnInit } from '@angular/core';
import { interval, Observable, Subscription, switchMap, take } from 'rxjs';
import { Router } from '@angular/router';
import { TopUpAccountService } from '../../top-up-account.service';
import { ModalData, ModalsService } from '../../../modals/modals.service';

import { ICommissionRequest, IExtraState, TOP_UP_PATH, TopupOperationType } from '../../top-up-account.interface';
import { PaymentSuccessResponse } from '../../../../models/payment.interfaces';
import {
  TransferConfirmCodeComponent
} from '../../../modals/components/transfer-confirm-code/transfer-confirm-code.component';
import { PaymentsService } from '../../../services/payments/payments.service';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { TransferSuccessComponent } from '../../../modals/components/transfer-success/transfer-success.component';

@Component({
  selector: 'app-top-up-account-confirmation',
  templateUrl: './top-up-account-confirmation.component.html',
  styleUrls: ['./top-up-account-confirmation.component.scss']
})
export class TopUpAccountConfirmationComponent implements OnInit {
  extraData!: IExtraState;
  rate!: number;
  amount!: string | number;
  papayaAccountId!: string;
  exchangeAmount!: string;
  totalAmount!: number;
  fee = 0;
  timer!: Subscription;
  higherLimit!: boolean;

  private operationData: ICommissionRequest;
  private rateTimer = 300; // 5 min

  constructor(
    private router: Router,
    private topUpAccountService: TopUpAccountService,
    private modals: ModalsService,
    private payments: PaymentsService
  ) {
  }

  ngOnInit() {
    this.getExtras();
    this.getRate();
    this.startTimer();
  }

  startTimer() {
    this.timer = interval(1000).subscribe(() => {
      this.updateTimer();
    });
  }

  onClickCancel() {
    this.timer.unsubscribe();
    this.router.navigate(
      [`/top-up-account/${ TOP_UP_PATH.binanceDetails }`]);
  }

  formatTimer(): string {
    const minutes: number = Math.floor(this.rateTimer / 60);
    const seconds: number = this.rateTimer % 60;
    return `${ this.formatDigit(minutes) }:${ this.formatDigit(seconds) }`;
  }

  topUpConfirm() {
    this.payments.requestBinanceTopUp(this.operationData)
      .pipe(
        switchMap(response => this.openTransferConfirmationModal(
          this.operationData,
          PaymentsService.getTokenFromConfirmCodeResponse(response)
        )),
        filter((modal: ModalData) => {
          const modalData = (modal.hasResult()) ? modal.getResult() : null;
          return modalData && modalData.hasOwnProperty('status') && modalData.status === 'ok';
        }),
        switchMap(response => {
          return this.openTransferSuccessModal(this.operationData, response.getResult().referenceNumber);
        }),
        catchError((err) => {
          this.topUpAccountService.openErrModal().subscribe();
          return err;
        })
      )
      .subscribe();
  }

  private updateTimer() {
    if (this.rateTimer > 0) {
      this.rateTimer--;
    } else {
      this.onClickCancel();
    }
  }

  private formatDigit(digit: number): string {
    return digit < 10 ? `0${ digit }` : `${ digit }`;
  }

  private openTransferSuccessModal(paymentData: ICommissionRequest, referenceNumber: string): Observable<ModalData<'save-template'>> {
    const modalOpts = {
      component: TransferSuccessComponent,
      componentProps: {
        transferType: 'PAYMENT_BINANCE',
        transferReferenceNumber: referenceNumber,
        activePayment: paymentData,
        saveTemplate: !(paymentData as any).originatorData,
      },
      cssClass: 'ModalFullScreen'
    };
    return this.modals.openModal(modalOpts);
  }

  private openTransferConfirmationModal(data: any, token: string): Observable<ModalData<PaymentSuccessResponse>> {
    return this.modals.openModal({
      component: TransferConfirmCodeComponent,
      componentProps: {
        operationType: TopupOperationType.Binance,
        transferData: data,
        transferToken: token
      },
      cssClass: 'TransferConfirmationModal ModalConfirmation'
    });
  }

  private getExtras() {
    this.extraData = this.router.getCurrentNavigation().extras.state as IExtraState;

    this.amount = this.extraData.amount;
    this.papayaAccountId = this.extraData.accountNumber;
  }

  private getRate() {
    this.topUpAccountService.getBinanceRate()
      .pipe(
        take(1),
        tap((rate => this.rate = rate.rateValue)),
        switchMap(() => this.topUpAccountService.requestCryptoBalances([this.papayaAccountId])
          .pipe(map((data): string => data.accounts[0].crypto[0].wallet)))
      )
      .subscribe({
        next: wallet => {
          this.getCommission(wallet);
        },
        error: () => this.topUpAccountService.openErrModal().subscribe()
      });
  }

  private getCommission(wallet: string) {
    const rate = this.rate;
    const amount = typeof this.amount === 'string' ? parseFloat(this.amount) : this.amount;

    this.exchangeAmount = (amount * rate).toFixed(8);
    this.higherLimit = +this.exchangeAmount > 1000;

    this.operationData = {
      debitAccountNumber: wallet,
      creditAccountNumber: null,
      amountSell: this.exchangeAmount,
      currencyCodeSell: 'XDT',
      amountBuy: this.amount,
      currencyCodeBuy: 'EUR',
      rate: 1 / this.rate,
      conversionDealType: 'BINANEXC'
    };

    this.topUpAccountService.getCommission(this.operationData)
      .pipe(
        take(1),
      )
      .subscribe({
        next: res => {
          this.fee = res.commission;
          this.totalAmount = res.total;
        },
        error: () => this.topUpAccountService.openErrModal().subscribe()
      });
  }
}
