import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';

import { RightContainerService } from '../../../right-container.service';

import { TrustedClient } from '../../../../users/organizations.interface';
import { MoneyOperation, PaymentOperationRepeat } from '../../../../users/operations/operations.interface';

@Component({
  selector: 'app-movement-money-history-item',
  templateUrl: './movement-money-history-item.component.html',
  styleUrls: ['./movement-money-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MovementMoneyHistoryItemComponent implements OnInit, OnChanges {

  @Input() item?: MoneyOperation;
  @Input() trustedClients?: Array<TrustedClient>;
  @Input() isLoading?: Observable<boolean>;

  @Output() repeatOperation = new EventEmitter<PaymentOperationRepeat>();

  itemIsOpen = false;
  itemColor = null;

  itemAmount!: number;

  isPaymentToContact!: boolean;

  private static getTotalAmount(amount: number, fee: number): number {
    const absAmount = Math.abs(amount);
    const totalAmount = (amount < 0) ? absAmount + fee : absAmount;
    return Number(totalAmount.toFixed(2));
  }

  constructor(
    private rightContainer: RightContainerService) {
  }

  ngOnInit() {
    this.itemAmount = MovementMoneyHistoryItemComponent.getTotalAmount(this.item.amount, this.item.fee);
    // console.log(this.item.amount, this.item.fee, this.itemAmount);
    this.isPaymentToContact = this.item && this.item.paymentToRepeat && this.item.paymentToRepeat.type === 'PAYMENT_PHONE';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('trustedClients')) {
      const transactionClientId = this.item.clientId;
      const foundClient = this.trustedClients.find(a => a.clientId === transactionClientId);
      this.itemColor = (foundClient && foundClient.color && this.trustedClients.length > 1)
        ? foundClient.color.toLocaleLowerCase()
        : null;
    }
  }

  openPaymentRecipe(item) {
    this.rightContainer.setRightPanel('movement-money-history-recipe');
    this.rightContainer.paymentReceiptItem = item;
  }

/*  getIntegerPart() {
    const price = Math.abs(this.item.amount);
    if (this.item.amount % 1 === 0) {
      return price;
    }
    const price1 = Math.abs(this.item.amount).toString().replace('.', ',');
    return price1.substring(0, price1.indexOf(',') + 1);
  }

  getDecimalPart() {
    if (this.item.amount % 1 === 0) {
      return '';
    }
    const price = this.item.amount.toString();
    return price.substring(price.indexOf('.') + 1, price.length);
  }*/

  repeat(operation: PaymentOperationRepeat) {
    // console.log(333, operation);
    this.repeatOperation.emit(operation);
  }

  showFullInfo() {
    this.itemIsOpen = !this.itemIsOpen;
  }

}
