<section class="page">
  <div class="page__content">
    <div class="top">
      <div class="card">
        <div class="card__text-container">
          <div class="card__title-container">
            <h3 class="title card__title card__title_first-part">{{ "topup.binance.info_header_text1" | translate }}</h3>
            <h3 class="title card__title card__title_second-part">{{ "topup.binance.info_header_text2" | translate }}</h3>
          </div>

          <p class="card__description">
            {{ "topup.binance.info_footer_text" | translate }}
          </p>
        </div>

        <div class="card__image-container">
          <img ngSrc="../../../../assets/custom-icons/topup-binance-logo.svg" alt="binance" height="80" width="80">
          <img ngSrc="../../../../assets/custom-icons/topup-binance-arrows.svg" alt="arrows" height="32" width="32">
          <img ngSrc="../../../../assets/custom-icons/topup-balckcat-logo.svg" alt="arrows" height="80" width="80">
        </div>
      </div>

      <div class="steps">
        <h3 class="title steps__title">{{ "topup.binance.info_howItWorks_header" | translate }}</h3>

        <div class="steps__container">
          <div class="steps__line"></div>

          <div *ngFor="let step of stepsData" class="step">
            <div class="step__circle">
              <img [src]="step.img" [alt]="step.alt">
            </div>

            <div class="step__text-container">
              <h5 class="step__title">{{ step.title }}</h5>

              <p class="step__description">{{ step.description  | translate }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="button-container">
        <ion-button expand="full" shape="round" fill="solid" size="large" color="success"
                    (click)="startBinanceConnect()">
          <ion-icon slot="start" name="arrow-white"></ion-icon>
          {{ 'topup.binance.info_startButton' | translate }}
        </ion-button>
      </div>
    </div>

  </div>
</section>
