import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { PermissionService } from './permission.service';

@Directive({
  selector: '[appActiveBankClass]'
})
export class ActiveBankClassDirective {

  constructor(
    private render: Renderer2,
    private hostElement: ElementRef,
    private permissions: PermissionService) {

    permissions.getActiveBankName()
      .subscribe((value) => render.addClass(hostElement.nativeElement, value));
  }

}
