// import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { Route, PreloadingStrategy } from '@angular/router';

import { Observable, timer, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppPreloadingStrategy implements PreloadingStrategy {

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // console.log('PRELOAD', route.path, route.data);
    if (/*environment.production &&*/ route.data && route.data['preload']) {
      if (route.data['delay']) {
        return timer(5000).pipe(
          flatMap(() => load())
        );
      }
      return load();
    } else {
      return of(null);
    }
  }

}
