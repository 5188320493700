import { Component } from '@angular/core';

@Component({
  selector: 'app-exchange-money-skeleton',
  templateUrl: './exchange-money-skeleton.component.html',
  styleUrls: ['./exchange-money-skeleton.component.scss']
})
export class ExchangeMoneySkeletonComponent {
  constructor() {
  }
}
