import { Component } from '@angular/core';

@Component({
  selector: 'app-no-accounts-available',
  templateUrl: './no-accounts-available.component.html',
  styleUrls: ['./no-accounts-available.component.scss']
})
export class NoAccountsAvailable {
  constructor() {
  }
}
