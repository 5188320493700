export const PAYMENTS_DETAILS: Map<string, string> = new Map([
  ['transferDetails.first_element', 'Tax - Property'],
  ['transferDetails.second_element', 'Top up deposit'],
  ['transferDetails.third_element', 'Transfer details'],
  ['transferDetails.fourth_element', 'The insurance - Real estate, policy'],
  ['transferDetails.fifth_element', 'Other'],
  ['transferDetails.sixth_element', 'The insurance - Health, policy'],
  ['transferDetails.seventh_element', 'Commission payment'],
  ['transferDetails.eighth_element', 'The insurance - Transport, policy'],
  ['transferDetails.ninth_element', 'Top up my account'],
  ['transferDetails.tenth_element', 'Tax - Income'],
  ['transferDetails.eleventh_element', 'The insurance - Vital, policy'],
  ['transferDetails.twelfth_element', 'Tax - Social'],
  ['transferDetails.tertiary_element', 'Loan repayment under the agreement'],
  ['transferDetails.fourteenth_element', 'Utilities'],
  ['transferDetails.fifteenth_element', 'Top up my card'],
  ['transferDetails.sixteenth_element', 'Top up card']
]);
